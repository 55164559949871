var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$q.screen.width <= 1366 ? _c('div', [_c('v-no-results', {
    attrs: {
      "image": "pc",
      "title": _vm.trans('warning_text.use_pc_title'),
      "text": _vm.trans('warning_text.use_pc_text')
    }
  })], 1) : _c('div', [_c('confirm', {
    attrs: {
      "title": _vm.trans('notice.confirm'),
      "active": _vm.confirmPopup,
      "on-hide": _vm.cancelDeactivate,
      "light-text": true
    }
  }, [_c('div', {
    staticClass: "confirm-text"
  }, [_vm._v("\n        " + _vm._s(_vm.trans('funding.funding-confirm')) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "popup-btn-control"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-150 mr-10",
    attrs: {
      "color": "red",
      "label": _vm.trans('button.cancel')
    },
    on: {
      "click": _vm.cancelDeactivate
    }
  }), _vm._v(" "), _c('q-btn', {
    staticClass: "s-btn--w-150",
    attrs: {
      "color": "green",
      "label": _vm.trans('button.approve')
    },
    on: {
      "click": _vm.confirm
    }
  })], 1)]), _vm._v(" "), _c('q-dialog', {
    attrs: {
      "value": _vm.calculating,
      "persistent": ""
    }
  }, [_c('div', {
    staticClass: "calc-loader"
  }, [_c('h4', [_vm._v(_vm._s(_vm.trans('notice.funding-calc-process')))]), _vm._v(" "), _c('q-spinner', {
    staticClass: "calc-spinner",
    attrs: {
      "color": "blue",
      "size": "3em"
    }
  }), _vm._v(" "), _c('div', [_vm._v(_vm._s(_vm.trans('notice.funding-calc-process-warn')))])], 1)]), _vm._v(" "), _c('q-dialog', {
    attrs: {
      "value": _vm.denyStatus,
      "persistent": ""
    }
  }, [_c('div', {
    staticClass: "calc-loader"
  }, [_c('h4', [_vm._v(_vm._s(_vm.trans('notice.funding-deny-process')))]), _vm._v(" "), _c('q-spinner', {
    staticClass: "calc-spinner",
    attrs: {
      "color": "blue",
      "size": "3em"
    }
  }), _vm._v(" "), _c('div', [_vm._v(_vm._s(_vm.trans('notice.funding-calc-process-warn')))])], 1)]), _vm._v(" "), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('q-select', {
    attrs: {
      "options": _vm.availableLocalities,
      "option-value": "id",
      "option-label": "name",
      "loading": _vm.anyIsLoading,
      "label": _vm.trans('label.locality')
    },
    model: {
      value: _vm.selectedLocality,
      callback: function callback($$v) {
        _vm.selectedLocality = $$v;
      },
      expression: "selectedLocality"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-6 funding-calc-panel"
  }, [!_vm.confirmed && !_vm.denied ? _c('div', [_c('q-btn', {
    staticClass: "s-btn--w-150 mr-20",
    attrs: {
      "color": "blue",
      "disable": !!_vm.calculating || !!_vm.calculated,
      "label": _vm.trans('button.calc')
    },
    on: {
      "click": _vm.calculate
    }
  }), _vm._v(" "), _vm.calculated ? _c('q-btn', {
    staticClass: "s-btn--w-150 mr-20",
    attrs: {
      "color": "green",
      "disable": !!_vm.confirmStatus,
      "label": _vm.trans('button.approve')
    },
    on: {
      "click": _vm.confirmPopupShow
    }
  }) : _vm._e(), _vm._v(" "), _vm.calculated ? _c('q-btn', {
    staticClass: "s-btn--w-150",
    attrs: {
      "disable": !!_vm.confirmStatus,
      "color": "red",
      "label": _vm.trans('button.cancel')
    },
    on: {
      "click": _vm.cancel
    }
  }) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.confirmed && !_vm.denied ? _c('div', [_c('q-btn', {
    staticClass: "s-btn--w-150 mr-20",
    attrs: {
      "color": "green",
      "label": _vm.trans('button.export'),
      "loading": _vm.loading
    },
    on: {
      "click": _vm.exportu
    }
  })], 1) : _vm._e()])]), _vm._v(" "), _c('div', {
    staticClass: "row date-panel"
  }, [_vm.currentYear ? _c('div', {
    staticClass: "col-md-2 col-sm-4"
  }, [_c('q-select', {
    attrs: {
      "disable": _vm.years.length <= 1 || _vm.kindergartensLoad,
      "options": _vm.years
    },
    on: {
      "input": _vm.onChangeYear
    },
    model: {
      value: _vm.currentYear,
      callback: function callback($$v) {
        _vm.currentYear = $$v;
      },
      expression: "currentYear"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.currentMonth ? _c('div', {
    staticClass: "col-md-2 col-sm-4"
  }, [_c('q-select', {
    attrs: {
      "disable": _vm.months.length <= 1 || _vm.kindergartensLoad,
      "options": _vm.months,
      "option-value": "value",
      "option-label": "label"
    },
    on: {
      "input": _vm.onChangeMonth
    },
    model: {
      value: _vm.currentMonth,
      callback: function callback($$v) {
        _vm.currentMonth = $$v;
      },
      expression: "currentMonth"
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-8 col-md-6"
  }, [_c('div', {
    staticClass: "funding-total-header"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('funding.amount-plan-date', {
    date: _vm.currentDate.split('-').slice(0, 2).join('-')
  })) + ":\n          "), _c('span', {
    staticClass: "s-medium"
  }, [_vm._v(_vm._s(_vm.formatPriceNoCurrency(_vm.totalPlan)))])])])]), _vm._v(" "), _c('div', [_c('q-table', {
    attrs: {
      "title": _vm.trans('title.balabaqsha_title'),
      "data": _vm.kindergartens,
      "columns": _vm.kindergartenTableColumns,
      "separator": "cell",
      "flat": "",
      "loading": _vm.kindergartensLoad
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, _vm._l(props.cols, function (col) {
          return _c('q-th', {
            key: col.name,
            staticClass: "funding-table-header",
            attrs: {
              "props": props
            }
          }, [_vm._v("\n              " + _vm._s(col.label) + "\n            ")]);
        }), 1)];
      }
    }, {
      key: "body",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, [_c('q-td', {
          key: "name",
          staticClass: "funding-table-cell",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n              " + _vm._s(props.row.name) + "\n            ")]), _vm._v(" "), _c('q-td', {
          key: "throughput",
          staticClass: "funding-table-cell",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n              " + _vm._s(props.row.throughput) + "\n            ")]), _vm._v(" "), _c('q-td', {
          key: "count",
          staticClass: "funding-table-cell",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n              " + _vm._s(props.row.count) + "\n            ")]), _vm._v(" "), _c('q-td', {
          key: "fundingPlanCount",
          staticClass: "funding-table-cell",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n              " + _vm._s(props.row.fundingPlanCount ? props.row.fundingPlanCount : 0) + "\n            ")]), _vm._v(" "), _c('q-td', {
          key: "fundingPlan",
          staticClass: "funding-table-cell",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n              " + _vm._s(props.row.fundingPlan && props.row.fundingPlan ? _vm.formatPriceNoCurrency(props.row.fundingPlan) : 0) + "\n            ")]), _vm._v(" "), _c('q-td', {
          key: "fundingPlanYear",
          staticClass: "funding-table-cell",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n              " + _vm._s(props.row.fundingPlan && props.row.fundingPlan ? _vm.formatPriceNoCurrency(props.row.fundingPlan * 12) : 0) + "\n            ")]), _vm._v(" "), _c('q-td', {
          key: "id",
          staticClass: "funding-table-cell",
          attrs: {
            "props": props
          }
        }, [_c('a', {
          staticClass: "funding-route-link",
          attrs: {
            "href": "".concat(_vm.detailUrl, "/").concat(props.row.id)
          }
        }, [_c('q-btn', {
          attrs: {
            "squire": "",
            "icon": "eye"
          }
        }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.view')))])], 1)], 1)])], 1)];
      }
    }, {
      key: "bottom-row",
      fn: function fn(props) {
        return [_c('tr', [_c('th', {
          attrs: {
            "colspan": _vm.kindergartenTableColumns.length
          }
        }, [_vm._v("\n              " + _vm._s(_vm.trans('label.total')) + "\n            ")])]), _vm._v(" "), _c('tr', [_c('th', [_vm._v("\n              " + _vm._s(_vm.trans('funding.amount-plan-date', {
          date: _vm.currentDate.split('-').slice(0, 2).join('-')
        })) + "\n            ")]), _vm._v(" "), _c('td', {
          attrs: {
            "colspan": _vm.kindergartenTableColumns.length - 1
          }
        }, [_c('span', {
          staticClass: "s-medium"
        }, [_vm._v(_vm._s(_vm.formatPriceNoCurrency(_vm.totalPlan)))])])])];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }