import { render, staticRenderFns } from "./VFundingCalculationView.vue?vue&type=template&id=2faf9f37&scoped=true&"
import script from "./VFundingCalculationView.vue?vue&type=script&lang=js&"
export * from "./VFundingCalculationView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2faf9f37",
  null
  
)

export default component.exports