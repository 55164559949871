import { StoreTranslator } from '@common/mixins';
import {
  QInput, QDate, QPopupProxy, QIcon,
} from '@quasar/components';

export default {
  mixins: [StoreTranslator],
  components: {
    QInput, QDate, QPopupProxy, QIcon,
  },
  props: {
    value: {},
    extraBinds: {
      type: Object,
      default: () => ({}),
    },
    error: Boolean,
    errorMessage: String,
  },
  data() {
    return {
      localValue: [],
    };
  },

  beforeCreate() {
    this.$trans.add(['label', 'placeholder']);
  },
  mounted() {
    this.setInitialValue();
  },
  methods: {
    emitChange(value = null) {
      this.$emit('input', value);
    },
    setInitialValue() {
      if (this.value) {
        this.localValue = this.value;
      }
    },
  },
  watch: {
    value(value) {
      // update value
      this.setInitialValue();
    },
    localValue(val) {
      this.emitChange(val);
    },
  },
};
