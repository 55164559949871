var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-dialog', {
    attrs: {
      "value": _vm.modal
    },
    on: {
      "input": _vm.handleModal
    }
  }, [_c('v-modal', {
    attrs: {
      "title": _vm.trans('label.remove_priority_status'),
      "size": "medium"
    }
  }, [_c('div', {
    staticClass: "mb-30"
  }, [_vm._v("\n      " + _vm._s(_vm.trans('label.remove_priority_status_confirm')) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-between"
  }, [_c('div', {
    staticClass: "col col-sm-auto sn--text-center mt-5 mb-5"
  }, [_c('q-btn', {
    directives: [{
      name: "close-popup",
      rawName: "v-close-popup"
    }],
    attrs: {
      "width": "20",
      "label": _vm.trans('button.cancel'),
      "label-wider": "",
      "color": "grey"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col col-sm-auto sn--text-center mt-5 mb-5"
  }, [_c('q-btn', {
    attrs: {
      "width": "20",
      "label": _vm.trans('button.approve'),
      "color": "green"
    },
    on: {
      "click": _vm.handleConfirm
    }
  })], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }