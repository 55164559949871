var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-dialog', {
    attrs: {
      "value": _vm.isOpen
    },
    on: {
      "input": _vm.closeModal
    }
  }, [_c('v-modal', {
    attrs: {
      "value": _vm.isOpen,
      "title": _vm.trans('attendance.timesheet-load-title'),
      "size": "700"
    }
  }, [!_vm.editableDays.length ? _c('v-form-inform', {
    attrs: {
      "message": _vm.trans('attendance.timesheet-load-no-date')
    }
  }) : _vm._e(), _vm._v(" "), _vm.massEditType > 1 ? _c('q-field', {
    attrs: {
      "borderless": ""
    }
  }, [_c('q-option-group', {
    staticClass: "d-flex",
    attrs: {
      "inline": "",
      "options": _vm.modalTypeOptions
    },
    on: {
      "click": _vm.clearModel
    },
    model: {
      value: _vm.modalType,
      callback: function callback($$v) {
        _vm.modalType = $$v;
      },
      expression: "modalType"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('q-slide-transition', [_vm.modalType === 1 ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-3"
  }, [_vm.editableDays.length ? _c('q-select', {
    attrs: {
      "label": _vm.trans('label.date'),
      "options": _vm.editableDays,
      "disable": _vm.confirming || _vm.reloading,
      "required": ""
    },
    model: {
      value: _vm.selectedDate,
      callback: function callback($$v) {
        _vm.selectedDate = $$v;
      },
      expression: "selectedDate"
    }
  }) : _vm._e()], 1)]) : _c('q-select', {
    attrs: {
      "label": _vm.trans('label.select_from_contingent'),
      "options": _vm.editableChildrenAlt,
      "disable": _vm.confirming || _vm.reloading,
      "required": "",
      "emit-value": "",
      "map-options": ""
    },
    on: {
      "input": function input($event) {
        _vm.altSelectedDates = [];
      }
    },
    model: {
      value: _vm.altSelectedChild,
      callback: function callback($$v) {
        _vm.altSelectedChild = $$v;
      },
      expression: "altSelectedChild"
    }
  })], 1), _vm._v(" "), _c('q-slide-transition', [_vm.altSelectedChild ? _c('q-checkbox', {
    attrs: {
      "label": _vm.trans('label.select_all_dates')
    },
    on: {
      "input": _vm.selectAllDates
    },
    model: {
      value: _vm.allDates,
      callback: function callback($$v) {
        _vm.allDates = $$v;
      },
      expression: "allDates"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('q-slide-transition', [_vm.altSelectedChild ? _c('v-wrapper-q-select', {
    attrs: {
      "options": _vm.editableDaysAlt,
      "multiple": "",
      "option-value": "value",
      "option-label": "label",
      "extra-binds": {
        labelTransKey: 'label.select_dates',
        mapOptions: true,
        required: true
      }
    },
    on: {
      "input": _vm.selectDate
    },
    model: {
      value: _vm.altSelectedDates,
      callback: function callback($$v) {
        _vm.altSelectedDates = $$v;
      },
      expression: "altSelectedDates"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('q-select', {
    attrs: {
      "options": _vm.reasons,
      "label": _vm.trans('attendance.timesheet-absent-reason'),
      "disable": _vm.confirming || _vm.reloading,
      "option-value": "value",
      "option-label": "label",
      "required": ""
    },
    model: {
      value: _vm.typeControl,
      callback: function callback($$v) {
        _vm.typeControl = $$v;
      },
      expression: "typeControl"
    }
  }), _vm._v(" "), _c('q-slide-transition', [_vm.typeControl.value === 1 ? _c('div', {
    staticClass: "mb-10"
  }, [_c('q-input', {
    attrs: {
      "error": _vm.hasValidateFail('other'),
      "error-message": _vm.fails.other,
      "disable": _vm.confirming || _vm.reloading,
      "required": "",
      "label": _vm.trans('attendance.timesheet-absent-other')
    },
    model: {
      value: _vm.otherControl,
      callback: function callback($$v) {
        _vm.otherControl = $$v;
      },
      expression: "otherControl"
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c('q-slide-transition', [![0, 7].includes(_vm.typeControl.value) ? _c('div', {
    staticClass: "mb-10"
  }, [_c('div', {
    staticClass: "sn-field__label"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('attendance.timesheet-absent-file')) + ":\n          "), _vm.typeControl.value !== 5 && _vm.typeControl.value !== 6 ? _c('i', [_vm._v("*")]) : _vm._e()]), _vm._v(" "), !_vm.reasonsFile ? _c('q-btn', {
    staticClass: "s-w-100 form-upload__button",
    attrs: {
      "icon": "clip",
      "color": "blue",
      "type": "button",
      "label": _vm.trans('button.overview'),
      "label-wider": "",
      "loading": _vm.confirming || _vm.reloading
    },
    on: {
      "click": _vm.triggerFile
    }
  }) : _vm._e(), _vm._v(" "), _vm.reasonsFile ? _c('q-btn', {
    staticClass: "s-w-100 s-btn--border sn-btn--ellipsis",
    attrs: {
      "icon": "close",
      "label": _vm.reasonsFile.name,
      "label-wider": "",
      "no-caps": "",
      "type": "button",
      "loading": _vm.confirming || _vm.reloading
    },
    on: {
      "click": _vm.clearFile
    },
    model: {
      value: _vm.reasonsFile,
      callback: function callback($$v) {
        _vm.reasonsFile = $$v;
      },
      expression: "reasonsFile"
    }
  }) : _vm._e(), _vm._v(" "), _vm.hasValidateFail('file') ? _c('div', {
    staticClass: "s-form__error-message mt-5"
  }, [_vm._v("\n          " + _vm._s(_vm.fails.file) + "\n        ")]) : _vm._e()], 1) : _vm._e()]), _vm._v(" "), _c('input', {
    ref: "fileInput",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": "image/*,.pdf"
    },
    on: {
      "change": _vm.fileChange
    }
  }), _vm._v(" "), _c('q-slide-transition', [_vm.modalType === 1 ? _c('div', {
    staticClass: "row mb-20"
  }, [_vm.selectedDate ? _c('q-slide-transition', {
    staticClass: "col-12"
  }, [_c('div', [_c('q-field', {
    attrs: {
      "label": _vm.trans('attendance.timesheet-load-child'),
      "borderless": ""
    }
  }, [_c('div', {
    staticClass: "row justify-content-between"
  }, [_c('div', {
    staticClass: "col-12 col-sm-auto mb-10"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "width": "20",
      "color": "blue",
      "label": _vm.trans('button.select-all'),
      "loading": _vm.confirming || _vm.reloading
    },
    on: {
      "click": _vm.selectAllChild
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-12 col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "width": "20",
      "color": "orange",
      "label": _vm.trans('button.un-select-all'),
      "loading": _vm.confirming || _vm.reloading
    },
    on: {
      "click": _vm.unselectAllChild
    }
  })], 1)])]), _vm._v(" "), _vm.editableChildren.length ? _c('q-option-group', {
    attrs: {
      "options": _vm.editableChildren,
      "type": "checkbox"
    },
    model: {
      value: _vm.sendAttendances,
      callback: function callback($$v) {
        _vm.sendAttendances = $$v;
      },
      expression: "sendAttendances"
    }
  }) : _vm._e(), _vm._v(" "), !_vm.editableChildren.length ? _c('v-form-inform', {
    attrs: {
      "message": _vm.trans('attendance.timesheet-load-no-child')
    }
  }) : _vm._e()], 1)]) : _vm._e()], 1) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-between"
  }, [_c('div', {
    staticClass: "col-12 col-sm-auto mb-10"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "width": "20",
      "label-wider": "",
      "color": "grey",
      "label": _vm.trans('button.cancel'),
      "loading": _vm.confirming || _vm.reloading
    },
    on: {
      "click": _vm.closeModal
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-12 col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "width": "20",
      "color": "green",
      "disable": !_vm.isValid,
      "loading": _vm.confirming || _vm.reloading
    },
    on: {
      "click": _vm.confirm
    }
  }, [_vm._v("\n          " + _vm._s(_vm.trans('button.approve')) + "\n        ")])], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }