// noinspection NpmUsedModulesInstalled
import {
  FUNDING_KINDERGARTEN_GROUPS_TRANSLATIONS,
} from '@vjs/balabaqsha/consts';

// noinspection NpmUsedModulesInstalled
import {
  QTable,
  QTr,
  QTd,
  QTh,
  QBtn,
  QSelect,
  QTooltip,
  QIcon,
} from '@quasar/components';

import _ from 'lodash';
import PriceFormat from '../Mixins/PriceFormat';
import Localization from '../Mixins/QuasarLocale';
import KindergartenGroupListProviders
  from './Mixins/KindergartenGroupListProviders';
import DateYearMonthPicker from '../Mixins/DateYearMonthPicker';

export default {
  name: 'VFundingKindergartenGroupList',
  components: {
    QTable,
    QTr,
    QTd,
    QTh,
    QBtn,
    QSelect,
    QTooltip,
    QIcon,
  },
  mixins: [
    Localization, PriceFormat,
    DateYearMonthPicker, KindergartenGroupListProviders,
  ],
  props: {
    apiConfig: {
      type: Object,
      default: () => {
      },
    },
    dataConfig: {
      type: Object,
      default: () => {
      },
    },
    editUrl: {
      type: String,
      default: () => '',
    },
    type: {
      type: String,
      default: () => 'common',
    },
  },
  data() {
    // eslint-disable-next-line camelcase
    const { core_project } = window;
    const locale = core_project.locale || 'kz';
    // eslint-disable-next-line camelcase
    const { dataConfig: { kindergarten_id, kindergartenName, date } } = this;

    return {
      locale,
      kindergartenId: kindergarten_id,
      kindergarten: kindergartenName,
      groups: [],
      groupsLoad: false,
      totalPlan: 0,
      totalFact: 0,
      planLock: false,
      currentDate: date,
    };
  },
  computed: {
    groupsColumns() {
      const { $trans: { get: trans }, type } = this;
      const { currentDate, currentYear } = this;
      const cols = [
        {
          name: 'group_name',
          field: 'group_name',
          label: trans('fields.group_name'),
        },
        {
          name: 'ages',
          field: 'ages',
          label: trans('funding.group.table.age'),
        },
        {
          name: 'contingent_count',
          field: 'contingent_count',
          label: trans('funding.group.table.contingent'),
        },
        {
          name: 'funding_count',
          field: 'funding_count',
          label: trans('funding.group.table.funding-count'),
        },
        {
          name: 'funding',
          field: 'funding',
          label: trans('funding.group.table.funding-plan-date', {
            date: currentDate.split('-').slice(0, 2).join('-'),
          }),
        },
        // {
        //   name: 'funding-year',
        //   field: 'funding',
        //   label: trans('funding.group.table.funding-plan-year', {
        //     year: currentYear,
        //   }),
        // },
      ];

      if (type === 'common') {
        return [
          ...cols,
          {
            name: 'id',
            field: 'id',
            label: '',
          },
        ];
      }

      return cols;
    },
  },
  beforeCreate() {
    this.$trans.add(FUNDING_KINDERGARTEN_GROUPS_TRANSLATIONS);
  },
  beforeMount() {
    const {
      apiConfig,
    } = this;
    this.initLocale();
    this.initProvider(apiConfig);
    this.setDefaultDate();
    this.loadGroups();
  },
  methods: {
    async loadGroups() {
      const { apiProvider, kindergartenId, selectedDate } = this;
      this.groupsLoad = true;

      const response = await apiProvider
        .provideListGroupsByKindergarten()
        .apply(this, [kindergartenId, selectedDate]);
      this.groupsLoad = false;
      if (
        response.groups
        && Array.isArray(response.groups)
        && response.groups.length
      ) {
        this.groups = response.groups;
      }

      // noinspection JSUnresolvedVariable
      if (
        response.total
        && response.total.plan
      ) {
        this.totalPlan = response.total.plan;
      } else {
        this.totalPlan = 0;
      }
      // noinspection JSUnresolvedVariable
      if (
        response.total
        && response.total.fact
      ) {
        this.totalFact = response.total.fact;
      }
      if (
        response.dates
      ) {
        this.availableDates = response.dates;
      }
      if (
        response.date
      ) {
        this.currentDate = response.date;
      }
      // noinspection JSUnresolvedVariable
      if (
        response.confirm
        && typeof response.confirm.plan !== 'undefined'
      ) {
        this.planLock = response.confirm.plan;
      }
    },
    onChangeYear(val) {
      this.currentYear = val;
      this.currentMonth = _.first(this.availableDates[val]);
      // noinspection JSIgnoredPromiseFromCall
      this.loadGroups();
    },
    onChangeMonth(val) {
      this.currentMonth = val;
      // noinspection JSIgnoredPromiseFromCall
      this.loadGroups();
    },
  },
};
