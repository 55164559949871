import { render, staticRenderFns } from "./VPhotoAlbum.vue?vue&type=template&id=f763e7d0&scoped=true&"
import script from "./VPhotoAlbum.js?vue&type=script&lang=js&"
export * from "./VPhotoAlbum.js?vue&type=script&lang=js&"
import style0 from "./VPhotoAlbum.vue?vue&type=style&index=0&id=f763e7d0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f763e7d0",
  null
  
)

export default component.exports