var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.disable || _vm.files.length ? _c('div', {
    staticClass: "mb-30"
  }, [_vm.label ? _c('span', {
    staticClass: "s-form__label mb-10"
  }, [_vm._v("\n    " + _vm._s(_vm.label) + ":\n    "), _vm.required ? _c('i', [_vm._v("*")]) : _vm._e()]) : _vm._e(), _vm._v(" "), _vm._l(_vm.files, function (file, key) {
    return _c('div', {
      key: key,
      staticClass: "row mb-20"
    }, [_c('div', {
      staticClass: "col-sm-6 col-12",
      class: {
        'mb-10': _vm.$q.screen.width < 576,
        'col-lg-6': _vm.equal,
        'col-md-6': _vm.equal,
        'col-lg-8': !_vm.equal,
        'col-md-8': !_vm.equal
      }
    }, [_c('q-btn', {
      staticClass: "s-w-100",
      staticStyle: {
        "height": "100%"
      },
      attrs: {
        "disable": _vm.disable,
        "outline": "",
        "no-caps": "",
        "label-wider": "",
        "ellipsis": "",
        "icon": _vm.$q.screen.width < 576 ? 'close' : undefined,
        "icon-right": _vm.$q.screen.width < 576 ? undefined : 'close',
        "color": "grey"
      },
      on: {
        "click": function click($event) {
          return _vm.$delete(_vm.files, key);
        }
      }
    }, [_c('span', {
      staticClass: "s-w-100 s-to-left"
    }, [_vm._v("\n          " + _vm._s(file.name) + "\n        ")])])], 1), _vm._v(" "), _c('div', {
      staticClass: "col-sm-6 col-12",
      class: {
        'col-lg-6': _vm.equal,
        'col-md-6': _vm.equal,
        'col-lg-4': !_vm.equal,
        'col-md-4': !_vm.equal
      }
    }, [_vm.withDownload && _vm.disable && file.publicUrl ? _c('q-btn', {
      staticClass: "s-w-100",
      attrs: {
        "label-wider": "",
        "no-caps": "",
        "icon-right": _vm.$q.screen.width < 576 ? 'download' : undefined,
        "icon": _vm.$q.screen.width < 576 ? undefined : 'download',
        "color": "green",
        "label": _vm.trans('button.download_file'),
        "type": "a",
        "target": "_blank",
        "to": file.publicUrl
      }
    }) : _c('q-btn', {
      staticClass: "s-w-100",
      attrs: {
        "disable": _vm.disable,
        "label-wider": "",
        "no-caps": "",
        "icon-right": _vm.$q.screen.width < 576 ? 'clip' : undefined,
        "icon": _vm.$q.screen.width < 576 ? undefined : 'clip',
        "color": "green",
        "label": _vm.trans('button.file_attached')
      },
      on: {
        "click": function click($event) {
          return _vm.onClickInputFile(key);
        }
      }
    })], 1)]);
  }), _vm._v(" "), _vm.files.length < _vm.max && !_vm.disable ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-6 col-12",
    class: {
      'mb-10': _vm.$q.screen.width < 576,
      'col-lg-6': _vm.equal,
      'col-md-6': _vm.equal,
      'col-lg-8': !_vm.equal,
      'col-md-8': !_vm.equal
    }
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    staticStyle: {
      "height": "100%"
    },
    attrs: {
      "disable": _vm.disable,
      "outline": "",
      "no-caps": "",
      "label-wider": "",
      "ellipsis": "",
      "align": "left",
      "color": "grey"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.trans('button.file_name_placeholder')) + "\n      ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "col-sm-6 col-12",
    class: {
      'col-lg-6': _vm.equal,
      'col-md-6': _vm.equal,
      'col-lg-4': !_vm.equal,
      'col-md-4': !_vm.equal
    }
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "disable": _vm.disable,
      "label-wider": "",
      "no-caps": "",
      "icon-right": _vm.$q.screen.width < 576 ? 'plus' : undefined,
      "icon": _vm.$q.screen.width < 576 ? undefined : 'plus',
      "color": "blue",
      "label": _vm.trans('button.add_file')
    },
    on: {
      "click": function click($event) {
        return _vm.onClickInputFile();
      }
    }
  })], 1)]) : _vm._e(), _vm._v(" "), _c('input', {
    ref: "input_file",
    staticClass: "hidden",
    attrs: {
      "type": "file"
    },
    on: {
      "change": _vm.onChangeInputFile
    }
  })], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }