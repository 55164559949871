export default {
  name: 'VReferenceTime',
  props: {
    timestamp: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      interval: null,
      localTimestamp: 0,
      date: null,
    };
  },
  mounted() {
    this.localTimestamp = this.timestamp * 1000;
    this.interval = setInterval(this.handleDate, 1000);
    this.handleDate();
  },
  unmounted() {
    clearInterval(this.interval);
  },
  methods: {
    handleDate() {
      this.date = new Date(
        new Date(this.tick()).toLocaleString('en-US', { timeZone: 'Asia/Almaty' }),
      );
    },
    tick() {
      this.localTimestamp += 1000;
      return this.localTimestamp;
    },
    formatNum(num) {
      if (num < 10) {
        return `0${num}`;
      }
      return num;
    },
  },
};
