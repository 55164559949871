var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h4', {
    staticClass: "group-list-header"
  }, [_vm._v("\n    " + _vm._s(_vm.trans('funding.kindergarten.header', {
    kindergarten: _vm.kindergarten
  })) + "\n  ")]), _vm._v(" "), _c('q-table', {
    attrs: {
      "data": _vm.funding,
      "columns": _vm.columns,
      "separator": "cell",
      "flat": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, _vm._l(props.cols, function (col) {
          return _c('q-th', {
            key: col.name,
            staticClass: "funding-table-header",
            attrs: {
              "props": props
            }
          }, [_vm._v("\n          " + _vm._s(col.label) + "\n        ")]);
        }), 1)];
      }
    }, {
      key: "body",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, [_c('q-td', {
          key: "name",
          staticClass: "funding-table-cell",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n          " + _vm._s(props.row.name) + "\n        ")]), _vm._v(" "), _c('q-td', {
          key: "group_children_count",
          staticClass: "funding-table-cell",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n          " + _vm._s(props.row.group_children_count && props.row.group_children_count ? props.row.group_children_count : 0) + "\n        ")]), _vm._v(" "), _c('q-td', {
          key: "plan",
          staticClass: "funding-table-cell",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n          " + _vm._s(props.row.plan && props.row.plan ? _vm.formatPrice(props.row.plan) : 0) + "\n        ")]), _vm._v(" "), _c('q-td', {
          key: "fact",
          staticClass: "funding-table-cell",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n          " + _vm._s(props.row.fact && props.row.fact ? _vm.formatPrice(props.row.fact) : 0) + "\n        ")])], 1)];
      }
    }, {
      key: "bottom-row",
      fn: function fn(props) {
        return [_c('tr', [_c('th', {
          attrs: {
            "colspan": _vm.columns.length
          }
        }, [_vm._v("\n          " + _vm._s(_vm.trans('label.total')) + "\n        ")])]), _vm._v(" "), _c('tr', [_c('th', [_vm._v(_vm._s(_vm.trans('funding.amount-fact-date', {
          date: _vm.date
        })))]), _vm._v(" "), _c('td', {
          attrs: {
            "colspan": _vm.columns.length - 1
          }
        }, [_c('span', {
          staticClass: "s-medium"
        }, [_vm._v(_vm._s(_vm.formatPrice(_vm.total)))])])])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }