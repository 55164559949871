var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('form', {
    staticClass: "row-sort-wrap",
    attrs: {
      "method": "get"
    }
  }, [_c('div', {
    staticClass: "row align-items-stretch mb-10 justify-content-end"
  }, [_c('div', {
    staticClass: "col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "color": "green",
      "no-caps": "",
      "label": _vm.trans('button.export'),
      "loading": _vm.exportLoading
    },
    on: {
      "click": _vm.exportu
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "row align-items-stretch mb-10  justify-content-end"
  }, [_c('div', {
    staticClass: "col-sm-auto"
  }, [_c('q-input', {
    attrs: {
      "dense": "",
      "debounce": "300",
      "placeholder": _vm.trans('placeholder.search')
    },
    model: {
      value: _vm.filterString,
      callback: function callback($$v) {
        _vm.filterString = $$v;
      },
      expression: "filterString"
    }
  }, [_c('q-icon', {
    attrs: {
      "slot": "append",
      "name": "search"
    },
    slot: "append"
  })], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "q-ml-md s-pos-float-right mt-10",
    attrs: {
      "squire": "",
      "unelevated": "",
      "icon": "fullscreen",
      "color": "grey"
    },
    on: {
      "click": function click($event) {
        _vm.table.inFullscreen = true;
      }
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.fullscreen_on')))])], 1)], 1)])]), _vm._v(" "), _vm.rows ? _c('q-table', {
    attrs: {
      "data": _vm.rows,
      "columns": _vm.table.columns.flat(1),
      "pagination": _vm.table.pagination,
      "row-key": "name",
      "separator": "cell",
      "filter": _vm.filterString,
      "fullscreen": _vm.table.inFullscreen
    },
    on: {
      "update:pagination": function updatePagination($event) {
        return _vm.$set(_vm.table, "pagination", $event);
      }
    },
    scopedSlots: _vm._u([_vm.table.inFullscreen ? {
      key: "top",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "row align-items-stretch justify-content-end s-w-100"
        }, [_c('div', {
          staticClass: "col-sm-auto"
        }, [_c('q-input', {
          attrs: {
            "dense": "",
            "debounce": "300",
            "placeholder": _vm.trans('placeholder.search')
          },
          model: {
            value: _vm.filterString,
            callback: function callback($$v) {
              _vm.filterString = $$v;
            },
            expression: "filterString"
          }
        }, [_c('q-icon', {
          attrs: {
            "slot": "append",
            "name": "search"
          },
          slot: "append"
        })], 1)], 1), _vm._v(" "), _c('div', {
          staticClass: "col-sm-auto"
        }, [_c('q-btn', {
          staticClass: "q-ml-md s-pos-float-right mt-10",
          attrs: {
            "squire": "",
            "unelevated": "",
            "icon": "fullscreen",
            "color": "blue"
          },
          on: {
            "click": function click($event) {
              _vm.table.inFullscreen = false;
            }
          }
        }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.fullscreen_off')))])], 1)], 1)])];
      }
    } : null, {
      key: "header",
      fn: function fn(props) {
        return [_c('q-tr', [_c('th', {
          staticClass: "sn--text-center",
          attrs: {
            "colspan": "100%"
          }
        }, [_vm._v("\n          " + _vm._s(_vm.table_name) + "\n        ")])]), _vm._v(" "), _c('q-tr', _vm._l(_vm.table.columns, function (col) {
          return _c('th', {
            key: col.name,
            style: Object.assign({}, _vm.getCellStyle(col.name)),
            attrs: {
              "props": props,
              "colspan": col.colspan,
              "rowspan": col.rowspan
            }
          }, [_vm._v("\n          " + _vm._s(col.label) + "\n        ")]);
        }), 0)];
      }
    }, {
      key: "body",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, [_c('q-td', {
          staticClass: "s-to-center"
        }, [_vm._v("\n                 " + _vm._s(props.row.__index) + "\n               ")]), _vm._v(" "), _vm._l(_vm.rowNames, function (rowName) {
          return [!['id', '__index', 'num'].includes(rowName) && rowName in props.row ? _c('q-td', {
            key: "".concat(rowName, "_").concat(props.row.__index),
            class: rowName === 'department' ? '' : 's-to-center',
            style: Object.assign({}, _vm.getCellStyle(rowName))
          }, [_vm._v("\n                   " + _vm._s(props.row[rowName]) + "\n                 ")]) : _vm._e()];
        })], 2)];
      }
    }, {
      key: "bottom-row",
      fn: function fn() {
        return [_c('q-tr', [_c('q-td'), _vm._v(" "), _c('q-td', [_c('b', [_vm._v(_vm._s(_vm.trans('statistics.contingent.total')))])]), _vm._v(" "), _vm._l(_vm.totalNames, function (item, i) {
          return [_c('q-td', {
            key: i,
            staticClass: "s-to-center"
          }, [_vm._v("\n            " + _vm._s(_vm.sum(item)) + "\n          ")])];
        })], 2)];
      },
      proxy: true
    }], null, true)
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }