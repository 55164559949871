var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.clickableRows ? 's-table-with-filters__clickable-rows' : ''
  }, [_vm.clickableRows ? _c('portal', {
    attrs: {
      "to": "footer-portal"
    }
  }, [_vm.hoveredRowId ? _c('div', {
    staticClass: "s-table-with-filters__row-btn",
    style: _vm.rowBtnStyles
  }, [_c('q-icon', {
    attrs: {
      "name": "arrow-right"
    }
  })], 1) : _vm._e()]) : _vm._e(), _vm._v(" "), _c('q-table', {
    staticClass: "s-b-white",
    attrs: {
      "data": _vm.rows,
      "columns": _vm.cols,
      "flat": _vm.$q.screen.width >= _vm.adaptiveWhen,
      "grid": _vm.$q.screen.width <= _vm.adaptiveWhen,
      "pagination": _vm.pagination,
      "no-data-label": _vm.trans('label.no_data'),
      "no-results-label": _vm.trans('label.no_results'),
      "visible-columns": _vm.visibleCols,
      "separator": _vm.separator,
      "row-key": "index",
      "wrap-cells": ""
    },
    on: {
      "update:pagination": function updatePagination($event) {
        _vm.pagination = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, _vm._l(props.cols, function (col) {
          return _c('q-th', {
            key: col.name,
            style: {
              width: "".concat(col.width, "px")
            },
            attrs: {
              "props": props
            }
          }, [_vm._v("\n          " + _vm._s(col.label) + "\n        ")]);
        }), 1)];
      }
    }, {
      key: "body",
      fn: function fn(props) {
        return [_c('q-tr', {
          ref: "row-".concat(props.row.__index),
          class: props.row.customClass,
          attrs: {
            "props": props
          },
          nativeOn: {
            "mouseover": function mouseover($event) {
              _vm.hoveredRowId = props.row.__index;
            },
            "mouseout": function mouseout($event) {
              _vm.hoveredRowId = null;
            },
            "click": [function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "left", 37, $event.key, ["Left", "ArrowLeft"])) { return null; }
              if ('button' in $event && $event.button !== 0) { return null; }
              if ($event.ctrlKey || $event.shiftKey || $event.altKey || $event.metaKey) { return null; }
              _vm.clickableRows && props.row.link ? _vm.window.open(props.row.link, '_self').focus() : undefined;
            }, function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "left", 37, $event.key, ["Left", "ArrowLeft"])) { return null; }
              if (!$event.ctrlKey) { return null; }
              if ('button' in $event && $event.button !== 0) { return null; }
              if ($event.shiftKey || $event.altKey || $event.metaKey) { return null; }
              _vm.clickableRows && props.row.link ? _vm.window.open(props.row.link, '_blank').focus() : undefined;
            }, function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "left", 37, $event.key, ["Left", "ArrowLeft"])) { return null; }
              if (!$event.metaKey) { return null; }
              if ('button' in $event && $event.button !== 0) { return null; }
              if ($event.ctrlKey || $event.shiftKey || $event.altKey) { return null; }
              _vm.clickableRows && props.row.link ? _vm.window.open(props.row.link, '_blank').focus() : undefined;
            }, function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "left", 37, $event.key, ["Left", "ArrowLeft"])) { return null; }
              if (!$event.shiftKey) { return null; }
              if ('button' in $event && $event.button !== 0) { return null; }
              if ($event.ctrlKey || $event.altKey || $event.metaKey) { return null; }
              _vm.clickableRows && props.row.link ? _vm.window.open(props.row.link).focus() : undefined;
            }],
            "contextmenu": function contextmenu($event) {
              if ($event.ctrlKey || $event.shiftKey || $event.altKey || $event.metaKey) { return null; }
              _vm.clickableRows && props.row.link ? _vm.window.open(props.row.link, '_blank').focus() : undefined;
            },
            "mouseup": function mouseup($event) {
              if ('button' in $event && $event.button !== 1) { return null; }
              if ($event.ctrlKey || $event.shiftKey || $event.altKey || $event.metaKey) { return null; }
              _vm.clickableRows && props.row.link ? _vm.window.open(props.row.link, '_blank').focus() : undefined;
            }
          }
        }, [_vm._l(props.cols, function (col, i) {
          return [col.field === 'index' ? _c('q-td', {
            staticClass: "s-to-center"
          }, [_vm._v("\n            " + _vm._s(!_vm.isMetaEmpty ? props.row.__index + _vm.meta.per_page * _vm.meta.current_page - _vm.meta.per_page : props.row.__index) + "\n          ")]) : _vm._e(), _vm._v(" "), !['index', 'actions'].includes(col.field) ? _c('q-td', {
            key: i,
            class: _vm.getCellClass(col.field),
            style: col.customStyle,
            domProps: {
              "innerHTML": _vm._s(col.value)
            }
          }) : _vm._e(), _vm._v(" "), col.field === 'actions' ? _c('q-td', {
            key: i,
            staticClass: "s-to-center"
          }, [_c('div', {
            staticClass: "s-group s-group--to-center"
          }, _vm._l(col.value, function (action, key) {
            return _c('q-btn', {
              key: key,
              attrs: {
                "color": action.color,
                "to": action.to,
                "icon": action.icon,
                "label": action.label,
                "outline": action.outline,
                "width": action.width,
                "target": action.target,
                "squire": "",
                "no-caps": "",
                "type": action.type
              },
              on: {
                "click": function click($event) {
                  return _vm.handleModal(action, props.row);
                }
              }
            }, [action.tooltip ? _c('q-tooltip', [_vm._v("\n                  " + _vm._s(action.tooltip) + "\n                ")]) : _vm._e()], 1);
          }), 1)]) : _vm._e()];
        })], 2)];
      }
    }, {
      key: "item",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "sn-table__grid-item col-xs-12 col-sm-6 col-md-4 col-lg-4"
        }, [_c('div', {
          staticClass: "sn-table__grid-item-card sn-table__card",
          class: props.row.customAdaptiveClass
        }, [_c('div', {
          staticClass: "sn-table__grid-item-row"
        }, [_c('div', {
          staticClass: "sn-table__grid-item-value"
        }, [_vm._v("\n              " + _vm._s("\u2116 ".concat(!_vm.isMetaEmpty ? props.row.__index + _vm.meta.per_page * _vm.meta.current_page - _vm.meta.per_page : props.row.__index)) + "\n            ")])]), _vm._v(" "), _vm._l(props.cols, function (col, i) {
          return [!['index', 'actions'].includes(col.field) && ![null, undefined, ''].includes(col.value) ? _c('div', {
            key: i,
            staticClass: "sn-table__grid-item-row"
          }, [_c('div', {
            staticClass: "sn-table__grid-item-title"
          }, [_vm._v("\n                " + _vm._s(col.label) + "\n              ")]), _vm._v(" "), _c('div', {
            staticClass: "sn-table__grid-item-value",
            style: col.customStyle,
            domProps: {
              "innerHTML": _vm._s(col.value)
            }
          })]) : _vm._e(), _vm._v(" "), col.field === 'actions' ? _c('div', {
            key: i
          }, [_c('div', {
            staticClass: "sn-table__grid-item-title"
          }, [_vm._v("\n                " + _vm._s(col.label) + "\n              ")]), _vm._v(" "), _c('div', {
            staticClass: "sn-table__grid-item-value"
          }, [_c('div', {
            staticClass: "s-group s-group--to-center"
          }, _vm._l(col.value, function (action, key) {
            return _c('q-btn', {
              key: key,
              attrs: {
                "color": action.color,
                "to": action.to,
                "icon": action.icon,
                "label": action.label,
                "outline": action.outline,
                "target": action.target,
                "squire": "",
                "no-caps": "",
                "type": action.type
              },
              on: {
                "click": function click($event) {
                  return _vm.handleModal(action, props.row);
                }
              }
            }, [action.tooltip ? _c('q-tooltip', [_vm._v("\n                      " + _vm._s(action.tooltip) + "\n                    ")]) : _vm._e()], 1);
          }), 1)])]) : _vm._e()];
        }), _vm._v(" "), _vm.clickableRows && props.row.link ? _c('div', {
          staticClass: "sn-table__grid-item-row d-flex justify-content-end s-h-100 align-items-end"
        }, [_c('q-btn', {
          attrs: {
            "color": "blue",
            "outline": "",
            "size": "15",
            "no-caps": "",
            "label": _vm.trans('button.go_to'),
            "type": "a",
            "to": props.row.link
          }
        })], 1) : _vm._e()], 2)])];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [!_vm.rows.length ? _c('v-no-results', {
          staticClass: "s-w-100",
          attrs: {
            "image": "search",
            "title": _vm.noResultTitle || _vm.trans('warning_text.no_result_title'),
            "text": _vm.withNoResultText ? _vm.noResultText ? _vm.noResultText : _vm.trans('warning_text.no_result_text') : ''
          }
        }) : _vm._e()];
      },
      proxy: true
    }, !_vm.isMetaEmpty ? {
      key: "bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "sn-table__control"
        }), _vm._v(" "), _c('div', {
          staticClass: "sn-table__separator sn--col"
        }), _vm._v(" "), _c('div', {
          staticClass: "sn-table__control"
        }, [_c('span', {
          staticClass: "sn-table__bottom-item"
        }, [_vm._v("\n          " + _vm._s(_vm.trans('label.table_pagination_rows_on_page')) + "\n        ")]), _vm._v(" "), _c('q-select', {
          staticClass: "sn-table__bottom-item",
          attrs: {
            "borderless": "",
            "options": _vm.paginationOptions,
            "emit-value": "",
            "map-options": ""
          },
          on: {
            "input": function input($event) {
              return _vm.emitGetItems('default');
            }
          },
          model: {
            value: _vm.pagination.rowsPerPage,
            callback: function callback($$v) {
              _vm.$set(_vm.pagination, "rowsPerPage", $$v);
            },
            expression: "pagination.rowsPerPage"
          }
        })], 1), _vm._v(" "), _c('div', {
          staticClass: "sn-table__control"
        }, [_c('span', {
          staticClass: "sn-table__bottom-item"
        }, [_vm._v("\n          " + _vm._s(_vm.trans('label.pagination_from_to', {
          total: _vm.meta.total,
          from: _vm.meta.from,
          to: _vm.meta.to
        })) + "\n        ")]), _vm._v(" "), _vm._l(_vm.paginationBtns, function (btn, key) {
          return _c('q-btn', {
            key: key,
            attrs: {
              "flat": "",
              "ripple": "",
              "round": "",
              "dense": "",
              "icon": btn.icon,
              "disabled": btn.disabled
            },
            on: {
              "click": function click($event) {
                return _vm.emitGetItems(btn.type);
              }
            }
          });
        })], 2)];
      }
    } : null], null, true)
  }), _vm._v(" "), _c('v-confirm-dialog', {
    attrs: {
      "modal": _vm.openModal,
      "loading": _vm.loadingModal,
      "confirm": _vm.requestConfirmModal
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.openModal = $event;
      }
    }
  }), _vm._v(" "), _c('image-viewer', {
    ref: "viewer"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }