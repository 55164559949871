<template>
  <div>
    <confirm
      :title="trans('notice.confirm')"
      :active="confirmPopup"
      :on-hide="cancel"
    >
      <div class="confirm-text">
        {{ popupText }}
      </div>
      <div class="popup-btn-control">
        <q-btn
          outline
          :disabled="sending"
          @click="cancel"
        >
          {{ trans('button.cancel') }}
        </q-btn>
        <q-btn
          :disabled="sending"
          color="green"
          @click="applyAct"
        >
          {{ trans('button.approve') }}
        </q-btn>
      </div>
    </confirm>
    <div class="row refuse-action-group">
      <div class="col-md-4 btn-wrapper">
        <q-btn
          color="orange"
          @click="()=> prepare(refuse.url, refuse.text)"
        >
          {{ refuse.button }}
        </q-btn>
      </div>
      <div class="col-md-4 sn--text-center btn-wrapper">
        <q-btn
          color="blue"
          @click="()=> prepare(returnTo.url, returnTo.text)"
        >
          {{ returnTo.button }}
        </q-btn>
      </div>
      <div class="col-md-4 sn--text-right btn-wrapper">
        <q-btn
          color="green"
          @click="()=> prepare(restore.url, restore.text)"
        >
          {{ restore.button }}
        </q-btn>
      </div>
    </div>
  </div>
</template>

<script>
// noinspection NpmUsedModulesInstalled
import { Confirm } from '@vjs/balabaqsha/views/VContingent/Dialog';
// noinspection NpmUsedModulesInstalled
import {
  QBtn,
} from '@quasar/components';

// noinspection NpmUsedModulesInstalled
import {
  STATEMENT_REFUSE_TRANSLATIONS, FALLBACK_LOCALE,
} from '@vjs/balabaqsha/consts';
import Localization from '../Mixins/QuasarLocale';

export default {
  name: 'VRefuseActions',
  components: {
    Confirm,
    QBtn,
  },
  mixins: [Localization],
  props: {
    returnTo: {
      type: Object,
      default: () => {},
    },
    refuse: {
      type: Object,
      default: () => {},
    },
    restore: {
      type: Object,
      default: () => {},
    },
    refuseId: {
      type: Number,
      default: () => -1,
    },
    locale: {
      type: String,
      default: () => FALLBACK_LOCALE,
    },
  },
  data() {
    return {
      confirmPopup: false,
      sending: false,
      confirmUri: null,
      popupText: null,
    };
  },
  beforeCreate() {
    this.$trans.add(STATEMENT_REFUSE_TRANSLATIONS);
  },
  beforeMount() {
    this.initLocale();
  },
  methods: {
    cancel() {
      this.confirmPopup = false;
      this.sending = false;
      this.confirmUri = null;
    },
    prepare(url, text) {
      this.confirmPopup = true;
      this.confirmUri = url;
      this.popupText = text;
    },
    async applyAct() {
      this.sending = true;
      const { $trans: { get: trans } } = this;
      try {
        const response = await this.$axios.post(
          this.confirmUri,
          {
            refuse_id: this.refuseId,
          },
          {
            headers: { 'X-localization': this.locale },
          },
        );
        const { data } = response;
        if (data.status === 'ok') {
          window.location.reload();
        } else if (data.message) {
          this.$notify({
            type: 'error',
            text: data.message,
          });
        } else {
          this.$notify({
            type: 'error',
            text: trans('notice.error_on_server'),
          });
        }
      } catch (e) {
        if (
          e.response
            && e.response.data
            && e.response.data.message
        ) {
          this.$notify({
            type: 'error',
            text: e.response.data.message,
          });
        } else {
          this.$notify({
            type: 'error',
            text: trans('notice.error_on_server'),
          });
        }
      }
      this.confirmPopup = false;
      this.sending = false;
    },
  },
};
</script>

<style scoped lang="scss">
  .confirm-text {
    font-size: 1.1em;
    padding: 0.8em 0;
  }

  .popup-btn-control{
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: 960px) {
    .refuse-action-group{
      .sn-btn{
        margin: 0.5em 0;
        min-height: 3.5em;
      }
    }
  }

  @media (max-width: 767px) {
    .refuse-action-group{
      display: flex;
      flex-direction: column;
      .btn-wrapper{
        text-align: center;
        .sn-btn{
          width: 100%;
          padding: 1em;
          margin: 0.5em 0;
        }
      }
    }
    .popup-btn-control{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .sn-btn{
        margin: 0;
      }
    }
  }
  @media (max-width: 350px) {
    .popup-btn-control{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .sn-btn{
        width: 100%;
        margin: 0.5em 0;
      }
    }
  }
</style>
