var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('form', {
    attrs: {
      "action": _vm.form.action,
      "method": _vm.form.method
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.handleAjaxFormSubmit(_vm.form);
      }
    }
  }, [_c('div', {
    staticClass: "s-form__inform"
  }, [_c('div', {
    staticClass: "s-str-ico s-str-ico--thm-info-alt s-str-ico--wider",
    domProps: {
      "innerHTML": _vm._s(_vm.trans('warning_text.modal_inform'))
    }
  })]), _vm._v(" "), _vm._l(_vm.form.fields, function (field) {
    return [_vm.handleVif(field) ? _c(field.component, _vm._g({
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.handleVShow(field),
        expression: "handleVShow(field)"
      }],
      key: field.name,
      tag: "component",
      attrs: {
        "field": field,
        "field-name": _vm.trans('label.role'),
        "errors": _vm.getErrors(_vm.form, field.name)
      },
      model: {
        value: field.value,
        callback: function callback($$v) {
          _vm.$set(field, "value", $$v);
        },
        expression: "field.value"
      }
    }, field.events ? field.events : {})) : _vm._e()];
  }), _vm._v(" "), _c('div', {
    staticClass: "row-sort-end"
  }, [_c('button', {
    staticClass: "s-btn s-btn--thm-green s-btn--w-200",
    attrs: {
      "type": "submit"
    }
  }, [_c('span', {
    staticClass: "s-str-ico s-str-ico--wider s-str-ico--thm-check"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('button.save')) + "\n        ")])])])], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }