var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-modal s-modal--big fade",
    attrs: {
      "id": "modal-user-files",
      "data-backdrop": "static",
      "tabindex": "-1"
    }
  }, [_c('div', {
    staticClass: "s-modal__container"
  }, [_c('div', {
    staticClass: "s-modal__head"
  }, [_c('div', {
    staticClass: "s-modal__title"
  }, [_vm._v("\n        " + _vm._s(_vm.trans('main.your_files')) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "s-modal__close",
    on: {
      "click": _vm.modalClose
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "s-modal__body"
  }, [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.file_inform')
    }
  }), _vm._v(" "), _vm.validateInform ? _c('v-form-inform', {
    attrs: {
      "message": _vm.validateInform
    }
  }) : _vm._e(), _vm._v(" "), _vm.multiple ? _c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.max_selected_files', {
        count: _vm.maxFiles
      })
    }
  }) : _vm._e(), _vm._v(" "), _vm.multiple ? _c('v-form-inform', {
    attrs: {
      "color": "blue",
      "message": _vm.trans('warning_text.file_select_instruction')
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "s-file__wrapper"
  }, [_c('div', {
    staticClass: "s-file-select"
  }, [_c('div', {
    staticClass: "s-file__preview"
  }, [_c('div', {
    staticClass: "s-file__preview-panel",
    on: {
      "click": function click($event) {
        return _vm.onClickInputFile();
      }
    }
  }, [_vm._m(0)]), _vm._v(" "), _c('input', {
    staticClass: "hidden",
    attrs: {
      "id": "user-file-upload-input",
      "type": "file"
    },
    on: {
      "change": _vm.onChangeInputFile
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "s-file__name"
  }, [_c('div', {
    staticClass: "s-str-ico s-str-ico--thm-plus"
  }, [_vm._v("\n              " + _vm._s(_vm.trans('main.add_file')) + "\n            ")])])]), _vm._v(" "), _vm.multiple ? _vm._l(_vm.files, function (file, key) {
    return _c('div', {
      key: key,
      staticClass: "s-file-select",
      class: {
        's-file-select--selected': _vm.multipleFileSelectCheck(file.id)
      }
    }, [_c('div', {
      staticClass: "s-file__preview",
      style: {
        backgroundImage: "url(".concat(file.url, ")")
      }
    }, [_c('div', {
      staticClass: "s-file__overlay-control",
      on: {
        "dblclick": function dblclick($event) {
          return _vm.onDblclickSelectFile(file);
        },
        "click": function click($event) {
          return _vm.onClickMultipleFiles(file);
        }
      }
    }, [_c('div', {
      staticClass: "s-file__btn s-file__btn--thm-select"
    })])]), _vm._v(" "), _c('div', {
      staticClass: "s-file__name"
    }, [_c('div', {
      staticClass: "s-str-ico s-str-ico--thm-clip",
      attrs: {
        "title": file.name
      }
    }, [_vm._v("\n                " + _vm._s(file.name) + "\n              ")])])]);
  }) : _vm._l(_vm.files, function (file, key) {
    return _c('div', {
      key: key,
      staticClass: "s-file-select",
      class: {
        's-file-select--selected': file.id === _vm.selectFile.id
      }
    }, [_c('div', {
      staticClass: "s-file__preview",
      style: {
        backgroundImage: "url(".concat(file.url, ")")
      }
    }, [_c('div', {
      staticClass: "s-file__overlay-control",
      on: {
        "click": function click($event) {
          return _vm.onChangeSelectFile(file);
        },
        "dblclick": function dblclick($event) {
          return _vm.onDblclickSelectFile(file);
        }
      }
    }, [_c('div', {
      staticClass: "s-file__btn s-file__btn--thm-select"
    })])]), _vm._v(" "), _c('div', {
      staticClass: "s-file__name"
    }, [_c('div', {
      staticClass: "s-str-ico s-str-ico--thm-clip",
      attrs: {
        "title": file.name
      }
    }, [_vm._v("\n                " + _vm._s(file.name) + "\n              ")])])]);
  })], 2), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-end"
  }, [_c('div', {
    staticClass: "col-12 col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "s-btn--mw-200 s-w-100",
    attrs: {
      "color": "green",
      "disable": !_vm.validate,
      "label-wider": "",
      "no-caps": "",
      "icon": "check",
      "label": _vm.trans('button.save')
    },
    on: {
      "click": _vm.onSaveSelect
    }
  })], 1)])], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-file__btn s-file__btn--thm-add"
  }, [_c('div', {
    staticClass: "s-ico s-ico--thm-plus"
  })]);
}]

export { render, staticRenderFns }