var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-region-select', {
    attrs: {
      "modal": _vm.modalRegionSelect,
      "persistent": "",
      "set-ajax": ""
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.modalRegionSelect = $event;
      },
      "update-locality": _vm.handleUpdateLocality
    }
  }), _vm._v(" "), _c('v-text-modal', {
    attrs: {
      "modal": _vm.modalText,
      "text": _vm.tab === 'all' ? _vm.trans('bulletin.bulletin_application_description_all') : _vm.trans('bulletin.bulletin_application_description_org')
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.modalText = $event;
      }
    }
  }), _vm._v(" "), _c('q-tabs', {
    staticClass: "sn-tabs--thm-button mb-20",
    attrs: {
      "vertical": _vm.$q.screen.width < 1349 && (_vm.$q.screen.width > 1008 || _vm.$q.screen.width < 480),
      "inline-label": "",
      "no-caps": "",
      "align": "left",
      "active-color": "white",
      "active-bg-color": "blue"
    },
    on: {
      "input": _vm.changedTab
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('q-tab', {
    attrs: {
      "name": "all",
      "icon": "layout-grid2-thumb",
      "label": _vm.trans('bulletin.all_list')
    }
  }), _vm._v(" "), _c('q-tab', {
    attrs: {
      "name": "org",
      "icon": "home",
      "label": _vm.trans('bulletin.bala_list')
    }
  })], 1), _vm._v(" "), _vm.$q.screen.width < 1009 ? _c('div', {
    staticClass: "row justify-content-end s-balabaqsha-catalog__filter-controls mb-20"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-btn', {
    staticClass: "s-w-100 d-flex",
    attrs: {
      "color": "blue",
      "outline": !_vm.filterShow,
      "icon": "menu-alt",
      "label": _vm.trans('button.filter'),
      "label-wider": ""
    },
    on: {
      "click": function click($event) {
        _vm.filterShow = !_vm.filterShow;
      }
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-auto"
  }, [_c('q-btn', {
    staticClass: "d-flex",
    attrs: {
      "color": "blue",
      "outline": "",
      "icon": "info-alt",
      "squire": ""
    },
    on: {
      "click": function click($event) {
        _vm.modalText = true;
      }
    }
  })], 1)]) : _vm._e(), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.filterShow || _vm.$q.screen.width > 1008 ? _c('div', {
    staticClass: "s-balabaqsha-catalog__filter"
  }, [_c('div', {
    staticClass: "row align-items-stretch mb-10"
  }, [_c('div', {
    staticClass: "col-lg-12 col-md-4 col-sm-6"
  }, [_c('q-select', {
    attrs: {
      "options": _vm.localityOptions,
      "label": _vm.trans('bulletin.locality')
    },
    on: {
      "input": _vm.requestOrg
    },
    model: {
      value: _vm.localitySelected,
      callback: function callback($$v) {
        _vm.localitySelected = $$v;
      },
      expression: "localitySelected"
    }
  })], 1), _vm._v(" "), _vm.filterItems && _vm.filterItems.age && _vm.tab !== 'org' ? _c('div', {
    staticClass: "col-lg-12 col-md-4 col-sm-6"
  }, [_c('q-select', {
    attrs: {
      "options": _vm.filterItems.age,
      "label": _vm.trans('bulletin.ages_list')
    },
    model: {
      value: _vm.ageSelectedNew,
      callback: function callback($$v) {
        _vm.ageSelectedNew = $$v;
      },
      expression: "ageSelectedNew"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.filterItems && _vm.filterItems.type ? _c('div', {
    staticClass: "col-lg-12 col-md-4 col-sm-6 mb-10"
  }, [_c('q-select', {
    attrs: {
      "options": _vm.bulletinTypeList,
      "label": _vm.trans('bulletin.correction_type')
    },
    model: {
      value: _vm.bulletinType,
      callback: function callback($$v) {
        _vm.bulletinType = $$v;
      },
      expression: "bulletinType"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.filterItems && _vm.filterItems.kind ? _c('div', {
    staticClass: "col-lg-12 col-md-4 col-sm-6 mb-10"
  }, [_c('q-select', {
    attrs: {
      "options": _vm.bulletinKindList,
      "label": _vm.trans('bulletin.kind'),
      "emit-value": "",
      "map-options": ""
    },
    model: {
      value: _vm.kindSelected,
      callback: function callback($$v) {
        _vm.kindSelected = $$v;
      },
      expression: "kindSelected"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.orgNameOptions.length > 0 ? _c('div', {
    staticClass: "col-lg-12 col-md-4 col-sm-6"
  }, [_c('v-wrapper-q-select', {
    attrs: {
      "options": _vm.orgNameOptions,
      "option-value": "value",
      "option-label": "label",
      "extra-binds": {
        label: _vm.trans('bulletin.organization_name'),
        placeholder: ''
      }
    },
    model: {
      value: _vm.orgNameSelected,
      callback: function callback($$v) {
        _vm.orgNameSelected = $$v;
      },
      expression: "orgNameSelected"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.filterItems && _vm.filterItems.status && _vm.tab !== 'org' ? _c('div', {
    staticClass: "col-lg-12 col-md-4 col-sm-6"
  }, [_c('q-field', {
    attrs: {
      "label": _vm.trans('bulletin.status'),
      "borderless": ""
    }
  }, [_c('div', {
    staticClass: "d-inline-flex flex-column"
  }, _vm._l(_vm.filterItems.status, function (item) {
    return _c('q-radio', {
      key: "".concat(item.value, "-status"),
      attrs: {
        "val": item.value,
        "label": _vm.trans(item.label)
      },
      model: {
        value: _vm.statusSelected,
        callback: function callback($$v) {
          _vm.statusSelected = $$v;
        },
        expression: "statusSelected"
      }
    });
  }), 1)])], 1) : _vm._e(), _vm._v(" "), _vm.filterItems && _vm.filterItems.language && _vm.tab !== 'org' ? _c('div', {
    staticClass: "col-lg-12 col-md-4 col-sm-6"
  }, [_c('q-field', {
    attrs: {
      "label": _vm.trans('bulletin.language'),
      "borderless": ""
    }
  }, [_c('div', {
    staticClass: "d-inline-flex flex-column"
  }, _vm._l(_vm.filterItems.language, function (item) {
    return _c('q-radio', {
      key: "".concat(item.value, "-lang"),
      attrs: {
        "val": item.value,
        "label": item.label
      },
      model: {
        value: _vm.languageSelected,
        callback: function callback($$v) {
          _vm.languageSelected = $$v;
        },
        expression: "languageSelected"
      }
    });
  }), 1)])], 1) : _vm._e(), _vm._v(" "), _vm.filterItems && _vm.filterItems.organizationType ? _c('div', {
    staticClass: "col-lg-12 col-md-4 col-sm-6"
  }, [_c('q-field', {
    attrs: {
      "label": _vm.trans('bulletin.org_type'),
      "borderless": ""
    }
  }, [_c('div', {
    staticClass: "d-inline-flex flex-column"
  }, _vm._l(_vm.filterItems.organizationType, function (item) {
    return _c('q-radio', {
      key: "".concat(item.value, "-org"),
      attrs: {
        "val": item.value,
        "label": _vm.trans(item.label)
      },
      model: {
        value: _vm.organizationTypeSelected,
        callback: function callback($$v) {
          _vm.organizationTypeSelected = $$v;
        },
        expression: "organizationTypeSelected"
      }
    });
  }), 1)])], 1) : _vm._e(), _vm._v(" "), _vm.filterItems && _vm.filterItems.groupType ? _c('div', {
    staticClass: "col-lg-12 col-md-4 col-sm-6"
  }, [_c('q-field', {
    attrs: {
      "label": _vm.trans('bulletin.group_type'),
      "borderless": ""
    }
  }, [_c('div', {
    staticClass: "d-inline-flex flex-column"
  }, _vm._l(_vm.filterItems.groupType, function (item) {
    return _c('q-radio', {
      key: "".concat(item.value, "-group"),
      attrs: {
        "val": item.value,
        "label": _vm.trans(item.label)
      },
      model: {
        value: _vm.groupTypeSelected,
        callback: function callback($$v) {
          _vm.groupTypeSelected = $$v;
        },
        expression: "groupTypeSelected"
      }
    });
  }), 1)])], 1) : _vm._e(), _vm._v(" "), _c('q-slide-transition', [_vm.filterItems && _vm.filterItems.diseases && _vm.typeSelected === 1 ? _c('div', {
    staticClass: "col-lg-12 col-md-4 col-sm-6",
    class: !_vm.diseasesSpoiler ? 'mb-10' : ''
  }, [_c('div', {
    staticClass: "s-form-filter__spoiler-head",
    on: {
      "click": function click($event) {
        _vm.diseasesSpoiler = !_vm.diseasesSpoiler;
      }
    }
  }, [_c('h5', {
    staticClass: "s-combine-title"
  }, [_c('span', {
    staticClass: "s-combine-title__label s-upper"
  }, [_vm._v("\n                  " + _vm._s(_vm.trans('bulletin.diseases')) + "\n                ")]), _vm._v(" "), _c('span', {
    staticClass: "s-w-100 s-to-right"
  }, [_c('button', {
    staticClass: "s-combine-title__button"
  }, [_c('span', {
    staticClass: "s-str-ico s-str-ico--wider",
    class: _vm.diseasesSpoiler ? 's-str-ico--thm-angle-up' : 's-str-ico--thm-angle-down',
    staticStyle: {
      "vertical-align": "middle"
    }
  })])])])]), _vm._v(" "), _c('q-slide-transition', [_vm.diseasesSpoiler ? _c('q-field', {
    attrs: {
      "borderless": ""
    }
  }, [_c('div', {
    staticClass: "d-inline-flex flex-column"
  }, [_c('q-option-group', {
    attrs: {
      "options": _vm.filterItems.diseases,
      "label": _vm.trans('bulletin.diseases'),
      "type": "checkbox"
    },
    model: {
      value: _vm.diseases,
      callback: function callback($$v) {
        _vm.diseases = $$v;
      },
      expression: "diseases"
    }
  })], 1)]) : _vm._e()], 1)], 1) : _vm._e(), _vm._v(" "), _vm.filterItems && _vm.filterItems.sanatorium && _vm.typeSelected === 2 ? _c('div', {
    staticClass: "col-lg-12 col-md-4 col-sm-6",
    class: !_vm.sanatoriumSpoiler ? 'mb-10' : ''
  }, [_c('div', {
    staticClass: "s-form-filter__spoiler-head",
    on: {
      "click": function click($event) {
        _vm.sanatoriumSpoiler = !_vm.sanatoriumSpoiler;
      }
    }
  }, [_c('h5', {
    staticClass: "s-combine-title"
  }, [_c('span', {
    staticClass: "s-combine-title__label s-upper"
  }, [_vm._v("\n                  " + _vm._s(_vm.trans('bulletin.sanatoria')) + "\n                ")]), _vm._v(" "), _c('span', {
    staticClass: "s-w-100 s-to-right"
  }, [_c('button', {
    staticClass: "s-combine-title__button"
  }, [_c('span', {
    staticClass: "s-str-ico s-str-ico--wider",
    class: _vm.sanatoriumSpoiler ? 's-str-ico--thm-angle-up' : 's-str-ico--thm-angle-down',
    staticStyle: {
      "vertical-align": "middle"
    }
  })])])])]), _vm._v(" "), _c('q-slide-transition', [_vm.sanatoriumSpoiler ? _c('q-field', {
    attrs: {
      "borderless": ""
    }
  }, [_c('div', {
    staticClass: "d-inline-flex flex-column"
  }, [_c('q-option-group', {
    attrs: {
      "options": _vm.filterItems.sanatorium,
      "label": _vm.trans('bulletin.sanatoria'),
      "type": "checkbox"
    },
    model: {
      value: _vm.sanatorium,
      callback: function callback($$v) {
        _vm.sanatorium = $$v;
      },
      expression: "sanatorium"
    }
  })], 1)]) : _vm._e()], 1)], 1) : _vm._e()])], 1), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-end"
  }, [_c('div', {
    staticClass: "col-lg-12 col-sm-auto mb-20"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "color": "green",
      "label": _vm.trans('button.enter'),
      "loading": _vm.loading
    },
    on: {
      "click": _vm.handleApplyFilters
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-lg-12 col-sm-auto s-balabaqsha-catalog__filter-reset"
  }, [_c('span', {
    staticClass: "s-w-100 mb-20 sn--cursor-pointer",
    on: {
      "click": _vm.resetFilters
    }
  }, [_vm._v(_vm._s(_vm.trans('button.flash_filter')))])])])]) : _vm._e()]), _vm._v(" "), _vm.$q.screen.width > 1008 ? _c('div', {
    staticClass: "s-balabaqsha-catalog__blockquote",
    domProps: {
      "innerHTML": _vm._s(_vm.tab === 'all' ? _vm.trans('bulletin.bulletin_application_description_all') : _vm.trans('bulletin.bulletin_application_description_org'))
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }