var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('form', {
    staticClass: "row-sort-wrap",
    attrs: {
      "method": "get"
    }
  }, [_c('div', {
    staticClass: "row align-items-stretch mb-10"
  }, [_c('div', {
    staticClass: "col-md-4 col-sm-6"
  }, [_c('q-select', {
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "options": _vm.localityOptions,
      "label": _vm.trans('label.locality'),
      "disable": _vm.loading,
      "emit-value": "",
      "map-options": "",
      "use-input": "",
      "input-debounce": "0"
    },
    on: {
      "input": _vm.requestOrg,
      "filter": _vm.filterFn
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('q-icon', {
          attrs: {
            "name": "place"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "name": "close"
          },
          on: {
            "click": _vm.clearLocality
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.locality,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "locality", $$v);
      },
      expression: "form.locality"
    }
  })], 1), _vm._v(" "), _vm.orgNameOptions.length > 0 ? _c('div', {
    staticClass: "col-md-4 col-sm-6"
  }, [_c('q-select', {
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "options": _vm.orgNameOptions,
      "label": _vm.trans('bulletin.organization_name'),
      "disable": _vm.loading,
      "emit-value": "",
      "map-options": "",
      "use-input": "",
      "input-debounce": "0"
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('q-icon', {
          attrs: {
            "name": "place"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "name": "close"
          },
          on: {
            "click": function click($event) {
              _vm.form.organization = '';
            }
          }
        })];
      },
      proxy: true
    }], null, false, 886872594),
    model: {
      value: _vm.form.organization,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "organization", $$v);
      },
      expression: "form.organization"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "col-md-4 col-sm-6"
  }, [_c('q-select', {
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "options": _vm.organizationTypesOptions,
      "label": _vm.trans('label.filter_organization_type'),
      "disable": _vm.loading,
      "emit-value": "",
      "map-options": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('q-icon', {
          attrs: {
            "name": "place"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "name": "close"
          },
          on: {
            "click": function click($event) {
              _vm.form.organizationType = '';
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.organizationType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "organizationType", $$v);
      },
      expression: "form.organizationType"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-md-4 col-sm-6"
  }, [_c('q-select', {
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "options": _vm.sourcesOptions,
      "label": _vm.trans('label.filter_source'),
      "disable": _vm.loading,
      "emit-value": "",
      "map-options": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('q-icon', {
          attrs: {
            "name": "place"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "name": "close"
          },
          on: {
            "click": function click($event) {
              _vm.form.source = '';
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.source,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "source", $$v);
      },
      expression: "form.source"
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "row align-items-stretch mb-10 justify-content-end"
  }, [_c('div', {
    staticClass: "col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "color": "green",
      "no-caps": "",
      "label": _vm.trans('button.export'),
      "loading": _vm.loading
    },
    on: {
      "click": _vm.exportu
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "color": "orange",
      "no-caps": "",
      "label": _vm.trans('button.enter'),
      "loading": _vm.loading
    },
    on: {
      "click": _vm.submit
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "row align-items-stretch mb-10  justify-content-end"
  }, [_c('div', {
    staticClass: "col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "q-ml-md s-pos-float-right mt-10",
    attrs: {
      "squire": "",
      "unelevated": "",
      "icon": "fullscreen",
      "color": "grey"
    },
    on: {
      "click": function click($event) {
        _vm.table.inFullscreen = true;
      }
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.fullscreen_on')))])], 1)], 1)])]), _vm._v(" "), _c('div', [_c('q-table', {
    attrs: {
      "data": _vm.statements,
      "pagination": _vm.table.pagination,
      "columns": _vm.table.columns,
      "row-key": "index + 1",
      "fullscreen": _vm.table.inFullscreen
    },
    on: {
      "update:pagination": function updatePagination($event) {
        return _vm.$set(_vm.table, "pagination", $event);
      }
    },
    scopedSlots: _vm._u([_vm.table.inFullscreen ? {
      key: "top",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "row align-items-stretch justify-content-end s-w-100"
        }, [_c('div', {
          staticClass: "col-sm-auto"
        }, [_c('q-btn', {
          staticClass: "q-ml-md s-pos-float-right mt-10",
          attrs: {
            "squire": "",
            "unelevated": "",
            "icon": "fullscreen",
            "color": "blue"
          },
          on: {
            "click": function click($event) {
              _vm.table.inFullscreen = false;
            }
          }
        }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.fullscreen_off')))])], 1)], 1)])];
      }
    } : null, {
      key: "body",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, _vm._l(props.cols, function (col) {
          return _c('q-td', {
            key: col.name,
            style: [col.name === 'ac' && {
              'white-space': 'normal'
            }]
          }, [['sf', 'df', 'cf'].includes(col.name) && props.row[col.name] ? _c('a', {
            attrs: {
              "target": "_blank",
              "href": props.row[col.name]
            }
          }, [_vm._v("\n                " + _vm._s(_vm.trans('label.view')) + "\n              ")]) : [_vm._v("\n                " + _vm._s(props.row[col.name] || _vm.trans('label.not_found')) + "\n              ")]], 2);
        }), 1)];
      }
    }], null, true)
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }