var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "catalog",
    staticClass: "s-balabaqsha-catalog"
  }, [_c('v-directions-filters', {
    staticClass: "s-balabaqsha-catalog__filter-wrapper",
    attrs: {
      "uri-get-organizations": _vm.uriGetOrganizations,
      "uri-get-years": _vm.uriGetYears,
      "uri-locality": _vm.uriLocality,
      "uri-statistic": _vm.uriStatistic,
      "uri-filters": _vm.uriFilters,
      "uri-get-directions": _vm.uriGetDirections
    },
    on: {
      "filtered-list": _vm.handleFilteredList,
      "filter-loading": _vm.handleFilterLoading
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "s-balabaqsha-catalog__content"
  }, [_c('div', {
    staticClass: "direction-information-panel sn--text-center"
  }, [_vm._v(" " + _vm._s(_vm.trans('directions.warning_message')))]), _vm._v(" "), !_vm.empty ? _c('div', [_c('table', {
    staticClass: "s-table s-table--striped s-table--thm-head-grey"
  }, [_c('thead', {
    staticClass: "s-table__head"
  }, [_c('tr', [_c('th', {
    staticClass: "s-table__col-7 s-table__col--to-center"
  }, [_vm._v("\n              " + _vm._s(_vm.trans('directions.kinder_garden')) + "\n            ")]), _vm._v(" "), _c('th', {
    staticClass: "s-table__col-5 s-table__col--to-center"
  }, [_vm._v("\n              " + _vm._s(_vm.trans('table.child_fullname')) + "\n            ")]), _vm._v(" "), _c('th', {
    staticClass: "s-table__col-1 s-table__col--to-center"
  }, [_vm._v("\n              " + _vm._s(_vm.trans('fields.group_category')) + "\n            ")]), _vm._v(" "), _c('th', {
    staticClass: "s-table__col-2 s-table__col--to-center"
  }, [_vm._v("\n              " + _vm._s(_vm.trans('directions.group')) + "\n            ")]), _vm._v(" "), _c('th', {
    staticClass: "s-table__col-7 s-table__col--to-center"
  }, [_vm._v("\n              " + _vm._s(_vm.trans('bulletin.kind')) + "\n            ")]), _vm._v(" "), _c('th', {
    staticClass: " s-table__col--to-center"
  }, [_vm._v("\n              " + _vm._s(_vm.trans('directions.lang')) + "\n            ")]), _vm._v(" "), _c('th', {
    staticClass: "s-table__col-4 s-table__col--to-center"
  }, [_vm._v("\n              " + _vm._s(_vm.trans('queue.privilege')) + "\n            ")]), _vm._v(" "), _c('th', {
    staticClass: " s-table__col--to-center"
  }, [_vm._v("\n              " + _vm._s(_vm.trans('table.queue_list_date')) + "\n            ")]), _vm._v(" "), _c('th', {
    staticClass: " s-table__col--to-center"
  }, [_vm._v("\n              " + _vm._s(_vm.trans('directions.date_of_issue')) + "\n            ")])])]), _vm._v(" "), _c('tbody', {
    staticClass: "s-table__body"
  }, _vm._l(_vm.paginate, function (itm, idx) {
    return _c('v-directions-table', {
      key: idx,
      attrs: {
        "school_name": itm.school_name,
        "child_name": itm.child_name,
        "child_year": itm.child_year,
        "group_name": itm.group_name,
        "free_place_type": itm.free_place_type,
        "language": itm.language,
        "privilege": itm.privilege,
        "created_at": itm.created_at,
        "updated_at": itm.updated_at,
        "loading": _vm.loading
      }
    });
  }), 1)])]) : _vm._e(), _vm._v(" "), _vm.countOfPages ? _c('div', {
    staticClass: "row justify-content-center mt-30"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-pagination', {
    attrs: {
      "max": _vm.countOfPages,
      "max-pages": 5,
      "boundary-links": true,
      "input": _vm.countOfPages > 5,
      "input-class": "s-c-blue"
    },
    on: {
      "input": _vm.changePagination
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)]) : _vm._e(), _vm._v(" "), _vm.empty ? _c('v-no-results', {
    attrs: {
      "image": "search",
      "title": _vm.trans('warning_text.no_result_directions_repository_title'),
      "text": _vm.trans('warning_text.no_result_directions_repository_text')
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }