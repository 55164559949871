import {
  requestWrapper,
  url,
} from '@vjs/helpers';
import {
  QForm,
  QInput,
  QSelect,
  QField,
  QCheckbox,
  QOptionGroup,
  QBtn,
  QSlideTransition,
} from '@quasar/components';
import { Loading } from '@quasar/plugins';
import VFormInform from '@vjs/components/VFormInform';
import VReferenceTime from '@vjs/components/VReferenceTime';
import _ from 'lodash';
import { is_null } from 'locutus/php/var';
import Validation from '../mixins/Validation';


export default {
  name: 'VStepAddStatement',

  components: {
    QForm,
    QInput,
    QBtn,
    QSelect,
    QField,
    QCheckbox,
    QOptionGroup,
    QSlideTransition,
    VFormInform,
    VReferenceTime,
  },

  mixins: [Validation],

  props: {
    uriGetStatementFormData: {
      type: String,
      required: true,
    },
    uriSendStatement: {
      type: String,
      required: true,
    },
    uriGetLgotTypeDocuments: {
      type: String,
      required: true,
    },
    uriGetLgotTypes: {
      type: String,
      required: true,
    },
    uriRegions: {
      type: String,
      default: 'api/v1/regions',
    },
    uriDepartments: {
      type: String,
      default: 'api/v1/department',
    },
    uriLocalities: {
      type: String,
      default: 'api/v1/active-localities',
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    queueTypeOptions: {
      type: Array,
      required: true,
    },
    parentId: {
      type: Number,
      required: true,
    },
    childId: {
      type: Number,
      required: true,
    },
    referenceTime: Number,
  },

  data() {
    return {
      loading: false,
      qLoading: Loading,
      activeFile: null,
      form: {
        groupTypeCurrent: { value: null },
        groupTypeOptions: [],
        langCurrent: [],
        langOptions: [],
        regionCurrent: { value: null },
        regionOptions: [],
        departmentCurrent: { value: null },
        departmentOptions: [],
        localityCurrent: { value: null },
        localityOptions: [],
        orgCurrent: { value: null },
        orgOptions: [],
        statusCurrent: this.statusOptions[0],
        queueType: null,
        lgotType: {
          value: 0,
          label: this.trans('statement.socStatuses.no_lgot'),
        },
        lgotTypeOptions: [],
        lgotTypeDocuments: [],
        lgotTypeDocumentRules: [],
      },
      options: {
      },
    };
  },

  created() {
    this.$eventHub.$on('SET_NEXT_STEP', this.requestForm);
  },

  beforeDestroy() {
    this.$eventHub.$off('SET_NEXT_STEP', this.requestForm);
  },

  async mounted() {
    this.qLoading.show();
    await this.$trans.add([
      'label',
      'validation',
      'warning_text',
      'statement',
    ]);

    const regRes = await requestWrapper.call(this, {
      url: url(this.uriRegions),
    });

    if (!regRes.error) {
      this.form.regionOptions = regRes.data;
    }

    const formData = await requestWrapper.call(this, {
      url: this.uriGetStatementFormData,
    });

    if (!formData.error) {
      this.form.groupTypeOptions = formData.groupType;
      this.form.groupTypeCurrent = this.form.groupTypeOptions[0];
      this.form.langOptions = formData.langs;
      this.form.lgotType = {
        value: 0,
        label: this.trans('statement.socStatuses.no_lgot'),
      };
    }
    this.qLoading.hide();
  },

  watch: {
    validate(val) {
      this.$store.commit('SET_ACCESS_STEP', {
        step: 5,
        access: val,
      });
    },
    'form.statementType': function (val) {
      if (!val) {
        this.form.statementDocument = null;
      }
    },
    'form.localityCurrent': function (val) {
      if (val && val.hasOrgQueue === 1) {
        this.requestOrgQueue(val.value);
      } else {
        this.form.orgCurrent = null;
        this.form.orgOptions = [];
      }
    },
  },

  computed: {
    validate() {
      let lgotDocumentsValidate = true;
      _.forEach(this.form.lgotTypeDocuments, (file, key) => {
        if (this.form.lgotTypeDocumentRules[key].required === true && is_null(file)) {
          lgotDocumentsValidate = false;
        }
      });

      return this.form.groupTypeCurrent
        && this.form.groupTypeCurrent.value !== null
        && this.form.langCurrent.length > 0
        && this.form.regionCurrent
        && this.form.regionCurrent.value !== null
        && this.form.departmentCurrent
        && this.form.departmentCurrent.value !== null
        && this.form.localityCurrent
        && this.form.localityCurrent.value !== null
        && (this.form.queueType === 0
          || (this.form.queueType !== null
            && this.form.lgotType.value !== null
            && this.form.lgotType.value !== 0
            && Object.keys(this.form.lgotTypeDocuments).length > 0
            && lgotDocumentsValidate
          )
        );
    },
  },

  methods: {
    async requestOrgQueue(localityId) {
      const res = await requestWrapper.call(this, {
        url: '/api/v1/statement-organizations',
        params: {
          locality_id: localityId,
        },
      });

      if (!res.error) {
        this.form.orgOptions = res.data;
      }
    },
    async requestForm() {
      this.$emit('loading', true);
      const formData = new FormData();
      formData.append('userId', this.parentId);
      formData.append('childId', this.childId);
      formData.append('type', this.form.groupTypeCurrent.value);
      this.form.langCurrent.forEach((item, index) => {
        formData.append(`lang[${index}]`, item);
      });
      // formData.append('lang', this.form.langCurrent);
      formData.append('locality', this.form.localityCurrent.value);
      formData.append('department', this.form.departmentCurrent.value);
      formData.append('soc_status', this.form.statusCurrent.value);
      formData.append('agreement', true);

      if (this.form.orgCurrent && parseInt(this.form.orgCurrent.value, 10) >= 0) {
        formData.append('is_organization_statement', 1);
        formData.append('organization_id', this.form.orgCurrent.value);
      }
      if (this.form.queueType > 0) {
        formData.append('lgot_type', this.form.lgotType.value);
        formData.append('queue_type', this.form.queueType);
        _.forEach(this.form.lgotTypeDocuments, (file, name) => {
          formData.append(name, file);
        });
      }
      const formRes = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriSendStatement,
        data: formData,
      });
      if (formRes.error) {
        if (formRes.data.errors) {
          _.forEach(formRes.data.errors, (value) => {
            this.$notify({
              type: 'error',
              text: value,
            });
          });
        }
      }
      if (!formRes.error) {
        this.$store.state.vSteps.currentStep = 6;
      }

      this.$emit('loading', false);
    },

    async checkOptions(keyCur = '', keyOptions, keyRequest, keyParams) {
      if (this.form[keyCur]
        && this.form[keyCur].value
        && keyOptions
        && keyRequest
        && keyParams
      ) {
        const res = await requestWrapper.call(this, {
          url: url(this[keyRequest]),
          params: {
            [keyParams]: this.form[keyCur].value,
          },
        });

        if (!res.error) {
          this.form[keyOptions] = res.data;
        } else if (keyOptions === 'localityOptions') {
          this.form[keyOptions] = [];
        }
      }

      if (keyCur === 'regionCurrent') {
        this.form.localityOptions = [];
        this.form.departmentCurrent = null;
        this.form.localityCurrent = null;
      }

      if (keyCur === 'departmentCurrent') {
        this.form.localityCurrent = null;
      }
    },
    clearFile(filename) {
      this.form.lgotTypeDocuments[filename] = null;
    },
    fileChange() {
      const file = this.$refs.fileInput.files[0];
      if (file) {
        this.form.lgotTypeDocuments[this.activeFile] = file;
      }
      this.activeFile = null;
      this.$refs.fileInput.value = null;
    },
    triggerFile(filename) {
      this.activeFile = filename;
      this.$refs.fileInput.click();
    },
    async getLgotTypeDocuments() {
      const response = await requestWrapper({
        method: 'GET',
        url: this.uriGetLgotTypeDocuments,
        params: {
          lgotType: this.form.lgotType.value,
        },
      });

      if (response.message) {
        this.$notify({
          text: response.message,
          type: 'error',
        });
        this.form.lgotTypeDocuments = {};
        this.form.lgotTypeDocumentRules = {};
      } else {
        this.form.lgotTypeDocuments = response.data.documents;
        this.form.lgotTypeDocumentRules = response.data.documentRules;
      }
    },
    async clearLgots() {
      await this.loadLgotTypeOptions();
      this.form.lgotType = {
        value: 0,
        label: this.trans('statement.socStatuses.no_lgot'),
      };
      this.form.lgotTypeDocuments = {};
      this.form.lgotTypeDocumentRules = {};
      this.form.lgotTypeDocumentRules = {};
    },
    async loadLgotTypeOptions() {
      const response = await requestWrapper({
        method: 'GET',
        url: this.uriGetLgotTypes,
        params: {
          queueType: this.form.queueType,
        },
      });

      if (response.message) {
        this.$notify({
          text: response.message,
          type: 'error',
        });
      }
      this.form.lgotTypeOptions = response.data;
    },
  },
};
