var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-form', {
    ref: "form",
    staticClass: "s-form__create",
    on: {
      "submit": _vm.sendRequest
    }
  }, [_vm.action ? _c('p', {
    staticClass: "mb-30"
  }, [_vm._v("\n    " + _vm._s(_vm.trans('label.search_iin')) + "\n  ")]) : _c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.modal_inform')
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "name": "user.iin",
      "placeholder": _vm.trans('placeholder.iin'),
      "label": _vm.trans('label.iin'),
      "mask": '############',
      "disable": _vm.disableIin,
      "required": "",
      "rules": [function (val) {
        return _vm.validateIin(val) || _vm.trans('validation.check_iin', {
          attribute: _vm.trans('placeholder.search_iin')
        });
      }],
      "lazy-rules": "",
      "error": _vm.hasValidationFail('user.iin'),
      "error-message": _vm.getValidationMessage('user.iin')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('user.iin');
      }
    },
    model: {
      value: _vm.user.iin,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "iin", $$v);
      },
      expression: "user.iin"
    }
  }), _vm._v(" "), _vm.user.user_id > 0 ? _c('h2', {
    staticClass: "mb-30",
    staticStyle: {
      "color": "green"
    }
  }, [_vm._v("\n    " + _vm._s(_vm.user.firstname) + " " + _vm._s(_vm.user.surname) + "\n  ")]) : _vm._e(), _vm._v(" "), _vm.user.loaded ? _c('div', [_c('q-select', {
    attrs: {
      "options": _vm.options.nationalitiesFiltered,
      "label": _vm.trans('label.select_nationality'),
      "option-value": "code",
      "use-input": ""
    },
    on: {
      "filter": _vm.filterNationality
    },
    model: {
      value: _vm.selected.nationality_id,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "nationality_id", $$v);
      },
      expression: "selected.nationality_id"
    }
  }), _vm._v(" "), _c('q-select', {
    attrs: {
      "options": _vm.options.migrationStatuses,
      "option-value": "code",
      "label": _vm.trans('label.migration_status')
    },
    model: {
      value: _vm.selected.migration_status,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "migration_status", $$v);
      },
      expression: "selected.migration_status"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "placeholder": _vm.trans('placeholder.surname'),
      "label": _vm.trans('label.surname'),
      "required": "",
      "error": _vm.hasValidationFail('user.surname'),
      "error-message": _vm.getValidationMessage('user.surname')
    },
    on: {
      "input": function input($event) {
        _vm.flashValidationFail('user.surname');

        _vm.validateInput('user.surname')($event);
      }
    },
    model: {
      value: _vm.user.surname,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "surname", $$v);
      },
      expression: "user.surname"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "placeholder": _vm.trans('placeholder.firstname'),
      "label": _vm.trans('label.firstname'),
      "required": "",
      "error": _vm.hasValidationFail('user.firstname'),
      "error-message": _vm.getValidationMessage('user.firstname')
    },
    on: {
      "input": function input($event) {
        _vm.flashValidationFail('user.firstname');

        _vm.validateInput('user.firstname')($event);
      }
    },
    model: {
      value: _vm.user.firstname,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "firstname", $$v);
      },
      expression: "user.firstname"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "placeholder": _vm.trans('placeholder.middle_name'),
      "label": _vm.trans('label.middle_name')
    },
    on: {
      "input": function input($event) {
        _vm.validateInput('user.lastname')($event);
      }
    },
    model: {
      value: _vm.user.lastname,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "lastname", $$v);
      },
      expression: "user.lastname"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "filled": "",
      "required": "",
      "mask": "##.##.####",
      "label": _vm.trans('label.born_date'),
      "placeholder": _vm.trans('label.select_date'),
      "error": _vm.hasValidationFail('user.born_date'),
      "error-message": _vm.getValidationMessage('user.born_date')
    },
    on: {
      "click": function click() {
        return _vm.$refs.qDateActiveProxy.show();
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "sn--cursor-pointer",
          attrs: {
            "name": "calendar"
          }
        }, [_c('q-popup-proxy', {
          ref: "qDateActiveProxy",
          attrs: {
            "transition-show": "scale",
            "transition-hide": "scale"
          }
        }, [_c('q-date', {
          attrs: {
            "mask": "DD.MM.YYYY",
            "color": "green"
          },
          on: {
            "input": function input() {
              _vm.$refs.qDateActiveProxy.hide();

              _vm.flashValidationFail('user.born_date');
            }
          },
          model: {
            value: _vm.user.born_date,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "born_date", $$v);
            },
            expression: "user.born_date"
          }
        })], 1)], 1)];
      },
      proxy: true
    }], null, false, 2936301285),
    model: {
      value: _vm.user.born_date,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "born_date", $$v);
      },
      expression: "user.born_date"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "placeholder": _vm.trans('placeholder.email'),
      "label": _vm.trans('label.email'),
      "rules": [function (val) {
        return _vm.validateEmail(val) || _vm.trans('validation.email', {
          attribute: _vm.trans('label.email')
        });
      }],
      "lazy-rules": "",
      "error": _vm.hasValidationFail('user.email'),
      "error-message": _vm.getValidationMessage('user.email')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('user.email');
      }
    },
    model: {
      value: _vm.user.email,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "email", $$v);
      },
      expression: "user.email"
    }
  }), _vm._v(" "), _c('q-field', {
    attrs: {
      "label": _vm.trans('label.gender'),
      "borderless": ""
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-radio', {
    attrs: {
      "val": _vm.genderOptions[0].value,
      "label": _vm.trans(_vm.genderOptions[0].label)
    },
    model: {
      value: _vm.user.sex,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "sex", $$v);
      },
      expression: "user.sex"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-auto"
  }, [_c('q-radio', {
    attrs: {
      "val": _vm.genderOptions[1].value,
      "label": _vm.trans(_vm.genderOptions[1].label)
    },
    model: {
      value: _vm.user.sex,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "sex", $$v);
      },
      expression: "user.sex"
    }
  })], 1)])]), _vm._v(" "), _c('q-input', {
    attrs: {
      "placeholder": "+7 (___) ____-__-__",
      "label": _vm.trans('label.phone'),
      "mask": '+7 (###) ###-##-##',
      "required": "",
      "error": _vm.hasValidationFail('user.phone'),
      "error-message": _vm.getValidationMessage('user.phone')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('user.phone');
      }
    },
    model: {
      value: _vm.user.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "phone", $$v);
      },
      expression: "user.phone"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.personnel.loaded ? _c('div', [_c('q-select', {
    attrs: {
      "options": _vm.options.educationKinds,
      "label": _vm.trans('fields.education_kind'),
      "option-value": "code",
      "required": "",
      "error": _vm.hasValidationFail('selected.education_kind'),
      "error-message": _vm.getValidationMessage('selected.education_kind')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('selected.education_kind');
      }
    },
    model: {
      value: _vm.selected.education_kind,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "education_kind", $$v);
      },
      expression: "selected.education_kind"
    }
  }), _vm._v(" "), _c('q-field', {
    attrs: {
      "label": _vm.trans('fields.defectological_edu'),
      "borderless": ""
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-radio', {
    attrs: {
      "val": _vm.yesNoOptions[0].value,
      "label": _vm.trans(_vm.yesNoOptions[0].label)
    },
    model: {
      value: _vm.personnel.defectologist_edu,
      callback: function callback($$v) {
        _vm.$set(_vm.personnel, "defectologist_edu", $$v);
      },
      expression: "personnel.defectologist_edu"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-auto"
  }, [_c('q-radio', {
    attrs: {
      "val": _vm.yesNoOptions[1].value,
      "label": _vm.trans(_vm.yesNoOptions[1].label)
    },
    model: {
      value: _vm.personnel.defectologist_edu,
      callback: function callback($$v) {
        _vm.$set(_vm.personnel, "defectologist_edu", $$v);
      },
      expression: "personnel.defectologist_edu"
    }
  })], 1)])]), _vm._v(" "), _c('q-select', {
    attrs: {
      "options": _vm.options.academicDegrees,
      "option-value": "code",
      "label": _vm.trans('fields.academic_degree')
    },
    model: {
      value: _vm.selected.academic_degree,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "academic_degree", $$v);
      },
      expression: "selected.academic_degree"
    }
  }), _vm._v(" "), _c('q-select', {
    attrs: {
      "options": _vm.options.academicCategories,
      "label": _vm.trans('fields.category'),
      "required": "",
      "option-value": "code",
      "error": _vm.hasValidationFail('selected.academic_category'),
      "error-message": _vm.getValidationMessage('selected.academic_category')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('selected.academic_category');
      }
    },
    model: {
      value: _vm.selected.academic_category,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "academic_category", $$v);
      },
      expression: "selected.academic_category"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "label": _vm.trans('fields.teacher_experience'),
      "mask": '##',
      "required": "",
      "error": _vm.hasValidationFail('personnel.pedagogical_experience'),
      "error-message": _vm.getValidationMessage('personnel.pedagogical_experience')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('personnel.pedagogical_experience');
      }
    },
    model: {
      value: _vm.personnel.pedagogical_experience,
      callback: function callback($$v) {
        _vm.$set(_vm.personnel, "pedagogical_experience", $$v);
      },
      expression: "personnel.pedagogical_experience"
    }
  }), _vm._v(" "), _c('q-field', {
    attrs: {
      "label": _vm.trans('fields.ikt_courses_passed'),
      "borderless": ""
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-radio', {
    attrs: {
      "val": _vm.yesNoOptions[0].value,
      "label": _vm.trans(_vm.yesNoOptions[0].label)
    },
    model: {
      value: _vm.personnel.ikt_training_passed,
      callback: function callback($$v) {
        _vm.$set(_vm.personnel, "ikt_training_passed", $$v);
      },
      expression: "personnel.ikt_training_passed"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-auto"
  }, [_c('q-radio', {
    attrs: {
      "val": _vm.yesNoOptions[1].value,
      "label": _vm.trans(_vm.yesNoOptions[1].label)
    },
    model: {
      value: _vm.personnel.ikt_training_passed,
      callback: function callback($$v) {
        _vm.$set(_vm.personnel, "ikt_training_passed", $$v);
      },
      expression: "personnel.ikt_training_passed"
    }
  })], 1)])]), _vm._v(" "), _c('q-select', {
    attrs: {
      "options": _vm.options.englishLevels,
      "option-value": "code",
      "label": _vm.trans('fields.english_level')
    },
    model: {
      value: _vm.selected.english_level,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "english_level", $$v);
      },
      expression: "selected.english_level"
    }
  }), _vm._v(" "), _c('q-field', {
    attrs: {
      "borderless": "",
      "label": _vm.trans('fields.qualification_courses_passed')
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-radio', {
    attrs: {
      "val": _vm.yesNoOptions[0].value,
      "label": _vm.trans(_vm.yesNoOptions[0].label)
    },
    model: {
      value: _vm.personnel.qual_training_passed,
      callback: function callback($$v) {
        _vm.$set(_vm.personnel, "qual_training_passed", $$v);
      },
      expression: "personnel.qual_training_passed"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-auto"
  }, [_c('q-radio', {
    attrs: {
      "val": _vm.yesNoOptions[1].value,
      "label": _vm.trans(_vm.yesNoOptions[1].label)
    },
    model: {
      value: _vm.personnel.qual_training_passed,
      callback: function callback($$v) {
        _vm.$set(_vm.personnel, "qual_training_passed", $$v);
      },
      expression: "personnel.qual_training_passed"
    }
  })], 1)])])], 1) : _vm._e(), _vm._v(" "), _vm.personnel_movement.both_loaded ? _c('div', [_c('q-select', {
    attrs: {
      "options": _vm.options.positionsFiltered,
      "label": _vm.trans('fields.position'),
      "required": "",
      "use-input": "",
      "option-value": "code",
      "error": _vm.hasValidationFail('selected.position'),
      "error-message": _vm.getValidationMessage('selected.position')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('selected.position');
      },
      "filter": _vm.filterPosition
    },
    model: {
      value: _vm.selected.position,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "position", $$v);
      },
      expression: "selected.position"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "filled": "",
      "required": "",
      "mask": "##.##.####",
      "label": _vm.trans('fields.hiring_date'),
      "placeholder": _vm.trans('label.select_date'),
      "error": _vm.hasValidationFail('personnel_movement.hiring_date'),
      "error-message": _vm.getValidationMessage('personnel_movement.hiring_date')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('personnel_movement.hiring_date');
      },
      "click": function click() {
        return _vm.$refs.qDateActiveProxyHiringDate.show();
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "sn--cursor-pointer",
          attrs: {
            "name": "calendar"
          }
        }, [_c('q-popup-proxy', {
          ref: "qDateActiveProxyHiringDate",
          attrs: {
            "transition-show": "scale",
            "transition-hide": "scale"
          }
        }, [_c('q-date', {
          attrs: {
            "mask": "DD.MM.YYYY",
            "color": "green"
          },
          on: {
            "input": function input() {
              return _vm.$refs.qDateActiveProxyHiringDate.hide();
            }
          },
          model: {
            value: _vm.personnel_movement.hiring_date,
            callback: function callback($$v) {
              _vm.$set(_vm.personnel_movement, "hiring_date", $$v);
            },
            expression: "personnel_movement.hiring_date"
          }
        })], 1)], 1)];
      },
      proxy: true
    }], null, false, 1152147352),
    model: {
      value: _vm.personnel_movement.hiring_date,
      callback: function callback($$v) {
        _vm.$set(_vm.personnel_movement, "hiring_date", $$v);
      },
      expression: "personnel_movement.hiring_date"
    }
  }), _vm._v(" "), _c('q-input', {
    staticClass: "mb-30",
    attrs: {
      "placeholder": _vm.trans('fields.hiring_order_number'),
      "label": _vm.trans('fields.hiring_order_number'),
      "required": "",
      "error": _vm.hasValidationFail('personnel_movement.hiring_order_number'),
      "error-message": _vm.getValidationMessage('personnel_movement.hiring_order_number')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('personnel_movement.hiring_order_number');
      }
    },
    model: {
      value: _vm.personnel_movement.hiring_order_number,
      callback: function callback($$v) {
        _vm.$set(_vm.personnel_movement, "hiring_order_number", $$v);
      },
      expression: "personnel_movement.hiring_order_number"
    }
  })], 1) : _vm._e(), _vm._v(" "), !_vm.personnel_movement.both_loaded ? _c('div', {
    staticClass: "row justify-content-between"
  }, [_c('div', {
    staticClass: "col-md-auto mb-10"
  }, [_c('q-btn', {
    ref: "pull",
    staticClass: "s-w-100",
    attrs: {
      "type": "submit",
      "icon": "search",
      "label": _vm.trans('button.check'),
      "no-caps": "",
      "color": "aqua",
      "loading": _vm.loading,
      "disable": _vm.user.iin.length !== 12
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-md-auto"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "type": "button",
      "icon": "pencil",
      "label": _vm.trans('button.create_personnel'),
      "no-caps": "",
      "color": "grey",
      "disable": _vm.loading
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.fill($event);
      }
    }
  })], 1)]) : _vm._e(), _vm._v(" "), !_vm.action ? _c('div', {
    staticClass: "row justify-content-between"
  }, [_c('div', {
    staticClass: "col-md-auto mb-10"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "type": "button",
      "label": _vm.trans('button.cancel'),
      "icon": "angle-left",
      "no-caps": "",
      "color": "grey",
      "disable": _vm.loading
    },
    on: {
      "click": _vm.cancel
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-md-auto"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "icon": "check",
      "label": _vm.trans('button.save'),
      "type": "submit",
      "no-caps": "",
      "color": "green",
      "loading": _vm.loading,
      "disable": !_vm.validate
    }
  })], 1)]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }