var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content-inner"
  }, [_c('div', {
    staticClass: "form__registration"
  }, [_vm.currentStep === 1 ? [_c('div', {
    staticClass: "form__string",
    class: {
      'is-errors': _vm.errors.hasOwnProperty('phone')
    }
  }, [_c('label', {
    staticClass: "s-form__label mb-10",
    attrs: {
      "for": "auth-phone"
    }
  }, [_vm._v(_vm._s(_vm.trans('label.login_phone')) + ":"), _c('i', [_vm._v("*")])]), _vm._v(" "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.phone,
      expression: "phone"
    }, {
      name: "mask",
      rawName: "v-mask",
      value: '+7 (###) ###-##-##',
      expression: "'+7 (###) ###-##-##'"
    }],
    attrs: {
      "id": "auth-phone",
      "disabled": _vm.sendCode,
      "name": "phone",
      "autocomplete": "off",
      "placeholder": "+7 (___) ___-__-__"
    },
    domProps: {
      "value": _vm.phone
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.phone = $event.target.value;
      }
    }
  }), _vm._v(" "), _vm.errors.hasOwnProperty('phone') && !_vm.sendCode ? _c('div', {
    staticClass: "form__warning",
    domProps: {
      "innerHTML": _vm._s(_vm.showErrors('phone'))
    }
  }) : _vm._e()]), _vm._v(" "), _vm.sendCode ? _c('div', {
    staticClass: "form__string",
    class: {
      'is-errors': _vm.errors.hasOwnProperty('code')
    }
  }, [_vm.seconds > 0 ? _c('span', {
    staticClass: "form-field__required"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('auth.code_timer')) + " " + _vm._s(_vm.seconds) + "  " + _vm._s(_vm.trans('auth.seconds')) + "\n        ")]) : _c('a', {
    staticClass: "s-countdown-sms__restart",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.takeCode(false);
      }
    }
  }, [_vm._v("\n          " + _vm._s(_vm.trans('auth.send_again')) + "\n        ")])]) : _vm._e(), _vm._v(" "), _vm.sendCode ? _c('div', {
    staticClass: "form__string",
    class: {
      'is-errors': _vm.errors.hasOwnProperty('code')
    }
  }, [_c('label', {
    attrs: {
      "for": "auth-code"
    }
  }, [_vm._v(_vm._s(_vm.trans('auth.sms_code')) + ":")]), _vm._v(" "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.code,
      expression: "code"
    }, {
      name: "mask",
      rawName: "v-mask",
      value: '####',
      expression: "'####'"
    }],
    attrs: {
      "id": "auth-code",
      "name": "code"
    },
    domProps: {
      "value": _vm.code
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.code = $event.target.value;
      }
    }
  })]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "d-flex form__string form__checkbox form__checkbox--offerta"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.checkOfferta,
      expression: "checkOfferta"
    }],
    staticStyle: {
      "margin-right": "0.5rem"
    },
    attrs: {
      "id": "offerta-code",
      "name": "offerta_code",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.checkOfferta) ? _vm._i(_vm.checkOfferta, null) > -1 : _vm.checkOfferta
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.checkOfferta,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.checkOfferta = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.checkOfferta = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.checkOfferta = $$c;
        }
      }
    }
  }), _vm._v(" "), _c('label', {
    attrs: {
      "for": "offerta-code"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.trans('auth.offerta_text')) + "\n          "), _c('span', {
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.offerInfo();
      }
    }
  }, [_vm._v(_vm._s(_vm.trans('auth.offerta_link')))])])]), _vm._v(" "), _vm.errors.hasOwnProperty('code') ? _c('div', {
    staticClass: "form__warning",
    domProps: {
      "innerHTML": _vm._s(_vm.showErrors('code'))
    }
  }) : _vm._e()] : _vm._e(), _vm._v(" "), _vm.currentStep === 2 ? [_c('div', {
    staticClass: "form__string"
  }, [_c('label', [_vm._v(_vm._s(_vm.trans('auth.phone.password_rule')))])]), _vm._v(" "), _c('div', {
    staticClass: "form__string",
    class: {
      'is-errors': _vm.errors.hasOwnProperty('password')
    }
  }, [_c('label', {
    attrs: {
      "for": "auth-password"
    }
  }, [_vm._v(_vm._s(_vm.trans('label.login_password')) + ":")]), _vm._v(" "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password,
      expression: "password"
    }],
    attrs: {
      "id": "auth-password",
      "type": "password",
      "name": "password",
      "placeholder": _vm.trans('placeholder.new_password')
    },
    domProps: {
      "value": _vm.password
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.password = $event.target.value;
      }
    }
  }), _vm._v(" "), _vm.errors.hasOwnProperty('password') ? _c('div', {
    staticClass: "form__warning",
    domProps: {
      "innerHTML": _vm._s(_vm.showErrors('password'))
    }
  }) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "form__string",
    class: {
      'is-errors': _vm.errors.hasOwnProperty('password')
    }
  }, [_c('label', {
    attrs: {
      "for": "auth-password_confirmation"
    }
  }, [_vm._v(_vm._s(_vm.trans('label.confirm_the_password')) + ":")]), _vm._v(" "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password_confirmation,
      expression: "password_confirmation"
    }],
    attrs: {
      "id": "auth-password_confirmation",
      "type": "password",
      "name": "password_confirmation",
      "placeholder": _vm.trans('placeholder.new_password')
    },
    domProps: {
      "value": _vm.password_confirmation
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.password_confirmation = $event.target.value;
      }
    }
  }), _vm._v(" "), _vm.errors.hasOwnProperty('password_confirmation') ? _c('div', {
    staticClass: "form__warning",
    domProps: {
      "innerHTML": _vm._s(_vm.showErrors('password_confirmation'))
    }
  }) : _vm._e()])] : _vm.currentStep === 3 ? [_c('div', {
    staticClass: "form__string",
    class: {
      'is-errors': _vm.errors.hasOwnProperty('iin')
    }
  }, [_c('label', {
    attrs: {
      "for": "auth-iin"
    }
  }, [_vm._v(_vm._s(_vm.trans('label.parent_iin')) + "\n          "), _c('span', {
    attrs: {
      "id": "document_number"
    }
  }, [_vm._v("\n            / " + _vm._s(_vm.trans('label.parent_document_number')) + "\n          ")]), _vm._v(":\n        ")]), _vm._v(" "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.iin,
      expression: "iin"
    }, {
      name: "mask",
      rawName: "v-mask",
      value: '############',
      expression: "'############'"
    }],
    attrs: {
      "id": "auth-iin",
      "name": "iin",
      "autocomplete": "off",
      "placeholder": "____________"
    },
    domProps: {
      "value": _vm.iin
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.iin = $event.target.value;
      }
    }
  }), _vm._v(" "), _vm.errors.hasOwnProperty('iin') ? _c('div', {
    staticClass: "form__warning",
    domProps: {
      "innerHTML": _vm._s(_vm.showErrors('iin'))
    }
  }) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "form__string ",
    class: {
      'is-errors': _vm.errors.hasOwnProperty('is_resident')
    }
  }, [_c('div', {
    staticClass: "form__radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.is_resident,
      expression: "is_resident"
    }],
    attrs: {
      "id": "auth-is_resident",
      "type": "checkbox",
      "name": "is_resident"
    },
    domProps: {
      "checked": Array.isArray(_vm.is_resident) ? _vm._i(_vm.is_resident, null) > -1 : _vm.is_resident
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.is_resident,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.is_resident = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.is_resident = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.is_resident = $$c;
        }
      }
    }
  }), _vm._v(" "), _c('label', {
    attrs: {
      "for": "auth-is_resident"
    },
    on: {
      "click": function click($event) {
        _vm.show = true;
      }
    }
  }, [_vm._v("\n            " + _vm._s(_vm.trans('label.kz_resident')) + "\n          ")])]), _vm._v(" "), _vm.errors.hasOwnProperty('is_resident') ? _c('div', {
    staticClass: "form__warning",
    domProps: {
      "innerHTML": _vm._s(_vm.showErrors('is_resident'))
    }
  }) : _vm._e()]), _vm._v(" "), _vm.show ? [_c('div', {
    staticClass: "form__string",
    class: {
      'is-errors': _vm.errors.hasOwnProperty('phone')
    }
  }, [_c('label', {
    attrs: {
      "for": "firstname"
    }
  }, [_vm._v(_vm._s(_vm.trans('label.firstname')) + ":")]), _vm._v(" "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.name,
      expression: "name"
    }],
    attrs: {
      "id": "firstname",
      "disabled": "",
      "name": "fistname"
    },
    domProps: {
      "value": _vm.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.name = $event.target.value;
      }
    }
  }), _vm._v(" "), _vm.errors.hasOwnProperty('phone') ? _c('div', {
    staticClass: "form__warning",
    domProps: {
      "innerHTML": _vm._s(_vm.showErrors('phone'))
    }
  }) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "form__string",
    class: {
      'is-errors': _vm.errors.hasOwnProperty('phone')
    }
  }, [_c('label', {
    attrs: {
      "for": "surname"
    }
  }, [_vm._v(_vm._s(_vm.trans('label.surname')) + ":")]), _vm._v(" "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.surname,
      expression: "surname"
    }],
    attrs: {
      "id": "surname",
      "disabled": "",
      "name": "surname"
    },
    domProps: {
      "value": _vm.surname
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.surname = $event.target.value;
      }
    }
  }), _vm._v(" "), _vm.errors.hasOwnProperty('phone') ? _c('div', {
    staticClass: "form__warning",
    domProps: {
      "innerHTML": _vm._s(_vm.showErrors('phone'))
    }
  }) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "form__string",
    class: {
      'is-errors': _vm.errors.hasOwnProperty('lastname')
    }
  }, [_c('label', {
    attrs: {
      "for": "lastname"
    }
  }, [_vm._v(_vm._s(_vm.trans('label.middle_name')) + ":")]), _vm._v(" "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.lastname,
      expression: "lastname"
    }],
    attrs: {
      "id": "lastname",
      "disabled": "",
      "name": "lastname"
    },
    domProps: {
      "value": _vm.lastname
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.lastname = $event.target.value;
      }
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "form__string",
    class: {
      'is-errors': _vm.errors.hasOwnProperty('phone')
    }
  }, [_c('label', [_vm._v(_vm._s(_vm.trans('label.born_date')))]), _vm._v(" "), _c('div', {
    staticClass: "input__date"
  }, [_c('date-picker', {
    attrs: {
      "disabled": "disabled",
      "name": "born_date",
      "lang": _vm.lang,
      "format": "DD.MM.YYYY",
      "value-type": "format"
    },
    model: {
      value: _vm.time,
      callback: function callback($$v) {
        _vm.time = $$v;
      },
      expression: "time"
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "form__string",
    class: {
      'is-errors': _vm.errors.hasOwnProperty('phone')
    }
  }, [_c('label', {
    attrs: {
      "for": "sex"
    }
  }, [_vm._v(_vm._s(_vm.trans('label.gender')) + ":")]), _vm._v(" "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.sex,
      expression: "sex"
    }],
    attrs: {
      "id": "sex",
      "disabled": "",
      "name": "sex"
    },
    domProps: {
      "value": _vm.sex
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.sex = $event.target.value;
      }
    }
  })])] : _vm._e()] : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "form__string"
  }, [!_vm.sendCode ? _c('div', [_c('a', {
    staticClass: "s-btn s-btn--thm-green",
    class: _vm.classButton(),
    attrs: {
      "href": "#",
      "disabled": !_vm.checkOfferta
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.takeCode(true);
      }
    }
  }, [_vm._v("\n          " + _vm._s(_vm.trans('button.request_code')) + "\n        ")])]) : _c('div', [_c('a', {
    staticClass: "s-btn s-btn--thm-green",
    class: _vm.classButton(),
    attrs: {
      "href": "#",
      "disabled": _vm.checkDisabled()
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.nextStep($event);
      }
    }
  }, [_vm.currentStep === 2 ? _c('span', [_vm._v(" " + _vm._s(_vm.trans('button.save')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.trans('button.send')) + " ")])])])])], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }