<template>
  <q-dialog
    :value="modal"
    @input="handleModal"
  >
    <v-modal
      :title="trans('label.update_statement_lang')"
      size="medium"
    >
      <v-form-inform
        :message="trans('label.warning_message')"
      />
      <q-field
        :label="trans('fields.study_language')"
        borderless
        required
        class="mb-10"
      >
        <q-option-group
          v-model="selected"
          :options="available"
          type="checkbox"
        />
      </q-field>
      <div class="row justify-content-between">
        <div class="col col-sm-auto sn--text-center mt-5 mb-5">
          <q-btn
            v-close-popup
            width="20"
            :label="trans('button.cancel')"
            label-wider
            color="grey"
          />
        </div>
        <div class="col col-sm-auto sn--text-center mt-5 mb-5">
          <q-btn
            width="20"
            :label="trans('button.send')"
            color="green"
            :disable="!selected || selected.length <= 0"
            @click="send"
          />
        </div>
      </div>
    </v-modal>
  </q-dialog>
</template>

<script>
import {
  QBtn, QDialog, QField, QOptionGroup,
} from '@quasar/components';
import { ClosePopup } from '@quasar/directives';
import VModal from '@vjs/components/VModal';
import VFormInform from '@vjs/components/VFormInform';
import { requestWrapper } from '@vjs/helpers';
import eventHub from '@vjs/config/eventHub';
import { Loading } from '@quasar/plugins';

export default {
  name: 'VApplicationLangModal',
  components: {
    QDialog,
    QOptionGroup,
    QBtn,
    QField,
    VModal,
    VFormInform,
  },
  directives: { ClosePopup },
  props: {
    lang: {
      type: Object,
      required: true,
    },
    statementId: {
      type: Number,
      required: true,
    },
    modal: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    const { lang: { availableLangs, languages, url } } = this;
    let langs = [];
    let available = {};
    if (languages) {
      langs = Object.keys(languages)
        .map(key => parseInt(key, 10));
    }
    if (availableLangs) {
      available = Object
        .keys(availableLangs)
        .map(key => ({
          value: parseInt(key, 10),
          label: availableLangs[key],
        }));
    }

    return {
      available,
      selected: langs || [],
      url,
      qLoading: Loading,
    };
  },
  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'fields',
    ]);
  },
  methods: {
    handleModal() {
      this.$emit('modal-close', false);
    },
    async send() {
      this.qLoading.show();

      const { statementId, selected,  } = this;

      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.url,
        data: {
          languages: selected,
          statementId,
        },
      });

      if (res.error) {
        this.qLoading.hide();
        if (res.data.errors && res.errorType === 422) {
          this.$notify({
            text: res.data.errors[Object.keys(res.data.errors)[0]],
            type: 'error',
          });
        }
        return;
      }

      if (res.message) {
        this.$notify({
          text: res.message,
          type: 'success',
        });
      }

      if (!res.error) {
        this.handleModal();
        eventHub.$emit('updatedLang', true);
      }

      this.qLoading.hide();
    },
  },
};
</script>

<style scoped>

</style>
