export default {
  props: {
    requestSmsAgain: {
      type: Function,
      required: true,
    },
    waitingTime: {
      type: Number,
      default: 90,
    },
  },

  beforeCreate() {
    this.$trans.add(['auth']);
  },

  data() {
    return {
      time: 0,
      timerEnd: false,
    };
  },

  mounted() {
    this.countdown();
  },

  unmounted() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },

  methods: {
    async restart() {
      const smsRes = await this.requestSmsAgain();

      if (smsRes) {
        this.timerEnd = false;
        this.countdown();
      }
    },

    countdown() {
      this.time = this.waitingTime;
      this.interval = setInterval(() => {

        if (this.time > 1) {
          this.time -= 1;
        } else {
          this.timerEnd = true;
          clearInterval(this.interval);
        }
      }, 1000);
    },

    createCountdown(h) {
      return h('div', {
        staticClass: 's-str-ico s-ico--thm-timer',
        domProps: {
          innerHTML: `${this.trans('auth.code_timer')} <span class="s-medium">${this.time} ${this.trans('auth.seconds')}</span>`,
        },
      });
    },

    createRestartTimer(h) {
      return h('div', {}, [
        `${this.trans('auth.not_received_code')} `,
        h('span', {
          staticClass: 's-countdown-sms__restart',
          on: {
            click: this.restart,
          },
        }, this.trans('auth.send_again')),
      ]);
    },
  },

  render(h) {
    let result = null;

    if (this.timerEnd) {
      result = this.createRestartTimer(h);
    } else {
      result = this.createCountdown(h);
    }

    return h('div', {
      staticClass: 's-countdown-sms',
    }, [result]);
  },
};
