// noinspection NpmUsedModulesInstalled
import {
  QBtn,
  QRadio,
  QSpinner,
  QTable,
  QTr,
  QTd,
  QInput,
  QPopupProxy,
  QDate,
  QIcon,
} from '@quasar/components';

// noinspection NpmUsedModulesInstalled
import { Confirm } from '@vjs/balabaqsha/views/VContingent/Dialog';

// noinspection NpmUsedModulesInstalled
import {
  ATTENDANCE_JOURNAL_TRANSLATIONS,
} from '@vjs/balabaqsha/consts';

import moment from 'moment';
import Localization from '../Mixins/QuasarLocale';
import JournalApiProvider from './Mixins/ExtendedJournalApiProvider';
import axios from "axios";

export default {
  name: 'VJournalViewExtended',
  components: {
    Confirm,
    QBtn,
    QRadio,
    QSpinner,
    QTable,
    QTr,
    QTd,
    QInput,
    QPopupProxy,
    QDate,
    QIcon,
  },
  mixins: [Localization, JournalApiProvider],
  props: {
    apiConfig: {
      type: Object,
      default: function _default() {
      },
    },
    dataConfig: {
      type: Object,
      default: function _default() {
      },
    },
  },
  data() {
    // eslint-disable-next-line camelcase
    const { core_project } = window;
    const locale = core_project.locale || 'kz';
    // eslint-disable-next-line camelcase
    const { dataConfig: { groupId, date } } = this;

    return {
      locale,
      contingent: [],
      columns: [],
      groupId,
      absent: [],
      selectedDate: date,
      initialDate: date,
      lastDate: date,
      currentDate: null,
      loadingData: false,
      loadedData: false,
      loadingItems: [],
      confirmationPopup: false,
    };
  },
  computed: {
    lock() {
      const { contingent } = this;
      return contingent.filter(item => item.lock === false).length === 0;
    },
    showTimes() {
      const { contingent } = this;
      return contingent.filter(item => item.showTimes === false).length === 0;
    },
    lockArrivalTime() {
      const { contingent } = this;
      return contingent.filter(item => item.lockArrivalTime === false).length === 0;
    },
    lockLeavingTime() {
      const { contingent } = this;
      return contingent.filter(item => item.lockLeavingTime === false).length === 0;
    },
    totalAbsent() {
      const { absent } = this;
      return absent.reduce(
        (acc, item) => (item.status === false ? acc + 1 : acc),
        0,
      );
    },
    totalAttend() {
      const { absent } = this;
      return absent.reduce(
        (acc, item) => (item.status === true ? acc + 1 : acc),
        0,
      );
    },
    isAllSelected() {
      const { absent } = this;
      return absent.reduce((acc, item) => {
        if (!acc) {
          return false;
        }
        if (item.status === null) {
          return false;
        }
        return acc;
      }, true);
    },
  },
  beforeCreate() {
    this.$trans.add(ATTENDANCE_JOURNAL_TRANSLATIONS);
  },
  beforeMount() {
    const { apiConfig } = this;
    this.initProvider(apiConfig);
    this.initLocale();
    this.loadContingent();
  },
  methods: {
    async loadContingent() {
      const {
        apiProvider, groupId, setAbsent, selectedDate,
      } = this;

      this.loadingData = true;
      const response = await apiProvider.provideFetch()
        .apply(this, [groupId, selectedDate]);

      if (!response) {
        this.contingent = [];
      }

      // if (!response) {
      //   this.selectedDate = this.lastDate;
      //   this.loadContingent();
      // }

      if (response.contingent) {
        this.contingent = response.contingent;
        setAbsent(this.contingent);
      }
      if (response.date) {
        this.currentDate = response.date;
      }
      if (response.columns) {
        this.columns = response.columns;
      }

      this.lastDate = this.selectedDate;
      this.loadingData = false;
      this.loadedData = true;

      // if (response.outdated) {
      //   this.loadedData = false;
      // }
    },
    setAbsent(contingent) {
      this.absent = contingent.map(item => ({
        id: item.id,
        status: typeof item.status !== 'undefined' ? item.status : null,
      }));
    },
    absentValue(contingentId) {
      const { absent } = this;
      const index = absent.map(item => item.id)
        .indexOf(contingentId);
      if (index !== -1) {
        return this.absent[index].status;
      }
      return null;
    },
    async selectAbsent({
      id, lock, arrival_time, leaving_time,
    }, status) {
      if (lock) {
        return null;
      }
      if (status) {
        if (this.showTimes && status === true && !arrival_time) {
          return this.$notify({
            text: this.trans('notice.journal_mark_time_required'),
            type: 'error',
          });
        }
        if (
          moment(arrival_time, 'H:m').format('x')
          > moment(leaving_time, 'H:m').format('x')
        ) {
          return this.$notify({
            text: this.trans('notice.journal_mark_arrival_time_more_than_leaving'),
            type: 'error',
          });
        }
      }
      this.loadingItems = [id, ...this.loadingItems];
      const { apiProvider, selectedDate } = this;

      const response = await apiProvider.provideMark()
        .apply(this, [id, status, selectedDate,arrival_time, leaving_time]);
      if (response) {
        const { absent } = this;
        const index = absent.map(item => item.id)
          .indexOf(id);
        if (index !== -1) {
          absent[index] = {
            id,
            status,
          };
          this.absent = [...absent];
        }
      }
      this.loadingItems = this.loadingItems.filter(item => item !== id);
      return true;
    },
    askConfirm() {
      this.confirmationPopup = true;
    },
    decline() {
      this.confirmationPopup = false;
    },
    async changeDate(date) {
      this.$refs.qDateActiveProxy.hide();
      this.selectedDate = date;
      this.loadContingent();
    },
    async confirm() {
      this.loadingData = true;
      const {
        apiProvider, absent, loadContingent, selectedDate,
      } = this;

      await apiProvider.provideConfirm()
        .apply(this, [this.groupId, selectedDate]);

      this.selectedDate = this.initialDate;

      await loadContingent();

      this.confirmationPopup = false;
    },
    saveLeaveTime({ status, attendanceId }, value) {
      if (status === null || attendanceId === null) {
        return null;
      }
      const locale = `${this.apiConfig.uriSaveLeaveTime.locale}`;
      axios({
        url: `${this.apiConfig.uriSaveLeaveTime.apiUrl}`,
        method: 'GET',
        responseType: 'blob',
        params: { attendanceId, leaving_time: value },
        headers: { 'X-localization': locale },
      }).then((response) => {
        if (response.status === 200) {
          this.$notify({
            type: 'success',
            text: this.trans('notice.saved'),
          });
        } else {
          this.$notify({
            type: 'error',
            text: this.trans('notice.attendance_not_found'),
          });
        }
      });

      return true;
    },
  },
};
