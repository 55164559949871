var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-dialog', {
    attrs: {
      "value": _vm.modal
    },
    on: {
      "input": _vm.handleModal
    }
  }, [_c('v-modal', {
    attrs: {
      "title": _vm.trans('label.uploading_docs'),
      "size": "700"
    }
  }, [_c('input', {
    ref: "fileInput",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "accept": "image/x-png,image/jpeg"
    },
    on: {
      "change": _vm.requestAddFile
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "mb-30",
    domProps: {
      "innerHTML": _vm._s(_vm.trans('notice.avatar_params'))
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-end"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-200",
    attrs: {
      "icon": "angle-left",
      "color": "grey",
      "label": _vm.trans('button.cancel'),
      "no-caps": "",
      "label-wider": ""
    },
    on: {
      "click": _vm.handleModal
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-auto"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-200",
    attrs: {
      "icon": "export",
      "color": "green",
      "label": _vm.trans('button.overview'),
      "no-caps": "",
      "label-wider": ""
    },
    on: {
      "click": _vm.triggerFile
    }
  })], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }