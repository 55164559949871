export default {
  name: 'VCatalogItem',

  props: {
    linkImage: String,
    linkItem: String,
    title: String,
    marks: {
      type: Array,
      default: () => ([
        {
          color: 'blue',
          text: '',
        },
      ]),
    },
    languages: String,
    yearsRestriction: String,
    address: String,
    throughputOrg: String,
    availablePlaces: String,
    loading: Boolean,
  },

  beforeCreate() {
    this.$trans.add(['label', 'fields']);
  },

  methods: {
  },
};
