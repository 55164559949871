var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-exchange-application-add"
  }, [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.modal_inform')
    }
  }), _vm._v(" "), _c('v-reference-time', {
    staticClass: "mb-20",
    attrs: {
      "timestamp": _vm.referenceTime,
      "label": _vm.trans('label.server_datetime')
    }
  }), _vm._v(" "), _c('q-form', {
    on: {
      "submit": _vm.saveApplication
    }
  }, [_c('q-field', {
    attrs: {
      "label": _vm.trans('label.select_child'),
      "borderless": "",
      "required": ""
    }
  }, [_c('q-option-group', {
    attrs: {
      "options": _vm.childrenOptions
    },
    model: {
      value: _vm.currentChild,
      callback: function callback($$v) {
        _vm.currentChild = $$v;
      },
      expression: "currentChild"
    }
  })], 1), _vm._v(" "), _vm.currentChild !== '' ? [_c('q-field', {
    attrs: {
      "label": _vm.trans('label.edu_lang'),
      "borderless": ""
    }
  }, [_vm._v("\n        " + _vm._s(_vm.language.label) + "\n      ")]), _vm._v(" "), _vm._l(_vm.currentSchool, function (itm, idx) {
    return _c('q-select', {
      key: idx,
      attrs: {
        "label": _vm.trans('label.direction_school'),
        "options": _vm.schoolOptionsFiltered,
        "required": "",
        "error": _vm.hasValidationFail("organization".concat(idx)),
        "error-message": _vm.getValidationMessage("organization".concat(idx)),
        "use-input": "",
        "input-debounce": "0"
      },
      on: {
        "input": function input($event) {
          return _vm.flashValidationFail("organization".concat(idx));
        },
        "filter": _vm.filterOptions
      },
      scopedSlots: _vm._u([_vm.currentSchool.length > 1 ? {
        key: "after",
        fn: function fn() {
          return [_c('q-btn', {
            attrs: {
              "type": "button",
              "color": "red",
              "icon": "minus",
              "squire": "",
              "noCaps": ""
            },
            on: {
              "click": function click($event) {
                return _vm.removeAdditionalSelect(idx);
              }
            }
          }, [_c('q-tooltip', [_vm._v("\n              " + _vm._s(_vm.trans('organization.organization_remove_from_list')) + "\n            ")])], 1)];
        },
        proxy: true
      } : null], null, true),
      model: {
        value: _vm.currentSchool[idx],
        callback: function callback($$v) {
          _vm.$set(_vm.currentSchool, idx, $$v);
        },
        expression: "currentSchool[idx]"
      }
    });
  }), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-end mb-30"
  }, [_vm.currentSchool.length < 3 ? _c('div', {
    staticClass: "col-auto"
  }, [_c('q-btn', {
    attrs: {
      "type": "button",
      "color": "orange",
      "icon": "plus",
      "noCaps": "",
      "label": _vm.trans('organization.organization_add')
    },
    on: {
      "click": _vm.addAdditionalSelect
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-end"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-btn', {
    attrs: {
      "type": "submit",
      "color": "green"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.trans('button.next')) + "\n          ")])], 1)])] : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }