var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-form', {
    staticClass: "s-form__edit--wide",
    on: {
      "submit": _vm.send
    }
  }, [_c('h4', {
    staticClass: "s-title s-upper s-light s-title--right-line mb-30"
  }, [_c('span', [_vm._v(_vm._s(_vm.trans('label.notifications_language')))])]), _vm._v(" "), _c('div', [_c('q-option-group', {
    attrs: {
      "options": _vm.dataFields.lang.options
    },
    model: {
      value: _vm.dataFields.lang.group,
      callback: function callback($$v) {
        _vm.$set(_vm.dataFields.lang, "group", $$v);
      },
      expression: "dataFields.lang.group"
    }
  })], 1), _vm._v(" "), _c('q-btn', {
    staticClass: "mt-20",
    attrs: {
      "type": "submit",
      "color": "green",
      "label": _vm.trans('button.save')
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }