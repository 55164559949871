var render = function () {
  var _domProps;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-field', {
    attrs: {
      "field": _vm.field,
      "field-name": _vm.fieldName,
      "errors": _vm.errors,
      "show-label": false
    }
  }, [_c('template', {
    slot: "field"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.localValue,
      expression: "localValue"
    }],
    staticClass: "s-form__checkbox",
    attrs: {
      "id": 'field-input-' + _vm.field.name,
      "type": "checkbox",
      "disabled": _vm.isDisabled,
      "name": _vm.field.name
    },
    domProps: (_domProps = {
      "checked": _vm.localValue
    }, _domProps["checked"] = Array.isArray(_vm.localValue) ? _vm._i(_vm.localValue, null) > -1 : _vm.localValue, _domProps),
    on: {
      "change": [function ($event) {
        var $$a = _vm.localValue,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.localValue = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.localValue = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.localValue = $$c;
        }
      }, _vm.onChange]
    }
  }), _vm._v(" "), _c('label', {
    staticClass: "s-form__label mb-10",
    attrs: {
      "for": 'field-input-' + _vm.field.name
    }
  }, [_vm._v(_vm._s(_vm.fieldLabel()))])])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }