import { QInput } from '@quasar/components';

export default {
  components: { QInput },

  props: {
    value: null,
    extraBinds: {
      type: Object,
      default: () => ({}),
    },
    error: Boolean,
    errorMessage: String,
  },

  data() {
    const config = this.getConfig();
    return {
      config,
      localValue: '',
    };
  },

  beforeMount() {
    this.$trans.add(['label', 'placeholder']);
  },

  mounted() {
    this.setInitialValue();
  },
  methods: {
    getConfig() {
      let config = {
        mask: '###',
        min: 0,
        max: 999,
      };
      if (this.extraBinds.hasOwnProperty('config')) {
        config = this.extraBinds.config;
      }
      return config;
    },
    emitChange(value = null) {
      this.$emit('input', value);
    },
    setInitialValue() {
      if (this.value) {
        this.localValue = this.value;
      }
    },
  },

  computed: {
    defaultAttributes() {
      return {
        placeholder: this.extraBinds.placeholder,
      };
    },

    extraAttributes() {
      const attrs = this.extraBinds;

      return {
        ...this.defaultAttributes,
        ...attrs,
      };
    },
  },

  watch: {
    value() {
      this.setInitialValue();
    },
    'extraBinds.config': function () {
      this.config = this.getConfig();
    },
    localValue(value) {
      const { min, max } = this.config;
      const ref = this.$refs['q-number-field'];
      if (![null, undefined, ''].includes(value)) {
        if (![null, undefined, ''].includes(min) && Number(value) < min) {
          this.localValue = min;
          setTimeout(ref.updateInnerValue, 0);
          return;
        }

        if (![null, undefined, ''].includes(max) && Number(value) > max) {
          this.localValue = max;
          setTimeout(ref.updateInnerValue, 0);
          return;
        }

        if (value !== Number(value)) {
          this.localValue = Number(value);
          setTimeout(ref.updateInnerValue, 0);
        }
      }
      this.emitChange(value);
    },
  },
};
