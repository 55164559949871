var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-dialog', {
    attrs: {
      "value": _vm.active,
      "persistent": _vm.persistent
    },
    on: {
      "hide": _vm.hideHandler
    }
  }, [_c('v-modal', {
    class: {
      's-light': _vm.lightText
    },
    attrs: {
      "title": _vm.title,
      "size": _vm.size
    }
  }, [_vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }