var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('h2', {
    staticClass: "main-ratio-header"
  }, [_vm._v("\n      " + _vm._s(_vm.trans('funding.calculator.ratio-header')) + "\n    ")]), _vm._v(" "), !_vm.demo ? _c('div', {
    staticClass: "info-panel"
  }, [_c('div', {
    staticClass: "d-flex flex-wrap justify-content-around constant-ratio-section"
  }, _vm._l(_vm.constRatios, function (item) {
    return _c('div', {
      key: item.type,
      staticClass: "constant-ratio"
    }, [_c('span', {
      staticClass: "s-bold with-helper",
      attrs: {
        "title": item.description
      }
    }, [_vm._v(_vm._s(item.name))]), _vm._v("\n          = " + _vm._s(item.value) + "\n        ")]);
  }), 0)]) : _vm._e(), _vm._v(" "), !_vm.demo ? _c('v-subheader', {
    staticClass: "ratio-header",
    attrs: {
      "text": _vm.trans('funding.calculator.select-ratio')
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "d-flex flex-column"
  }, _vm._l(_vm.selectableListRatios, function (item) {
    return _c('div', {
      key: item.type,
      staticClass: "ratio-card"
    }, [_c('div', [_c('label', {
      staticClass: "s-bold ratio-sub-header"
    }, [_vm._v("\n            * " + _vm._s(item.description) + " (" + _vm._s(item.name) + ")\n          ")]), _vm._v(" "), _c('div', {
      staticClass: "d-flex flex-column"
    }, _vm._l(item.value, function (option) {
      return _c('div', {
        key: option.index
      }, [item.type === 17 && [1, 2, 3].indexOf(option.index) !== -1 ? _c('div', {
        staticClass: "radio-description"
      }, [option.index === 1 ? _c('span', {
        staticClass: "ratio-header-text"
      }, [_vm._v("\n                  " + _vm._s(_vm.trans('funding.ratio-group.not-full-day')) + "\n                ")]) : _vm._e(), _vm._v(" "), option.index === 2 ? _c('span', {
        staticClass: "ratio-header-text"
      }, [_vm._v("\n                  " + _vm._s(_vm.trans('funding.ratio-group.school')) + "\n                ")]) : _vm._e(), _vm._v(" "), option.index === 3 ? _c('span', {
        staticClass: "ratio-header-text"
      }, [_vm._v("\n                  " + _vm._s(_vm.trans('funding.ratio-group.nine-or-ten-and-half')) + "\n                ")]) : _vm._e()]) : _vm._e(), _vm._v(" "), _c('q-radio', {
        staticClass: "ratio-text",
        attrs: {
          "value": item.selected,
          "val": option.index
        },
        on: {
          "input": function input(value) {
            return _vm.selectRatio(item.type, value);
          }
        }
      }, [_c('span', {
        staticClass: "ratio-text"
      }, [_vm._v("\n                  " + _vm._s(option.description) + " = " + _vm._s(option.value) + "\n                ")])])], 1);
    }), 0)])]);
  }), 0), _vm._v(" "), _c('div', {
    staticClass: "ratio-card"
  }, [_c('div', {
    staticClass: "d-flex flex-column "
  }, _vm._l(_vm.selectableSimpleRatios, function (item) {
    return _c('div', {
      key: item.type
    }, [_c('q-checkbox', {
      staticClass: "ratio-text",
      attrs: {
        "value": item.selected === true
      },
      on: {
        "input": function input() {
          return _vm.toggleSimpleRatio(item.type);
        }
      }
    }, [_c('span', {
      staticClass: "ratio-text"
    }, [_vm._v("\n              " + _vm._s(item.description) + " (" + _vm._s(item.name) + ") = " + _vm._s(item.value) + "\n            ")])])], 1);
  }), 0)]), _vm._v(" "), !_vm.demo ? _c('v-subheader', {
    staticClass: "ratio-header",
    attrs: {
      "text": _vm.trans('funding.calculator.functions')
    }
  }) : _vm._e(), _vm._v(" "), !_vm.demo ? _c('div', {
    staticClass: "row info-panel"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('ul', _vm._l(_vm.availableFormulas, function (item) {
    return _c('li', {
      key: item.formula,
      staticClass: "formula"
    }, [_c('span', {
      staticClass: "s-bold with-helper",
      attrs: {
        "title": item.description
      }
    }, [_vm._v(_vm._s(item.translation))]), _vm._v("\n            = " + _vm._s(item.calculated ? parseFloat(item.value).toFixed(2) : '-') + "\n          ")]);
  }), 0)]), _vm._v(" "), _c('div', {
    staticClass: "col-6"
  }, [_c('ul', _vm._l(_vm.availableFormulasDescription, function (item) {
    return _c('li', {
      key: item.formula,
      staticClass: "formula"
    }, [_vm._v("\n            " + _vm._s(item.calculation_description) + "\n          ")]);
  }), 0)])]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "info-panel"
  }, [_c('ul', [_c('li', {
    staticClass: "total-info-item"
  }, [_c('span', [_vm._v(_vm._s(_vm.trans('funding.calculator.kindergarten-child-count')))]), _vm._v("\n          = " + _vm._s(_vm.ratios.kindergartenChildLimit) + "\n        ")]), _vm._v(" "), _c('li', {
    staticClass: "total-info-item"
  }, [_c('span', [_vm._v(_vm._s(_vm.trans('funding.calculator.previous-child-count')))]), _vm._v("\n          = " + _vm._s(_vm.ratios.previousFundingChildCount) + "\n        ")]), _vm._v(" "), _c('li', {
    staticClass: "total-info-item"
  }, [_c('span', [_vm._v(_vm._s(_vm.trans('funding.calculator.group-child-count')))]), _vm._v("\n          = " + _vm._s(_vm.ratios.groupChildCount) + "\n        ")]), _vm._v(" "), _c('li', {
    staticClass: "total-info-item"
  }, [_c('span', [_vm._v(_vm._s(_vm.trans('funding.calculator.funding-child-count')))]), _vm._v("\n          = " + _vm._s(_vm.ratios.fundingChildCount ? _vm.ratios.fundingChildCount : 0) + "\n        ")])])]), _vm._v(" "), !_vm.demo ? _c('div', {
    staticClass: "info-panel-total"
  }, [_c('ul', [_c('li', {
    staticClass: "total-info-item"
  }, [_c('span', [_vm._v(_vm._s(_vm.trans('funding.amount-plan')))]), _vm._v("\n          = "), _c('span', [_vm._v(_vm._s(_vm.trans('funding.calculator.funding-child-count')))]), _vm._v("\n          *\n          "), _vm._v(" "), _c('span', {
    staticClass: "s-bold with-helper",
    attrs: {
      "title": _vm.getFormulaByName('Nz').description
    }
  }, [_vm._v(_vm._s(_vm.getFormulaByName('Nz').translation))])]), _vm._v(" "), _c('li', {
    staticClass: "total-info-item"
  }, [_vm._v("\n          " + _vm._s(_vm.ratios.fundingChildCount ? _vm.ratios.fundingChildCount : 0) + " * " + _vm._s(_vm.getFormulaByName('Nz').calculated ? parseFloat(_vm.getFormulaByName('Nz').value).toFixed(2) : '0') + " = " + _vm._s(_vm.ratios.total ? parseFloat(_vm.ratios.total).toFixed(2) : '0') + "\n        ")]), _vm._v(" "), _c('li', {
    staticClass: "total-info-item"
  }, [_c('h4', [_vm._v("\n            " + _vm._s(_vm.trans('funding.amount-plan')) + ":\n            " + _vm._s(_vm.ratios.total ? parseFloat(_vm.ratios.total).toFixed(2) : '0') + "\n          ")])])])]) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "radio-button-container"
  }, [_c('q-btn', {
    staticClass: "ratio-btn",
    attrs: {
      "color": "blue",
      "disabled": !_vm.isSelected,
      "loading": _vm.calculateLoad
    },
    on: {
      "click": _vm.calculate
    }
  }, [_vm._v("\n      " + _vm._s(_vm.trans('button.calc')) + "\n    ")]), _vm._v(" "), _vm.calculated ? _c('q-btn', {
    staticClass: "ratio-btn",
    attrs: {
      "color": "green"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("\n      " + _vm._s(_vm.trans('button.save')) + "\n    ")]) : _vm._e()], 1), _vm._v(" "), _vm.demo ? _c('div', {
    staticClass: "info-panel-total"
  }, [_c('h4', [_vm._v("\n      " + _vm._s(_vm.trans('funding.amount-plan')) + ":\n      " + _vm._s(_vm.ratios.total ? _vm.formatPrice(_vm.ratios.total) : '0') + "\n    ")])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }