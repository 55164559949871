import VFormInform from '@vjs/components/VFormInform';
import VConfirmDialog from '@vjs/modals/VConfirmDialog';
import { requestWrapper } from '@vjs/helpers';
import VDirection from '../../components/VDirection';
import VNoResults from '../../../components/VNoResults';

export default {
  name: 'VDirectionList',
  components: {
    VDirection,
    VNoResults,
    VFormInform,
    VConfirmDialog,
  },
  props: {
    directionListProp: {
      type: String,
      default: '',
    },
    refuseUrl: {
      type: String,
      default: () => null,
    },
    fetchUrl: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      directionList: [],
      selectedDirectionId: null,
      selectedDirectionType: null,
      sendingConfirm: false,
    };
  },
  beforeCreate() {
    this.$trans.add(['label', 'directions', 'warning_text']);
  },
  mounted() {
    this.directionList = JSON.parse(this.directionListProp);
  },
  computed: {
    empty() {
      return this.directionList.length <= 0;
    },
    showConfirmPopup() {
      return this.selectedDirectionId !== null;
    },
  },
  methods: {
    handleConfirmPopup({ id, type }) {
      this.selectedDirectionId = id;
      this.selectedDirectionType = type;
    },
    async cancelConfirm() {
      await this.freshDirections();
      this.selectedDirectionId = null;
      this.selectedDirectionType = null;
    },
    async applyConfirm() {
      this.sendingConfirm = true;

      if (this.refuseUrl) {
        const response = await requestWrapper.call(this, {
          method: 'post',
          url: this.refuseUrl,
          data: {
            id: this.selectedDirectionId,
            type: this.selectedDirectionType,
          },
        });


        if (!response.error) {
          this.selectedDirectionId = null;
          this.selectedDirectionType = null;
          await this.freshDirections();
        }
        if (response.status && response.message) {
          this.$notify({
            type: 'success',
            text: response.message,
          });
        }
      }

      this.sendingConfirm = false;
    },
    async freshDirections() {
      if (this.fetchUrl) {
        const response = await requestWrapper.call(this, {
          method: 'get',
          url: this.fetchUrl,
        });

        if (!response.error) {
          this.directionList = response;
        }
      }
    },
  },
};
