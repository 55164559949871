var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('form', {
    staticClass: "row-sort-wrap",
    attrs: {
      "method": "get"
    }
  }, [_vm.mode !== 'details' ? _c('div', {
    staticClass: "row align-items-end mb-10"
  }, [_c('div', {
    staticClass: "col-md-4 col-sm-6"
  }, [_c('q-select', {
    attrs: {
      "filled": "",
      "options": _vm.localityOptions,
      "label": _vm.trans('label.locality'),
      "emit-value": "",
      "map-options": "",
      "input-debounce": "0"
    },
    on: {
      "input": _vm.requestOrg,
      "filter": _vm.filtersFn
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('q-icon', {
          attrs: {
            "name": "place"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "name": "close"
          },
          on: {
            "click": _vm.clearFilters
          }
        })];
      },
      proxy: true
    }], null, false, 15839115),
    model: {
      value: _vm.form.locality,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "locality", $$v);
      },
      expression: "form.locality"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-md-4 col-sm-6"
  }, [_c('q-select', {
    attrs: {
      "readonly": _vm.orgNameOptions.length === 0,
      "filled": "",
      "options": _vm.orgNameOptions,
      "label": _vm.trans('bulletin.organization_name'),
      "emit-value": "",
      "map-options": "",
      "input-debounce": "0"
    },
    on: {
      "input": _vm.requestGetData
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('q-icon', {
          attrs: {
            "name": "place"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "name": "close"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              _vm.form.organization = '';

              _vm.submit();
            }
          }
        })];
      },
      proxy: true
    }], null, false, 2636108581),
    model: {
      value: _vm.form.organization,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "organization", $$v);
      },
      expression: "form.organization"
    }
  })], 1)]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "row align-items-stretch mb-10 justify-content-end"
  }, [_c('div', {
    staticClass: "col-sm-auto mb-10"
  }, [_vm.mode !== 'details' ? _c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "no-caps": "",
      "label": _vm.trans('button.flash_filter'),
      "color": "orange"
    },
    on: {
      "click": _vm.clearFilters
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "color": "green",
      "no-caps": "",
      "label": _vm.trans('button.export'),
      "loading": _vm.exportLoading
    },
    on: {
      "click": _vm.exportu
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "row align-items-stretch mb-10  justify-content-end"
  }, [_c('div', {
    staticClass: "col-sm-auto"
  }, [_c('q-input', {
    attrs: {
      "dense": "",
      "debounce": "300",
      "placeholder": _vm.trans('placeholder.search')
    },
    model: {
      value: _vm.filterString,
      callback: function callback($$v) {
        _vm.filterString = $$v;
      },
      expression: "filterString"
    }
  }, [_c('q-icon', {
    attrs: {
      "slot": "append",
      "name": "search"
    },
    slot: "append"
  })], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "q-ml-md s-pos-float-right mt-10",
    attrs: {
      "squire": "",
      "unelevated": "",
      "icon": "fullscreen",
      "color": "grey"
    },
    on: {
      "click": function click($event) {
        _vm.table.inFullscreen = true;
      }
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.fullscreen_on')))])], 1)], 1)])]), _vm._v(" "), _c('q-table', {
    attrs: {
      "data": _vm.rows,
      "columns": _vm.table.columns,
      "pagination": _vm.table.pagination,
      "row-key": "__index",
      "custom-indexing-on-sort-excepts": ['number'],
      "separator": "cell",
      "custom-indexing-on-sort": "",
      "filter": _vm.filterString,
      "fullscreen": _vm.table.inFullscreen
    },
    on: {
      "update:pagination": function updatePagination($event) {
        return _vm.$set(_vm.table, "pagination", $event);
      }
    },
    scopedSlots: _vm._u([_vm.table.inFullscreen ? {
      key: "top",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "row align-items-stretch justify-content-end s-w-100"
        }, [_c('div', {
          staticClass: "col-sm-auto"
        }, [_c('q-input', {
          attrs: {
            "dense": "",
            "debounce": "300",
            "placeholder": _vm.trans('placeholder.search')
          },
          model: {
            value: _vm.filterString,
            callback: function callback($$v) {
              _vm.filterString = $$v;
            },
            expression: "filterString"
          }
        }, [_c('q-icon', {
          attrs: {
            "slot": "append",
            "name": "search"
          },
          slot: "append"
        })], 1)], 1), _vm._v(" "), _c('div', {
          staticClass: "col-sm-auto"
        }, [_c('q-btn', {
          staticClass: "q-ml-md s-pos-float-right mt-10",
          attrs: {
            "squire": "",
            "unelevated": "",
            "icon": "fullscreen",
            "color": "blue"
          },
          on: {
            "click": function click($event) {
              _vm.table.inFullscreen = false;
            }
          }
        }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.fullscreen_off')))])], 1)], 1)])];
      }
    } : null, {
      key: "header",
      fn: function fn(props) {
        return [_c('q-tr', [_c('th', {
          staticClass: "sn--text-center",
          attrs: {
            "colspan": "100%"
          }
        }, [_vm._v("\n          " + _vm._s(_vm.headerName) + "\n        ")])]), _vm._v(" "), _c('q-tr', {
          attrs: {
            "props": props
          }
        }, _vm._l(props.cols, function (col) {
          return _c('q-th', {
            key: col.name,
            style: _vm.getCellStyle(col.name),
            attrs: {
              "props": props,
              "colspan": col.colspan,
              "rowspan": col.rowspan
            }
          }, [_vm._v("\n          " + _vm._s(col.label) + "\n        ")]);
        }), 1)];
      }
    }, {
      key: "body",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, [_c('q-td', {
          staticClass: "s-to-center"
        }, [_vm._v("\n          " + _vm._s(props.row.__index) + "\n        ")]), _vm._v(" "), _vm._l(props.row, function (item, i) {
          return [!['id', '__index', 'show_href'].includes(i) ? _c('q-td', {
            key: i,
            class: i === 'org_name' ? '' : 's-to-center',
            style: _vm.getCellStyle(i)
          }, [_vm._v("\n            " + _vm._s(item) + "\n          ")]) : _vm._e()];
        }), _vm._v(" "), _vm.mode !== 'details' ? _c('q-td', {
          staticClass: "s-to-center"
        }, [_c('q-btn', {
          attrs: {
            "color": "orange",
            "squire": "",
            "icon": "eye",
            "type": "a",
            "to": props.row.show_href
          }
        }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('button.see')))])], 1)], 1) : _vm._e()], 2)];
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }