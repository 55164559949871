import ContingentApiProvider
  from '../../../providers/Contingent/ContingentApiProvider';
import ContingentRepository from '../../../repositories/ContingentRepository';

const Providers = {
  data: () => ({
    apiProvider: {},
  }),
  methods: {
    initProvider(contingentApi) {
      const trans = this.$trans.get;
      this.apiProvider = new ContingentApiProvider({
        ContingentRepository: new ContingentRepository(contingentApi),
      }, trans);
    },
  },
};

export default Providers;
