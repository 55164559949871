var render = function () {
  var _class;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-no-results"
  }, [_c('div', {
    staticClass: "s-no-results__image",
    class: (_class = {}, _class["s-no-results__image--".concat(_vm.image)] = !!_vm.image, _class)
  }), _vm._v(" "), _c('div', {
    staticClass: "s-no-results__message"
  }, [_vm.title ? _c('div', {
    staticClass: "s-no-results__message-title"
  }, [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]) : _vm._e(), _vm._v(" "), _vm.text ? _c('div', {
    staticClass: "s-no-results__message-text",
    domProps: {
      "innerHTML": _vm._s(_vm.text)
    }
  }) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }