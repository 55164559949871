import {
  QInput,
  QSelect,
  QBtn,
  QForm,
  QDate,
  QIcon,
  QPopupProxy,
  QField,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import { Loading } from '@quasar/plugins';
import Validation from '@vjs/mixins/Validation';
import VFormInform from '@vjs/components/VFormInform';
import _ from 'lodash';

export default {
  name: 'VStatementContractCreateForm',
  mixins: [Validation],
  components: {
    QInput,
    QSelect,
    QBtn,
    QForm,
    QDate,
    QIcon,
    QPopupProxy,
    QField,
    VFormInform,
  },
  props: {
    apiConfig: {
      type: Object,
      default: () => ({
        backButtonUrl: '',
        storeApiUrl: '',
        getData: '',
      }),
    },
    language: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        contract_number: null,
        region_name: null,
        department_name: null,
        locality_name: null,
        ddo_name: null,
        ddo_address: null,
        ddo_index: null,
        ddo_director: null,
        ddo_bin: null,
        ddo_phone: null,
        schedule_type: null,
        stay_mode_type: null,
        morning_reception_time: null,
        contract_end: null,
      },
      options: {
        scheduleList: [],
        stayModeTypeList: [],
      },

      inputList: [
        'contract_number',
        'region_name',
        'department_name',
        'locality_name',
        'ddo_name',
        'ddo_address',
        'ddo_index',
        'ddo_director',
        'ddo_bin',
        'ddo_phone',
      ],
      disable: {
        contract_number: false,
        ddo_address: false,
        ddo_index: false,
        ddo_director: false,
        ddo_phone: false,
        ddo_bin: false,
        schedule_type: false,
        stay_mode_type: false,
        morning_reception_time: false,
      },
      qLoading: Loading,
    };
  },

  computed: {
    validate() {
      return this.form.contract_number !== null
        && this.form.ddo_address !== null
        && this.form.ddo_index !== null
        && this.form.ddo_director !== null
        && this.form.ddo_phone !== null
        && this.form.ddo_bin !== null
        && this.form.schedule_type !== null
        && this.form.stay_mode_type !== null
        && this.form.contract_end !== null
        && this.form.morning_reception_time !== null;
    },
  },

  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'statement',
      'warning_text',
    ]);
    await this.requestGetData();
  },

  methods: {
    isInputRequired(name) {
      switch (name) {
        case 'locality_name': return false;
        default: return true;
      }
    },
    isInputDisabled(name) {
      if (this.disable.hasOwnProperty(name)) {
        return this.disable[name];
      }
      return true;
    },
    async requestGetData() {
      this.qLoading.show({ message: this.trans('label.wait_for_load_data') });
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.getData,
      });

      if (!res.error) {
        const { data } = res;
        this.form = { ...this.form, ...data.data };
        this.options = data.options;
        if (data.disabled && !_.isEmpty(data.disabled)) {
          _.forEach(this.disable, (value, name) => {
            this.disable[name] = data.disabled[name] ?? false;
          });
        }
      }
      this.qLoading.hide();
    },

    async requestAction() {
      this.qLoading.show({ message: this.trans('label.wait_for_load_data') });
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.storeApiUrl,
        method: 'post',
        data: { ...this.form, language: this.language },
      });

      if (res.error) {
        this.qLoading.hide();
      }
    },
  },
};
