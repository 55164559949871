var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-field', {
    attrs: {
      "field": _vm.field,
      "field-name": _vm.fieldName,
      "label-class": '',
      "errors": _vm.errors
    }
  }, [_c('template', {
    slot: "field"
  }, [_c('q-input', _vm._b({
    ref: "q-number-field",
    attrs: {
      "mask": _vm.config.mask,
      "name": _vm.field.name
    },
    scopedSlots: _vm._u([{
      key: "counter",
      fn: function fn() {
        return [_vm._v("\n        " + _vm._s(_vm.trans('label.from_to_number_field', {
          from: _vm.config.min || 0,
          to: _vm.config.max || new Array(_vm.config.mask).fill('9').join('')
        })) + "\n      ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.localValue,
      callback: function callback($$v) {
        _vm.localValue = $$v;
      },
      expression: "localValue"
    }
  }, 'q-input', _vm.extraAttributes, false))], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }