var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-passport-page"
  }, [_c('div', {
    staticClass: "s-passport-page__columns-wrapper"
  }, [_c('div', {
    staticClass: "s-passport-page__column-left"
  }, [_c('div', {
    staticClass: "s-passport-page__panel mb-30"
  }, [_c('v-photo-album', {
    staticClass: "mb-20",
    attrs: {
      "pic-list": _vm.picItems,
      "autoplay": true,
      "org-type": "balabaqsha"
    }
  }), _vm._v(" "), _c('q-btn', {
    staticClass: "s-w-100 mb-15",
    attrs: {
      "color": "blue",
      "label": _vm.trans('organization.about_organization'),
      "no-caps": "",
      "outline": ""
    },
    on: {
      "click": function click($event) {
        return _vm.scrollTo('about');
      }
    }
  }), _vm._v(" "), _c('q-btn', {
    staticClass: "s-w-100 mb-15",
    attrs: {
      "color": "blue",
      "outline": "",
      "label": _vm.trans('organization.body'),
      "no-caps": ""
    },
    on: {
      "click": function click($event) {
        return _vm.scrollTo('description');
      }
    }
  }), _vm._v(" "), _c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "color": "blue",
      "outline": "",
      "label": _vm.trans('organization.groups'),
      "no-caps": ""
    },
    on: {
      "click": function click($event) {
        return _vm.scrollTo('groups');
      }
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "s-passport-page__panel mb-30"
  }, [_c('div', {
    staticClass: "s-list-between s-c-blue-dark s-f-14"
  }, [_c('div', {
    staticClass: "s-list-between__row"
  }, [_c('div', {
    staticClass: "s-list-between__label"
  }, [_c('div', {
    staticClass: "s-str-ico-fa s-ico-fa--thm-user-circle s-ico--bold"
  }, [_vm._v("\n                " + _vm._s(_vm.trans('organization.bandwidth_of_organization')) + ":\n              ")])]), _vm._v(" "), _c('div', {
    staticClass: "s-list-between__value"
  }, [_vm._v("\n              " + _vm._s(_vm.statistics.throughput) + "\n            ")])]), _vm._v(" "), _c('div', {
    staticClass: "s-list-between__row"
  }, [_c('div', {
    staticClass: "s-list-between__label"
  }, [_c('div', {
    staticClass: "s-str-ico-fa s-ico-fa--thm-books"
  }, [_vm._v("\n                " + _vm._s(_vm.trans('organization.group_edu_langs')) + ":\n              ")])]), _vm._v(" "), _c('div', {
    staticClass: "s-list-between__value"
  }, [_vm._v("\n              " + _vm._s(_vm.statistics.eduLangs) + "\n            ")])]), _vm._v(" "), _c('div', {
    staticClass: "s-list-between__row"
  }, [_c('div', {
    staticClass: "s-list-between__label"
  }, [_c('div', {
    staticClass: "s-str-ico-fa s-ico-fa--thm-grip-vertical s-ico--bold"
  }, [_vm._v("\n                " + _vm._s(_vm.trans('organization.age_groups')) + ":\n              ")])]), _vm._v(" "), _c('div', {
    staticClass: "s-list-between__value"
  }, [_vm._v("\n              " + _vm._s(_vm.statistics.yearRestriction) + "\n            ")])])]), _vm._v(" "), !!_vm.statistics.pieChart.length ? _c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('v-pie-chart', {
    attrs: {
      "data": _vm.statistics.pieChart
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "s-diagram__label"
  }, [_vm._v("\n              " + _vm._s(_vm.trans('organization.org_child_ages')) + "\n            ")])], 1)]) : _vm._e()])]), _vm._v(" "), _c('div', {
    staticClass: "s-passport-page__column-right"
  }, [_c('div', {
    staticClass: "s-passport-page__panel mb-30"
  }, [_c('div', {
    staticClass: "s-passport-page__mark mb-15"
  }, _vm._l(_vm.flags, function (flag, flagIdx) {
    return _c('div', {
      key: flagIdx,
      class: "s-flag s-b-".concat(flag.color, " s-c-white")
    }, [_vm._v("\n            " + _vm._s(flag.text) + "\n          ")]);
  }), 0), _vm._v(" "), _c('div', {
    staticClass: "s-passport-page__name mb-30"
  }, [_vm._v("\n          " + _vm._s(_vm.name) + "\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "row flex-column"
  }, [_c('div', {
    staticClass: "col-auto mb-15"
  }, [_c('div', {
    staticClass: "s-desc-item__label"
  }, [_vm._v("\n              " + _vm._s(_vm.trans('organization.address')) + ":\n            ")]), _vm._v(" "), _c('div', {
    staticClass: "s-desc-item__value"
  }, [_vm._v("\n              " + _vm._s(_vm.address) + "\n            ")])]), _vm._v(" "), _c('div', {
    staticClass: "col-auto mb-15"
  }, [_c('div', {
    staticClass: "s-desc-item__label"
  }, [_vm._v("\n              " + _vm._s(_vm.trans('organization.phone_number')) + ":\n            ")]), _vm._v(" "), _c('div', {
    staticClass: "s-desc-item__value"
  }, [_vm._v("\n              " + _vm._s(_vm.phone) + "\n            ")])]), _vm._v(" "), _c('div', {
    staticClass: "col-auto"
  }, [_c('div', {
    staticClass: "s-desc-item__label"
  }, [_vm._v("\n              " + _vm._s(_vm.trans('organization.email')) + ":\n            ")]), _vm._v(" "), _c('div', {
    staticClass: "s-desc-item__value"
  }, [_vm._v("\n              " + _vm._s(_vm.email) + "\n            ")])])])]), _vm._v(" "), _vm.aboutOrg.length > 0 ? _c('div', {
    ref: "about",
    staticClass: "s-passport-page__panel mb-30"
  }, [_c('div', {
    staticClass: "s-passport-page__panel-title mb-30"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('organization.about_organization')) + "\n        ")]), _vm._v(" "), _c('table', {
    staticClass: "s-table-simple"
  }, [_c('tbody', {
    staticClass: "s-table__body"
  }, _vm._l(_vm.aboutOrg, function (itm, itmIdx) {
    return _c('tr', {
      key: itmIdx
    }, [_c('td', {
      staticClass: "s-to-right"
    }, [_vm._v("\n                " + _vm._s(itm.label) + "\n              ")]), _vm._v(" "), _c('td', [_c('div', {
      staticClass: "s-medium"
    }, [_vm._v("\n                  " + _vm._s(itm.value) + "\n                ")])])]);
  }), 0)])]) : _vm._e()])]), _vm._v(" "), _c('div', {
    ref: "description",
    staticClass: "s-passport-page__panel mb-30"
  }, [_c('div', {
    staticClass: "s-passport-page__panel-title mb-30"
  }, [_c('div', {
    staticClass: "s-str-ico-fa s-ico-fa--thm-pen-alt"
  }, [_vm._v("\n        " + _vm._s(_vm.trans('organization.body')) + "\n      ")])]), _vm._v(" "), _c('div', {
    staticClass: "s-plain-text",
    domProps: {
      "innerHTML": _vm._s(_vm.description)
    }
  })]), _vm._v(" "), _c('div', {
    ref: "groups",
    staticClass: "s-passport-page__panel mb-30"
  }, [_c('div', {
    staticClass: "s-passport-page__panel-title mb-30"
  }, [_c('div', {
    staticClass: "s-str-ico-fa s-ico-fa--thm-users"
  }, [_vm._v("\n        " + _vm._s(_vm.trans('organization.groups')) + "\n      ")])]), _vm._v(" "), _c('table', {
    staticClass: "s-table s-table--striped s-table--thm-head-grey"
  }, [_c('thead', {
    staticClass: "s-table__head"
  }, [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.trans('organization.group_names')))]), _vm._v(" "), _c('th', [_vm._v(_vm._s(_vm.trans('organization.age_groups')))]), _vm._v(" "), _c('th', [_vm._v(_vm._s(_vm.trans('organization.group_edu_langs')))]), _vm._v(" "), _c('th', {
    staticClass: "s-to-center"
  }, [_vm._v("\n            " + _vm._s(_vm.trans('organization.group_child_amount')) + "\n          ")]), _vm._v(" "), _c('th', {
    staticClass: "s-to-center"
  }, [_vm._v("\n            " + _vm._s(_vm.trans('organization.group_throughput')) + "\n          ")])])]), _vm._v(" "), _c('tbody', {
    staticClass: "s-table__body"
  }, _vm._l(_vm.groups, function (grp, grpIdx) {
    return _c('tr', {
      key: grpIdx
    }, [_c('td', {
      attrs: {
        "data-table-head": _vm.trans('organization.group_names')
      }
    }, [_c('div', {
      staticClass: "s-medium"
    }, [_vm._v("\n              " + _vm._s(grp.name) + "\n            ")])]), _vm._v(" "), _c('td', {
      attrs: {
        "data-table-head": _vm.trans('organization.age_groups')
      }
    }, [_vm._v("\n            " + _vm._s(grp.category) + "\n          ")]), _vm._v(" "), _c('td', {
      attrs: {
        "data-table-head": _vm.trans('organization.group_edu_langs')
      }
    }, [_vm._v("\n            " + _vm._s(grp.lang) + "\n          ")]), _vm._v(" "), _c('td', {
      staticClass: "s-to-center",
      attrs: {
        "data-table-head": _vm.trans('organization.group_child_amount')
      }
    }, [_vm._v("\n            " + _vm._s(grp.contingent) + "\n          ")]), _vm._v(" "), _c('td', {
      staticClass: "s-to-center",
      attrs: {
        "data-table-head": _vm.trans('organization.group_throughput')
      }
    }, [_c('div', {
      staticClass: "s-medium s-c-green"
    }, [_vm._v("\n              " + _vm._s(grp.freeplaces) + "\n            ")])])]);
  }), 0)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }