var render = function () {
  var _vm$modalMessage, _vm$urls$modalAction;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-container-up"
  }, [_c('form-photo', {
    staticClass: "align-self-center align-self-md-start mb-30 mr-30",
    attrs: {
      "action": _vm.urls.avatarAction,
      "name": "avatar",
      "photo": _vm.form.avatar,
      "child-avatar": _vm.createChild
    },
    on: {
      "change": function change($event) {
        _vm.form.childAvatar = $event;
      }
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "form-right-container"
  }, [_c('h4', {
    staticClass: "s-title s-upper s-light s-title--right-line mb-30"
  }, [_c('span', [_vm._v(_vm._s(_vm.title))])]), _vm._v(" "), _c('q-form', {
    on: {
      "submit": _vm.requestForCheckIin
    }
  }, [!_vm.createChild || !_vm.withoutIin ? _c('q-input', {
    attrs: {
      "label": _vm.trans('placeholder.search_iin'),
      "mask": "############",
      "readonly": _vm.profileExists,
      "rules": [function (val) {
        return _vm.validateIin(val) || _vm.trans('validation.check_iin', {
          attribute: _vm.trans('placeholder.search_iin')
        });
      }],
      "required": "",
      "lazy-rules": "",
      "error": _vm.hasValidationFail('iin'),
      "error-message": _vm.getValidationMessage('iin')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('iin');
      }
    },
    scopedSlots: _vm._u([{
      key: "after",
      fn: function fn() {
        return [_c('q-btn', {
          attrs: {
            "color": "orange",
            "label": _vm.trans('label.iin_button_text'),
            "icon": "search",
            "no-wrap": "",
            "no-caps": "",
            "unelevated": "",
            "loading": _vm.loading,
            "disable": _vm.checkIinDisable || _vm.profileExists,
            "type": "submit"
          }
        })];
      },
      proxy: true
    }], null, false, 734273107),
    model: {
      value: _vm.form.iin,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "iin", $$v);
      },
      expression: "form.iin"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('q-form', {
    on: {
      "submit": _vm.requestForm
    }
  }, [!_vm.createChild ? _c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.first_fill')
    }
  }) : _vm._e(), _vm._v(" "), _c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.modal_inform')
    }
  }), _vm._v(" "), _vm.createChild ? _c('div', [_vm.existsStatementMessage ? _c('v-form-inform', {
    attrs: {
      "color": "blue",
      "message": _vm.existsStatementMessage
    }
  }) : _vm._e(), _vm._v(" "), _vm.createChild ? _c('q-checkbox', {
    attrs: {
      "label": _vm.trans('label.without_iin')
    },
    on: {
      "input": _vm.handleWithoutIinCheck
    },
    model: {
      value: _vm.withoutIin,
      callback: function callback($$v) {
        _vm.withoutIin = $$v;
      },
      expression: "withoutIin"
    }
  }) : _vm._e(), _vm._v(" "), _vm.withoutIin ? _c('div', {
    staticClass: "mb-20"
  }, [_c('q-input', {
    attrs: {
      "label": _vm.trans('label.document_number'),
      "mask": "####################",
      "readonly": _vm.profileExists,
      "required": "",
      "lazy-rules": "",
      "error": _vm.hasValidationFail('documentNumber'),
      "error-message": _vm.getValidationMessage('documentNumber')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('documentNumber');
      }
    },
    model: {
      value: _vm.form.documentNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "documentNumber", $$v);
      },
      expression: "form.documentNumber"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "sn-field__label mb-15"
  }, [_vm._v("\n            " + _vm._s(_vm.trans('label.nonresident_document')) + ":\n            "), _c('i', [_vm._v("*")])]), _vm._v(" "), !_vm.form.nonresidentDocument ? _c('q-btn', {
    staticClass: "s-w-100 form-upload__button",
    attrs: {
      "icon": "clip",
      "color": "blue",
      "type": "button",
      "label": _vm.trans('button.overview'),
      "label-wider": ""
    },
    on: {
      "click": function click($event) {
        return _vm.triggerFile();
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.form.nonresidentDocument ? _c('q-btn', {
    staticClass: "s-w-100 s-btn--border sn-btn--ellipsis",
    attrs: {
      "icon": "close",
      "label": _vm.form.nonresidentDocument.name,
      "label-wider": "",
      "no-caps": "",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.clearFile();
      }
    },
    model: {
      value: _vm.form.nonresidentDocument,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nonresidentDocument", $$v);
      },
      expression: "form.nonresidentDocument"
    }
  }) : _vm._e(), _vm._v(" "), _c('input', {
    ref: "fileInput",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": "application/msword, application/pdf, image/*"
    },
    on: {
      "change": _vm.fileChange
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.access || _vm.withoutIin ? _c('div', [_c('q-input', {
    attrs: {
      "label": _vm.trans('label.surname'),
      "readonly": !_vm.withoutIin,
      "required": _vm.withoutIin
    },
    model: {
      value: _vm.form.lastname,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "lastname", $$v);
      },
      expression: "form.lastname"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "label": _vm.trans('label.firstname'),
      "readonly": !_vm.withoutIin,
      "required": _vm.withoutIin
    },
    model: {
      value: _vm.form.firstname,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "firstname", $$v);
      },
      expression: "form.firstname"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "label": _vm.trans('label.middle_name'),
      "readonly": !_vm.withoutIin
    },
    model: {
      value: _vm.form.middlename,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "middlename", $$v);
      },
      expression: "form.middlename"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "readonly": !_vm.withoutIin,
      "mask": "##.##.####",
      "label": _vm.trans('label.born_date'),
      "required": _vm.withoutIin
    },
    model: {
      value: _vm.form.birthday,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "birthday", $$v);
      },
      expression: "form.birthday"
    }
  }), _vm._v(" "), _c('q-field', {
    attrs: {
      "label": _vm.trans('label.gender'),
      "borderless": "",
      "required": ""
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-radio', {
    attrs: {
      "val": _vm.genderOptions[0].value,
      "label": _vm.trans(_vm.genderOptions[0].label)
    },
    model: {
      value: _vm.form.genderCurrent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "genderCurrent", $$v);
      },
      expression: "form.genderCurrent"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-auto"
  }, [_c('q-radio', {
    attrs: {
      "val": _vm.genderOptions[1].value,
      "label": _vm.trans(_vm.genderOptions[1].label)
    },
    model: {
      value: _vm.form.genderCurrent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "genderCurrent", $$v);
      },
      expression: "form.genderCurrent"
    }
  })], 1)])]), _vm._v(" "), !_vm.createChild ? _c('q-input', {
    attrs: {
      "label": _vm.trans('label.phone'),
      "mask": "+7 (###) ###-##-##",
      "placeholder": "+7 (___) ___-__-__",
      "readonly": _vm.phoneExists,
      "required": "",
      "error": _vm.hasValidationFail('phone'),
      "error-message": _vm.getValidationMessage('phone'),
      "rules": [function (val) {
        return !!val || _vm.trans('validation.required', {
          attribute: _vm.trans('label.login_phone')
        });
      }]
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('phone');
      }
    },
    model: {
      value: _vm.form.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  }) : _vm._e(), _vm._v(" "), !_vm.createChild ? _c('q-input', {
    attrs: {
      "label": _vm.trans('label.address'),
      "required": ""
    },
    model: {
      value: _vm.form.address,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "address", $$v);
      },
      expression: "form.address"
    }
  }) : _vm._e(), _vm._v(" "), !_vm.createChild ? _c('q-input', {
    attrs: {
      "label": _vm.trans('label.email'),
      "rules": [function (val) {
        return _vm.validateEmail(val) || _vm.trans('validation.email', {
          attribute: _vm.trans('label.email')
        });
      }],
      "lazy-rules": "",
      "error": _vm.hasValidationFail('email'),
      "error-message": _vm.getValidationMessage('email')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('email');
      }
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }) : _vm._e(), _vm._v(" "), !_vm.createChild ? _c('q-input', {
    attrs: {
      "label": _vm.trans('label.additional_phone'),
      "mask": "+7 (###) ###-##-##",
      "placeholder": "+7 (___) ___-__-__",
      "error": _vm.hasValidationFail('additional_phone'),
      "error-message": _vm.getValidationMessage('additional_phone')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('additional_phone');
      }
    },
    model: {
      value: _vm.form.additionalPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "additionalPhone", $$v);
      },
      expression: "form.additionalPhone"
    }
  }) : _vm._e(), _vm._v(" "), _c('q-select', {
    attrs: {
      "options": _vm.nationalityOptionsFiltered,
      "label": _vm.trans('label.select_nationality'),
      "option-value": "code",
      "use-input": "",
      "required": _vm.withoutIin
    },
    on: {
      "filter": _vm.filterNationality
    },
    model: {
      value: _vm.form.nationalityCurrent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nationalityCurrent", $$v);
      },
      expression: "form.nationalityCurrent"
    }
  }), _vm._v(" "), !_vm.createChild ? _c('q-field', {
    staticClass: "mb-30",
    attrs: {
      "label": _vm.trans('label.notifications_language'),
      "borderless": ""
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-radio', {
    attrs: {
      "val": _vm.langOptions[0].value,
      "label": _vm.trans(_vm.langOptions[0].label),
      "required": ""
    },
    model: {
      value: _vm.form.langCurrent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "langCurrent", $$v);
      },
      expression: "form.langCurrent"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-auto"
  }, [_c('q-radio', {
    attrs: {
      "val": _vm.langOptions[1].value,
      "label": _vm.trans(_vm.langOptions[1].label)
    },
    model: {
      value: _vm.form.langCurrent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "langCurrent", $$v);
      },
      expression: "form.langCurrent"
    }
  })], 1)])]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "row-sort-between-btn"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-200",
    attrs: {
      "type": "button",
      "label": _vm.trans('button.cancel'),
      "no-caps": "",
      "color": "grey"
    },
    on: {
      "click": _vm.back
    }
  }), _vm._v(" "), _c('q-btn', {
    staticClass: "s-btn--w-200",
    attrs: {
      "color": "green",
      "no-caps": "",
      "type": "submit",
      "disabled": !_vm.validate,
      "loading": _vm.loading
    }
  }, [_vm._v("\n            " + _vm._s(_vm.trans('button.save')) + "\n          ")])], 1)], 1) : _vm._e()], 1)], 1), _vm._v(" "), _vm.showModal ? _c('v-user-profile-modal', {
    attrs: {
      "modal": _vm.showModal,
      "text": (_vm$modalMessage = _vm.modalMessage) !== null && _vm$modalMessage !== void 0 ? _vm$modalMessage : _vm.trans('notice.confirm_exist_iin'),
      "action": (_vm$urls$modalAction = _vm.urls.modalAction) !== null && _vm$urls$modalAction !== void 0 ? _vm$urls$modalAction : _vm.urls.confirmIin,
      "user-iin": _vm.form.iin,
      "user-document-number": _vm.form.documentNumber,
      "user-id": _vm.form.userId
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.showModal = $event;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }