var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-multiselect', _vm._b({
    staticClass: "mb-10",
    attrs: {
      "label": "name",
      "track-by": "id",
      "multiple": _vm.multiple,
      "options": _vm.options,
      "tag-placeholder": _vm.trans('select.tag_placeholder'),
      "select-label": _vm.trans('select.select_label'),
      "select-group-label": _vm.trans('select.select_group_label'),
      "selected-label": _vm.trans('select.selected_label'),
      "deselect-label": _vm.trans('select.deselect_label'),
      "deselect-group-label": _vm.trans('select.deselect_group_label')
    },
    on: {
      "input": _vm.changeValue
    },
    model: {
      value: _vm.selectValue,
      callback: function callback($$v) {
        _vm.selectValue = $$v;
      },
      expression: "selectValue"
    }
  }, 'v-multiselect', _vm.extraAttributes, false), [_c('template', {
    slot: "noOptions"
  }, [_vm._v("\n    " + _vm._s(_vm.trans('select.list_is_empty')) + "\n  ")]), _vm._v(" "), _c('template', {
    slot: "noResult"
  }, [_vm._v("\n    " + _vm._s(_vm.trans('select.list_is_empty')) + "\n  ")])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }