var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row align-items-stretch justify-content-center s-statistic-panel__wrapper"
  }, _vm._l(_vm.list, function (itm, idx) {
    return _c('div', {
      key: idx,
      staticClass: "col mb-30 d-flex justify-content-center"
    }, [_c('div', {
      staticClass: "s-statistic-panel",
      class: {
        'is-loading': _vm.loading
      }
    }, [_c('div', {
      staticClass: "s-statistic-panel__icon"
    }, [itm && itm.key && typeof itm.key === 'string' && itm.key !== '' ? _c('img', {
      attrs: {
        "src": "".concat(_vm.iconUrl, "/").concat(itm.key.split('.')[1], ".svg")
      }
    }) : _vm._e()]), _vm._v(" "), _c('div', {
      staticClass: "s-statistic-panel__label"
    }, [_vm._v("\n        " + _vm._s(itm && itm.key && _vm.trans(itm.key)) + "\n      ")]), _vm._v(" "), _c('div', {
      staticClass: "s-statistic-panel__value"
    }, [_vm._v("\n        " + _vm._s(itm && itm.key && itm.value) + "\n      ")])])]);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }