import { StoreTranslator } from '@common/mixins';
import _ from 'lodash';

export default {
  mixins: [StoreTranslator],
  props: {
    value: {},
    addDefaultOption: {
      type: Object,
      required: false,
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    extraBinds: {
      type: Object,
      default: () => ({}),
    },
    inputDebounce: {
      type: String,
      default: '0',
    },
    customFilterFn: {
      type: Function,
      required: false,
    },
    optionValue: {
      type: String,
      default: 'id',
    },
    optionLabel: {
      type: String,
      default: 'name',
    },
    multiple: Boolean,
    showMultipleValues: {
      type: Boolean,
      default: true,
    },
    useInput: {
      type: Boolean,
      default: true,
    },
    oldSearch: {
      type: Boolean,
      default: true,
    },
    error: Boolean,
    errorMessage: String,
    useOptionsFromProp: Boolean,
  },
  data() {
    return {
      loadedValues: [],
      selectValue: [],
      filterOptions: [],
      filteredOptions: [],
      isMounted: false,
    };
  },

  beforeCreate() {
    this.$trans.add(['select', 'placeholder']);
  },
  mounted() {
    this.filteredOptions = this.options;
    this.setInitialSelectValue();
    this.isMounted = true;
  },
  methods: {
    filterFn(val, update) {
      if (this.customFilterFn) {
        this.customFilterFn(val, update);
        return;
      }
      if (val === '') {
        update(() => {
          this.filteredOptions = this.options;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.filteredOptions = this.options.filter(v => v?.[this.optionLabel]?.toLowerCase()?.indexOf(needle) > -1);
      });
    },
    emitChange(value = null) {
      if (this.multiple) {
        if (_.isNumber(value)) {
          const values = [this.options.find(option => option[this.optionValue] === value)];
          this.$emit('input', values);
        } else {
          this.$emit('input', value);
        }
      } else if (value && value.hasOwnProperty(this.optionValue)) {
        this.$emit('input', value[this.optionValue]);
        this.$emit('input-full-option', value);
      } else {
        this.$emit('input', null);
      }
    },
    setInitialSelectValue() {
      const options = _.cloneDeep(this.localOptions);
      if (this.value !== '' && this.value !== null) {
        if (!this.multiple) {
          this.selectValue = _.find(options, { [this.optionValue]: this.value });
        } else if (_.isNumber(this.value)) {
          const values = [this.options.find(option => option[this.optionValue] === this.value)];
          this.selectValue = values;
        } else {
          this.selectValue = this.value;
        }
      } else if (
        this.extraBinds?.placeholder
        && !this.useInput
        && !options.find(option => option[this.optionValue] === this.value)
      ) {
        this.selectValue = {
          [this.optionValue]: null,
          [this.optionLabel]: this.extraBinds.placeholder,
        };
      } else if (options.find(option => option[this.optionValue] === this.value)) {
        this.selectValue = options.find(option => option[this.optionValue] === this.value);
      } else {
        this.selectValue = null;
      }
    },
  },
  watch: {
    inputValue(val) {
      this.$emit('input-value-change', val);
    },
    value(value) {
      // update value
      this.setInitialSelectValue();
    },
    options(value) {
      if (!_.isEqual(value, this.filteredOptions)) {
        this.filteredOptions = value;
      }
    },
    localOptions(value) {
      // update value
      this.setInitialSelectValue();
    },
  },
  computed: {
    inputValue() {
      const value = this.$refs?.['q-select-wrapper-ref']?.inputValue;
      if (
        !this.isMounted || [
          this.trans('select.placeholder'),
          this.trans('placeholder.choose'),
          this.selectValue?.[this.optionLabel],
        ].includes(value)
      ) {
        return '';
      }
      return this.$refs?.['q-select-wrapper-ref']?.inputValue;
    },
    localOptions() {
      let options = this.useOptionsFromProp ? this.options : this.filteredOptions;
      if (this.addDefaultOption) {
        options = options.filter(item => !_.isEqual(item, this.addDefaultOption));
        options.unshift(this.addDefaultOption);
      }
      return options;
    },
    defaultAttributes() {
      let placeholder = this.extraBinds?.placeholder ?? this.trans('placeholder.choose');
      if (this.selectValue) {
        placeholder = '';
      }
      return {
        placeholder,
      };
    },

    extraAttributes() {
      const attrs = this.extraBinds;

      return {
        ...this.defaultAttributes,
        ...attrs,
      };
    },
  },
};
