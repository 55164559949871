var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-dialog', {
    attrs: {
      "value": _vm.modal
    },
    on: {
      "input": _vm.handleModal
    }
  }, [_c('div', {
    staticClass: "s-photo-album__modal-container",
    style: _vm.picSize
  }, [_c('img', {
    attrs: {
      "src": _vm.curPic.imgLoading ? _vm.oldPic.img : _vm.curPic.img
    }
  }), _vm._v(" "), _vm.curPic.imgLoading ? _c('q-spinner', {
    attrs: {
      "color": "grey",
      "size": "4.8rem"
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "s-photo-album__modal-directions"
  }, [_vm.withDirections ? _c('button', {
    staticClass: "s-photo-album__modal-prev",
    on: {
      "click": function click($event) {
        return _vm.$emit('prev');
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.withDirections ? _c('button', {
    staticClass: "s-photo-album__modal-next",
    on: {
      "click": function click($event) {
        return _vm.$emit('next');
      }
    }
  }) : _vm._e()])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }