var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-3"
  }, [_c('q-input', {
    attrs: {
      "label": _vm.trans('statement-refuse.table.fio')
    },
    on: {
      "input": _vm.changeFilterField
    },
    model: {
      value: _vm.child_full_name,
      callback: function callback($$v) {
        _vm.child_full_name = $$v;
      },
      expression: "child_full_name"
    }
  }, [_c('q-icon', {
    attrs: {
      "slot": "prepend",
      "name": "search"
    },
    slot: "prepend"
  })], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "col-md-3"
  }, [_c('q-input', {
    attrs: {
      "label": _vm.trans('statement-refuse.table.school')
    },
    on: {
      "input": _vm.changeFilterField
    },
    model: {
      value: _vm.school_name,
      callback: function callback($$v) {
        _vm.school_name = $$v;
      },
      expression: "school_name"
    }
  }, [_c('q-icon', {
    attrs: {
      "slot": "prepend",
      "name": "search"
    },
    slot: "prepend"
  })], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "col-md-3"
  }, [_c('q-select', {
    attrs: {
      "label": _vm.trans('statement-refuse.table.status'),
      "options": _vm.getStatusList
    },
    model: {
      value: _vm.commonStatus,
      callback: function callback($$v) {
        _vm.commonStatus = $$v;
      },
      expression: "commonStatus"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-md-3"
  }, [_c('q-select', {
    attrs: {
      "label": _vm.trans('statement-refuse.table.type-refuse'),
      "options": _vm.getStatementStatusList
    },
    model: {
      value: _vm.statementStatus,
      callback: function callback($$v) {
        _vm.statementStatus = $$v;
      },
      expression: "statementStatus"
    }
  })], 1)]), _vm._v(" "), _c('q-table', {
    attrs: {
      "data": _vm.statements,
      "columns": _vm.columns,
      "table-class": "adaptive-table",
      "row-key": "id",
      "loading": _vm.loading,
      "pagination": _vm.pagination,
      "rows-per-page": _vm.pagination.rowsPerPage,
      "flat": "",
      "binary-state-sort": ""
    },
    on: {
      "update:pagination": function updatePagination($event) {
        _vm.pagination = $event;
      },
      "request": _vm.onRequest
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, _vm._l(props.cols, function (col) {
          return _c('q-th', {
            key: col.name,
            staticClass: "refuse-statement-th",
            attrs: {
              "props": props
            }
          }, [_vm._v("\n          " + _vm._s(col.label) + "\n        ")]);
        }), 1)];
      }
    }, {
      key: "body",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, [_c('q-td', {
          key: "number",
          staticClass: "refuse-statement-td",
          attrs: {
            "props": Object.assign({
              thClass: 'sn-sortable sn--text-center'
            }, props)
          }
        }, [_vm._v("\n          " + _vm._s(props.row.number) + "\n        ")]), _vm._v(" "), _c('q-td', {
          key: "child_full_name",
          staticClass: "refuse-statement-td",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n          " + _vm._s(props.row.child_full_name) + "\n        ")]), _vm._v(" "), _c('q-td', {
          key: "school_name",
          staticClass: "refuse-statement-td",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n          " + _vm._s(props.row.school_name) + "\n        ")]), _vm._v(" "), _c('q-td', {
          key: "created_at",
          staticClass: "refuse-statement-td",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n          " + _vm._s(props.row.created_at) + "\n        ")]), _vm._v(" "), _c('q-td', {
          key: "statement_status",
          staticClass: "refuse-statement-td",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n          " + _vm._s(_vm.getStatementStatus(props.row.statement_status).label) + "\n        ")]), _vm._v(" "), _c('q-td', {
          key: "status",
          staticClass: "refuse-statement-td",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n          " + _vm._s(_vm.getStatus(props.row.status).label) + "\n        ")]), _vm._v(" "), _c('q-td', {
          key: "id",
          staticClass: "refuse-statement-td",
          attrs: {
            "props": props
          }
        }, [_c('a', {
          staticClass: "refuse-btn-container",
          attrs: {
            "href": "".concat(_vm.viewUri, "/").concat(props.row.id)
          }
        }, [_c('q-btn', {
          attrs: {
            "squire": "",
            "icon": "eye"
          }
        }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('statement-refuse.table.show')))])], 1)], 1)])], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }