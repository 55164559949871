import VSteps from './VSteps';
import VStepsBody from './VStepsBody';
import VStepInformation from './VStepInformation';
import VStepAuthentication from './VStepAuthentication';
import VStepUserinfo from './VStepUserinfo';
import VStepAddChild from './VStepAddChild';
import VStepAddStatement from './VStepAddStatement';
import VStepCongratulations from './VStepCongratulations';

export default {
  name: 'VStatementCreateView',
  components: {
    VSteps,
    VStepsBody,
    VStepInformation,
    VStepAuthentication,
    VStepUserinfo,
    VStepAddChild,
    VStepAddStatement,
    VStepCongratulations,
  },
  props: {
    uriStatic: {
      type: String,
      default: '',
    },
    uriAbout: {
      type: String,
      required: true,
    },
    // Registration
    uriSendPhone: {
      type: String,
      required: true,
    },
    uriSendCode: {
      type: String,
      required: true,
    },
    uriSendPassword: {
      type: String,
      required: true,
    },
    // Authorization
    uriSendAuth: {
      type: String,
      required: true,
    },
    uriGetProfile: {
      type: String,
      required: true,
    },
    uriSendProfileUpdate: {
      type: String,
      required: true,
    },
    // Password restore
    uriSendRestorePhone: {
      type: String,
      required: true,
    },
    uriSendRestoreCode: {
      type: String,
      required: true,
    },
    uriSendRestorePassword: {
      type: String,
      required: true,
    },
    // Request userinfo by iin
    uriSendIin: {
      type: String,
      required: true,
    },
    // Запросы по добавлению ребёнка
    uriSendChildIin: {
      type: String,
      required: true,
    },
    uriSendChild: {
      type: String,
      required: true,
    },
    uriSendStatement: {
      type: String,
      required: true,
    },
    // Request select options for nationalities
    uriNationalities: {
      type: String,
      required: true,
    },
    referenceTime: Number,
    // Authorization status
    auth: {
      type: Boolean,
      required: true,
    },
    uriGetStatementFormData: {
      type: String,
      required: true,
    },
    linkStatements: {
      type: String,
      default: '',
    },
    uriOfferModal: {
      type: String,
      required: true,
    },
    uriGetLgotTypeDocuments: {
      type: String,
      required: true,
    },
    uriGetLgotTypes: {
      type: String,
      required: true,
    },
    queueTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      authData: {},
      userInfo: {
        parentId: 0,
        childId: 0,
        statusOptions: [{ value: null }],
      },
    };
  },

  beforeCreate() {
    this.$trans.add(['statement']);
  },

  mounted() {
    if (this.auth) {
      this.$store.commit('SET_VISIBLE_STEP', {
        step: 2,
        visible: false,
      });
    }
  },

  computed: {
    current() {
      return this.$store.state.vSteps.currentStep;
    },
  },

  methods: {
    loadingProcessing(status) {
      this.loading = status;
    },
    sendData(data) {
      this.authData = data;
    },
    sendUserInfo(data) {
      this.userInfo = data;
    },
  },
};
