var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-statement-create__item-inner"
  }, [_c('v-page-content', {
    attrs: {
      "uri-content": _vm.uriAbout
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "row mb-20"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('q-checkbox', {
    attrs: {
      "val": "confidence"
    },
    model: {
      value: _vm.accept,
      callback: function callback($$v) {
        _vm.accept = $$v;
      },
      expression: "accept"
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.trans('statement.accept_confidence'))
    }
  })])], 1)]), _vm._v(" "), _c('div', {
    staticClass: "row mb-20"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('q-checkbox', {
    staticStyle: {
      "vertical-align": "middle"
    },
    attrs: {
      "label": _vm.trans('statement.i_agree_and'),
      "val": "policy"
    },
    model: {
      value: _vm.accept,
      callback: function callback($$v) {
        _vm.accept = $$v;
      },
      expression: "accept"
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "s-c-blue s-u-dashed sn--cursor-pointer",
    staticStyle: {
      "vertical-align": "middle"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.offerInfo();
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.trans('statement.accept_policy_link')) + "\n      ")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }