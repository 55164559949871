import _ from 'lodash';
import {
  requestWrapper,
} from '@vjs/helpers';
import {
  QInput,
  QDate,
  QPopupProxy,
  QSelect,
  QTable,
  QIcon,
  QTr,
  QTh,
  QTd,
  QBtn,
  QTooltip,
} from '@quasar/components';
import { Loading } from '@quasar/plugins';
import axios from 'axios';
import { ERR_404 } from '../../../../helpers/requestWrapper';


export default {
  name: 'VFreePlacesMonitoringView',
  components: {
    QInput,
    QDate,
    QPopupProxy,
    QSelect,
    QTable,
    QIcon,
    QTr,
    QTh,
    QTd,
    QBtn,
    QTooltip,
  },
  props: {
    apiUrl: {
      type: String,
    },
    mode: {
      type: String,
    },
    colNames: {
      type: Array,
      required: true,
    },
    uriLocality: {
      type: String,
    },
    uriGetOrganizations: {
      type: String,
    },
    headerName: {
      type: String,
    },
  },
  data: () => ({
    form: {
      locality: null,
      organization: null,
      page: 1,
    },
    qLoading: Loading,
    exportLoading: false,
    table: {
      inFullscreen: false,
      pagination: {
        rowsPerPage: 10,
      },
      columns: [],
      title: '',
    },
    rows: [],
    filterString: '',
    localityOptions: [],
    orgNameOptions: [],
    localityOptionsRaw: [],
  }),
  async mounted() {
    await this.$trans.add(['statistics', 'button', 'menu', 'placeholder', 'bulletin']);
    this.requestGetLocalities();

    const cols = [];

    this.colNames.forEach((itm, itmIdx) => {
      cols.push({
        name: itm.name,
        required: true,
        label: this.trans(`statistics.free_places.${itm.label}`),
        align: itmIdx === 0 ? 'left' : 'center',
        field: itm.name,
        sortable: true,
        colspan: itm.colspan,
        rowspan: itm.rowspan,
      });
    });
    this.table.columns = cols;
  },
  beforeCreate() {
  },
  methods: {
    async requestOrg() {
      this.form.organization = null;
      this.orgNameOptions = [];
      const orgRes = await requestWrapper.call(this, {
        url: this.uriGetOrganizations,
        params: {
          locality: this.form.locality,
        },
      });
      this.orgNameOptions = (_.isArray(orgRes.data) && orgRes.data) || [];

      if (this.organization && this.organization.value && this.orgNameOptions.length) {
        this.organization = { value: null };
      }
      this.requestGetData();
    },
    async requestGetLocalities() {
      const localityRes = await requestWrapper({
        url: this.uriLocality,

      });
      if (localityRes.error) {
        if (localityRes.errorType === ERR_404 && _.isBoolean(localityRes.data.region)) {
          this.modalRegionSelect = true;
        }
        return;
      }
      this.localityOptions = localityRes.data;
      this.localityOptionsRaw = localityRes.data;
      this.requestGetData();
    },
    filtersFn(val, update) {
      if (val === '') {
        update(() => {
          this.localityOptions = this.localityOptionsRaw;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.localityOptions = this.localityOptionsRaw.filter(item => _.lowerCase(item.label).indexOf(needle) > -1);
      });
    },
    sum(col) {
      const rows = _.filter(
        this.rows,
        item => typeof item[col.name] === 'number',
      );
      return _.sumBy(rows, col.name);
    },
    clearFilters() {
      this.form.organization = '';
      this.orgNameOptions = [];
      this.form.locality = '';
      this.requestGetData();
    },

    async requestGetData() {
      this.qLoading.show({ message: this.trans('label.wait_loading') });
      const dataRes = await requestWrapper({
        url: this.apiUrl,
        params: {
          locality_id: this.form.locality,
          organization_id: this.form.organization,
        },
      });

      if (!dataRes.error) {
        this.rows = dataRes.data;
      }
      this.qLoading.hide();
    },
    async exportu(e) {
      this.qLoading.show({ message: this.trans('label.wait_loading') });
      this.loading = true;
      axios({
        url: `${this.apiUrl}?export=1`,
        method: 'GET',
        responseType: 'blob',
        params: {
          locality_id: this.form.locality,
          organization_id: this.form.organization,
        },
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        const date = new Date();

        fileLink.setAttribute('download',
          `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()
          } ${this.$trans.get('menu.monitoring_new.free_places')
          } ${this.form.locality ? this.$trans.get('statistics.free_places.locality', { date: this.form.locality }) : ''
          } ${this.form.organization ? this.$trans.get('statistics.free_places.org_name', { date: this.form.organization }) : ''
          } .xlsx`);
        document.body.appendChild(fileLink);

        fileLink.click();
        this.qLoading.hide();
        this.loading = false;
      });
    },
    getCellStyle(cellName) {
      switch (cellName) {
        case 'available_count': return {
          whiteSpace: 'unset',
          minWidth: '25rem',
        };
        case 'org_name': return {
          whiteSpace: 'unset',
          minWidth: '30rem',
        };
        case 'group_name': return {
          whiteSpace: 'unset',
          minWidth: '30rem',
        };
        case 'ages_group': return {
          whiteSpace: 'unset',
          minWidth: '35rem',
        };
        default: return {};
      }
    },
  },
};
