var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.items.length === 0 ? _c('v-no-results', {
    attrs: {
      "image": "search",
      "title": _vm.noResultsTitle,
      "text": _vm.noResultsLabel
    }
  }) : _c('q-table', {
    attrs: {
      "data": _vm.items,
      "columns": _vm.table.columns,
      "row-key": "name",
      "hide-bottom": "",
      "hide-header": "",
      "pagination": _vm.table.pagination,
      "wrap-cells": ""
    },
    on: {
      "update:pagination": function updatePagination($event) {
        return _vm.$set(_vm.table, "pagination", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, [_c('q-td', [_vm._v("\n          " + _vm._s(props.row.name) + "\n        ")]), _vm._v(" "), _c('q-td', {
          staticClass: "s-to-right"
        }, [_c('q-btn', {
          attrs: {
            "color": "orange",
            "squire": "",
            "icon": "eye",
            "type": "a",
            "to": "".concat(props.row.url)
          }
        }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('button.see')))])], 1)], 1)], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }