var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-field', {
    attrs: {
      "field": _vm.field,
      "field-name": _vm.fieldName,
      "errors": _vm.errors
    }
  }, [_c('template', {
    slot: "field"
  }, [_c('vue-date-picker', _vm._b({
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        mask: '99.99.9999',
        placeholder: '__.__.____'
      },
      expression: "{mask: '99.99.9999', placeholder: '__.__.____' }"
    }],
    staticClass: "s-form__input",
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.localValue,
      callback: function callback($$v) {
        _vm.localValue = $$v;
      },
      expression: "localValue"
    }
  }, 'vue-date-picker', _vm.extraAttributes, false))], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }