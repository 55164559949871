var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-photo-album"
  }, [_c('div', {
    staticClass: "s-photo-album__picture",
    style: {
      backgroundImage: "url(".concat(_vm.background, ")")
    }
  }, [_vm.curPic.thumbLoading && _vm.picList.length > 0 ? _c('q-spinner', {
    attrs: {
      "color": "white",
      "size": "4.8rem"
    }
  }) : _vm._e(), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "opacity"
    }
  }, [_vm.changingPic ? _c('div', {
    staticClass: "s-photo-album__picture-prev",
    style: {
      backgroundImage: "url(".concat(_vm.oldPic.thumb, ")")
    }
  }) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "s-photo-album__picture-footer"
  }, [_vm.isEdit ? _c('button', {
    staticClass: "s-ico-fa s-ico-fa--thm-plus sn--cursor-pointer s-photo-album__add-photo",
    attrs: {
      "type": "button",
      "loading": _vm.disableEdit
    },
    on: {
      "click": function click($event) {
        _vm.modalAdd = true;
      }
    }
  }, [_c('q-tooltip', [_vm._v(" " + _vm._s(_vm.trans('label.photo_album_actions.add_photo')))])], 1) : _vm._e(), _vm._v(" "), _vm.isEdit && _vm.currentPic ? _c('button', {
    staticClass: "s-ico-fa s-ico-fa--thm-pen sn--cursor-pointer s-photo-album__edit-photo",
    attrs: {
      "type": "button",
      "loading": _vm.disableEdit
    },
    on: {
      "click": function click($event) {
        _vm.modalEdit = true;
      }
    }
  }, [_c('q-tooltip', [_vm._v(" " + _vm._s(_vm.trans('label.photo_album_actions.edit_photo')))])], 1) : _vm._e(), _vm._v(" "), _vm.curPic.img ? _c('button', {
    staticClass: "s-ico-fa s-ico-fa--thm-expand sn--cursor-pointer s-photo-album__fullscreen",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.openFullPreview
    }
  }, [_c('q-tooltip', [_vm._v(" " + _vm._s(_vm.trans('label.photo_album_actions.open_fullscreen')))])], 1) : _vm._e()])], 1), _vm._v(" "), _vm.picItems.length > 1 ? _c('div', {
    staticClass: "s-photo-album__mini-items-wrapper mt-10"
  }, [_vm.picItems.length > _vm.countOfVisibleThumbs ? _c('button', {
    staticClass: "s-photo-album__control-prev",
    attrs: {
      "disabled": !_vm.isSlideBackDisabled
    },
    on: {
      "click": function click($event) {
        return _vm.thumbSlide(false);
      }
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    ref: "rowScrollWrapper",
    staticClass: "s-photo-album__mini-items-scroll-wrapper"
  }, [_c('div', {
    staticClass: "s-photo-album__mini-items-scroll",
    style: {
      transform: "translateX(".concat(_vm.convertToRem(_vm.scrollRowPosition), ")")
    }
  }, _vm._l(_vm.picItems, function (pic, picIdx) {
    return _c('div', {
      key: picIdx,
      staticClass: "s-photo-album__mini-item",
      class: {
        'is-active': _vm.curPicIdx === picIdx
      },
      style: {
        backgroundImage: pic.thumbLoaded ? "url(".concat(pic.thumb, ")") : '',
        marginRight: _vm.convertToRem(_vm.marginRight),
        width: _vm.convertToRem(_vm.thumbWidth)
      },
      on: {
        "click": function click($event) {
          return _vm.selectPic(picIdx);
        }
      }
    }, [pic.thumbLoading ? _c('q-spinner', {
      attrs: {
        "color": "white",
        "size": "2.4rem"
      }
    }) : _vm._e()], 1);
  }), 0)]), _vm._v(" "), _vm.picItems.length > _vm.countOfVisibleThumbs ? _c('button', {
    staticClass: "s-photo-album__control-next",
    attrs: {
      "disabled": !_vm.isSlideForwardDisabled
    },
    on: {
      "click": function click($event) {
        return _vm.thumbSlide(true);
      }
    }
  }) : _vm._e()]) : _vm._e(), _vm._v(" "), _c('v-photo-album-fullscreen-modal', {
    attrs: {
      "modal": _vm.modalFullPreview,
      "cur-pic": _vm.curPic,
      "old-pic": _vm.oldPic,
      "changing-pic": _vm.changingPic,
      "with-directions": _vm.picList.length > 1
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.modalFullPreview = $event;
      },
      "prev": _vm.prevPic,
      "next": _vm.nextPic
    }
  }), _vm._v(" "), _c('v-photo-album-add-modal', {
    attrs: {
      "modal": _vm.modalAdd,
      "is-custom": _vm.isCustom
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.modalAdd = $event;
      }
    }
  }), _vm._v(" "), _vm.modalEdit ? _c('v-photo-album-edit-modal', {
    attrs: {
      "current-pic": _vm.currentPic,
      "modal": _vm.modalEdit,
      "is-custom": _vm.isCustom
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.modalEdit = $event;
      },
      "photo-deleted": _vm.changeCurrentPic
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }