var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-select-from-list"
  }, [_c('span', {
    staticClass: "sn-field__label"
  }, [_vm._v(_vm._s(_vm.label) + ": "), _vm.required ? _c('i', [_vm._v("*")]) : _vm._e()]), _vm._v(" "), _vm._l(_vm.selectedList, function (option, optionIdx) {
    return _c('v-select-from-list-option', {
      key: optionIdx,
      attrs: {
        "value": option.label,
        "key-id": option.value,
        "readonly": ""
      },
      on: {
        "remove": _vm.handleRemoveOption
      }
    });
  }), _vm._v(" "), _vm.options.length === 0 ? _c('q-select', {
    attrs: {
      "display-value": _vm.trans('label.no_data'),
      "readonly": ""
    },
    model: {
      value: _vm.selectedOption,
      callback: function callback($$v) {
        _vm.selectedOption = $$v;
      },
      expression: "selectedOption"
    }
  }) : _vm._e(), _vm._v(" "), _vm.optionsList.length > 0 ? _c('q-select', {
    attrs: {
      "options": _vm.optionsList,
      "display-value": _vm.selectPlaceholder
    },
    on: {
      "input": _vm.handleSelect
    },
    model: {
      value: _vm.selectedOption,
      callback: function callback($$v) {
        _vm.selectedOption = $$v;
      },
      expression: "selectedOption"
    }
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }