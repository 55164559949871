var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.localValue,
      expression: "localValue"
    }],
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.localValue
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.localValue = $event.target.value;
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }