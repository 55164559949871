var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-field', {
    attrs: {
      "field": _vm.field,
      "field-name": _vm.fieldName,
      "errors": _vm.errors
    }
  }, [_c('template', {
    slot: "field"
  }, [_c('div', {}, [_c(_vm.config.component, _vm._g({
    key: _vm.field.name + '[from]',
    tag: "component",
    staticClass: "s-form-between__input",
    attrs: {
      "field": _vm.config.fieldFrom,
      "field-name": ""
    },
    model: {
      value: _vm.fromValue,
      callback: function callback($$v) {
        _vm.fromValue = $$v;
      },
      expression: "fromValue"
    }
  }, _vm.config.fieldFrom.events ? _vm.config.fieldFrom.events : {})), _vm._v("\n      -\n      "), _c(_vm.config.component, _vm._g({
    key: _vm.field.name + '[to]',
    tag: "component",
    staticClass: "s-form-between__input",
    attrs: {
      "field": _vm.config.fieldTo,
      "field-name": ""
    },
    model: {
      value: _vm.toValue,
      callback: function callback($$v) {
        _vm.toValue = $$v;
      },
      expression: "toValue"
    }
  }, _vm.config.fieldTo.events ? _vm.config.fieldTo.events : {}))], 1)])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }