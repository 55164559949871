var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.images && _vm.images.length ? _c('viewer', {
    ref: "viewer",
    staticClass: "viewer",
    attrs: {
      "images": _vm.images,
      "options": _vm.options
    },
    on: {
      "inited": _vm.inited
    }
  }, _vm._l(_vm.images, function (_ref) {
    var source = _ref.source,
        thumbnail = _ref.thumbnail;
    return _c('img', {
      key: source,
      staticClass: "image",
      attrs: {
        "src": thumbnail,
        "data-source": source
      }
    });
  }), 0) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }