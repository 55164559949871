var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    class: _vm.cellClass,
    on: {
      "click": function click($event) {
        return _vm.$emit('click');
      }
    }
  }, [_vm.value !== null ? _c('span', [_vm._v(_vm._s(_vm.getValue))]) : _vm._e(), _vm._v(" "), _vm.value === null ? _vm._t("default") : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }