var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.items.length ? _c('v-no-results', {
    attrs: {
      "image": "search",
      "title": _vm.trans('feedback.title_null')
    }
  }) : _vm._l(_vm.items, function (item, key) {
    return _c('div', {
      key: key,
      ref: "feedback-ref-".concat(item.id),
      refInFor: true,
      staticClass: "reviews-block mb-30"
    }, [_c('div', {
      staticClass: "reviews-block__wrapper"
    }, [_c('div', {
      staticClass: "reviews-block__title"
    }, [_c('div', {
      staticClass: "reviews-block__image",
      style: "background-image: ".concat(item.field_avatar)
    }), _vm._v(" "), _c('div', {
      staticClass: "reviews-block__name mb-10"
    }, [_vm._v("\n            " + _vm._s(item.name) + "\n          ")]), _vm._v(" "), item.locality_name ? _c('div', {
      staticClass: "s-str-ico-fa s-str-ico-fa--thm-map-marker-alt s-c-grey"
    }, [_vm._v("\n            " + _vm._s(item.locality_name) + "\n          ")]) : _vm._e()]), _vm._v(" "), _c('div', {
      staticClass: "reviews-block__desc"
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(_vm.getDescription(item.body, item.id))
      }
    }), _vm._v(" "), _c('br'), _vm._v(" "), _vm.getSplittedDesc(item.body).length > _vm.maxDescLength ? _c('button', {
      staticClass: "button-expand",
      on: {
        "click": function click($event) {
          return _vm.toggleFullDesc(item.id);
        }
      }
    }, [_vm._v("\n            " + _vm._s(_vm.idsWithFullText.includes(item.id) ? _vm.trans('feedback.hide_full_desc') : _vm.trans('feedback.show_full_desc')) + "\n          ")]) : _vm._e()])])]);
  }), _vm._v(" "), _c('div', {
    staticClass: "row-sort-start"
  }, [_c('a', {
    staticClass: "s-btn s-btn--w-200 s-btn--thm-grey",
    attrs: {
      "href": _vm.uriBack
    }
  }, [_vm._v("\n      " + _vm._s(_vm.trans('button.back')) + "\n    ")])])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }