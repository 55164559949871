export default {
  name: 'VDirectionsTable',

  props: {
    school_name: String,
    child_name: String,
    child_year: String,
    group_name: String,
    free_place_type: String,
    language: String,
    privilege: String,
    created_at: String,
    updated_at: String,
  },
};
