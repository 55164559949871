var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-dialog', {
    attrs: {
      "value": _vm.modal
    },
    on: {
      "input": _vm.handleModal
    }
  }, [_c('v-modal', {
    attrs: {
      "title": _vm.trans('label.doc_extend_direction'),
      "size": "medium"
    }
  }, [_c('div', {
    staticClass: "s-plain-text"
  }, [_c('p', {
    staticClass: "s-medium"
  }, [_vm._v("\n        " + _vm._s(_vm.trans('label.docs_criteries')) + ":\n      ")]), _vm._v(" "), _c('ul', {
    staticClass: "mb-20"
  }, [_c('li', [_vm._v(_vm._s(_vm.trans('label.file_formats')) + ";")]), _vm._v(" "), _c('li', [_vm._v(_vm._s(_vm.trans('label.file_size')) + ";")]), _vm._v(" "), _c('li', [_vm._v(_vm._s(_vm.trans('label.file_dimensions')) + ";")])])]), _vm._v(" "), _c('q-select', {
    staticClass: "mb-10",
    attrs: {
      "popup-content-class": "sn-menu-short",
      "options": _vm.lgotTypeOptions,
      "label": _vm.trans('label.lgot_type'),
      "required": ""
    },
    on: {
      "input": function input($event) {
        return _vm.getLgotTypeDocuments();
      }
    },
    model: {
      value: _vm.lgotType,
      callback: function callback($$v) {
        _vm.lgotType = $$v;
      },
      expression: "lgotType"
    }
  }), _vm._v(" "), _c('q-slide-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: Object.keys(_vm.lgotTypeDocuments).length,
      expression: "Object.keys(lgotTypeDocuments).length"
    }]
  }, _vm._l(_vm.lgotTypeDocuments, function (item, key) {
    return _c('div', {
      key: key,
      staticClass: "mt-10 mb-20"
    }, [_c('div', {
      staticClass: "sn-field__label mb-15"
    }, [_vm._v("\n            " + _vm._s(_vm.lgotTypeDocumentRules[key].label) + ":\n            "), _vm.lgotTypeDocumentRules[key].required === true ? _c('i', [_vm._v("*")]) : _vm._e()]), _vm._v(" "), !item ? _c('q-btn', {
      staticClass: "s-w-100 form-upload__button",
      attrs: {
        "icon": "clip",
        "color": "blue",
        "type": "button",
        "label": _vm.trans('button.overview'),
        "label-wider": ""
      },
      on: {
        "click": function click($event) {
          return _vm.triggerFile(key);
        }
      }
    }) : _vm._e(), _vm._v(" "), item ? _c('q-btn', {
      staticClass: "s-w-100 s-btn--border sn-btn--ellipsis",
      attrs: {
        "icon": "close",
        "label": item.name,
        "label-wider": "",
        "type": "button"
      },
      on: {
        "click": function click($event) {
          return _vm.clearFile(key);
        }
      }
    }) : _vm._e()], 1);
  }), 0)]), _vm._v(" "), _c('input', {
    ref: "fileInput",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": "application/msword, application/pdf, image/*"
    },
    on: {
      "change": _vm.fileChange
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-between"
  }, [_c('div', {
    staticClass: "col col-sm-auto sn--text-center mt-5 mb-5"
  }, [_c('q-btn', {
    directives: [{
      name: "close-popup",
      rawName: "v-close-popup"
    }],
    attrs: {
      "width": "20",
      "label": _vm.trans('button.cancel'),
      "label-wider": "",
      "color": "grey"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col col-sm-auto sn--text-center mt-5 mb-5"
  }, [_c('q-btn', {
    attrs: {
      "width": "20",
      "label": _vm.trans('button.send'),
      "color": "green",
      "disable": !_vm.validate
    },
    on: {
      "click": _vm.send
    }
  })], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }