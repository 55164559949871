var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('portal', {
    attrs: {
      "to": "tabs-portal"
    }
  }, [_c('div', {
    staticClass: "container-content-head__tabs-wrapper container-content-head__tabs--free"
  }, [_c('a', {
    staticClass: "container-content-head__tab-item",
    class: _vm.tab === 'department' ? 'is-active' : '',
    on: {
      "click": function click($event) {
        _vm.tab = 'department';
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.trans('label.monitoring_tabs.department')) + "\n      ")]), _vm._v(" "), _c('a', {
    staticClass: "container-content-head__tab-item",
    class: _vm.tab === 'region' ? 'is-active' : '',
    on: {
      "click": function click($event) {
        _vm.tab = 'region';
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.trans('label.monitoring_tabs.region')) + "\n      ")])])]), _vm._v(" "), (_vm.tab === 'department' ? !_vm.items.length : !_vm.regionItems.length) ? _c('v-no-results', {
    attrs: {
      "image": "search",
      "title": _vm.noResultsTitle,
      "text": _vm.noResultsLabel
    }
  }) : _c('div', [_vm.tab === 'department' ? _c('div', {
    staticClass: "col-md-6 mb-20"
  }, [_c('q-select', {
    attrs: {
      "options": _vm.departments,
      "label": _vm.trans('label.choose_region')
    },
    model: {
      value: _vm.selectedDepartment,
      callback: function callback($$v) {
        _vm.selectedDepartment = $$v;
      },
      expression: "selectedDepartment"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('q-table', {
    staticClass: "mb-20",
    attrs: {
      "data": _vm.tab === 'department' ? _vm.items : _vm.regionItems,
      "columns": _vm.table.columns,
      "row-key": "name",
      "hide-bottom": "",
      "hide-header": "",
      "wrap-cells": ""
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, [_c('q-td', [_vm._v("\n            " + _vm._s(props.row.name) + "\n          ")]), _vm._v(" "), _c('q-td', {
          staticClass: "s-to-right"
        }, [_c('q-btn', {
          attrs: {
            "color": "orange",
            "squire": "",
            "icon": "eye",
            "type": "a",
            "to": "".concat(props.row.url).concat(_vm.tab === 'department' ? "/".concat(_vm.selectedDepartment.value) : '')
          }
        }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('button.see')))])], 1)], 1)], 1)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }