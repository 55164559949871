var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "catalog",
    staticClass: "s-balabaqsha-catalog"
  }, [_c('v-queue-filters', {
    staticClass: "s-balabaqsha-catalog__filter-wrapper",
    attrs: {
      "typeOptions": _vm.typeOptions,
      "stoplist": _vm.stoplist,
      "uriLocality": _vm.uriLocality,
      "diseases": _vm.diseases,
      "sanatorium": _vm.sanatorium,
      "uriGetOrganizations": _vm.uriGetOrganizations,
      "uriGetYears": _vm.uriGetYears
    },
    on: {
      "filtered-list": _vm.handleFilteredList,
      "filter-loading": function filterLoading($event) {
        _vm.loading = $event;
      }
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "s-balabaqsha-catalog__content"
  }, [[_c('v-statistic-panel', {
    attrs: {
      "icon-url": "".concat(_vm.staticUrl, "/indv/balabaqsha/images/statistics"),
      "data-list": _vm.statisticItems,
      "loading": _vm.loading,
      "loadingCount": 5
    }
  }), _vm._v(" "), _c('q-input', {
    staticClass: "s-balabaqsha-catalog__search mb-30",
    attrs: {
      "placeholder": _vm.trans('label.search_by_keyword')
    },
    on: {
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.handleSearch($event);
      },
      "input": _vm.debounceSearch
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('q-btn', {
          attrs: {
            "icon": "search",
            "color": "blue",
            "squire": ""
          },
          on: {
            "click": _vm.handleSearch
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.searchValue,
      callback: function callback($$v) {
        _vm.searchValue = $$v;
      },
      expression: "searchValue"
    }
  })], _vm._v(" "), _vm._l(_vm.paginate, function (queue, index) {
    return _c('v-queue-item', {
      key: index,
      attrs: {
        "stoplist": _vm.stoplist,
        "queueNumber": queue.queueNumber,
        "childFullName": queue.childFullName,
        "userFullName": queue.userFullName,
        "dateSendStatement": queue.dateSendStatement,
        "expiredDate": queue.expiredDate,
        "privilegeClient": queue.privilegeClient,
        "queueFlagStyle": queue.queueFlagStyle,
        "loading": _vm.loading
      }
    });
  }), _vm._v(" "), _vm.countOfPages ? _c('div', {
    staticClass: "row justify-content-center mt-30"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-pagination', {
    attrs: {
      "max": _vm.countOfPages,
      "max-pages": 5,
      "boundary-links": true,
      "input": _vm.countOfPages > 5,
      "input-class": "s-c-blue"
    },
    on: {
      "input": _vm.changePagination
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)]) : _vm._e(), _vm._v(" "), _vm.empty ? _c('v-no-results', {
    attrs: {
      "image": "queue",
      "title": _vm.trans('warning_text.no_result_queue_title'),
      "text": _vm.trans('warning_text.no_result_queue_text')
    }
  }) : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }