var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.currentReason ? _c('div', [_vm.selectedAttendance !== null ? _c('v-time-sheet-reason', {
    attrs: {
      "show": _vm.selectedAttendance !== null,
      "reasons": _vm.reasonsList,
      "is-alt": _vm.isAlt,
      "reloading": _vm.reloading,
      "confirming": _vm.confirming,
      "reason": _vm.currentReason,
      "fails": _vm.reasonFails,
      "is-edit": _vm.isEdit
    },
    on: {
      "close": _vm.closeReason,
      "change": _vm.changeReason,
      "confirm": _vm.confirmReason
    }
  }) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "s-time-sheet-container"
  }, [_c('div', {
    staticClass: "no-gutters row attendance-panel"
  }, [_c('div', {
    staticClass: "no-gutters col-md-6 col-sm-12 row attendance-calendar-wrapper"
  }, [_c('div', {
    staticClass: "col-1"
  }, [_vm.timeSheet.currentYear ? _c('div', {
    staticClass: "attendance-calendar-icon"
  }, [_c('q-icon', {
    attrs: {
      "name": "calendar",
      "size": "sm"
    }
  })], 1) : _vm._e()]), _vm._v(" "), _vm.timeSheet.currentYear ? _c('div', {
    staticClass: "col-md-3 col-sm-12 attendance-calendar-field"
  }, [_c('q-select', {
    attrs: {
      "disable": _vm.years.length <= 1,
      "options": _vm.years
    },
    on: {
      "input": _vm.onChangeYear
    },
    model: {
      value: _vm.timeSheet.currentYear,
      callback: function callback($$v) {
        _vm.$set(_vm.timeSheet, "currentYear", $$v);
      },
      expression: "timeSheet.currentYear"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.timeSheet.currentMonth ? _c('div', {
    staticClass: "col-md-3 col-sm-12 attendance-calendar-field"
  }, [_c('q-select', {
    attrs: {
      "disable": _vm.months.length <= 1,
      "options": _vm.months,
      "option-value": "value",
      "option-label": "label"
    },
    on: {
      "input": _vm.onChangeMonth
    },
    model: {
      value: _vm.timeSheet.currentMonth,
      callback: function callback($$v) {
        _vm.$set(_vm.timeSheet, "currentMonth", $$v);
      },
      expression: "timeSheet.currentMonth"
    }
  })], 1) : _vm._e()]), _vm._v(" "), _vm.isWorker ? _c('div', {
    class: "".concat(_vm.$q.screen.width < 768 ? '' : 'sn--text-right', " col-md-2 col-sm-auto  load-btn-wrapper")
  }, [_c('div', {
    staticClass: "sn-field__wrapper"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "no-caps": "",
      "color": "orange"
    },
    on: {
      "click": _vm.exportu
    }
  }, [_vm._v("\n            " + _vm._s(_vm.trans('attendance.export')) + "\n          ")])], 1)]) : _vm._e(), _vm._v(" "), !_vm.isWorker ? _c('div', {
    staticClass: "no-gutters row align-items-stretch mb-10 justify-content-end col-md-6 col-sm-12"
  }, [_c('div', {
    staticClass: "sn-field__wrapper col-lg-3 col-md-5 col-sm-12"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "no-caps": "",
      "color": "orange"
    },
    on: {
      "click": _vm.exportu
    }
  }, [_vm._v("\n            " + _vm._s(_vm.trans('attendance.export')) + "\n          ")])], 1), _vm._v(" "), !_vm.isChildrenTimeSheet ? _c('div', {
    staticClass: "sn-field__wrapper col-lg-4 col-md-5 col-sm-12 "
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "disabled": !_vm.timeSheet.currentMonth || _vm.reloading || _vm.confirming,
      "no-caps": "",
      "color": "green"
    },
    on: {
      "click": _vm.openMassAbsentReasonDialog
    }
  }, [_vm._v("\n            " + _vm._s(_vm.trans('attendance.timesheet-load-btn')) + "\n          ")])], 1) : _vm._e()]) : _vm._e()])]), _vm._v(" "), _c('v-time-sheet-table', {
    ref: "timeSheet",
    attrs: {
      "loading": _vm.timeSheetLoad,
      "day-columns": _vm.timeSheet.dayColumns,
      "days-count": _vm.timeSheet.dayCount,
      "date-strings": _vm.timeSheet.dateStrings,
      "time-sheet-data": _vm.timeSheetData,
      "is-alt": _vm.isAlt,
      "current-day": _vm.currentDay,
      "allowed-edit-days": _vm.timeSheet.allowedEditDays,
      "change-reason": _vm.chooseReason,
      "is-children-timesheet": _vm.isChildrenTimeSheet,
      "is-worker": _vm.isWorker
    }
  }), _vm._v(" "), _c('v-time-sheet-mass-load-doc-dialog', {
    ref: "massLoadDialog",
    attrs: {
      "is-alt": _vm.isAlt,
      "mass-edit-type": _vm.massEditType,
      "api-provider": _vm.apiProvider,
      "reasons": _vm.reasonsList,
      "reloading": _vm.reloading,
      "confirming": _vm.confirming,
      "is-open": _vm.massLoadDialog,
      "allowed-edit-days": _vm.timeSheet.allowedEditDays,
      "attendances": _vm.timeSheet.attendance
    },
    on: {
      "modal-close": _vm.closeMassAbsentReasonDialog,
      "modal-reload": _vm.reloadSheet
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }