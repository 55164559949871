var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-field', {
    attrs: {
      "field": _vm.field,
      "field-name": _vm.fieldName,
      "errors": _vm.errors,
      "show-label": false
    }
  }, [_c('template', {
    slot: "field"
  }, [_c('q-checkbox', {
    attrs: {
      "label": _vm.fieldLabel(),
      "disable": _vm.isDisabled
    },
    on: {
      "input": _vm.onChange
    },
    model: {
      value: _vm.localValue,
      callback: function callback($$v) {
        _vm.localValue = $$v;
      },
      expression: "localValue"
    }
  })], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }