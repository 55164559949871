var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-field', {
    attrs: {
      "field": _vm.field,
      "field-name": _vm.fieldName,
      "errors": _vm.errors,
      "label-class": ''
    }
  }, [_c('template', {
    slot: "field"
  }, [_c('q-input', _vm._b({
    attrs: {
      "filled": "",
      "mask": _vm.extraAttributes.withTime ? '##.##.#### (##:##)' : '##.##.####',
      "date": ""
    },
    on: {
      "click": function click() {
        return _vm.$refs.qDateFieldActiveProxy.show();
      }
    },
    scopedSlots: _vm._u([{
      key: "before",
      fn: function fn() {
        return [_c('q-popup-proxy', {
          ref: "qDateFieldActiveProxy",
          attrs: {
            "transition-show": "scale",
            "transition-hide": "scale",
            "content-class": _vm.extraAttributes.withTime && 'd-flex s-group align-content-center align-items-start'
          }
        }, [_c('q-date', {
          attrs: {
            "color": "blue",
            "mask": _vm.extraAttributes.withTime ? 'DD.MM.YYYY (HH:mm)' : 'DD.MM.YYYY',
            "today-btn": ""
          },
          on: {
            "input": function input() {
              return !_vm.extraAttributes.withTime && _vm.$refs.qDateFieldActiveProxy.hide();
            }
          },
          model: {
            value: _vm.localValue,
            callback: function callback($$v) {
              _vm.localValue = $$v;
            },
            expression: "localValue"
          }
        }), _vm._v(" "), _vm.extraAttributes.withTime ? _c('q-time', {
          attrs: {
            "color": "blue",
            "mask": "DD.MM.YYYY (HH:mm)",
            "now-btn": "",
            "format24h": ""
          },
          model: {
            value: _vm.localValue,
            callback: function callback($$v) {
              _vm.localValue = $$v;
            },
            expression: "localValue"
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "sn--cursor-pointer",
          attrs: {
            "name": "calendar"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.localValue,
      callback: function callback($$v) {
        _vm.localValue = $$v;
      },
      expression: "localValue"
    }
  }, 'q-input', _vm.extraAttributes, false))], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }