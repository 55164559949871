import _ from 'lodash';
// noinspection NpmUsedModulesInstalled
import VNoResults from '@vjs/components/VNoResults';
// noinspection NpmUsedModulesInstalled
import { requestWrapper } from '@vjs/helpers';
// noinspection NpmUsedModulesInstalled
import {
  QBtn,
  QIcon,
  QInnerLoading,
  QInput,
  QSelect,
  QTable,
  QTd,
  QTh,
  QTooltip,
  QTr,
} from '@quasar/components';
// noinspection NpmUsedModulesInstalled
import { Confirm } from '@vjs/balabaqsha/views/VContingent/Dialog';

// noinspection NpmUsedModulesInstalled
import {
  ATTENDANCE_ORGANIZATION_CONTROL,
} from '@vjs/balabaqsha/consts';
import { ERR_404 } from '../../../helpers/requestWrapper';


import Localization from '../Mixins/QuasarLocale';

export default {
  name: 'VAttendanceOrgList',
  components: {
    Confirm,
    QSelect,
    QIcon,
    QTable,
    QTh,
    QTr,
    QTd,
    QBtn,
    QInnerLoading,
    QTooltip,
    QInput,
    VNoResults,
  },
  mixins: [Localization],
  props: {
    uriGetData: {
      type: String,
    },
    uriLocality: {
      type: String,
    },
    uriSchoolConfig: {
      type: String,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    description: {
      type: String,
    },
  },
  data: () => ({
    filter: '',
    loading: false,
    localityOptions: [],
    localityOptionsFiltered: [],
    localitySelected: null,
    configPopup: false,
    selectedSchoolConfig: null,
    configs: {},
    table: {
      pagination: {
        rowsPerPage: 20,
      },
      rows: [],
      columns: [],
    },
  }),
  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'warning_text',
      'config',
    ]);

    this.table.columns = [
      {
        name: 'name',
        required: true,
        label: this.trans('label.name'),
        align: 'left',
        field: 'name',
        sortable: false,
      },
      {
        name: 'groups_count',
        required: true,
        label: this.trans('label.groups_count'),
        align: 'center',
        field: 'groups_count',
        sortable: false,
        width: 200,
      },
      {
        name: 'actions',
        required: false,
        label: this.trans('label.operations'),
        align: 'center',
        field: 'actions',
        sortable: false,
        width: 175,
      },
    ];

    await this.requestGetLocalities();
    await this.requestGetTable();
  },
  beforeMount() {
    this.initLocale();
  },
  beforeCreate() {
    this.$trans.add(ATTENDANCE_ORGANIZATION_CONTROL);
  },
  methods: {
    cancelConfig() {
      this.configPopup = false;
      this.selectedSchoolConfig = null;
      this.configs = {};
    },
    async confirmConfig(config, status) {
      const { $trans: { get: trans } } = this;
      this.configs[config] = status;
      const { selectedSchoolConfig: schoolId, uriSchoolConfig: uri, configs } = this;

      const response = await this.$axios.post(`${uri}/${schoolId}`, {
        configs,
      });

      if (
        config === 'timesheet_extended_edit'
      ) {
        this.$notify({
          type: 'success',
          text: status
            ? trans('config.activated')
            : trans('config.deactivated'),
        });
      }

      if (response.data && response.data.status && response.data.status === 'ok') {
        this.configPopup = false;
        this.selectedSchoolConfig = null;
        this.configs = {};
        this.requestGetTable();
      }
    },
    selectConfig(schoolId, configs) {
      this.configs = configs || { timesheet_extended_edit: 0 };
      this.configPopup = true;
      this.selectedSchoolConfig = schoolId;
    },
    async requestGetLocalities() {
      const res = await requestWrapper({
        url: this.uriLocality,
      });
      if (res.error) {
        if (res.errorType === ERR_404 && _.isBoolean(res.data.region)) {
          this.modalRegionSelect = true;
        }
        return;
      }
      this.localityOptions = res.data;
      this.localityOptionsFiltered = res.data;
      this.localitySelected = res.data[0].value;
    },
    async requestGetTable() {
      this.loading = true;
      const res = await requestWrapper({
        url: this.uriGetData,
        params: {
          locality_id: this.localitySelected,
        },
      });

      if (!res.errors) {
        this.table.rows = res.data;
      }
      this.loading = false;
    },
    filterFn(val, update) {
      if (val === '') {
        update(() => {
          this.localityOptions = this.localityOptionsFiltered;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.localityOptions = this.localityOptionsFiltered.filter(item => _.lowerCase(item.label).indexOf(needle) > -1);
      });
    },
  },
};
