var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-reference-time"
  }, [_c('div', {
    staticClass: "s-reference-time__label"
  }, [_vm._v("\n    " + _vm._s(_vm.label) + ":\n  ")]), _vm._v(" "), _vm.date ? _c('div', {
    staticClass: "s-reference-time__value s-medium"
  }, [_vm._v("\n    " + _vm._s(_vm.formatNum(_vm.date.getDate())) + "." + _vm._s(_vm.formatNum(_vm.date.getMonth() + 1)) + "." + _vm._s(_vm.date.getFullYear()) + "\n    " + _vm._s(_vm.formatNum(_vm.date.getHours())) + ":" + _vm._s(_vm.formatNum(_vm.date.getMinutes())) + ":" + _vm._s(_vm.formatNum(_vm.date.getSeconds())) + "\n  ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }