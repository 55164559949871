import VEditSchoolLimitDialog from '@vjs/balabaqsha/modals/VEditSchoolLimitDialog';
import VEditGroupLimitDialog from '@vjs/balabaqsha/modals/VEditGroupLimitDialog';
import Confirm from './Confirm.vue';
import PersonnelTerminationModal from './PersonnelTerminationModal.vue';

export default function (Vue) {
  Vue.component('v-edit-school-limit-dialog', VEditSchoolLimitDialog);
  Vue.component('v-edit-group-limit-dialog', VEditGroupLimitDialog);
  Vue.component('v-modal-confirm', Confirm);
  Vue.component('v-modal-personnel-termination', PersonnelTerminationModal);
}
