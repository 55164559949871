var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-field', {
    attrs: {
      "field": _vm.field,
      "field-name": '',
      "label-class": '',
      "errors": _vm.errors
    }
  }, [_c('template', {
    slot: "field"
  }, [_c('div', [_vm.isFileLabel ? _c('label', {
    staticClass: "s-form__label mb-10"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.field.labelTransKey ? _vm.trans(_vm.field.labelTransKey) : _vm.field.label)
    }
  }), _vm._v(":\n        "), _vm.field.required ? _c('i', [_vm._v("*")]) : _vm._e(), _vm._v(" "), _vm.field.beforeModal ? _c('span', [_c('a', {
    staticStyle: {
      "color": "#5673bf"
    },
    attrs: {
      "href": "#"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.field.beforeModal.text)
    },
    on: {
      "click": function click($event) {
        return _vm.showNewModal(_vm.field.beforeModal.config);
      }
    }
  })]) : _vm._e()]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-6 col-md-6 col-sm-6 col-12",
    class: _vm.$q.screen.width < 576 ? 'mb-10' : ''
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "label-wider": "",
      "no-caps": "",
      "icon-right": _vm.localFileValues.length ? 'clip' : 'plus',
      "color": _vm.localFileValues.length ? 'green' : 'blue',
      "label": _vm.trans(_vm.localFileValues.length ? 'label.attached' : 'button.add')
    },
    on: {
      "click": _vm.triggerFile
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-lg-6 col-md-6 col-sm-6 col-12"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "outline": "",
      "no-caps": "",
      "label-wider": "",
      "ellipsis": "",
      "icon-right": _vm.$q.screen.width < 576 ? 'close' : undefined,
      "icon": _vm.$q.screen.width < 576 ? undefined : 'close',
      "color": _vm.localFileValues.length ? undefined : 'grey',
      "label": _vm.computedFileLabel
    },
    on: {
      "click": _vm.clearFile
    }
  })], 1)])])])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }