import _ from 'lodash';
import {
  date,
} from '@quasar/utils';

const DateYearMonthPicker = {
  data: () => ({
    currentYear: null,
    currentMonth: null,
    currentDate: null,
    availableDates: [],
  }),
  computed: {
    years() {
      return this.availableDates ? Object.keys(this.availableDates) : [];
    },
    months() {
      return typeof this.availableDates[this.currentYear] !== 'undefined'
        ? this.availableDates[this.currentYear] : [];
    },
    selectedDate() {
      const year = this.currentYear;
      if (this.currentMonth) {
        const month = this.currentMonth.value;
        return `${year}-${month}`;
      }
      return null;
    },
  },
  methods: {
    setDefaultDate() {
      const localDate = new Date();

      const month = this.currentDate.split('-')[1];
      localDate.setMonth(parseInt(month, 10) - 1, 1);


      // eslint-disable-next-line prefer-destructuring
      this.currentYear = this.currentDate.split('-')[0];
      this.currentMonth = {
        value: localDate.getMonth() + 1,
        label: _.upperFirst(
          date.formatDate(localDate, 'MMMM'),
        ),
      };
    },
    onChangeYear(val) {
      // eslint-disable-next-line
     console.dir(val);
    },
    onChangeMonth(val) {
      // eslint-disable-next-line
      console.dir(val);
    },
  },
};

export default DateYearMonthPicker;
