var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-dialog', {
    attrs: {
      "value": _vm.modal,
      "persistent": ""
    },
    on: {
      "input": _vm.handleModal
    }
  }, [_c('v-modal', {
    attrs: {
      "size": _vm.size,
      "title": _vm.title || _vm.trans('notice.confirm')
    }
  }, [_vm.text ? _c('div', {
    class: {
      's-light': _vm.lightText
    }
  }, [_vm._v("\n      " + _vm._s(_vm.text) + "\n    ")]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "row-sort-between-btn"
  }, [_c('q-btn', {
    directives: [{
      name: "close-popup",
      rawName: "v-close-popup"
    }],
    attrs: {
      "icon": "close",
      "width": "20",
      "label": _vm.trans('button.cancel'),
      "labelWider": "",
      "color": _vm.declineColor
    }
  }), _vm._v(" "), _c('q-btn', {
    attrs: {
      "icon": "check",
      "width": "20",
      "label": _vm.trans('button.approve'),
      "labelWider": "",
      "color": _vm.submitColor,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.confirm
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }