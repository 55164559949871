import axios from 'axios';
import { FALLBACK_LOCALE } from '@vjs/balabaqsha/consts';

export default class TimeSheetMarkRepository {
  constructor({
    apiUrl,
    locale,
  }) {
    this.url = apiUrl || '';
    this.locale = locale || FALLBACK_LOCALE;
  }

  async mark({
    type: reasonType,
    other: reasonOther,
    file: reasonFile,
  }, attendanceId) {
    const { url, locale } = this;
    // eslint-disable-next-line no-return-await
    const data = new FormData();
    data.append('attendance_id', attendanceId);
    data.append('type', reasonType);
    data.append('other_reason', reasonOther);
    data.append('file', reasonFile);

    // eslint-disable-next-line no-return-await
    return await axios.post(
      `${url}`,
      data,
      {
        headers: {
          'X-localization': locale,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  }
}
