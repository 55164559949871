var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "catalog",
    staticClass: "s-balabaqsha-catalog"
  }, [_c('v-catalog-filters', {
    staticClass: "s-balabaqsha-catalog__filter-wrapper",
    attrs: {
      "uriLocality": _vm.uriLocality,
      "uriStatistic": _vm.uriStatistic,
      "uriFilters": _vm.uriFilters,
      "uriGetOrganizations": _vm.uriGetOrganizations
    },
    on: {
      "filtered-list": _vm.handleFilteredList,
      "filter-loading": function filterLoading($event) {
        _vm.loading = $event;
      }
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "s-balabaqsha-catalog__content"
  }, [[_c('v-statistic-panel', {
    staticClass: "s-balabaqsha-catalog__statistics--nth-4",
    attrs: {
      "iconUrl": "".concat(_vm.staticUrl, "/indv/balabaqsha/images/statistics"),
      "data-list": _vm.statisticItems,
      "loading": _vm.loading,
      "loadingCount": 6
    }
  }), _vm._v(" "), _c('q-input', {
    staticClass: "s-balabaqsha-catalog__search mb-30",
    attrs: {
      "placeholder": _vm.trans('label.search_by_keyword')
    },
    on: {
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.handleSearch($event);
      },
      "input": _vm.debounceSearch
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('q-btn', {
          attrs: {
            "icon": "search",
            "color": "blue",
            "squire": ""
          },
          on: {
            "click": _vm.handleSearch
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.searchValue,
      callback: function callback($$v) {
        _vm.searchValue = $$v;
      },
      expression: "searchValue"
    }
  })], _vm._v(" "), _vm._l(_vm.paginate, function (itm, idx) {
    return _c('v-catalog-item', {
      key: idx,
      attrs: {
        "linkImage": itm.linkImage,
        "linkItem": itm.linkItem,
        "marks": itm.marks,
        "languages": itm.languages,
        "yearsRestriction": itm.yearsRestriction,
        "address": itm.address,
        "title": itm.title,
        "throughputOrg": itm.throughputOrg,
        "availablePlaces": itm.availablePlaces,
        "loading": _vm.loading
      }
    });
  }), _vm._v(" "), _vm.countOfPages ? _c('div', {
    staticClass: "row justify-content-center mt-30"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-pagination', {
    attrs: {
      "max": _vm.countOfPages,
      "max-pages": 5,
      "boundary-links": true,
      "input": _vm.countOfPages > 5,
      "input-class": "s-c-blue"
    },
    on: {
      "input": _vm.changePagination
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)]) : _vm._e(), _vm._v(" "), _vm.empty ? _c('v-no-results', {
    attrs: {
      "image": "search",
      "title": _vm.trans('warning_text.no_result_catalog_title'),
      "text": _vm.trans('warning_text.no_result_catalog_text')
    }
  }) : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }