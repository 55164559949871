export default class FundingApiProvider {
  constructor({ FundingFetchRepository, FundingCalcRepository, FundingStoreRepository }, trans) {
    this.fundingFetchRepository = FundingFetchRepository;
    this.fundingCalcRepository = FundingCalcRepository;
    this.fundingStoreRepository = FundingStoreRepository;
    this.trans = trans;
  }

  provideFetch() {
    const { fundingFetchRepository, trans } = this;
    // eslint-disable-next-line func-names
    return async function (groupId) {
      try {
        const response = await fundingFetchRepository
          .get(groupId);
        const {
          status,
          message,
          funding,
        } = response.data;

        if (status !== 'ok') {
          this.$notify({
            type: 'warning',
            title: message,
          });
        } else {
          return funding;
        }
      } catch (error) {
        const message = error.response && error.response.message
          ? error.response.message
          : trans('notice.error_on_server');

        this.$notify({
          type: 'error',
          text: message,
        });
      }

      return [];
    };
  }

  provideCalculate() {
    const { fundingCalcRepository } = this;
    // eslint-disable-next-line func-names
    return async function (context) {
      try {
        const response = await fundingCalcRepository
          .calculate(context);
        const {
          status,
          message,
          context: responseContext,
        } = response.data;

        if (status !== 'ok') {
          this.$notify({
            type: 'warning',
            title: message,
          });
        } else {
          return responseContext;
        }
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'server error',
          text: error,
        });
      }

      return null;
    };
  }

  provideStore() {
    const { fundingStoreRepository } = this;
    // eslint-disable-next-line func-names
    return async function (context) {
      try {
        const response = await fundingStoreRepository
          .save(context);
        const {
          status,
          message,
        } = response.data;

        if (status !== 'ok') {
          this.$notify({
            type: 'warning',
            title: message,
          });
        } else {
          return true;
        }
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'server error',
          text: error,
        });
      }

      return null;
    };
  }
}
