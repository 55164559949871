var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "s-organization-profile__panel mb-30"
  }, [_c('div', {
    staticClass: "s-organization-profile__name mb-15"
  }, [_c('span', {
    staticClass: "s-str-ico s-str-ico--thm-home"
  }, [_vm._v("\n        " + _vm._s(_vm.name) + "\n      ")])]), _vm._v(" "), _c('table', {
    staticClass: "s-table-simple"
  }, [_c('tbody', {
    staticClass: "s-table__body"
  }, _vm._l(_vm.fields, function (item, id) {
    return _c('tr', {
      key: id
    }, [_c('td', [_vm._v("\n            " + _vm._s(item.label) + "\n          ")]), _vm._v(" "), _c('td', [_c('div', {
      staticClass: "s-medium"
    }, [_vm._v("\n              " + _vm._s(item.value) + "\n            ")])])]);
  }), 0)])]), _vm._v(" "), _vm.editLink ? _c('div', {
    staticClass: "row justify-content-end"
  }, [_c('div', {
    staticClass: "col col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "to": _vm.editLink,
      "type": "a",
      "color": "orange",
      "icon": "pencil",
      "label": _vm.trans('button.edit'),
      "no-caps": ""
    }
  })], 1)]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }