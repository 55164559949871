var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-form', {
    attrs: {
      "action": _vm.action
    }
  }, [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.modal_inform')
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "label": _vm.trans('label.current_password'),
      "name": _vm.fields[0].name,
      "rules": _vm.fields[0].rules,
      "type": "password",
      "lazy-rules": "",
      "required": _vm.fields[0].required
    },
    model: {
      value: _vm.fields[0].value,
      callback: function callback($$v) {
        _vm.$set(_vm.fields[0], "value", $$v);
      },
      expression: "fields[0].value"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "label": _vm.trans('label.new_password'),
      "name": _vm.fields[1].name,
      "rules": _vm.fields[1].rules,
      "type": "password",
      "lazy-rules": "",
      "required": _vm.fields[1].required
    },
    model: {
      value: _vm.fields[1].value,
      callback: function callback($$v) {
        _vm.$set(_vm.fields[1], "value", $$v);
      },
      expression: "fields[1].value"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "label": _vm.trans('label.replay_new_password'),
      "name": _vm.fields[2].name,
      "rules": _vm.fields[2].rules,
      "type": "password",
      "lazy-rules": "",
      "required": _vm.fields[2].required
    },
    model: {
      value: _vm.fields[2].value,
      callback: function callback($$v) {
        _vm.$set(_vm.fields[2], "value", $$v);
      },
      expression: "fields[2].value"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "row-sort-between-btn"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-200",
    attrs: {
      "color": "grey",
      "label": _vm.trans('button.back'),
      "to": _vm.routeBack,
      "type": "a"
    }
  }), _vm._v(" "), _c('q-btn', {
    staticClass: "s-btn--w-200",
    attrs: {
      "color": "green",
      "type": "submit",
      "label": _vm.trans('button.save')
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }