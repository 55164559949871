import _ from 'lodash';
import {
  QIcon,
  QSlideTransition,
} from '@quasar/components';

export default {
  name: 'VMenu',
  components: {
    QIcon,
    QSlideTransition,
  },
  props: {
    menu: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      menuList: [],
    };
  },
  created() {
    this.menuList = JSON.parse(this.menu);
  },
  methods: {
    openMenu(key) {
      _.forEach(this.menuList, (item, index) => {
        let isOpen = false;
        if (!item.active) {
          if (index === key) {
            isOpen = !this.menuList[index].isOpen;
          }
          this.$set(this.menuList[index], 'isOpen', isOpen);
        } else {
          this.$set(this.menuList[index], 'isOpen', true);
        }
      });

      return this.menuList[key].isOpen;
    },
    selectMenu(key) {
      _.forEach(this.menuList, (item, index) => {
        if (index === key) {
          this.$set(this.menuList[index], 'active', true);
        } else {
          this.$set(this.menuList[index], 'active', false);
        }
        this.$set(this.menuList[key], 'isOpen', false);
      });
    },
    submenuClass(key) {
      let res = '';
      if (this.menuList[key].active) {
        res = 'is-active is-open';
        this.$set(this.menuList[key], 'isOpen', true);
      } else if (this.menuList[key].isOpen) {
        res = 'is-open';
      }

      return res;
    },
    selectSubmenu(menuKey, submenuKey) {
      _.forEach(this.menuList, (item, index) => {
        let res = false;
        if (index === menuKey) {
          res = true;
          this.$set(this.menuList[index].submenu[submenuKey], 'active', res);
        }
        this.$set(this.menuList[index], 'active', res);
        this.$set(this.menuList[index], 'isOpen', res);
      });
    },
  },
};
