var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-input', _vm._b({
    attrs: {
      "mask": "##.##.####",
      "placeholder": "__.__.____",
      "error": _vm.error,
      "error-message": _vm.errorMessage,
      "label": _vm.extraBinds.labelTransKey ? _vm.trans(_vm.extraBinds.labelTransKey) : _vm.extraBinds.label,
      "date": ""
    },
    on: {
      "click": function click() {
        return _vm.$refs.wrapperQDateProxy.show();
      }
    },
    scopedSlots: _vm._u([{
      key: "before",
      fn: function fn() {
        return [_c('q-popup-proxy', {
          ref: "wrapperQDateProxy",
          attrs: {
            "transition-show": "scale",
            "transition-hide": "scale"
          }
        }, [_c('q-date', _vm._b({
          attrs: {
            "value": _vm.localValue,
            "today-btn": "",
            "mask": "DD.MM.YYYY",
            "color": "blue"
          },
          on: {
            "input": function input() {
              return _vm.$refs.wrapperQDateProxy.hide();
            }
          },
          model: {
            value: _vm.localValue,
            callback: function callback($$v) {
              _vm.localValue = $$v;
            },
            expression: "localValue"
          }
        }, 'q-date', _vm.extraBinds.date, false))], 1)];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "sn--cursor-pointer",
          attrs: {
            "name": "calendar"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.localValue,
      callback: function callback($$v) {
        _vm.localValue = $$v;
      },
      expression: "localValue"
    }
  }, 'q-input', _vm.extraBinds.input, false));
}
var staticRenderFns = []

export { render, staticRenderFns }