var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-statement-contract"
  }, [_vm.additionalConfig.statementExpiredDate ? _c('v-form-inform', {
    attrs: {
      "color": "red",
      "message": _vm.trans('warning_text.statement_contract_days_left_for_conclude', {
        date: _vm.additionalConfig.statementExpiredDate
      })
    }
  }) : _vm._e(), _vm._v(" "), _c('table', {
    staticClass: "s-table-simple mb-30"
  }, [_c('tbody', {
    staticClass: "s-table__body"
  }, _vm._l(_vm.table, function (row, key) {
    return _c('tr', {
      key: key
    }, [_c('td', {
      staticClass: "s-table__col--to-right"
    }, [_vm._v("\n          " + _vm._s(row.label) + "\n        ")]), _vm._v(" "), row.isUrl ? _c('td', {
      staticClass: "s-table__col--to-left",
      domProps: {
        "innerHTML": _vm._s(row.value)
      }
    }) : _c('td', {
      staticClass: "s-table__col--to-left"
    }, [_vm._v("\n          " + _vm._s(row.value) + "\n        ")])]);
  }), 0)]), _vm._v(" "), _c('div', {
    staticClass: "s-group s-group--wider justify-content-end mb-30"
  }, [_vm.apiConfig.deleteContract ? [_c('q-btn', {
    attrs: {
      "width": "20",
      "label": _vm.trans('button.delete_statement_contract'),
      "color": "red",
      "no-caps": ""
    },
    on: {
      "click": function click($event) {
        _vm.isConfirmModalOpen = true;
      }
    }
  }), _vm._v(" "), _c('v-confirm-dialog', {
    attrs: {
      "modal": _vm.isConfirmModalOpen,
      "confirm": _vm.requestDeleteContract
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.isConfirmModalOpen = $event;
      }
    }
  })] : _vm._e(), _vm._v(" "), _vm.apiConfig.sendRevision ? [_c('v-statement-contract-revision-modal', {
    attrs: {
      "modal": _vm.revisionModal,
      "uri-action": _vm.apiConfig.sendRevision,
      "for-correction": _vm.forCorrection,
      "inform-message": _vm.trans('warning_text.statement_contract_worker_edit_tries_left', {
        tries: _vm.additionalConfig.editTriesLeft
      })
    },
    on: {
      "loading": _vm.handleLoading,
      "modal-close": function modalClose($event) {
        _vm.revisionModal = false;
      }
    }
  }), _vm._v(" "), _c('q-btn', {
    attrs: {
      "width": "20",
      "label": _vm.trans('button.to_revision'),
      "color": "orange",
      "no-caps": ""
    },
    on: {
      "click": function click($event) {
        _vm.revisionModal = true;
      }
    }
  })] : _vm._e(), _vm._v(" "), _vm.apiConfig.editFilesUrl ? _c('q-btn', {
    attrs: {
      "width": "20",
      "label": _vm.trans('button.edit_contract_files'),
      "to": _vm.apiConfig.editFilesUrl,
      "type": "a",
      "color": "orange",
      "no-caps": ""
    }
  }) : _vm._e(), _vm._v(" "), _vm.apiConfig.editUrl ? _c('q-btn', {
    attrs: {
      "width": "20",
      "label": _vm.trans('button.edit'),
      "to": _vm.apiConfig.editUrl,
      "type": "a",
      "color": "orange",
      "no-caps": ""
    }
  }) : _vm._e(), _vm._v(" "), _vm.apiConfig.signApiUrl && _vm.apiConfig.sendRevision ? _c('q-btn', {
    attrs: {
      "width": "20",
      "label": _vm.trans('button.sign'),
      "color": "green",
      "no-caps": ""
    },
    on: {
      "click": _vm.sign
    }
  }) : _vm._e()], 2), _vm._v(" "), (_vm.apiConfig.submitUrl || _vm.apiConfig.signApiUrl) && !_vm.apiConfig.sendRevision ? [_c('label', {
    staticClass: "s-form__label"
  }, [_vm._v(_vm._s(_vm.trans('label.statement_contract.contract')))]), _vm._v(" "), _vm.apiConfig.submitUrl || _vm.apiConfig.signApiUrl ? _c('div', {
    staticClass: "s-statement-contract__contract-wrapper"
  }, [_c('q-input', {
    staticClass: "s-to-center",
    staticStyle: {
      "flex": "auto"
    },
    attrs: {
      "disable": ""
    },
    scopedSlots: _vm._u([{
      key: "before",
      fn: function fn() {
        return [_c('img', {
          attrs: {
            "src": "".concat(_vm.apiConfig.staticUrl, "/indv/balabaqsha/images/contract.svg")
          }
        })];
      },
      proxy: true
    }], null, false, 3778516840),
    model: {
      value: _vm.contractName,
      callback: function callback($$v) {
        _vm.contractName = $$v;
      },
      expression: "contractName"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "s-statement-contract__contract-buttons"
  }, [_c('q-btn', {
    attrs: {
      "label-wider": "",
      "width": "14",
      "no-caps": "",
      "label": _vm.trans('button.download'),
      "color": "blue",
      "to": _vm.apiConfig.downloadApiUrl,
      "type": "a",
      "target": "_blank",
      "icon-right": "download"
    }
  }), _vm._v(" "), _c('q-btn', {
    attrs: {
      "label-wider": "",
      "width": "14",
      "no-caps": "",
      "label": _vm.trans('button.send'),
      "color": "green"
    },
    on: {
      "click": _vm.handleSendBtn
    }
  })], 1)], 1) : _vm._e()] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }