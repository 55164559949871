var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-statement-create__item-inner"
  }, [_c('v-reference-time', {
    staticClass: "mb-10",
    attrs: {
      "timestamp": _vm.referenceTime,
      "label": _vm.trans('label.server_datetime')
    }
  }), _vm._v(" "), _c('v-form-inform', {
    attrs: {
      "message": _vm.trans('label.warning_message')
    }
  }), _vm._v(" "), _c('q-form', {
    on: {
      "submit": _vm.requestForm
    }
  }, [_c('q-input', {
    attrs: {
      "label": _vm.trans('placeholder.search_iin'),
      "mask": "############",
      "readonly": _vm.profileExists,
      "rules": [function (val) {
        return _vm.validateIin(val) || _vm.trans('validation.check_iin', {
          attribute: _vm.trans('placeholder.search_iin')
        });
      }],
      "required": "",
      "lazy-rules": "",
      "error": _vm.hasValidationFail('iin'),
      "error-message": _vm.getValidationMessage('iin')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('iin');
      }
    },
    scopedSlots: _vm._u([{
      key: "after",
      fn: function fn() {
        return [_c('q-btn', {
          attrs: {
            "color": "orange",
            "label": _vm.trans('label.iin_button_text'),
            "no-wrap": "",
            "loading": _vm.loading,
            "disable": _vm.checkIinDisable || _vm.profileExists
          },
          on: {
            "click": _vm.requestForCheckIin
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.iin,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "iin", $$v);
      },
      expression: "form.iin"
    }
  }), _vm._v(" "), _vm.access ? [_c('q-input', {
    attrs: {
      "label": _vm.trans('label.surname'),
      "readonly": ""
    },
    model: {
      value: _vm.form.lastname,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "lastname", $$v);
      },
      expression: "form.lastname"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "label": _vm.trans('label.firstname'),
      "readonly": ""
    },
    model: {
      value: _vm.form.firstname,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "firstname", $$v);
      },
      expression: "form.firstname"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "label": _vm.trans('label.middle_name')
    },
    model: {
      value: _vm.form.middlename,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "middlename", $$v);
      },
      expression: "form.middlename"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "readonly": "",
      "mask": "##.##.####",
      "label": _vm.trans('label.born_date')
    },
    model: {
      value: _vm.form.birthday,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "birthday", $$v);
      },
      expression: "form.birthday"
    }
  }), _vm._v(" "), _c('q-field', {
    attrs: {
      "label": _vm.trans('label.gender'),
      "borderless": "",
      "required": ""
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-radio', {
    attrs: {
      "val": _vm.form.genderOptions[0].value,
      "label": _vm.trans(_vm.form.genderOptions[0].label)
    },
    model: {
      value: _vm.form.genderCurrent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "genderCurrent", $$v);
      },
      expression: "form.genderCurrent"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-auto"
  }, [_c('q-radio', {
    attrs: {
      "val": _vm.form.genderOptions[1].value,
      "label": _vm.trans(_vm.form.genderOptions[1].label)
    },
    model: {
      value: _vm.form.genderCurrent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "genderCurrent", $$v);
      },
      expression: "form.genderCurrent"
    }
  })], 1)])]), _vm._v(" "), _c('q-input', {
    attrs: {
      "label": _vm.trans('label.phone'),
      "mask": "+7 (###) ###-##-##",
      "readonly": ""
    },
    model: {
      value: _vm.form.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "label": _vm.trans('label.address'),
      "required": ""
    },
    model: {
      value: _vm.form.address,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "address", $$v);
      },
      expression: "form.address"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "label": _vm.trans('label.email'),
      "rules": [function (val) {
        return _vm.validateEmail(val) || _vm.trans('validation.email', {
          attribute: _vm.trans('label.email')
        });
      }],
      "lazy-rules": "",
      "error": _vm.hasValidationFail('email'),
      "error-message": _vm.getValidationMessage('email')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('email');
      }
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "label": _vm.trans('label.additional_phone'),
      "mask": "+7 (###) ###-##-##",
      "placeholder": "+7 (___) ___-__-__",
      "error": _vm.hasValidationFail('additional_phone'),
      "error-message": _vm.getValidationMessage('additional_phone')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('additional_phone');
      }
    },
    model: {
      value: _vm.form.additionalPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "additionalPhone", $$v);
      },
      expression: "form.additionalPhone"
    }
  }), _vm._v(" "), _c('q-select', {
    attrs: {
      "options": _vm.form.nationalityOptionsFiltered,
      "label": _vm.trans('label.select_nationality'),
      "option-value": "code",
      "use-input": ""
    },
    on: {
      "filter": _vm.filterNationality
    },
    model: {
      value: _vm.form.nationalityCurrent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nationalityCurrent", $$v);
      },
      expression: "form.nationalityCurrent"
    }
  }), _vm._v(" "), _c('q-field', {
    staticClass: "mb-30",
    attrs: {
      "label": _vm.trans('label.notifications_language'),
      "borderless": ""
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-radio', {
    attrs: {
      "val": _vm.form.langOptions[0].value,
      "label": _vm.trans(_vm.form.langOptions[0].label)
    },
    model: {
      value: _vm.form.langCurrent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "langCurrent", $$v);
      },
      expression: "form.langCurrent"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-auto"
  }, [_c('q-radio', {
    attrs: {
      "val": _vm.form.langOptions[1].value,
      "label": _vm.trans(_vm.form.langOptions[1].label)
    },
    model: {
      value: _vm.form.langCurrent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "langCurrent", $$v);
      },
      expression: "form.langCurrent"
    }
  })], 1)])])] : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }