var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "s-organization-profile__columns-wrapper"
  }, [_c('div', {
    staticClass: "s-organization-profile__column-left"
  }, [_c('v-photo-album', {
    staticClass: "mb-20",
    attrs: {
      "pic-list": _vm.images,
      "autoplay": false,
      "is-edit": true,
      "count-of-visible-thumbs": 3,
      "org-type": _vm.orgType
    },
    on: {
      "renew-photos": _vm.requestGetPhotos
    }
  }), _vm._v(" "), _vm.statistics.rows.length || _vm.statistics.pie.length ? _c('div', {
    staticClass: "s-passport-page__panel mb-30"
  }, [_vm.statistics.rows.length ? _c('div', {
    staticClass: "s-list-between s-c-blue-dark s-f-14"
  }, _vm._l(_vm.statistics.rows, function (item, i) {
    return _c('div', {
      key: i,
      staticClass: "s-list-between__row"
    }, [_c('div', {
      staticClass: "s-list-between__label"
    }, [_c('div', {
      class: "s-str-ico-fa s-ico--bold s-ico-fa--thm-".concat(item.icon)
    }, [_vm._v("\n                " + _vm._s(item.label) + ":\n              ")])]), _vm._v(" "), _c('div', {
      staticClass: "s-list-between__value"
    }, [_vm._v("\n              " + _vm._s(item.value) + "\n            ")])]);
  }), 0) : _vm._e(), _vm._v(" "), _vm.statistics.pie.data.length ? _c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('v-pie-chart', {
    attrs: {
      "data": _vm.statistics.pie.data
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "s-diagram__label"
  }, [_vm._v("\n              " + _vm._s(_vm.statistics.pie.label) + "\n            ")])], 1)]) : _vm._e()]) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "s-organization-profile__column-right"
  }, [_c('div', {
    staticClass: "s-organization-profile__panel mb-30"
  }, [_c('div', {
    staticClass: "s-organization-profile__name mb-15"
  }, [_c('span', {
    staticClass: "s-str-ico s-str-ico--thm-home"
  }, [_vm._v("\n            " + _vm._s(_vm.name) + "\n          ")])]), _vm._v(" "), _vm._l(_vm.fields, function (item, id) {
    return _c('div', {
      key: id,
      staticClass: "s-organization-profile__item"
    }, [_c('div', {
      staticClass: "s-organization-profile__item-label"
    }, [_vm._v("\n            " + _vm._s(item.label) + ":\n          ")]), _vm._v(" "), _c('div', {
      staticClass: "s-organization-profile__item-value"
    }, [_vm._v("\n            " + _vm._s(item.value) + "\n          ")])]);
  })], 2)])]), _vm._v(" "), _vm.description ? _c('div', {
    staticClass: "s-organization-profile__panel mb-30"
  }, [_c('div', {
    staticClass: "s-organization-profile__name mb-15"
  }, [_vm._v("\n      " + _vm._s(_vm.trans('organization.body')) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "s-organization-profile__description sn-ck-editor-data",
    domProps: {
      "innerHTML": _vm._s(_vm.description)
    }
  })]) : _vm._e(), _vm._v(" "), _vm.editLink ? _c('div', {
    staticClass: "row justify-content-end"
  }, [_c('div', {
    staticClass: "col col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "to": _vm.editLink,
      "type": "a",
      "color": "orange",
      "icon": "pencil",
      "label": _vm.trans('button.edit'),
      "no-caps": ""
    }
  })], 1)]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }