var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('q-dialog', {
    attrs: {
      "persistent": ""
    },
    model: {
      value: _vm.modalWarning,
      callback: function callback($$v) {
        _vm.modalWarning = $$v;
      },
      expression: "modalWarning"
    }
  }, [_c('v-modal', [_c('div', {
    staticClass: "s-plain-text"
  }, [_vm.type === 'user' ? [_c('p', {
    staticClass: "s-to-center",
    domProps: {
      "innerHTML": _vm._s(_vm.promoText)
    }
  }), _vm._v(" "), _c('p', {
    staticClass: "s-to-center"
  }, [_c('q-btn', {
    attrs: {
      "color": "green",
      "label": _vm.trans('label.interested')
    },
    on: {
      "~click": function click($event) {
        return _vm.handleClick($event);
      }
    }
  })], 1)] : _vm._e(), _vm._v(" "), _vm.type === 'guest' ? [_c('p', {
    staticClass: "s-to-center",
    domProps: {
      "innerHTML": _vm._s(_vm.promoText)
    }
  })] : _vm._e()], 2)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }