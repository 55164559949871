var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('table', {
    staticClass: "s-table-cabinet mb-20"
  }, [_c('thead', {
    staticClass: "s-table__head"
  }, [_c('tr', [_c('th', {
    staticClass: "s-table__col-1 s-table__col--to-center"
  }, [_vm._v("\n          №\n        ")]), _vm._v(" "), _c('th', {
    staticClass: "s-table__col-5 s-table__col--to-center"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('label.name_document')) + "\n        ")]), _vm._v(" "), _c('th', {
    staticClass: "s-table__col-6 s-table__col--to-center"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('label.name_file')) + "\n        ")]), _vm._v(" "), _c('th', {
    staticClass: "s-table__col-4 s-table__col--to-center"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('label.date_download')) + "\n        ")]), _vm._v(" "), _c('th', {
    staticClass: "s-table__col-4 s-table__col--to-center"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('label.operations')) + "\n        ")])])]), _vm._v(" "), _c('tbody', {
    staticClass: "s-table__body"
  }, _vm._l(_vm.mutatedFields, function (field, index) {
    var _class;

    return _c('tr', {
      key: index,
      class: (_class = {}, _class["tbl-cont__tr_".concat(field.status)] = true, _class)
    }, [_c('td', {
      staticClass: "s-table__col--to-center",
      attrs: {
        "data-table-head": "№"
      }
    }, [_vm._v("\n          " + _vm._s(index + 1) + "\n        ")]), _vm._v(" "), _c('td', {
      attrs: {
        "data-table-head": _vm.trans('label.name_document')
      }
    }, [_vm._v("\n          " + _vm._s(field.file_name) + "\n        ")]), _vm._v(" "), _c('td', {
      staticClass: "doc_name",
      attrs: {
        "data-table-head": _vm.trans('label.name_file')
      }
    }, [field.status === 'downloaded' ? _c('span', {
      staticClass: "tbl-cont__status"
    }, [_vm._v("\n            " + _vm._s(field.userFile.fileName) + "\n          ")]) : _c('span', {
      staticClass: "tbl-cont__status"
    }, [_vm._v("\n            " + _vm._s(_vm.trans('label.not_uploaded')) + "\n          ")])]), _vm._v(" "), _c('td', {
      staticClass: "s-table__col--to-center",
      attrs: {
        "data-table-head": _vm.trans('label.date_download')
      }
    }, [field.status === 'downloaded' ? _c('span', [_vm._v("\n            " + _vm._s(field.userFile.updated_at) + "\n          ")]) : _c('span', [_vm._v("-")])]), _vm._v(" "), _c('td', {
      staticClass: "s-table__col--to-center",
      attrs: {
        "data-table-head": _vm.trans('label.operations')
      }
    }, [_c('div', {
      staticClass: "s-group"
    }, [field.status === 'downloaded' ? _c('a', {
      staticClass: "s-btn-table s-btn--ico",
      attrs: {
        "href": field.userFile.upload_file,
        "title": _vm.trans('button.see'),
        "target": "_blank"
      }
    }, [_c('span', {
      staticClass: "s-ico s-ico--thm-eye"
    })]) : _c('button', {
      staticClass: "s-btn-table s-btn--ico",
      attrs: {
        "title": _vm.trans('button.load')
      },
      on: {
        "click": function click($event) {
          return _vm.switchType(index);
        }
      }
    }, [_c('span', {
      staticClass: "s-ico s-ico--thm-export"
    })]), _vm._v(" "), _c('button', {
      staticClass: "s-btn-table s-btn--ico",
      class: {
        's-btn--disabled': field.status !== 'downloaded'
      },
      attrs: {
        "disabled": field.status !== 'downloaded',
        "title": _vm.trans('button.remove')
      },
      on: {
        "click": function click($event) {
          return _vm.switchType(index);
        }
      }
    }, [_c('span', {
      staticClass: "s-ico s-ico--thm-trash"
    })])])])]);
  }), 0)]), _vm._v(" "), _c('input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "inputFile",
    attrs: {
      "type": "file"
    },
    on: {
      "change": _vm.uploadFile
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }