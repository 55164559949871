import axios from 'axios';
import _ from 'lodash';
import DatePicker from 'vue2-datepicker';
import { mask } from 'vue-the-mask';
import Offer from '@vjs/modals/Offer.vue';

export default {
  name: 'registration-sms',
  components: { DatePicker },
  directives: {
    mask,
  },
  props: {
    urls: {
      type: Object,
      default: () => ({
        service: '',
        success: '',
        redirect: '',
        checkIIN: '',
        offerModal: '',
      }),
    },
    project: {
      type: Number,
    },
  },

  data: () => ({
    currentStep: 1,
    lang: window.core_project.locale,
    errors: {},
    iin: '',
    show: false,
    is_resident: true,
    code: '',
    sendCode: false,
    phone: '',
    time: '',
    name: '',
    count: 0,
    timer: null,
    surname: '',
    lastname: '',
    sex: '',
    password: '',
    password_confirmation: '',
    processSend: false,
    checkOfferta: false,
    hash: '',
    debounceTimeout: null,
    showIinFields: true,
  }),
  computed: {
    seconds() {
      return this.count;
    },
  },
  beforeCreate() {
    this.$trans.add(['auth', 'button', 'label', 'placeholder']);
  },
  watch: {
    currentStep(value) {
      const steps = document.querySelectorAll('.s-steps .s-steps__area');
      Array.from(steps).forEach((item) => {
        if (parseInt(item.dataset.step, 10) <= value) {
          item.classList.add('is-active');
        }
      });
    },
    iin(value) {
      const vm = this;

      let iin = value;
      if (iin.indexOf('_')) {
        iin = iin.split('_')[0];
      }
      if (iin.match(/\d+/)[0].length === 12 && iin.indexOf('_') === -1) {
        let y = value.substr(0, 2);
        const m = value.substr(2, 2);
        const d = value.substr(4, 2);
        if (value.substr(0, 1) < 3) {
          y = `20${y}`;
        } else {
          y = `19${y}`;
        }
        if (m <= 12 && d <= 31) {
          const time = `${d}.${m}.${y}`;
          vm.$set(vm, 'time', time);
        }
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
          this.checkIin(iin);
        }, 1000);
      }
    },
    time(value) {
      const vm = this;
      if (value != null && value.indexOf('_') === -1) {
        value = value.split('.');
        const year = value['2'];
        let day = value['0'];
        let month = value['1'];
        if (day.split()['0'] === '0') {
          day = day.split()['1'];
        }
        if (month.split()['0'] === '0') {
          month = month.split()['1'];
        }
      }
    },
  },
  methods: {
    timerStart() {
      const vm = this;
      clearInterval(vm.timer);
      vm.count = 90;
      this.timer = setInterval(() => {
        if (vm.count > 0) {
          vm.count--;
        } else {
          clearInterval(vm.timer);
        }
      }, 1000);
    },
    showErrors(attr) {
      let errors = '';
      if (errors.hasOwnProperty(attr)) {
        _.forEach(errors[attr], (i, v) => {
          errors += `${v}<br/>`;
        });
      }
    },
    takeCode(useCheck) {
      const vm = this;
      if (useCheck && vm.checkDisabled()) return;
      let url = this.urls.service.replace(/\/+$/, '');
      url += '/api/v1/registration-sms';

      this.send(
        url,
        {
          phone: vm.phone,
        },
        (response) => {
          if (response.redirect) {
            window.location = response.redirect;
          }
          if (response.message) {
            vm.showNotification(response.message, 'notice');
          }
          vm.sendCode = true;
          vm.timerStart();
        },
      );
    },
    nextStep() {
      const vm = this;
      if (vm.checkDisabled()) return;

      let url = this.urls.service.replace(/\/+$/, '');
      switch (vm.currentStep) {
        case 1:
          if (vm.checkOfferta) {
            url += '/api/v1/registration-sms/code';
            this.send(
              url,
              {
                code: vm.code,
              },
              (response) => {
                vm.$set(vm, 'hash', vm.code);
                vm.$set(vm, 'currentStep', vm.currentStep + 1);
                if (response.redirect) {
                  window.location = response.redirect;
                }
                if (response.message) {
                  vm.showNotification(response.message, 'notice');
                }
              },
            );
          }
          break;
        case 2:

          vm.$set(vm, 'currentStep', 3);

          break;
        case 3:
          this.showIinFields = false;
          url += '/api/v1/registration-sms/password';
          this.send(
            url,
            {
              password: vm.password,
              password_confirmation: vm.password_confirmation,
              code: vm.code,
              project_id: vm.project,
              iin: vm.iin,
              name: vm.name,
              born_date: vm.time,
              surname: vm.surname,
            },
            (response) => {
              if (response.message) {
                vm.$notify({
                  type: 'success',
                  title: window.L.message,
                  text: response.message,
                });
              }
              setTimeout(() => { window.location.href = vm.urls.success; }, 3000);
            },
          );

          break;
        default:
      }
    },
    async send(url, body, success) {
      const vm = this;
      if (vm.processSend) {
        return;
      }
      try {
        vm.loadingButton();
        const res = await axios({
          method: 'POST',
          url,
          data: body,
        });
        const { data } = res;
        success(data);
      } catch (err) {
        if (err.response.status === 422 || err.response.status === 423) {
          let errors = [];
          _.forEach(err.response.data.errors, (val) => {
            errors = errors.concat(val);
          });
          vm.$notify({
            type: 'error',
            title: window.L.error,
            text: errors.toString(),
          });
        } else if (err.response.status === 200) {
          window.location.reload();
        }
      } finally {
        vm.loadingButton();
      }
    },
    loadingButton() {
      const vm = this;
      vm.$set(vm, 'processSend', !vm.processSend);
    },
    offertaButton() {
      const vm = this;
      vm.$set(vm, 'checkOfferta', !vm.checkOfferta);
    },
    checkFillIin() {
      return (this.currentStep === 3
        && this.is_resident === true
        && !/^\d{12}$/.test(this.iin));
    },
    checkDisabled() {
      return !this.checkOfferta
        || this.checkFillIin()
        || !this.showIinFields
        || (this.sendCode && this.code.length === 0)
        || (this.currentStep === 2
          && (this.password.length < 8 || this.password !== this.password_confirmation));
    },
    classButton() {
      if (this.checkDisabled()) {
        return '__disabled';
      }

      if (this.processSend || (/^\d{12}$/.test(this.iin)
        && !this.show)) {
        return 'loading-progress';
      }
      return '';
    },
    async offerInfo() {
      const res = await axios.get(this.urls.offerModal);
      this.$modal.open({
        title: this.trans('auth.offerta_link'),
        props: {
          text: res.data,
        },
        component: Offer,
      });
    },
    async checkIin(iin) {
      try {
        const res = await axios({
          method: 'POST',
          url: this.urls.checkIIN,
          data: {
            iin,
            born_date: this.time,
            resident: this.is_resident,
          },
        });
        const { data } = res;
        this.$set(this, 'surname', '');
        this.$set(this, 'lastname', '');
        this.$set(this, 'sex', '');
        this.$set(this, 'name', '');
        this.$set(this, 'showIinFields', true);

        if (data.status === 'error') {
          if (data.message !== this.trans('auth.already_exist')) {
            this.show = true;
          } else {
            this.$notify({
              type: 'error',
              title: window.L.error,
              text: data.message,
            });
          }
        }

        if (data.status === 'success_curl') {
          this.name = data.name;
          this.surname = data.surname;
          this.lastname = data.lastname;
          this.sex = this.trans('label.female');
          if (data.sex === 'm') {
            this.sex = this.trans('label.male');
          }
          this.show = true;
        }
      } catch (err) {
        if (typeof err.response.data !== 'undefined'
          && err.response.data.errors
          && err.response.data.errors.iin
        ) {
          this.$notify({
            type: 'error',
            title: window.L.error,
            text: err.response.data.errors.iin[0],
          });
        } else if (err.response.data && err.response.data.message) {
          this.$notify({
            type: 'error',
            title: window.L.error,
            text: err.response.data.message,
          });
        } else {
          this.$notify({
            type: 'error',
            title: window.L.error,
            text: err.response.data,
          });
        }

        this.$set(this, 'surname', '');
        this.$set(this, 'lastname', '');
        this.$set(this, 'sex', '');
        this.$set(this, 'name', '');
        this.$set(this, 'show', false);
        this.$set(this, 'showIinFields', false);
        this.checkDisabled();
      }
    },
  },
};
