var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-dialog', {
    attrs: {
      "value": _vm.modal,
      "persistent": _vm.persistent,
      "transition-show": "scale",
      "transition-hide": "scale"
    },
    on: {
      "input": _vm.handleModal
    }
  }, [_c('v-modal', {
    attrs: {
      "title": _vm.title,
      "size": "medium"
    }
  }, [_c('div', {
    staticStyle: {
      "text-align": "center"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.text)
    }
  })])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }