var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-dialog', {
    attrs: {
      "value": _vm.modal,
      "persistent": _vm.persistent,
      "transition-show": "scale",
      "transition-hide": "scale"
    },
    on: {
      "input": _vm.handleModal
    }
  }, [_c('v-modal', {
    attrs: {
      "title": _vm.trans('label.choose_region'),
      "noClose": _vm.persistent,
      "size": "small"
    }
  }, [_c('q-select', {
    attrs: {
      "options": _vm.optionsRegions,
      "label": _vm.trans('label.choose_region')
    },
    on: {
      "filter": _vm.filterRegions,
      "input": function input($event) {
        return _vm.checkOptions('curRegion', 'optionsDepartments', 'departments', 'region');
      }
    },
    model: {
      value: _vm.curRegion,
      callback: function callback($$v) {
        _vm.curRegion = $$v;
      },
      expression: "curRegion"
    }
  }), _vm._v(" "), _vm.optionsDepartments.length > 0 ? _c('q-select', {
    attrs: {
      "options": _vm.optionsDepartments,
      "label": _vm.trans('label.choose_department')
    },
    on: {
      "input": function input($event) {
        return _vm.checkOptions('curDep', 'optionsLocalities', 'localities', 'department');
      }
    },
    model: {
      value: _vm.curDep,
      callback: function callback($$v) {
        _vm.curDep = $$v;
      },
      expression: "curDep"
    }
  }) : _vm._e(), _vm._v(" "), _vm.optionsLocalities.length > 0 ? _c('q-select', {
    attrs: {
      "options": _vm.optionsLocalities,
      "label": _vm.trans('label.choose_locality')
    },
    on: {
      "input": function input($event) {
        return _vm.checkOptions('curLoc');
      }
    },
    model: {
      value: _vm.curLoc,
      callback: function callback($$v) {
        _vm.curLoc = $$v;
      },
      expression: "curLoc"
    }
  }) : _vm._e(), _vm._v(" "), _c('q-btn', {
    staticClass: "s-w-100 mt-10",
    attrs: {
      "color": "green",
      "icon": "check",
      "label": _vm.trans('button.enter'),
      "labelWider": "",
      "disable": !_vm.validateChooseRegion
    },
    on: {
      "click": _vm.submit
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }