import {
  QBtn,
  QCheckbox,
  QField,
  QForm,
  QInput,
  QOptionGroup,
  QSelect,
  QSlideTransition,
} from '@quasar/components';
import { Loading } from '@quasar/plugins';
import VReferenceTime from '@vjs/components/VReferenceTime';
import { requestWrapper, url } from '@vjs/helpers';
import Validation from '@vjs/mixins/Validation';
import VFormInform from '@vjs/components/VFormInform';
import _ from 'lodash';
import {empty, is_null} from "locutus/php/var";


export default {
  name: 'VRegularStatementCreateView',
  mixins: [Validation],
  components: {
    QInput,
    QBtn,
    QCheckbox,
    QOptionGroup,
    QSelect,
    QForm,
    QField,
    VReferenceTime,
    QSlideTransition,
    VFormInform,
  },
  props: {
    referenceTime: {
      type: Number,
      required: true,
    },
    uriChildren: {
      type: String,
      default: '/api/v1/user/statements/get/children',
    },
    uriChildCreate: {
      type: String,
      required: true,
    },
    uriLanguages: {
      type: String,
      default: '/api/v1/user/statements/get/languages',
    },
    uriBenefits: {
      type: String,
      default: '/api/v1/user/statements/get/benefits',
    },
    uriGroupTypes: {
      type: String,
      default: '/api/v1/user/statements/get/group-types',
    },
    uriSubmit: {
      type: String,
      required: true,
    },
    uriSubmitDisease: {
      type: String,
      required: true,
    },
    uriSubmitSanatorium: {
      type: String,
      required: true,
    },
    uriCheckBenefits: {
      type: String,
    },
    uriGetLgotTypes: {
      type: String,
    },
    uriGetLgotTypeDocuments: {
      type: String,
    },
    regions: {
      type: String,
      default: 'api/v1/regions',
    },
    departments: {
      type: String,
      default: 'api/v1/department',
    },
    localities: {
      type: String,
      default: 'api/v1/active-localities',
    },
    organizations: {
      type: String,
      default: 'api/v1/statement-organizations',
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    uriGetStatementFormData: {
      type: String,
      required: true,
    },
    diseaseTypes: {
      type: Array,
      required: true,
    },
    sanatoriumTypes: {
      type: Array,
      required: true,
    },
    queueTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: Loading,
      license: false,
      children: [],
      benefits: [],
      languages: [],
      groupTypes: [],
      statement: {
        child: null,
        locality_id: null,
        languages: [],
        correction: null,
        sanatorium: null,
        langOptions: [],
        type: null,
        soc_status_id: 0,
        lgotType: {
          value: 0,
          label: this.trans('statement.socStatuses.no_lgot'),
        },
        queueType: 0,
        groupTypeOptions: [],
        regionOptions: [],
        departmentOptions: [],
        localityOptions: [],
        organization_id: null,
        lgotTypeOptions: [],
        lgotTypeDocuments: {},
        lgotTypeDocumentRules: [],
        statementDocumentDisease: null,
        sanatoriumDocumentDisease: null,
      },
      current: null,
      curRegion: null,
      curDep: null,
      curLoc: null,
      curOrg: null,
      optionsRegions: [],
      optionsDepartments: [],
      optionsLocalities: [],
      optionsOrganization: [],
      activeFile: null,
      activeLgotFile: null,
    };
  },
  async mounted() {
    await this.$trans.add([
      'statement',
      'queue',
      'button',
      'warning_text',
      'main',
      'label',
    ]);

    this.loading.show({
      message: this.trans('label.wait_loading'),
    });

    await this.loadChildren();

    const languagesRes = await requestWrapper({
      url: this.uriLanguages,
    });
    this.languages = languagesRes.data;

    const benefitsRes = await requestWrapper({
      url: this.uriBenefits,
    });
    this.benefits = benefitsRes.data;

    const groupTypesRes = await requestWrapper({
      url: this.uriGroupTypes,
    });
    this.groupTypes = groupTypesRes.data;

    const formData = await requestWrapper.call(this, {
      url: this.uriGetStatementFormData,
    });
    if (!formData.error) {
      this.statement.groupTypeOptions = formData.groupType;
      this.statement.type = 0;
      this.statement.langOptions = formData.langs;
    }
    this.statement.lgotType = {
      value: 0,
      label: this.trans('statement.socStatuses.no_lgot'),
    };
    this.loading.hide();
  },
  computed: {
    getCorrection() {
      if (!this.statement.type) {
        return false;
      }
      if (this.statement.type === 3) {
        return false;
      }
      if (this.statement.correction) {
        return this.statement.correction;
      }
      return 1;
    },
    getSanatorium() {
      if (!this.statement.type) {
        return false;
      }
      if (this.statement.type === 3) {
        return this.statement.sanatorium ? this.statement.sanatorium : 1;
      }
      return false;
    },
    queueTypeOptions() {
      const { queueTypes } = this;
      const types = [];
      _.forEach(queueTypes, (item) => {
        types.push(item);
      });
      if (this.statement.type === 1) {
        types.pop();
      }
      return types;
    },
    validateChooseRegion() {
      return this.curDep || this.curLoc;
    },
    validate() {
      let lgotDocumentsValidate = true;
      _.forEach(this.statement.lgotTypeDocuments, (file, key) => {
        if (this.statement.lgotTypeDocumentRules[key].required === true && is_null(file)) {
          lgotDocumentsValidate = false;
        }
      });
      return (
        this.license !== false
        && this.statement.child !== null
        && this.statement.type !== null
        && this.statement.languages.length > 0
        && this.curRegion
        && this.curRegion.value !== null
        && this.curDep
        && this.curDep.value !== null
        && this.statement.locality_id !== null
        && (this.statement.queueType === 0
          || (this.statement.queueType !== null
            && this.statement.lgotType.value !== null
            && this.statement.lgotType.value !== 0
            && Object.keys(this.statement.lgotTypeDocuments).length > 0
            && lgotDocumentsValidate
          )
        )
      ) && (
        this.statement.type === 0
        || (
          this.statement.type === 1
          && this.statement.statementDocumentDisease
          && this.statement.correction
          && this.statement.organization_id
        )
        || (
          this.statement.type === 3
          && this.statement.sanatoriumDocumentDisease
          && this.statement.sanatorium
          && this.statement.organization_id
        )
      );
    },
  },
  methods: {
    async request(address = '', params) {
      const options = {
        method: 'get',
        url: url(address),
      };

      if (params) {
        options.params = params;
      }

      const res = await requestWrapper.call(this, {
        ...options,
      });

      if (!res.error) {
        const { data } = res;
        return Array.isArray(data) ? data : [];
      }
      if (res.error) {
        return [];
      }
    },
    async filterRegions(val, update, abort) {
      if (this.optionsRegions.length > 0) {
        update();
        return;
      }

      try {
        this.optionsRegions = await this.request(this.regions);
        update();
      } catch {
        abort();
      }
    },
    async checkOptions(keyCur = '', keyOptions, keyRequest, keyParams) {
      if (this[keyCur]
        && this[keyCur].value
        && keyOptions
        && keyRequest
        && keyParams
      ) {
        this[keyOptions] = await this.request(this[keyRequest], {
          [keyParams]: this[keyCur].value,
        });
      }

      this.current = {
        keyParams,
        request: this[keyRequest],
        ...this[keyCur],
      };

      if (keyCur === 'curRegion') {
        this.optionsLocalities = [];
        this.curDep = null;
        this.statement.locality_id = null;
      }

      if (keyCur === 'curDep') {
        this.statement.locality_id = null;
      }
      this.optionsOrganization = [];
    },
    async changeGroupType() {
      await this.loadChildren();
      await this.loadOrganizations();
      if (this.sanatoriumTypes.length) {
        this.statement.sanatorium = this.sanatoriumTypes[0].value;
      }
    },
    async loadChildren() {
      const {
        getCorrection: correction,
        getSanatorium: sanatorium,
        statement: { child },
      } = this;
      this.statement.child = null;
      const childrenRes = await requestWrapper({
        url: this.uriChildren,
        params: {
          correction,
          sanatorium,
        },
      });
      this.children = childrenRes.data;
      if (child) {
        const found = _.find(childrenRes.data, { value: child });
        this.statement.child = found ? child : null;
      }
    },
    async loadOrganizations() {
      if (this.statement.locality_id) {
        const { statement: { organization_id } } = this;
        this.statement.organization_id = null;
        this.optionsOrganization = await this.request(this.organizations, {
          locality_id: this.statement.locality_id,
          correction: this.getCorrection,
          sanatorium: this.getSanatorium,
        });
        const found = _.find(this.optionsOrganization, { value: organization_id });
        this.statement.organization_id = found ? organization_id : null;
        if (
          (
            this.getCorrection
            || this.getSanatorium
          )
          && this.optionsOrganization.length === 0
        ) {
          this.$notify({
            text: this.trans('notice.no_orgs_to_statement'),
            type: 'warn',
          });
        }
      }
    },
    async onSubmit() {
      this.loading.show();
      const statementData = new FormData();
      statementData.append('childId', this.statement.child);
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const i in this.statement.languages) {
        // noinspection JSUnfilteredForInLoop
        statementData.append('lang[]', this.statement.languages[i]);
      }
      statementData.append('department', this.curDep.value);
      statementData.append('locality', this.statement.locality_id);
      if (this.statement.organization_id) {
        statementData.append('is_organization_statement', 1);
        statementData.append('organization_id', this.statement.organization_id);
      }
      statementData.append('soc_status', this.statement.soc_status_id);
      statementData.append('type', this.statement.type);
      if (this.statement.queueType > 0) {
        statementData.append('lgot_type', this.statement.lgotType.value);
        statementData.append('queue_type', this.statement.queueType);
        _.forEach(this.statement.lgotTypeDocuments, (file, name) => {
          statementData.append(name, file);
        });
      }
      if (this.statement.type === 1) {
        statementData.append('diseases', parseInt(this.statement.correction, 10));
        statementData.append('document_disease', this.statement.statementDocumentDisease);
      }

      if (this.statement.type === 3) {
        statementData.append('sanatorium', parseInt(this.statement.sanatorium, 10));
        statementData.append('document_sanatorium', this.statement.sanatoriumDocumentDisease);
      }

      statementData.append('agreement', this.license);

      let uri = this.uriSubmit;
      if (this.statement.type === 1) {
        uri = this.uriSubmitDisease;
      }
      if (this.statement.type === 3) {
        uri = this.uriSubmitSanatorium;
      }

      const res = await requestWrapper.call(this, {
        method: 'POST',
        url: uri,
        data: statementData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      this.loading.hide();
      if (res.error) {
        this.loading.hide();
        if (res.data.errors && res.errorType === 422) {
          this.$notify({
            text: res.data.errors[Object.keys(res.data.errors)[0]][0],
            type: 'error',
          });
        }
      }
    },


    async checkBenefits() {
      this.statement.soc_status_id = 0;
      this.benefits.forEach((benefit) => {
        // eslint-disable-next-line no-param-reassign
        benefit.disable = !(benefit.value === 0);
      });

      const response = await requestWrapper({
        method: 'POST',
        url: this.uriCheckBenefits,
        data: {
          child_id: this.statement.child,
        },
      });

      if (response.error && response.data.errors && response.errorType === 422) {
        this.$notify({
          text: response.data.errors[Object.keys(response.data.errors)[0]][0],
          type: 'error',
        });
      }

      if (!response.error && response.data.socialStatusExist) {
        this.benefits.forEach((benefit) => {
          // eslint-disable-next-line no-param-reassign
          benefit.disable = !(
            response.data.socialStatusID === benefit.value
            || benefit.value === 0
          );
        });
        this.statement.soc_status_id = response.data.socialStatusID;
      }
    },

    clearLgotFile(filename) {
      this.statement.lgotTypeDocuments[filename] = null;
    },
    lgotFileChange() {
      const file = this.$refs.lgotFileInput.files[0];
      if (file) {
        this.statement.lgotTypeDocuments[this.activeLgotFile] = file;
      }
      this.activeLgotFile = null;
      this.$refs.lgotFileInput.value = null;
    },
    triggerLgotFile(filename) {
      this.activeLgotFile = filename;
      this.$refs.lgotFileInput.click();
    },
    clearFile(filename) {
      this.statement[filename] = null;
    },
    fileChange() {
      const file = this.$refs.fileInput.files[0];
      if (file) {
        this.statement[this.activeFile] = file;
      }
      this.activeFile = null;
      this.$refs.fileInput.value = null;
    },
    triggerFile(filename) {
      this.activeFile = filename;
      this.$refs.fileInput.click();
    },
    async getLgotTypeDocuments() {
      const response = await requestWrapper({
        method: 'GET',
        url: this.uriGetLgotTypeDocuments,
        params: {
          lgotType: this.statement.lgotType.value,
        },
      });

      if (response.message) {
        this.$notify({
          text: response.message,
          type: 'error',
        });
        this.statement.lgotTypeDocuments = {};
        this.statement.lgotTypeDocumentRules = {};
      } else {
        this.statement.lgotTypeDocuments = response.data.documents;
        this.statement.lgotTypeDocumentRules = response.data.documentRules;
      }
    },
    async clearLgots() {
      await this.loadLgotTypeOptions();
      this.statement.lgotType = {
        value: 0,
        label: this.trans('statement.socStatuses.no_lgot'),
      };
      this.statement.lgotTypeDocuments = {};
      this.statement.lgotTypeDocumentRules = {};
    },
    async loadLgotTypeOptions() {
      const response = await requestWrapper({
        method: 'GET',
        url: this.uriGetLgotTypes,
        params: {
          queueType: this.statement.queueType,
        },
      });

      if (response.message) {
        this.$notify({
          text: response.message,
          type: 'error',
        });
      }
      this.statement.lgotTypeOptions = response.data;
    },
  },
};
