var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-20",
    class: {
      's-form__error': _vm.errors.length > 0
    }
  }, [_vm.showLabelLocal ? _c('label', {
    class: [_vm.labelClass === null ? 'mb-10' : _vm.labelClass, 's-form__label'],
    domProps: {
      "innerHTML": _vm._s("".concat(_vm.fieldLabel, ": ").concat(_vm.fieldRequired ? '<i>*</i>' : ''))
    }
  }) : _vm._e(), _vm._v(" "), _vm._t("field"), _vm._v(" "), _vm.errors.length > 0 ? _c('div', {
    staticClass: "s-form__error-message"
  }, [_vm._v("\n    " + _vm._s(_vm.getError()) + "\n  ")]) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }