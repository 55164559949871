<template>
  <q-dialog
    :value="modal"
    @input="handleModal"
  >
    <v-modal
      :title="trans('label.doc_extend_direction')"
      size="medium"
    >
      <div class="s-plain-text">
        <p class="s-medium">
          {{ trans('label.docs_criteries') }}:
        </p>
        <ul class="mb-20">
          <li>{{ trans('label.file_formats') }};</li>
          <li>{{ trans('label.file_size') }};</li>
          <li>{{ trans('label.file_dimensions') }};</li>
        </ul>
      </div>

      <q-select
        v-model="lgotType"
        class="mb-10"
        popup-content-class="sn-menu-short"
        :options="lgotTypeOptions"
        :label="trans('label.lgot_type')"
        required
        @input="getLgotTypeDocuments()"
      />
      <q-slide-transition>
        <div
          v-show="Object.keys(lgotTypeDocuments).length"
        >
          <div
            v-for="(item, key) in lgotTypeDocuments"
            :key="key"
            class="mt-10 mb-20"
          >
            <div class="sn-field__label mb-15">
              {{ lgotTypeDocumentRules[key].label }}:
              <i v-if="lgotTypeDocumentRules[key].required === true">*</i>
            </div>
            <q-btn
              v-if="!item"
              class="s-w-100 form-upload__button"
              icon="clip"
              color="blue"
              type="button"
              :label="trans('button.overview')"
              label-wider
              @click="triggerFile(key)"
            />
            <q-btn
              v-if="item"
              icon="close"
              class="s-w-100 s-btn--border sn-btn--ellipsis"
              :label="item.name"
              label-wider
              type="button"
              @click="clearFile(key)"
            />
          </div>
        </div>
      </q-slide-transition>

      <input
        ref="fileInput"
        class="d-none"
        type="file"
        accept="application/msword, application/pdf, image/*"
        @change="fileChange"
      >

      <div class="row justify-content-between">
        <div class="col col-sm-auto sn--text-center mt-5 mb-5">
          <q-btn
            v-close-popup
            width="20"
            :label="trans('button.cancel')"
            label-wider
            color="grey"
          />
        </div>
        <div class="col col-sm-auto sn--text-center mt-5 mb-5">
          <q-btn
            width="20"
            :label="trans('button.send')"
            color="green"
            :disable="!validate"
            @click="send"
          />
        </div>
      </div>
    </v-modal>
  </q-dialog>
</template>

<script>
import {
  QBtn, QDialog, QSelect, QSlideTransition,
} from '@quasar/components';
import { Loading } from '@quasar/plugins';
import { requestWrapper } from '@vjs/helpers';
import { ClosePopup } from '@quasar/directives';
import VModal from '@vjs/components/VModal';
import eventHub from '@vjs/config/eventHub';
import _ from 'lodash';
import { is_null } from 'locutus/php/var';

export default {
  name: 'VApplicationLgotsModal',
  components: {
    QDialog,
    QSelect,
    QSlideTransition,
    QBtn,
    VModal,
  },
  directives: { ClosePopup },
  props: {
    modal: Boolean,
    uriUpdateLgots: {
      type: String,
      required: true,
    },
    uriGetLgotTypes: {
      type: String,
      required: true,
    },
    uriGetLgotTypeDocuments: {
      type: String,
      required: true,
    },
    identifier: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      lgotTypeOptions: [],
      lgotTypeDocuments: {},
      lgotTypeDocumentRules: {},
      lgotType: {
        value: 0,
        label: this.trans('statement.socStatuses.no_lgot'),
      },
      qLoading: Loading,
      activeFile: null,
    };
  },
  computed: {
    validate() {
      let lgotDocumentsValidate = true;
      _.forEach(this.lgotTypeDocuments, (file, key) => {
        if (this.lgotTypeDocumentRules[key].required === true && is_null(file)) {
          lgotDocumentsValidate = false;
        }
      });
      return this.lgotType.value !== null && this.lgotType.value !== 0
        && Object.keys(this.lgotTypeDocuments).length > 0
        && lgotDocumentsValidate;
    },
  },
  beforeCreate() {
    this.$trans.add(['organization', 'button', 'statement', 'notice', 'label']);
  },

  async mounted() {
    await this.$trans.add([
      'statement',
      'label',
      'button',
    ]);
    await this.loadLgotTypeOptions();
  },

  methods: {
    clearFile(filename) {
      this.lgotTypeDocuments[filename] = null;
    },
    fileChange() {
      const file = this.$refs.fileInput.files[0];
      if (file) {
        this.lgotTypeDocuments[this.activeFile] = file;
      }
      this.activeFile = null;
      this.$refs.fileInput.value = null;
    },
    triggerFile(filename) {
      this.activeFile = filename;
      this.$refs.fileInput.click();
    },
    handleModal() {
      this.$emit('modal-close', false);
    },
    async getLgotTypeDocuments() {
      const response = await requestWrapper({
        method: 'GET',
        url: this.uriGetLgotTypeDocuments,
        params: {
          lgotType: this.lgotType.value,
        },
      });

      if (response.message) {
        this.$notify({
          text: response.message,
          type: 'error',
        });
        this.lgotTypeDocuments = {};
        this.lgotTypeDocumentRules = {};
      } else {
        this.lgotTypeDocuments = response.data.documents;
        this.lgotTypeDocumentRules = response.data.documentRules;
      }
    },
    async loadLgotTypeOptions() {
      const response = await requestWrapper({
        method: 'GET',
        url: this.uriGetLgotTypes,
      });

      if (response.message) {
        this.$notify({
          text: response.message,
          type: 'error',
        });
      }
      this.lgotTypeOptions = response.data;
      this.lgotType = {
        value: 0,
        label: this.trans('statement.socStatuses.no_lgot'),
      };
    },
    async send() {
      this.qLoading.show();
      const formData = new FormData();
      formData.append('lgot_type', this.lgotType.value);
      formData.append('statement_id', this.identifier);
      _.forEach(this.lgotTypeDocuments, (file, name) => {
        formData.append(name, file);
      });

      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriUpdateLgots,
        data: formData,
      });

      if (res.error) {
        this.qLoading.hide();
        if (res.data.errors && res.errorType === 422) {
          this.$notify({
            text: res.data.errors[Object.keys(res.data.errors)[0]][0],
            type: 'error',
          });
        }
        return;
      }

      if (!res.error) {
        this.handleModal();
        eventHub.$emit('updatedLgot      activeAdditionalFile: null,', true);
      }

      this.qLoading.hide();
    },
  },
};
</script>

<style lang="scss">
.sn-menu.sn-menu-short {
  max-width: 10vw;
}
</style>
