var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('form', {
    staticClass: "row-sort-wrap",
    attrs: {
      "method": "get"
    }
  }, [_c('div', {
    staticClass: "row align-items-stretch mb-10  justify-content-end"
  }, [_c('div', {
    staticClass: "col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "color": "green",
      "no-caps": "",
      "label": _vm.trans('button.export'),
      "loading": _vm.loading
    },
    on: {
      "click": _vm.exportu
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "row align-items-stretch mb-10  justify-content-end"
  }, [_c('div', {
    staticClass: "col-sm-auto"
  }, [_c('q-input', {
    attrs: {
      "dense": "",
      "debounce": "300",
      "placeholder": _vm.trans('placeholder.search')
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }, [_c('q-icon', {
    attrs: {
      "slot": "append",
      "name": "search"
    },
    slot: "append"
  })], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "q-ml-md s-pos-float-right mt-10",
    attrs: {
      "squire": "",
      "unelevated": "",
      "icon": "fullscreen",
      "color": "grey"
    },
    on: {
      "click": function click($event) {
        _vm.table.inFullscreen = true;
      }
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.fullscreen_on')))])], 1)], 1)])]), _vm._v(" "), _c('div', [_c('q-table', {
    attrs: {
      "data": _vm.rows,
      "columns": _vm.table.columns,
      "pagination": _vm.table.pagination,
      "row-key": "name",
      "separator": "cell",
      "fullscreen": _vm.table.inFullscreen,
      "filter": _vm.filter
    },
    on: {
      "update:pagination": function updatePagination($event) {
        return _vm.$set(_vm.table, "pagination", $event);
      }
    },
    scopedSlots: _vm._u([_vm.table.inFullscreen ? {
      key: "top",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "row align-items-stretch justify-content-end s-w-100"
        }, [_c('div', {
          staticClass: "col-sm-auto"
        }, [_c('q-input', {
          attrs: {
            "dense": "",
            "debounce": "300",
            "placeholder": _vm.trans('placeholder.search')
          },
          model: {
            value: _vm.filter,
            callback: function callback($$v) {
              _vm.filter = $$v;
            },
            expression: "filter"
          }
        }, [_c('q-icon', {
          attrs: {
            "slot": "append",
            "name": "search"
          },
          slot: "append"
        })], 1)], 1), _vm._v(" "), _c('div', {
          staticClass: "col-sm-auto"
        }, [_c('q-btn', {
          staticClass: "q-ml-md s-pos-float-right mt-10",
          attrs: {
            "squire": "",
            "unelevated": "",
            "icon": "fullscreen",
            "color": "blue"
          },
          on: {
            "click": function click($event) {
              _vm.table.inFullscreen = false;
            }
          }
        }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.fullscreen_off')))])], 1)], 1)])];
      }
    } : null, {
      key: "header",
      fn: function fn(props) {
        return [_c('q-tr', [_c('th', {
          staticClass: "sn--text-center",
          attrs: {
            "colspan": "7"
          }
        }, [_vm._v("\n            " + _vm._s(_vm.trans('statistics.personnel.ddo_personnel')) + "\n          ")])]), _vm._v(" "), _c('q-tr', [_c('th'), _vm._v(" "), _c('th'), _vm._v(" "), _c('th', {
          staticClass: "sn--text-center",
          attrs: {
            "colspan": "3"
          }
        }, [_vm._v("\n            " + _vm._s(_vm.trans('statistics.personnel.ddo_count')) + "\n          ")]), _vm._v(" "), _c('th', {
          staticClass: "sn--text-center",
          attrs: {
            "colspan": "3"
          }
        }, [_vm._v("\n            " + _vm._s(_vm.trans('statistics.personnel.ddo_personnel_count')) + "\n          ")])]), _vm._v(" "), _c('q-tr', {
          attrs: {
            "props": props
          }
        }, _vm._l(props.cols, function (col) {
          return _c('q-th', {
            key: col.name,
            attrs: {
              "props": props
            }
          }, [_vm._v("\n            " + _vm._s(_vm.trans(col.label)) + "\n          ")]);
        }), 1)];
      }
    }, {
      key: "bottom-row",
      fn: function fn() {
        return [_c('q-tr', [_c('q-td', [_c('b', [_vm._v(_vm._s(_vm.trans('statistics.contingent.total')))])]), _vm._v(" "), _vm._l(_vm.table.colNames, function (item, i) {
          return _c('q-td', {
            key: i
          }, [_vm._v("\n            " + _vm._s(_vm.sum(item)) + "\n          ")]);
        })], 2)];
      },
      proxy: true
    }], null, true)
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }