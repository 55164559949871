var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-field', {
    attrs: {
      "field": _vm.field,
      "field-name": _vm.fieldName,
      "show-label": _vm.showLabel,
      "errors": _vm.errors
    }
  }, [_c('template', {
    slot: "field"
  }, [_vm.extraAttributes.type === 'checkbox' ? _c('input', _vm._b({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.localValue,
      expression: "localValue"
    }],
    staticClass: "s-form__input",
    attrs: {
      "name": _vm.field.name,
      "placeholder": _vm.field.placeholder ? _vm.trans(_vm.field.placeholder) : undefined,
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.localValue) ? _vm._i(_vm.localValue, null) > -1 : _vm.localValue
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.localValue,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.localValue = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.localValue = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.localValue = $$c;
        }
      }
    }
  }, 'input', _vm.extraAttributes, false)) : _vm.extraAttributes.type === 'radio' ? _c('input', _vm._b({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.localValue,
      expression: "localValue"
    }],
    staticClass: "s-form__input",
    attrs: {
      "name": _vm.field.name,
      "placeholder": _vm.field.placeholder ? _vm.trans(_vm.field.placeholder) : undefined,
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.localValue, null)
    },
    on: {
      "change": function change($event) {
        _vm.localValue = null;
      }
    }
  }, 'input', _vm.extraAttributes, false)) : _c('input', _vm._b({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.localValue,
      expression: "localValue"
    }],
    staticClass: "s-form__input",
    attrs: {
      "name": _vm.field.name,
      "placeholder": _vm.field.placeholder ? _vm.trans(_vm.field.placeholder) : undefined,
      "type": _vm.extraAttributes.type
    },
    domProps: {
      "value": _vm.localValue
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.localValue = $event.target.value;
      }
    }
  }, 'input', _vm.extraAttributes, false))])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }