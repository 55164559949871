import {
  QBtn,
  QIcon,
  QSelect,
  QTable,
  QTd,
  QTh,
  QTooltip,
  QTr,
} from '@quasar/components';
import VNoResults from '@vjs/components/VNoResults';
import VConfirmDialog from '@vjs/modals/VConfirmDialog';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';
import { eventBus } from '@common/mixins';
import ImageViewer from '@common/components/ImageViewer';

export default {
  name: 'NewTable',
  components: {
    QBtn,
    VNoResults,
    QIcon,
    QTable,
    QTh,
    QTr,
    QTd,
    QTooltip,
    QSelect,
    VConfirmDialog,
    ImageViewer,
  },
  props: {
    cols: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    meta: {
      type: Object,
      default: () => ({}),
    },
    paginationBtns: {
      type: Array,
      default: () => ({}),
    },
    adaptiveWhen: {
      type: Number,
      default: 1200,
    },
    separator: {
      type: String,
      default: 'horizontal',
    },
    clickableRows: Boolean,
    noResultTitle: String,
    noResultText: String,
    withNoResultText: Boolean,
    visibleCols: Array,
  },
  data() {
    return {
      openModal: false,
      modalMethod: 'post',
      uriConfirm: '',
      loadingModal: false,
      hoveredRowId: null,
      pagination: {
        rowsPerPage: 15,
      },
    };
  },
  computed: {
    rowBtnStyles() {
      const refName = `row-${this.hoveredRowId}`;
      const ref = this.$refs?.[refName];
      if (ref) {
        const bodyRect = document.body.getBoundingClientRect();
        const refRect = ref.$el.getBoundingClientRect();
        const top = (refRect.top + (refRect.height / 2) - 15) - bodyRect.top;
        return { top: `${top}px`, left: `${refRect.left - 15}px` };
      }
      return null;
    },
    window() {
      return window;
    },
    paginationOptions() {
      const options = [10, 15, 25, 50, 100];
      return options.map(item => ({
        value: item,
        label: item,
      }));
    },
    isMetaEmpty() {
      return _.isEmpty(this.meta);
    },
  },
  mounted() {
    if (this.isMetaEmpty) {
      this.pagination.rowsPerPage = 0;
    } else {
      this.pagination.rowsPerPage = this.meta.per_page;
    }
  },
  methods: {
    async emitGetItems(type = 'default') {
      await eventBus.$emit('sendRowsPerPage', this.pagination.rowsPerPage);
      await eventBus.$emit('getItems', type);
    },
    getCellClass(name) {
      const col = this.cols.find(column => column.name === name);
      let style = '';

      if (col) {
        if (col.align && !col.alignOnlyHead) {
          style += `s-to-${col.align} `;
        }
        if (col.fontWeight) {
          style += `s-${col.fontWeight} `;
        }
      }
      return style;
    },
    handleModal(action, row) {
      if (action.type === 'button') {
        if (action.modal === 'confirm' && action.modalLink && action.method) {
          this.openModal = true;
          this.uriConfirm = action.modalLink;
          this.modalMethod = action.method;
        }

        if (action.modal === 'image' && action.modalLink) {
          const tmpImages = [{ thumbnail: action.modalLink, source: action.modalLink }];
          this.$refs.viewer.show(tmpImages);
        }

        if (action.modal === 's-modal' && action['s-modal-config']) {
          const config = action['s-modal-config'];
          const binds = config?.binds ?? {};
          const events = {};
          if (config.getItemsOnSuccess) {
            const func = async () => { await this.emitGetItems(); };
            events.onApprove = func;
            events.onSuccess = func;
          }
          binds.row = row;
          this.$sModal.open(
            action['s-modal-config'].component,
            { ...action['s-modal-config'], binds, events },
          );
        }
      }
    },

    async requestConfirmModal() {
      this.$emit('toggleLoaders', true);
      const res = await requestWrapper.call(this, {
        method: this.modalMethod,
        url: this.uriConfirm,
      });

      if (!res.error) {
        this.openModal = false;
        if (res.data && res.data.message) {
          Helper.showNotice(res.data.message, 'success', this.trans('notice.message'));
        }
        Helper.handlerResponseError({ data: res, status: res.status });
        await eventBus.$emit('getItems', 'default');
      } else {
        Helper.handlerResponseError({ data: res.data, status: res.errorType });
      }
      this.$emit('toggleLoaders');
    },
  },
};
