var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-bulletin-item",
    class: {
      'is-loading': _vm.loading
    }
  }, [_c('div', {
    staticClass: "s-bulletin-item__head"
  }, [_c('div', {
    staticClass: "s-bulletin-item__head-left"
  }, [_c('div', {
    staticClass: "s-bulletin-item__title mb-15"
  }, [_vm._v(_vm._s(_vm.organization.name))]), _vm._v(" "), _vm.groupName ? _c('div', {
    staticClass: "s-w-100 mb-5 s-bulletin-item__line"
  }, [_c('strong', [_vm._v(_vm._s(_vm.trans('bulletin.group_name')) + ":")]), _vm._v(" " + _vm._s(_vm.groupName) + "\n      ")]) : _vm._e(), _vm._v(" "), _vm.agesList ? _c('div', {
    staticClass: "s-w-100 mb-5 s-bulletin-item__line"
  }, [_c('strong', [_vm._v(_vm._s(_vm.trans('bulletin.ages_list')) + ":")]), _vm._v(" " + _vm._s(_vm.agesList) + "\n      ")]) : _vm._e(), _vm._v(" "), _vm.correction && !_vm.sanatoria ? _c('div', {
    staticClass: "s-w-100 mb-5 s-bulletin-item__line"
  }, [_c('strong', [_vm._v(_vm._s(_vm.trans('bulletin.diseases')) + ":")]), _vm._v(" " + _vm._s(_vm.diseases) + "\n      ")]) : _vm._e(), _vm._v(" "), _vm.sanatoria ? _c('div', {
    staticClass: "s-w-100 mb-5 s-bulletin-item__line"
  }, [_c('strong', [_vm._v(_vm._s(_vm.trans('bulletin.sanatoria')) + ":")]), _vm._v(" " + _vm._s(_vm.sanatorium) + "\n      ")]) : _vm._e(), _vm._v(" "), _vm.organization.address ? _c('div', {
    staticClass: "s-w-100 mb-5 s-bulletin-item__line"
  }, [_c('strong', [_vm._v(_vm._s(_vm.trans('bulletin.address')) + ":")]), _vm._v(" " + _vm._s(_vm.organization.address) + "\n      ")]) : _vm._e(), _vm._v(" "), _vm.educationYear ? _c('div', {
    staticClass: "s-w-100 mb-5 s-bulletin-item__line"
  }, [_c('strong', [_vm._v(_vm._s(_vm.trans('bulletin.education_year')) + ":")]), _vm._v(" " + _vm._s(_vm.educationYear) + "\n      ")]) : _vm._e()]), _vm._v(" "), _vm.expiredAt || _vm.publishDate ? _c('div', {
    staticClass: "s-bulletin-item__head-right"
  }, [_c('div', {
    staticClass: "s-bulletin-item__mark"
  }, [_vm.expiredAt ? _c('div', {
    staticClass: "s-flag s-b-light-purple s-c-white mb-15"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('bulletin.expired_at')) + " " + _vm._s(_vm.expiredAt) + "\n        ")]) : _vm._e(), _vm._v(" "), _vm.publishDate ? _c('div', {
    staticClass: "s-flag s-b-blue s-c-white"
  }, [_vm._v(_vm._s(_vm.trans('bulletin.publish_date')) + " " + _vm._s(_vm.publishDate))]) : _vm._e()])]) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "s-bulletin-item__body"
  }, [_c('div', {
    staticClass: "row"
  }, _vm._l(_vm.params, function (item, idx) {
    return _c('div', {
      key: idx,
      staticClass: "col-lg-3 col-md-4 col-sm-6"
    }, [_vm.typeOfTab === 'all' ? _c('div', {
      staticClass: "s-desc-item"
    }, [_c('div', {
      staticClass: "s-desc-item__label mb-5"
    }, [_vm._v(_vm._s(_vm.trans(item.key)) + ":")]), _vm._v(" "), _c('div', {
      staticClass: "s-desc-item__value"
    }, [_vm._v(_vm._s(item.value))])]) : _vm._e(), _vm._v(" "), _vm.typeOfTab === 'org' ? _c('div', {
      staticClass: "s-desc-item"
    }, [_c('div', {
      staticClass: "s-desc-item__label mb-5"
    }, [_vm._v(_vm._s(item.year) + ":")]), _vm._v(" "), _vm._l(item.byLang, function (group, index) {
      return _c('div', {
        key: index,
        staticClass: "s-desc-item__value"
      }, [_vm._v(_vm._s(group.langName) + ": " + _vm._s(group.count))]);
    })], 2) : _vm._e()]);
  }), 0), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-end"
  }, [_vm.visibleRequestButton && _vm.typeOfTab === 'all' ? _c('div', {
    staticClass: "col-auto"
  }, [_c('q-btn', {
    attrs: {
      "color": "green",
      "label": _vm.trans('bulletin.leave_request'),
      "loading": _vm.statementsLoading
    },
    on: {
      "click": _vm.requestStatements
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.typeOfTab === 'org' && !_vm.loading ? _c('div', {
    staticClass: "col-auto"
  }, [_c('q-btn', {
    attrs: {
      "color": "blue",
      "label": _vm.trans('button.see')
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('select-organization', {
          value: _vm.organizationId,
          label: _vm.organization.name
        });
      }
    }
  })], 1) : _vm._e()])]), _vm._v(" "), _vm.typeOfTab === 'all' && _vm.visibleRequestButton ? _c('v-bulletin-item-modal', {
    attrs: {
      "modal": _vm.modalBulletinItem,
      "statementItems": _vm.modalBulletinItemStatements,
      "uriStatementRequest": _vm.uriStatementRequest,
      "organizationId": _vm.organizationId,
      "correction": _vm.correction
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.modalBulletinItem = $event;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }