var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row align-items-stretch mb-10"
  }, [_c('div', {
    staticClass: "col-md-4 col-sm-6"
  }, [_c('q-select', {
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "options": _vm.localityOptions,
      "label": _vm.trans('label.locality'),
      "emit-value": "",
      "map-options": "",
      "use-input": "",
      "input-debounce": "0"
    },
    on: {
      "filter": _vm.filterFn
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('q-icon', {
          attrs: {
            "name": "place"
          }
        })];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "name": "close"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              _vm.form.locality = _vm.localityOptions[0].value;
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.locality,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "locality", $$v);
      },
      expression: "form.locality"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-md-4 col-sm-6"
  }, [_c('q-select', {
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "options": _vm.yearsOptions,
      "label": _vm.trans('queue.select_year'),
      "emit-value": "",
      "map-options": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('q-icon', {
          attrs: {
            "name": "place"
          }
        })];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "name": "close"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              _vm.form.year = _vm.yearsOptions[0].value;
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.year,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "year", $$v);
      },
      expression: "form.year"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-md-4 col-sm-6"
  }, [_c('q-select', {
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "options": _vm.statusOptions,
      "label": _vm.trans('label.status'),
      "emit-value": "",
      "map-options": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('q-icon', {
          attrs: {
            "name": "place"
          }
        })];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "name": "close"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              _vm.form.status = _vm.statusOptions[0].value;
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "row align-items-stretch mb-10 justify-content-end"
  }, [_c('div', {
    staticClass: "col-sm-auto mb-10"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "no-caps": "",
      "label": _vm.trans('button.flash_filter'),
      "color": "orange",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.clearFilters
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "color": "green",
      "no-caps": "",
      "label": _vm.trans('button.enter'),
      "loading": _vm.loading
    },
    on: {
      "click": _vm.submit
    }
  })], 1)]), _vm._v(" "), _vm.noResult ? _c('v-no-results', {
    attrs: {
      "image": "search",
      "title": _vm.trans('warning_text.no_result_data_title'),
      "text": _vm.trans('warning_text.no_result_data_text')
    }
  }) : !_vm.noResult && _vm.statements.length !== 0 ? _c('div', [_c('div', {
    staticClass: "row align-items-stretch mb-10"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('div', {
    staticClass: "mb-10"
  }, [_c('div', {
    staticClass: "mb-15"
  }, [_vm._v("\n            " + _vm._s(_vm.trans('label.total')) + ": " + _vm._s(_vm.statements.length) + "\n          ")]), _vm._v(" "), _vm._l(_vm.bystatus, function (item, indx) {
    return _c('div', {
      key: indx,
      staticClass: "mb-15"
    }, [_vm._v("\n            " + _vm._s(indx) + ": " + _vm._s(item.length) + "\n          ")]);
  }), _vm._v(" "), _c('div', {
    staticClass: "mb-15"
  }, [_vm._v("\n            " + _vm._s(_vm.trans('menu.accepted_kids_portal')) + ": " + _vm._s(_vm.statistic.byportal) + "\n          ")]), _vm._v(" "), _c('div', {
    staticClass: "mb-15"
  }, [_vm._v("\n            " + _vm._s(_vm.trans('label.from_import')) + ": " + _vm._s(_vm.statistic.byimport) + "\n          ")])], 2)])]), _vm._v(" "), _c('div', {
    staticClass: "row align-items-stretch mb-10  justify-content-end"
  }, [_c('div', {
    staticClass: "col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "color": "green",
      "no-caps": "",
      "label": _vm.trans('button.export'),
      "loading": _vm.loading
    },
    on: {
      "click": _vm.exportu
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "row align-items-stretch mb-10  justify-content-end"
  }, [_c('div', {
    staticClass: "col-sm-auto"
  }, [_c('q-input', {
    attrs: {
      "dense": "",
      "debounce": "300",
      "placeholder": _vm.trans('placeholder.search')
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }, [_c('q-icon', {
    attrs: {
      "slot": "append",
      "name": "search"
    },
    slot: "append"
  })], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "q-ml-md s-pos-float-right mt-10",
    attrs: {
      "squire": "",
      "unelevated": "",
      "icon": "fullscreen",
      "color": "grey"
    },
    on: {
      "click": function click($event) {
        _vm.table.inFullscreen = true;
      }
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.fullscreen_on')))])], 1)], 1)]), _vm._v(" "), _c('div', [_c('q-table', {
    attrs: {
      "data": _vm.statements,
      "pagination": _vm.table.pagination,
      "columns": _vm.table.columns,
      "row-key": "index + 1",
      "fullscreen": _vm.table.inFullscreen,
      "loading": _vm.loading,
      "filter": _vm.filter
    },
    on: {
      "update:pagination": function updatePagination($event) {
        return _vm.$set(_vm.table, "pagination", $event);
      }
    },
    scopedSlots: _vm._u([_vm.table.inFullscreen ? {
      key: "top",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "row align-items-stretch justify-content-end s-w-100"
        }, [_c('div', {
          staticClass: "col-sm-auto"
        }, [_c('q-input', {
          attrs: {
            "dense": "",
            "debounce": "300",
            "placeholder": _vm.trans('placeholder.search')
          },
          model: {
            value: _vm.filter,
            callback: function callback($$v) {
              _vm.filter = $$v;
            },
            expression: "filter"
          }
        }, [_c('q-icon', {
          attrs: {
            "slot": "append",
            "name": "search"
          },
          slot: "append"
        })], 1)], 1), _vm._v(" "), _c('div', {
          staticClass: "col-sm-auto"
        }, [_c('q-btn', {
          staticClass: "q-ml-md s-pos-float-right mt-10",
          attrs: {
            "squire": "",
            "unelevated": "",
            "icon": "fullscreen",
            "color": "blue"
          },
          on: {
            "click": function click($event) {
              _vm.table.inFullscreen = false;
            }
          }
        }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.fullscreen_off')))])], 1)], 1)])];
      }
    } : null], null, true)
  })], 1)]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }