import {
  QInput,
  QBtn,
  QForm,
  QIcon,
  QField,
  QOptionGroup,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import { Loading } from '@quasar/plugins';
import Validation from '@vjs/mixins/Validation';
import VFormInform from '@vjs/components/VFormInform';
import _ from 'lodash';
import SelectUserFile from '@vjs/balabaqsha/components/SelectUserFile';

export default {
  name: 'VStatementContractConcludeForm',
  mixins: [Validation],
  components: {
    QInput,
    QBtn,
    QForm,
    QIcon,
    QField,
    QOptionGroup,
    VFormInform,
    SelectUserFile,
  },
  props: {
    apiConfig: {
      type: Object,
      default: () => ({
        backButtonUrl: '',
        submitUrl: '',
        filesApiUrl: '',
        getData: '',
        staticUrl: '',
      }),
    },
    additionalConfig: {
      type: Object,
      default: () => ({
        statementExpiredDate: null,
        editTriesLeft: null,
      }),
    },
    contractName: String,
    editFiles: Boolean,
    forCorrection: Boolean,
    comment: String,
  },
  data() {
    const files = {
      child_birth_document: [],
      child_medical_card: [],
      photo_3x4: null,
      parent_identity_card: null,
    };
    if (this.forCorrection) {
      files.pmpk_document = [];
    }

    return {
      files,

      form: {
        child_name: null,
        child_iin: null,
        child_born_date: null,
        child_sex: null,

        issuer_name: null,
        issuer_iin: null,
        issuer_passport: null,
        issuer_address: null,
        issuer_phone: null,
        issuer_work_place: null,
        issuer_work_position: null,
      },

      inputList: [
        'child_name',
        'child_iin',
        'child_born_date',
        'issuer_name',
        'issuer_iin',
        'issuer_passport',
        'issuer_address',
        'issuer_phone',
        'issuer_work_place',
        'issuer_work_position',
      ],

      disable: {
        issuer_passport: false,
        issuer_address: false,
        issuer_phone: false,
        issuer_work_place: false,
        issuer_work_position: false,
      },
      disableFiles: {
        child_birth_document: this.editFiles,
        child_medical_card: this.editFiles,
        photo_3x4: this.editFiles,
        parent_identity_card: this.editFiles,
        pmpk_document: this.editFiles,
      },

      modalFiles: false,
      activeFile: null,
      qLoading: Loading,
    };
  },

  computed: {
    triesLeftInform() {
      switch (this.additionalConfig.editTriesLeft) {
        case 1: return this.trans('warning_text.statement_contract_parent_conclude_one_try_left');
        case 0: return this.trans('warning_text.statement_contract_parent_conclude_last_try_left');
        default: return '';
      }
    },
    _() {
      return _;
    },
    filesDefaultValue() {
      if (this.activeFile) {
        const isMultiple = this.getMaxFiles(this.activeFile) > 1;
        if (isMultiple) {
          return this.files[this.activeFile];
        }
        return this.files[this.activeFile]?.id;
      }
      return undefined;
    },
    genderOptions() {
      return [
        {
          value: 'm',
          label: this.trans('label.male'),
        },
        {
          value: 'w',
          label: this.trans('label.female'),
        },
      ];
    },
    validateFiles() {
      const files = _.cloneDeep(this.files);
      return !this.hasValidationFail('child_birth_document') && files.child_birth_document.length !== 0
        && !this.hasValidationFail('photo_3x4') && files.photo_3x4?.id !== null
        && !this.hasValidationFail('child_medical_card') && files.child_medical_card.length !== 0
        && !this.hasValidationFail('parent_identity_card') && files.parent_identity_card?.id !== null
        && (this.forCorrection ? !this.hasValidationFail('pmpk_document') && files.pmpk_document.length !== 0 : true);
    },
    validate() {
      if (this.editFiles) {
        return this.validateFiles;
      }
      return this.validateFiles
      && this.form.issuer_passport !== null
      && this.form.issuer_address !== null
      && this.form.issuer_phone !== null
      && this.form.issuer_work_place !== null
      && this.form.issuer_work_position !== null;
    },
  },

  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'statement',
      'warning_text',
    ]);
    await this.requestGetData();
  },

  methods: {
    getFileName(fileType) {
      const isMultiple = this.getMaxFiles(fileType) > 1;
      if (_.isEmpty(this.files[fileType])) {
        return this.trans('label.file_not_chosen');
      }
      if (isMultiple) {
        return this.trans('label.files_downloaded', { count: this.files[fileType].length });
      }
      return this.files[fileType].name;
    },
    clearFile(fileType) {
      const isMultiple = this.getMaxFiles(fileType) > 1;
      if (isMultiple) {
        this.files[fileType] = [];
      } else {
        this.files[fileType] = null;
      }
    },
    selectFile(file) {
      if (this.hasValidationFail(this.activeFile)) {
        if (!_.isEqual(file, this.files[this.activeFile])) {
          this.flashValidationFail(this.activeFile);
        } else {
          this.$notify({
            text: this.trans('notice.file_already_selected'),
            type: 'error',
          });
        }
      }

      this.files[this.activeFile] = file;
      this.modalFiles = false;
      this.activeFile = null;
    },
    fileModalClose() {
      this.activeFile = null;
      this.modalFiles = false;
    },
    triggerFile(fileType) {
      this.activeFile = fileType;
      this.modalFiles = true;
    },
    isInputDisabled(name) {
      if (this.disable.hasOwnProperty(name)) {
        return this.disable[name];
      }
      return true;
    },
    getMaxFiles(fileType) {
      switch (fileType) {
        case 'photo_3x4': return 1;
        case 'child_medical_card': return 4;
        default: return 2;
      }
    },
    async requestGetData() {
      this.qLoading.show({ message: this.trans('label.wait_for_load_data') });
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.getData,
      });

      if (!res.error) {
        const { data } = res;
        this.form = { ...this.form, ...data.data };
        if (data.disabled && !_.isEmpty(data.disabled)) {
          _.forEach(this.disable, (value, name) => {
            this.disable[name] = data.disabled[name] ?? false;
          });
        }

        this.files = { ...this.files, ...data.files };
        if (this.editFiles && !_.isEmpty(data.errors)) {
          _.forEach(data.errors, (name) => {
            this.addValidationError(name, [true]);
            this.disableFiles[name] = false;
          });
        }
      }
      this.qLoading.hide();
    },

    async requestAction() {
      this.qLoading.show({ message: this.trans('label.wait_for_load_data') });
      const files = {};
      _.forEach(this.files, (file, fileType) => {
        const isMultiple = this.getMaxFiles(fileType) > 1;
        if (isMultiple) {
          files[fileType] = file;
        } else {
          files[fileType] = file?.id;
        }
      });
      const data = { ...this.form, ...files };

      const res = await requestWrapper.call(this, {
        url: this.apiConfig.submitUrl,
        method: 'post',
        data,
      });

      if (res.error) {
        this.qLoading.hide();
      }
    },
  },
};
