var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sn-contacts-wrapper"
  }, [_c('div', {
    staticClass: "sn-contacts__form"
  }, [_c('q-form', {
    on: {
      "submit": function submit($event) {
        return _vm.requestAction();
      }
    }
  }, [_c('div', {
    staticClass: "sn-form__row"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.name,
      expression: "name"
    }],
    staticClass: "sn-contacts-form__input",
    attrs: {
      "maxlength": "50",
      "type": "text",
      "placeholder": _vm.trans('feedback.your_name')
    },
    domProps: {
      "value": _vm.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.name = $event.target.value;
      }
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "sn-form__row"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.phone_number,
      expression: "phone_number"
    }, {
      name: "mask",
      rawName: "v-mask",
      value: '+7 (###) ###-##-##',
      expression: "'+7 (###) ###-##-##'"
    }],
    staticClass: "sn-contacts-form__input",
    attrs: {
      "type": "text",
      "placeholder": "+7 (___) ___-__-__"
    },
    domProps: {
      "value": _vm.phone_number
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.phone_number = $event.target.value;
      }
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "sn-form__row"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.email,
      expression: "email"
    }],
    staticClass: "sn-contacts-form__input",
    attrs: {
      "maxlength": "50",
      "type": "text",
      "placeholder": _vm.trans('feedback.email')
    },
    domProps: {
      "value": _vm.email
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.email = $event.target.value;
      }
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "sn-form__row"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.message,
      expression: "message"
    }],
    staticClass: "sn-contacts-form__textarea",
    attrs: {
      "maxlength": "3000",
      "placeholder": _vm.trans('feedback.message')
    },
    domProps: {
      "value": _vm.message
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.message = $event.target.value;
      }
    }
  })]), _vm._v(" "), _vm.isWarningEnabled ? _c('div', {
    staticClass: "sn-contact-warning"
  }, [_c('span', {
    staticClass: "sn-contact-warning-message s-str-ico s-str-ico--thm-info-alt s-str-ico--wider"
  }, [_vm._v("\n          " + _vm._s(this.warningMessage) + "\n        ")])]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-end",
    staticStyle: {
      "margin-top": "3rem"
    }
  }, [_c('div', {
    staticClass: "col-12 col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "color": "green",
      "outline": "",
      "width": "20",
      "type": "submit"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.trans('feedback.send')) + "\n          ")])], 1)])])], 1), _vm._v(" "), _c('div', {
    staticClass: "sn-contacts__info"
  }, [_c('div', {
    staticClass: "sn-contacts__info-wrapper"
  }, [_c('div', {
    staticClass: "sn-contacts__info-label"
  }, [_vm._v("\n        " + _vm._s(_vm.trans('feedback.address')) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "sn-contacts__info-text"
  }, [_vm._v("\n        " + _vm._s(_vm.trans('feedback.location')) + "\n      ")])]), _vm._v(" "), _c('div', {
    staticClass: "sn-contacts__info-wrapper"
  }, [_c('div', {
    staticClass: "sn-contacts__info-label"
  }, [_vm._v("\n        " + _vm._s(_vm.trans('feedback.phone')) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "sn-contacts__info-text"
  }, [_vm._v("\n        " + _vm._s(_vm.trans('feedback.phone_number1'))), _c('br'), _vm._v(_vm._s(_vm.trans('feedback.phone_number2')) + "\n      ")])]), _vm._v(" "), _c('div', {
    staticClass: "sn-contacts__info-wrapper"
  }, [_c('div', {
    staticClass: "sn-contacts__info-label"
  }, [_vm._v("\n        " + _vm._s(_vm.trans('feedback.social')) + "\n      ")]), _vm._v(" "), _vm._m(0)])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "sn-contacts__info-social"
  }, [_c('li', {
    staticClass: "sn-contacts__info-social--instagram"
  }, [_c('a', {
    staticClass: "sn-contacts__info-text",
    attrs: {
      "href": "https://www.instagram.com/smartnation_kz/?hl=ru",
      "target": "_blank"
    }
  }, [_vm._v("@SmartNation_kz")])]), _vm._v(" "), _c('li', {
    staticClass: "sn-contacts__info-social--facebook"
  }, [_c('a', {
    staticClass: "sn-contacts__info-text",
    attrs: {
      "href": "https://www.facebook.com/SmartNationkz/",
      "target": "_blank"
    }
  }, [_vm._v("@SmartNation_kz")])]), _vm._v(" "), _c('li', {
    staticClass: "sn-contacts__info-social--whatsapp"
  }, [_c('a', {
    staticClass: "sn-contacts__info-text",
    attrs: {
      "href": "https://wa.me/+77003397222",
      "target": "_blank"
    }
  }, [_vm._v("+77003397222")])])]);
}]

export { render, staticRenderFns }