<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <q-input
          v-model="child_full_name"
          :label="trans('statement-refuse.table.fio')"
          @input="changeFilterField"
        >
          <q-icon
            slot="prepend"
            name="search"
          />
        </q-input>
      </div>
      <div class="col-md-3">
        <q-input
          v-model="school_name"
          :label="trans('statement-refuse.table.school')"
          @input="changeFilterField"
        >
          <q-icon
            slot="prepend"
            name="search"
          />
        </q-input>
      </div>
      <div class="col-md-3">
        <!--suppress JSUnresolvedVariable -->
        <q-select
          v-model="commonStatus"
          :label="trans('statement-refuse.table.status')"
          :options="getStatusList"
        />
      </div>
      <div class="col-md-3">
        <!--suppress JSUnresolvedVariable -->
        <q-select
          v-model="statementStatus"
          :label="trans('statement-refuse.table.type-refuse')"
          :options="getStatementStatusList"
        />
      </div>
    </div>
    <q-table
      :data="statements"
      :columns="columns"
      table-class="adaptive-table"
      row-key="id"
      :loading="loading"
      :pagination.sync="pagination"
      :rows-per-page="pagination.rowsPerPage"
      flat
      binary-state-sort
      @request="onRequest"
    >
      <template v-slot:header="props">
        <q-tr :props="props">
          <q-th
            v-for="col in props.cols"
            :key="col.name"
            :props="props"
            class="refuse-statement-th"
          >
            {{ col.label }}
          </q-th>
        </q-tr>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td
            key="number"
            :props="{thClass:'sn-sortable sn--text-center', ...props }"
            class="refuse-statement-td"
          >
            {{ props.row.number }}
          </q-td>
          <!--suppress JSUnresolvedVariable -->
          <q-td
            key="child_full_name"
            :props="props"
            class="refuse-statement-td"
          >
            {{ props.row.child_full_name }}
          </q-td>
          <!--suppress JSUnresolvedVariable -->
          <q-td
            key="school_name"
            :props="props"
            class="refuse-statement-td"
          >
            {{ props.row.school_name }}
          </q-td>
          <!--suppress JSUnresolvedVariable -->
          <q-td
            key="created_at"
            :props="props"
            class="refuse-statement-td"
          >
            {{ props.row.created_at }}
          </q-td>
          <!--suppress JSUnresolvedVariable -->
          <q-td
            key="expired_at"
            :props="props"
            class="refuse-statement-td"
          >
            {{ props.row.expired_at }}
          </q-td>
          <!--suppress JSUnresolvedVariable -->
          <q-td
            key="statement_status"
            :props="props"
            class="refuse-statement-td"
          >
            {{ getStatementStatus(props.row.statement_status).label }}
          </q-td>
          <!--suppress JSUnresolvedVariable -->
          <q-td
            key="status"
            :props="props"
            class="refuse-statement-td"
          >
            {{ getStatus(props.row.status).label }}
          </q-td>
          <q-td
            v-if="statementsType === 'disease'"
            class="refuse-statement-td"
          >
            {{ props.row.correction_type }}
          </q-td>
          <q-td
            v-if="statementsType === 'sanatorium'"
            class="refuse-statement-td"
          >
            {{ props.row.sanatorium_type }}
          </q-td>
          <q-td
            key="id"
            :props="props"
            class="refuse-statement-td"
          >
            <a
              :href="`${viewUri}/${props.row.id}`"
              class="refuse-btn-container"
            >
              <q-btn
                squire
                icon="eye"
              >
                <q-tooltip>{{ trans('statement-refuse.table.show') }}</q-tooltip>
              </q-btn>
            </a>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
// noinspection NpmUsedModulesInstalled
import {
  QTable,
  QTr,
  QTd,
  QTh,
  QBtn,
  QInput,
  QIcon,
  QSelect,
  QTooltip,
} from '@quasar/components';

// noinspection NpmUsedModulesInstalled
import {
  STATEMENT_REFUSE_TRANSLATIONS, FALLBACK_LOCALE,
} from '@vjs/balabaqsha/consts';
import _ from 'lodash';
import Localization from '../Mixins/QuasarLocale';

export default {
  name: 'VRefuseStatementsList',
  components: {
    QTable,
    QTr,
    QTd,
    QTh,
    QInput,
    QIcon,
    QSelect,
    QBtn,
    QTooltip,
  },
  mixins: [Localization],
  props: {
    apiFetchUri: {
      type: String,
      default: () => '',
    },
    viewUri: {
      type: String,
      default: () => '',
    },
    statementStatusList: {
      type: Object,
      default: () => {
      },
    },
    statusList: {
      type: Object,
      default: () => {
      },
    },
    locale: {
      type: String,
      default: () => FALLBACK_LOCALE,
    },
    statementsType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      statements: [],
      loading: false,
      pagination: {
        sortBy: null,
        descending: null,
        page: null,
        rowsPerPage: null,
        rowsNumber: null,
      },
      paginationNew: {
        sortBy: null,
        descending: null,
        page: null,
        rowsPerPage: null,
        rowsNumber: null,
      },
      child_full_name: null,
      school_name: null,
      status: null,
      statement_status: null,
    };
  },
  computed: {
    statementStatus: {
      get() {
        return this.getStatementStatus(this.statement_status);
      },
      set(item) {
        this.statement_status = item.value;
        this.fetchStatements();
      },
    },
    commonStatus: {
      get() {
        return this.getStatus(this.status);
      },
      set(item) {
        this.status = item.value;
        this.fetchStatements();
      },
    },
    columns() {
      const { $trans: { get: trans } } = this;
      const columns = [
        {
          name: 'number',
          field: 'number',
          label: '№',
        },
        {
          name: 'child_full_name',
          field: 'child_full_name',
          label: trans('statement-refuse.table.fio'),
        },
        {
          name: 'school_name',
          field: 'school_name',
          label: trans('statement-refuse.table.school'),
        },
        {
          name: 'created_at',
          field: 'created_at',
          label: trans('statement-refuse.table.refuse-date'),
          sortable: true,
        },
        {
          name: 'expired_at',
          field: 'expired_at',
          label: trans('statement-refuse.table.expire-date'),
          sortable: true,
        },
        {
          name: 'statement_status',
          field: 'statement_status',
          label: trans('statement-refuse.table.type-refuse'),
          sortable: true,
        },
        {
          name: 'status',
          field: 'status',
          label: trans('statement-refuse.table.status'),
          sortable: true,
        },
      ];
      if (this.statementsType === 'disease') {
        columns.push(
          {
            name: 'correction_type',
            field: 'correction_type',
            label: trans('statement-refuse.table.correction-type'),
          },
        );
      }
      if (this.statementsType === 'sanatorium') {
        columns.push(
          {
            name: 'sanatorium_type',
            field: 'sanatorium_type',
            label: trans('statement-refuse.table.sanatorium-type'),
          },
        );
      }
      columns.push(
        {
          name: 'id',
          field: 'id',
          label: trans('statement-refuse.table.act'),
        },
      );
      return columns;
    },
    getStatementStatusList() {
      const { statementStatusList } = this;
      const { $trans: { get: trans } } = this;
      return [
        {
          value: null,
          label: trans('statement-refuse.unselected'),
        },
        ...Object.keys(statementStatusList)
          .map((index) => {
            const name = statementStatusList[index];
            return {
              value: parseInt(index, 10),
              label: name,
            };
          }),
      ];
    },
    getStatusList() {
      const { statusList } = this;
      const { $trans: { get: trans } } = this;
      return [
        {
          value: null,
          label: trans('statement-refuse.unselected'),
        },
        ...Object.keys(statusList)
          .map((index) => {
            const name = statusList[index];
            return {
              value: parseInt(index, 10),
              label: name,
            };
          }),
      ];
    },
  },
  beforeCreate() {
    this.$trans.add(STATEMENT_REFUSE_TRANSLATIONS);
  },
  beforeMount() {
    this.initLocale();
    this.fetchStatements();
  },
  methods: {
    ignoreColumn(column) {
      return ['statement_id', 'created_at', 'expired_at'].indexOf(column) !== -1;
    },
    async fetchStatements() {
      this.loading = true;
      const {
        paginationNew,
        // eslint-disable-next-line camelcase
        child_full_name,
        // eslint-disable-next-line camelcase
        school_name,
        // eslint-disable-next-line camelcase
        status,
        // eslint-disable-next-line camelcase
        statement_status,
      } = this;
      const response = await this.$axios.get(this.apiFetchUri, {
        params: {
          pagination: paginationNew,
          filter: {
            child_full_name,
            school_name,
            status,
            statement_status,
          },
        },
        headers: { 'X-localization': this.locale },
      });
      if (
        response.status === 200
          && response.data
      ) {
        const { data } = response;
        if (data.statements) {
          this.statements = data.statements;
        }
        if (data.pagination) {
          this.pagination = data.pagination;
        }
        if (data.filter) {
          this.child_full_name = data.filter.child_full_name;
          this.school_name = data.filter.school_name;
          this.status = data.filter.status;
          this.statement_status = data.filter.statement_status;
        }
      }
      this.loading = false;
    },
    async onRequest(request) {
      if (!this.loading) {
        const { pagination } = request;
        this.paginationNew = pagination;
        await this.fetchStatements();
      }
    },
    getStatementStatus(status) {
      const statuses = this.getStatementStatusList;
      const found = _.findIndex(
        statuses,
        item => parseInt(item.value, 10)
            === parseInt(status, 10) || (status === null && item.value === null),
      );
      return statuses[found];
    },
    getStatus(status) {
      const statuses = this.getStatusList;
      const found = _.findIndex(
        statuses,
        item => parseInt(item.value, 10)
            === parseInt(status, 10) || (status === null && item.value === null),
      );
      return statuses[found];
    },
    changeFilterField() {
      this.fetchStatements();
    },
  },
};
</script>

<style scoped lang="scss">
  .refuse-statement-th {
    text-align: center;
  }
  .refuse-statement-td {
    text-align: center;
  }
  .refuse-btn-container {
    color: #030303;
    text-decoration: none;
  }
  .refuse-statement-td,
  .refuse-statement-th {
    max-width: 10em;
    word-wrap: break-word;
    white-space: normal;
    text-align: center;
  }
</style>
