import {
  QInput,
  QBtn,
  QPagination,
} from '@quasar/components';
import { Loading } from '@quasar/plugins';
import VNoResults from '@vjs/components/VNoResults';
import MXListView from '@vjs/mixins/MXListView';
import VDirectionsFilters from './VDirectionsFilters';
import VDirectionsTable from './VDirectionsTable';

import { scrollTop } from '../../helpers';

export default {
  name: 'VCatalogListView',
  mixins: [MXListView],
  components: {
    QInput,
    QBtn,
    QPagination,
    VDirectionsTable,
    VDirectionsFilters,
    VNoResults,
  },
  props: {
    uriGetOrganizations: {
      type: String,
      required: true,
    },
    uriGetDirections: {
      type: String,
      required: true,
    },
    uriFilters: {
      type: String,
      required: true,
    },
    uriGetYears: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchValue: '',
      debounceTimeout: null,
      qLoading: Loading,
    };
  },
  watch: {
    // Сбрасывает отфильтрованный список при удалении строки в поиске
    searchValue(val) {
      if (val === '') {
        this.itemsFiltered = this.items;
      }
    },
  },
  async mounted() {
    await this.$trans.add(['queue', 'button', 'warning_text', 'table', 'directions', 'queue', 'fields', 'label', 'bulletin']);
  },
  computed: {
    // Отдаёт кол-во очереди на страницу
    paginate() {
      const current = (this.currentPage - 1) * this.countByPage;
      return this.itemsFiltered.slice(current, current + this.countByPage);
    },
    // Считает кол-во страниц в пагинации по выставленным в data параметрам
    countOfPages() {
      let count = parseInt(this.itemsFiltered.length / this.countByPage, 10);
      const remains = this.itemsFiltered.length % this.countByPage;
      count += remains !== 0 ? 1 : 0;
      this.currentPage = 1;
      return count <= 1 ? 0 : count;
    },
  },
  methods: {
    handleFilterLoading(val = false) {
      this.loading = val;
      if (val) {
        this.qLoading.show({ message: this.trans('label.wait_loading') });
      } else {
        this.qLoading.hide();
      }
    },
    // Принимает и обрабатывает список от фильтра
    handleFilteredList(arr) {
      this.items = arr.data.items || [];
      this.itemsFiltered = this.items;
      this.searchValue = '';
    },
    // Запускается на событие @input в q-pagination
    async changePagination() {
      // Перевод страницы к началу при выборе страницы
      scrollTop(
        window,
        window.scrollY,
        window.scrollY - Math.abs(this.$refs.catalog.getBoundingClientRect().top),
        500,
      );
    },
  },
};
