var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-statement-create__item-inner"
  }, [_c('div', {
    staticClass: "s-congratulations__title"
  }, [_vm._v("\n    " + _vm._s(_vm.trans('notifications.congratulations')) + "\n  ")]), _vm._v(" "), _c('div', {
    staticClass: "s-congratulations__image"
  }, [_c('img', {
    attrs: {
      "src": "".concat(_vm.uriStatic, "/images/congratulations_image.svg")
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "s-congratulations__message mb-30"
  }, [_vm._v("\n    " + _vm._s(_vm.trans('notifications.statement_is_done')) + "\n  ")]), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-btn', {
    attrs: {
      "to": _vm.linkStatements,
      "type": "a",
      "color": "blue",
      "label": _vm.trans('button.goto_statement')
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }