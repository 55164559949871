var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-dialog', {
    attrs: {
      "value": _vm.modal
    },
    on: {
      "input": _vm.handleModal
    }
  }, [_c('v-modal', {
    attrs: {
      "title": _vm.trans('notice.confirm'),
      "size": "medium"
    }
  }, [_c('div', {
    staticClass: "s-modal__body"
  }, [_c('div', {
    staticClass: "mb-30"
  }, [_vm._v("\n        " + _vm._s(_vm.text) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "row-sort-between-btn"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-200",
    attrs: {
      "label": _vm.trans('button.cancel'),
      "color": "grey",
      "type": "button"
    },
    on: {
      "click": _vm.handleModal
    }
  }), _vm._v(" "), _c('q-btn', {
    staticClass: "s-btn--w-200",
    attrs: {
      "label": _vm.trans('button.approve'),
      "color": "green",
      "type": "button"
    },
    on: {
      "click": _vm.send
    }
  })], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }