import {
  QForm,
  QInput,
  QBtn,
} from '@quasar/components';
import { required } from '@vjs/validations';

export default {
  components: {
    QForm,
    QInput,
    QBtn,
  },
  props: {
    links: {
      type: Object,
      default: () => {},
    },
    login: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: String,
      default: '',
    },
  },
  data() {
    const trans = this.$store.getters['translator/trans'];
    return {
      errorMessages: JSON.parse(this.errors),
      fields: [
        {
          name: 'login',
          value: this.login.value,
          label: this.login.label,
          placeholder: this.login.placeholder,
          rules: [
            required(trans('validation.required', {
              attribute: this.login.label,
            })),
          ],
        },
        {
          type: 'password',
          name: 'password',
          value: '',
          rules: [
            required(trans('validation.required', {
              attribute: trans('auth.password'),
            })),
          ],
        },
      ],
      token: '',
    };
  },
  beforeCreate() {
    this.$trans.add(['auth', 'validation']);
  },
  mounted() {
    this.$nextTick(this.notifyErrors);
    this.token = window.core_project.csrfToken;
  },
  methods: {
    submit(arg) {
      arg.target.method = 'post';
      arg.target.action = this.links.action;
      arg.target.submit();
    },
  },
};
