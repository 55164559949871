var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-form__photo",
    class: _vm.classlist,
    style: !!_vm.submitValue ? "background-image: url(".concat(_vm.submitValue, ");") : 'background-size: 90%;'
  }, [_c('input', {
    ref: "input",
    attrs: {
      "id": "".concat(_vm.name, "-input"),
      "type": "file",
      "accept": "image/jpeg,image/png,image/gif",
      "name": _vm.name,
      "disabled": _vm.disabled
    },
    on: {
      "change": _vm.setPhoto
    }
  }), _vm._v(" "), !_vm.hideButton ? _c('button', {
    staticClass: "s-btn s-btn--thm-aqua s-form__photo-button",
    class: {
      's-process': _vm.loading
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.openDialog
    }
  }, [_c('span', {
    staticClass: "s-str-ico s-str-ico--thm-plus"
  }, [_vm._v("\n      " + _vm._s(_vm.trans('cabinet.change_avatar')) + "\n    ")])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }