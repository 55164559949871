<template>
  <div class="s-vue-modal__body" v-html="text" />
</template>

<script>
export default {
  name: 'Offer',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
};
</script>
