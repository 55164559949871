var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-form__reset-password-wrapper mb-40"
  }, [_c('q-form', {
    staticClass: "s-form__reset-password",
    on: {
      "submit": _vm.onSubmit
    }
  }, [_c('transition', {
    attrs: {
      "name": "swipe-x",
      "mode": "out-in"
    }
  }, [_vm.currentStep === 1 ? _c('q-input', {
    key: "step-1",
    attrs: {
      "label": _vm.trans('auth.phone.register_field'),
      "mask": "+7 (###) ###-##-##",
      "type": "tel",
      "fill-mask": "",
      "error": !!_vm.errorMessages.phone,
      "error-message": _vm.errorMessages.phone ? _vm.errorMessages.phone[0] : '',
      "rules": [_vm.required(_vm.trans('validation.required', {
        attribute: _vm.trans('auth.phone.register_field')
      }))]
    },
    on: {
      "input": function input($event) {
        delete _vm.errorMessages.phone;
      }
    },
    model: {
      value: _vm.form.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  }) : _vm._e(), _vm._v(" "), _vm.currentStep === 2 ? _c('div', [_c('q-input', {
    key: "step-2",
    attrs: {
      "label": _vm.trans('auth.sms_code'),
      "mask": "####",
      "autofocus": "",
      "error": !!_vm.errorMessages.code,
      "error-message": _vm.errorMessages.code ? _vm.errorMessages.code[0] : '',
      "rules": [_vm.required(_vm.trans('validation.required', {
        attribute: _vm.trans('auth.sms_code')
      }))]
    },
    on: {
      "input": function input($event) {
        delete _vm.errorMessages.code;
      }
    },
    model: {
      value: _vm.form.code,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  }), _vm._v(" "), _vm.sendCode ? _c('div', {
    staticClass: "form__string"
  }, [_vm.seconds > 0 ? _c('span', {
    staticClass: "form-field__required"
  }, [_vm._v("\n            " + _vm._s(_vm.trans('auth.code_timer')) + " " + _vm._s(_vm.seconds) + "  " + _vm._s(_vm.trans('auth.seconds')) + "\n          ")]) : _c('a', {
    staticClass: "s-medium s-countdown-sms__restart",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.sendCodeAgain($event);
      }
    }
  }, [_vm._v("\n            " + _vm._s(_vm.trans('auth.send_again')) + "\n          ")])]) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.currentStep === 3 ? _c('div', {
    key: "step-3"
  }, [_c('q-input', {
    attrs: {
      "type": "password",
      "label": _vm.trans('label.login_password'),
      "placeholder": _vm.trans('placeholder.new_password'),
      "autofocus": "",
      "error": !!_vm.errorMessages.password,
      "error-message": _vm.errorMessages.password ? _vm.errorMessages.password[0] : '',
      "rules": [_vm.required(_vm.trans('validation.required', {
        attribute: _vm.trans('label.login_password')
      }))]
    },
    on: {
      "input": function input($event) {
        delete _vm.errorMessages.password;
      }
    },
    model: {
      value: _vm.form.password,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "type": "password",
      "label": _vm.trans('label.confirm_the_password'),
      "placeholder": _vm.trans('placeholder.new_password'),
      "error": !!_vm.errorMessages.password_confirmation,
      "error-message": _vm.errorMessages.password_confirmation ? _vm.errorMessages.password_confirmation[0] : '',
      "rules": [_vm.required(_vm.trans('validation.required', {
        attribute: _vm.trans('label.confirm_the_password')
      })), _vm.confirmed(_vm.form.password, _vm.trans('validation.confirmed', {
        attribute: _vm.trans('label.new_password')
      }))]
    },
    on: {
      "input": function input($event) {
        delete _vm.errorMessages.password_confirmation;
      }
    },
    model: {
      value: _vm.form.password_confirmation,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password_confirmation", $$v);
      },
      expression: "form.password_confirmation"
    }
  }), _vm._v(" "), _c('q-input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "hidden": ""
    },
    model: {
      value: _vm.form.code,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _vm.currentStep === 1 ? _c('div', {
    staticClass: "mb-20"
  }, [_vm._v("\n      " + _vm._s(_vm.trans('auth.phone.registration_info_recovery')) + "\n    ")]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "s-group s-group--equal s-group--wider s-group--align-center"
  }, [_c('div', [_c('a', {
    staticClass: "s-medium s-countdown-sms__restart",
    attrs: {
      "href": _vm.dataUrls.redirect
    }
  }, [_vm._v("\n          " + _vm._s(_vm.trans('auth.return_to_login')) + "\n        ")])]), _vm._v(" "), _c('div', [_c('q-btn', {
    staticClass: "sn--full-width",
    attrs: {
      "type": "submit",
      "color": "green",
      "label": _vm.trans('button.send'),
      "loading": _vm.loading
    }
  })], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }