var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-table-with-filters"
  }, [_c('new-table-filters', {
    attrs: {
      "uri-get-filters": _vm.uriGetFilters,
      "uri-get-items": _vm.uriGetItems,
      "uri-export": _vm.uriExport,
      "meta-links": _vm.metaLinks,
      "data-config": _vm.dataConfig,
      "storage-name": _vm.storageName,
      "rows": _vm.rows,
      "force-storage-filters": _vm.forceStorageFilters,
      "alternative-export": _vm.alternativeExport,
      "force-hide-filters": _vm.forceHideFilters,
      "force-custom-search-placeholder": _vm.forceCustomSearchPlaceholder,
      "export-name": _vm.exportName,
      "column-filter": _vm.columnFilter
    },
    on: {
      "toggleLoaders": _vm.toggleLoader,
      "returnItems": _vm.handleGetItemsEvent,
      "returnVisibleCols": function returnVisibleCols($event) {
        _vm.visibleCols = $event;
      }
    }
  }), _vm._v(" "), _vm.cols && _vm.cols.length || _vm.rows && _vm.rows.length || _vm.meta && _vm.meta.length ? _c('new-table', {
    attrs: {
      "cols": _vm.cols,
      "rows": _vm.rows,
      "meta": _vm.meta,
      "pagination-btns": _vm.paginationButtons,
      "adaptive-when": _vm.adaptiveWhen,
      "clickable-rows": _vm.clickableRows,
      "no-result-title": _vm.noResultTitle,
      "no-result-text": _vm.noResultText,
      "separator": _vm.separator,
      "with-no-result-text": _vm.withNoResultText,
      "visible-cols": _vm.visibleCols
    },
    on: {
      "toggleLoaders": _vm.toggleLoader
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }