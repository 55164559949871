import VOrganizationInfo from '@common/views/VOrganizationInfo';
import VOrganizationProfile from '@common/views/VOrganizationProfile';
import NewTableWithFilters from '@common/views/NewTableWithFilters';
import VAdBanners from '@common/components/VAdBanners';
import * as components from './components';
import VPasswordChange from './views/VPasswordChange/VPasswordChange.vue';
import VUserProfileUpdate
  from './views/VUserProfileUpdate/VUserProfileUpdate.vue';
import VProfileSettings from './views/VProfileSettings/VProfileSettings.vue';
import VMonitoringBad
  from './balabaqsha/views/VMonitoringBad/VMonitoringBad';
import FormLogin from './views/FormLogin/FormLogin.vue';
import FormResetPassword from './views/FormResetPassword/FormResetPassword.vue';
import FormPersonnelCreate
  from './views/FormPersonnelCreate/FormPersonnelCreate.vue';
import FormRegistrationSms
  from './views/FormRegistrationSms';
import VFaqSpoiler from './views/VFaqSpoiler/VFaqSpoiler.vue';
import VFaq from './views/VFaq/VFaq.vue';
import Modal from './plugins/ModalWindow/ModalWindow.vue';
import VDocAttach from './views/VDocAttach/VDocAttach.vue';
import VStatisticPanel from './components/VStatisticPanel';
import VBulletinListView from './balabaqsha/views/VBulletinListView';
import VQueueListView from './balabaqsha/views/VQueueListView';
import VStatementCreateView from './balabaqsha/views/VStatementCreateView';
import VReferenceTime from './components/VReferenceTime';
import VFreePlaceCreateForm
  from './balabaqsha/views/VFreePlace/VFreePlaceCreateForm.vue';
import VFreePlaceUpdateForm
  from './balabaqsha/views/VFreePlace/VFreePlaceUpdateForm.vue';
import VOrganizationChildTable
  from './balabaqsha/views/VContingent/VOrganizationChildTable.vue';
import VInsta from './views/VInsta/VInsta.vue';
import WarningModal from './balabaqsha/modals/WarningModal.vue';
import VApplicationListView from './balabaqsha/views/VApplicationListView';
import VDirection from './balabaqsha/components/VDirection';
import VDirectionList from './balabaqsha/views/VDirectionList';
import * as funding from './balabaqsha/views/VFunding';
import * as attendance from './balabaqsha/views/VAttendance';
import * as refuse from './balabaqsha/views/VRefuseStatements';
import VWorkerQueueListView from './balabaqsha/views/VWorkerQueueListView';
import VCatalogListView from './balabaqsha/views/VCatalogListView';
import VPassportPageView from './balabaqsha/views/VPassportPageView';
import VPersonnelMonitoringView
  from './balabaqsha/views/Monitorings/VPersonnelMonitoringView';
import VContingentMonitoringView
  from './balabaqsha/views/Monitorings/VContingentMonitoringView';
import VQueueMonitoringView
  from './balabaqsha/views/Monitorings/VQeueuMonitoringView';
import VDirectionsMonitoringView
  from './balabaqsha/views/Monitorings/VDirectionsMonitoringView';
import VWorkerContingentListView
  from './balabaqsha/views/VWorkerContingentListView';
import VMonitoringList from './balabaqsha/views/Monitorings/VMonitoringList';
import VGovServicesMonitoringView
  from './balabaqsha/views/Monitorings/VGovServicesMonitoringView';
import VFreePlacesRecalledMonitoringView
  from './balabaqsha/views/Monitorings/VFreePlacesRecalledMonitoringView';
import VExchangeApplication from './balabaqsha/views/VExchangeApplication';
import VTabelTableView from './balabaqsha/views/VTabelTableView';
import VFreePlacesMonitoringView
  from './balabaqsha/views/Monitorings/VFreePlacesMonitoringView';
import VEnrolledChildrensByAgeMonitoringView
  from './balabaqsha/views/Monitorings/VEnrolledChildrensByAgeMonitoringView';
import VEnrolledChildrensByAgeMonitoringViewNew
  from './balabaqsha/views/Monitorings/VEnrolledChildrensByAgeMonitoringViewNew';
import VQueueMonitoringByYearView
  from './balabaqsha/views/Monitorings/VQueueMonitoringByYearView';
import VContingentByPeriodMonitoringView
  from './balabaqsha/views/Monitorings/VContingentByPeriodMonitoringView';
import VRegularStatementCreateView
  from './balabaqsha/views/VRegularStatementCreateView';
import VAttendanceOrgList from './balabaqsha/views/VAttendanceOrgList';
import VGroupCreateView from './balabaqsha/views/VGroupCreateView';
import VSelectRoleForm from './balabaqsha/views/VSelectRoleForm';
import VSelectOrganizationForm from './balabaqsha/views/VSelectOrganizationForm';
import VFeedbackForm from './views/VFeedbackForm';
import VMonitoringListArea
  from './balabaqsha/views/Monitorings/VMonitoringListArea';
import VFeedbacksList from './views/VFeedbacksList';
import WarningRolesModal from './balabaqsha/modals/WarningRolesModal.vue';
import VDirectionsListView from './balabaqsha/views/VDirectionsListView';
import VSupportTeamButton from './balabaqsha/views/VSupportTeamButton';
import VStatsCounter from './balabaqsha/views/VStatsCounter';
import VStatementContractCreateForm from './balabaqsha/views/VStatementContractCreateForm';
import VStatementContractShow from './balabaqsha/views/VStatementContractShow';
import VStatementContractConcludeForm from './balabaqsha/views/VStatementContractConcludeForm';
import UserFiles from './balabaqsha/views/UserFiles';

export default {
  ...components,
  ...funding,
  ...attendance,
  ...refuse,
  VSupportTeamButton,
  VPasswordChange,
  VUserProfileUpdate,
  VProfileSettings,
  FormLogin,
  FormResetPassword,
  FormPersonnelCreate,
  FormRegistrationSms,
  VFaqSpoiler,
  VFaq,
  Modal,
  VDocAttach,
  VMonitoringBad,
  VFreePlaceCreateForm,
  VFreePlaceUpdateForm,
  VOrganizationChildTable,
  VInsta,
  WarningModal,
  VApplicationListView,
  VDirectionList,
  VDirection,
  VStatisticPanel,
  VBulletinListView,
  VQueueListView,
  VStatementCreateView,
  VReferenceTime,
  VWorkerQueueListView,
  VCatalogListView,
  VPassportPageView,
  VPersonnelMonitoringView,
  VFreePlacesMonitoringView,
  VContingentByPeriodMonitoringView,
  VContingentMonitoringView,
  VQueueMonitoringView,
  VDirectionsMonitoringView,
  VWorkerContingentListView,
  VMonitoringList,
  VGovServicesMonitoringView,
  VOrganizationInfo,
  VOrganizationProfile,
  ...VExchangeApplication,
  VTabelTableView,
  VRegularStatementCreateView,
  VAttendanceOrgList,
  VGroupCreateView,
  VSelectRoleForm,
  VSelectOrganizationForm,
  VFreePlacesRecalledMonitoringView,
  VEnrolledChildrensByAgeMonitoringView,
  VEnrolledChildrensByAgeMonitoringViewNew,
  VQueueMonitoringByYearView,
  VMonitoringListArea,
  VFeedbackForm,
  WarningRolesModal,
  VDirectionsListView,
  VStatsCounter,
  VFeedbacksList,
  VStatementContractCreateForm,
  VStatementContractShow,
  VStatementContractConcludeForm,
  UserFiles,
  NewTableWithFilters,
  VAdBanners,
};
