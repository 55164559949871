var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-region-select', {
    attrs: {
      "modal": _vm.modalRegionSelect,
      "persistent": "",
      "set-ajax": ""
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.modalRegionSelect = $event;
      },
      "update-locality": _vm.handleUpdateLocality
    }
  }), _vm._v(" "), _c('v-text-modal', {
    attrs: {
      "modal": _vm.modalText,
      "text": _vm.stoplist ? _vm.trans('queue.queue_application_description_stoplist') : _vm.trans('queue.queue_application_description')
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.modalText = $event;
      }
    }
  }), _vm._v(" "), _vm.$q.screen.width < 1009 ? _c('div', {
    staticClass: "row justify-content-end align-items-center s-balabaqsha-catalog__filter-controls mb-20"
  }, [_c('div', {
    staticClass: "col col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "s-w-100 d-flex",
    attrs: {
      "color": "blue",
      "outline": !_vm.filterShow,
      "icon": "menu-alt",
      "label": _vm.trans('button.filter'),
      "label-wider": ""
    },
    on: {
      "click": function click($event) {
        _vm.filterShow = !_vm.filterShow;
      }
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-auto"
  }, [_c('q-btn', {
    attrs: {
      "color": "blue",
      "outline": "",
      "icon": "info-alt",
      "squire": ""
    },
    on: {
      "click": function click($event) {
        _vm.modalText = true;
      }
    }
  })], 1)]) : _vm._e(), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.filterShow || _vm.$q.screen.width > 1008 ? _c('div', {
    staticClass: "s-balabaqsha-catalog__filter"
  }, [_c('div', {
    staticClass: "row align-items-end mb-10"
  }, [_c('div', {
    staticClass: "col-lg-12 col-md-4 col-sm-6"
  }, [_c('q-select', {
    attrs: {
      "label": _vm.trans('queue.select_locality'),
      "options": _vm.localityOptions
    },
    model: {
      value: _vm.localitySelected,
      callback: function callback($$v) {
        _vm.localitySelected = $$v;
      },
      expression: "localitySelected"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-lg-12 col-md-4 col-sm-6"
  }, [_c('q-select', {
    attrs: {
      "label": _vm.trans('queue.select_type'),
      "options": _vm.typeOptions
    },
    scopedSlots: _vm._u([{
      key: "selected",
      fn: function fn() {
        return [_vm._v("\n              " + _vm._s(_vm.trans(_vm.typeSelected && _vm.typeSelected.label)) + "\n            ")];
      },
      proxy: true
    }, {
      key: "option",
      fn: function fn(scope) {
        return [_c('q-item', _vm._g(_vm._b({}, 'q-item', scope.itemProps, false), scope.itemEvents), [_vm._v("\n                " + _vm._s(_vm.trans(scope.opt.label)) + "\n              ")])];
      }
    }], null, false, 3643819166),
    model: {
      value: _vm.typeSelected,
      callback: function callback($$v) {
        _vm.typeSelected = $$v;
      },
      expression: "typeSelected"
    }
  })], 1), _vm._v(" "), _c('q-slide-transition', [_vm.orgOptions.length > 0 ? _c('div', {
    staticClass: "col-lg-12 col-md-4 col-sm-6"
  }, [_c('q-select', {
    attrs: {
      "options": _vm.orgOptions,
      "label": _vm.trans('label.choose_organization')
    },
    model: {
      value: _vm.orgSelected,
      callback: function callback($$v) {
        _vm.orgSelected = $$v;
      },
      expression: "orgSelected"
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "col-lg-12 col-md-4 col-sm-6",
    class: _vm.isCorrection || _vm.isSanatoria ? 'mb-10' : ''
  }, [_c('q-select', {
    attrs: {
      "options": _vm.yearOptions,
      "label": _vm.trans('queue.select_year')
    },
    model: {
      value: _vm.yearSelected,
      callback: function callback($$v) {
        _vm.yearSelected = $$v;
      },
      expression: "yearSelected"
    }
  })], 1), _vm._v(" "), _c('q-slide-transition', [_vm.isCorrection ? _c('div', {
    staticClass: "col-lg-12 col-md-4 col-sm-6 mb-10"
  }, [_c('div', {
    staticClass: "s-form-filter__spoiler-head",
    on: {
      "click": function click($event) {
        _vm.diseasesSpoiler = !_vm.diseasesSpoiler;
      }
    }
  }, [_c('h5', {
    staticClass: "s-combine-title"
  }, [_c('span', {
    staticClass: "s-combine-title__label s-upper"
  }, [_vm._v("\n                  " + _vm._s(_vm.trans('bulletin.diseases')) + "\n                ")]), _vm._v(" "), _c('span', {
    staticClass: "s-w-100 s-to-right"
  }, [_c('button', {
    staticClass: "s-combine-title__button"
  }, [_c('span', {
    staticClass: "s-str-ico s-str-ico--wider",
    class: _vm.diseasesSpoiler ? 's-str-ico--thm-angle-up' : 's-str-ico--thm-angle-down',
    staticStyle: {
      "vertical-align": "middle"
    }
  })])])])]), _vm._v(" "), _c('q-slide-transition', [_vm.diseasesSpoiler ? _c('q-field', {
    attrs: {
      "borderless": ""
    }
  }, [_c('div', {
    staticClass: "d-inline-flex flex-column"
  }, [_c('q-option-group', {
    attrs: {
      "options": _vm.diseases,
      "label": _vm.trans('bulletin.diseases'),
      "type": "radio"
    },
    model: {
      value: _vm.disease,
      callback: function callback($$v) {
        _vm.disease = $$v;
      },
      expression: "disease"
    }
  })], 1)]) : _vm._e()], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isSanatoria ? _c('div', {
    staticClass: "col-lg-12 col-md-4 col-sm-6 mb-10"
  }, [_c('div', {
    staticClass: "s-form-filter__spoiler-head",
    on: {
      "click": function click($event) {
        _vm.sanatoriaSpoiler = !_vm.sanatoriaSpoiler;
      }
    }
  }, [_c('h5', {
    staticClass: "s-combine-title"
  }, [_c('span', {
    staticClass: "s-combine-title__label s-upper"
  }, [_vm._v("\n                  " + _vm._s(_vm.trans('bulletin.sanatorium-type')) + "\n                ")]), _vm._v(" "), _c('span', {
    staticClass: "s-w-100 s-to-right"
  }, [_c('button', {
    staticClass: "s-combine-title__button"
  }, [_c('span', {
    staticClass: "s-str-ico s-str-ico--wider",
    class: _vm.sanatoriaSpoiler ? 's-str-ico--thm-angle-up' : 's-str-ico--thm-angle-down',
    staticStyle: {
      "vertical-align": "middle"
    }
  })])])])]), _vm._v(" "), _c('q-slide-transition', [_vm.sanatoriaSpoiler ? _c('q-field', {
    attrs: {
      "borderless": ""
    }
  }, [_c('div', {
    staticClass: "d-inline-flex flex-column"
  }, [_c('q-option-group', {
    attrs: {
      "options": _vm.sanatorium,
      "label": _vm.trans('bulletin.sanatorium-type'),
      "type": "radio"
    },
    model: {
      value: _vm.sanatoria,
      callback: function callback($$v) {
        _vm.sanatoria = $$v;
      },
      expression: "sanatoria"
    }
  })], 1)]) : _vm._e()], 1)], 1) : _vm._e()])], 1), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-end"
  }, [_c('div', {
    staticClass: "col-lg col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "width": "20",
      "color": "green",
      "label": _vm.trans('button.enter'),
      "loading": _vm.loading,
      "disable": !_vm.btnEnable
    },
    on: {
      "click": _vm.requestQueue
    }
  })], 1)])]) : _vm._e()]), _vm._v(" "), _vm.$q.screen.width > 1008 ? _c('div', {
    staticClass: "s-balabaqsha-catalog__blockquote",
    domProps: {
      "innerHTML": _vm._s(_vm.stoplist ? _vm.trans('queue.queue_application_description_stoplist') : _vm.trans('queue.queue_application_description'))
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }