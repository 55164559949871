var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('td', {
    staticClass: "s-table__col-7 s-table__col--to-center",
    attrs: {
      "data-table-head": _vm.trans('directions.kinder_garden')
    }
  }, [_vm._v("\n    " + _vm._s(_vm.school_name) + "\n  ")]), _vm._v(" "), _c('td', {
    staticClass: "s-table__col-5 s-table__col--to-center",
    attrs: {
      "data-table-head": _vm.trans('table.child_fullname')
    }
  }, [_c('span', {
    staticClass: "sn--text-no-wrap"
  }, [_vm._v(_vm._s(_vm.child_name))])]), _vm._v(" "), _c('td', {
    staticClass: "s-table__col-1 s-table__col--to-center",
    attrs: {
      "data-table-head": _vm.trans('fields.group_category')
    }
  }, [_vm._v("\n    " + _vm._s(_vm.child_year) + "\n  ")]), _vm._v(" "), _c('td', {
    staticClass: "s-table__col-2 s-table__col--to-center",
    attrs: {
      "data-table-head": _vm.trans('directions.group')
    }
  }, [_vm._v("\n    " + _vm._s(_vm.group_name) + "\n  ")]), _vm._v(" "), _c('td', {
    staticClass: "s-table__col-2 s-table__col--to-center",
    attrs: {
      "data-table-head": _vm.trans('bulletin.kind')
    }
  }, [_vm._v("\n    " + _vm._s(_vm.free_place_type) + "\n  ")]), _vm._v(" "), _c('td', {
    staticClass: " s-table__col--to-center",
    attrs: {
      "data-table-head": _vm.trans('directions.lang')
    }
  }, [_vm._v("\n    " + _vm._s(_vm.language) + "\n  ")]), _vm._v(" "), _c('td', {
    staticClass: "s-table__col-4 s-table__col--to-center",
    attrs: {
      "data-table-head": _vm.trans('queue.privilege')
    }
  }, [_vm._v("\n    " + _vm._s(_vm.privilege) + "\n  ")]), _vm._v(" "), _c('td', {
    staticClass: " s-table__col--to-center",
    attrs: {
      "data-table-head": _vm.trans('table.queue_list_date')
    }
  }, [_vm._v("\n    " + _vm._s(_vm.created_at) + "\n  ")]), _vm._v(" "), _c('td', {
    staticClass: " s-table__col--to-center",
    attrs: {
      "data-table-head": _vm.trans('directions.date_of_issue')
    }
  }, [_vm._v("\n    " + _vm._s(_vm.updated_at) + "\n  ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }