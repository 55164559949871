import {
  genArray,
} from '@vjs/helpers';

export default {
  name: 'VStatisticPanel',
  props: {
    iconUrl: String,
    dataList: {
      type: Array,
      default: () => ([]),
    },
    loading: Boolean,
    loadingCount: Number,
  },

  computed: {
    list() {
      if (this.loading) {
        return genArray(null, this.loadingCount || 4);
      }

      this.transLoaded(this.dataList);
      return this.dataList;
    },
  },

  methods: {
    transLoaded(arr) {
      // Подгрузка переводов
      if (Array.isArray(arr)) {
        arr.forEach(item => this.$trans.add(item.key.split('.')[0]));
      }
    },
  },
};
