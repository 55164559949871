import VReferenceTime from '@vjs/components/VReferenceTime';
import VFormInform from '@vjs/components/VFormInform';
import Validation from '@vjs/mixins/Validation';
import {
  QForm,
  QField,
  QOptionGroup,
  QSelect,
  QBtn,
  QTooltip,
} from '@quasar/components';
import {
  requestWrapper,
  textUtils,
} from '@vjs/helpers';

export default {
  name: 'VExchangeApplicationAdd',

  mixins: [Validation],

  components: {
    VFormInform,
    VReferenceTime,
    QForm,
    QField,
    QOptionGroup,
    QSelect,
    QBtn,
    QTooltip,
  },

  props: {
    uriGetChildren: {
      type: String,
      required: true,
    },
    uriGetChildInfo: {
      type: String,
      required: true,
    },
    uriSaveApplication: {
      type: String,
      required: true,
    },
    referenceTime: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      currentChild: '',
      childrenOptions: [],
      language: {
        value: null,
        label: '',
      },
      currentSchool: [
        {
          value: '',
          label: '',
        },
      ],
      schoolOptions: [],
      schoolOptionsFiltered: [],
    };
  },

  beforeCreate() {
    this.$trans.add(['label', 'button', 'queue', 'validation', 'organization']);
  },

  async mounted() {
    const childrenRes = await requestWrapper.call(this, {
      url: this.uriGetChildren,
    });

    if (!childrenRes.error && childrenRes.children) {
      this.childrenOptions = childrenRes.children.map(itm => ({
        ...itm,
        label: textUtils.capitalizeByWords(itm.label),
      }));
    }
  },

  watch: {
    async currentChild(val) {
      const childRes = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriGetChildInfo,
        data: {
          child_id: val,
        },
      });

      if (!childRes.error) {
        this.language = (childRes.language && childRes.language) || [];
        this.schoolOptionsFiltered = this.schoolOptions = (childRes.organizations && childRes.organizations) || [];
        this.currentSchool = [
          {
            value: '',
            label: '',
          },
        ];
        this.currentSchool[0] = {
          label: this.trans('queue.notice.select_organization'),
        };
      }
    },
  },

  methods: {
    async saveApplication() {
      const currentSchoolLength = this.currentSchool.length;

      for (let idx = 0; idx < currentSchoolLength; idx += 1) {
        if (!this.currentSchool[idx].value) {
          this.addValidationError(`organization${idx}`, [
            this.trans('validation.filled', {
              attribute: this.trans('label.direction_school'),
            }),
          ]);
          return false;
        }
      }

      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriSaveApplication,
        data: {
          child_id: this.currentChild,
          language_id: this.language.value,
          prefer_organizations_ids: this.currentSchool.reduce((acc, item) => [...acc, item.value], []),
        },
      });

      if (!res.error) {
        console.log(res);
      }
    },
    addAdditionalSelect() {
      if (this.currentSchool.length < 3) {
        this.currentSchool.push({ value: '', label: this.trans('queue.notice.select_organization') });
      }
    },
    removeAdditionalSelect(index) {
      if (typeof index === 'number') {
        this.currentSchool = this.currentSchool.filter((itm, idx) => idx !== index);
      }
    },
    filterOptions(val, update) {
      if (val === '') {
        update(() => {
          this.schoolOptionsFiltered = this.schoolOptions;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.schoolOptionsFiltered = this.schoolOptions
          .filter(v => v.label.toLowerCase().indexOf(needle) > -1);
      });
    },
  },
};
