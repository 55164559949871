var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-menu-cabinet"
  }, [_vm._l(_vm.menuList, function (menu, menu_key) {
    return [menu.submenu ? [menu.submenu.length > 1 || menu.submenu.length === 1 && menu.hardShowTree ? [_c('div', {
      key: menu_key,
      staticClass: "s-menu-cabinet__item",
      class: _vm.submenuClass(menu_key),
      on: {
        "click": function click($event) {
          return _vm.openMenu(menu_key);
        }
      }
    }, [_c('span', {
      staticClass: "sn--flex sn--items-center sn--justify-between sn--no-wrap"
    }, [_vm._v("\n            " + _vm._s(menu.name) + "\n            "), _c('q-icon', {
      attrs: {
        "name": "angle-down"
      }
    })], 1), _vm._v(" "), _c('q-slide-transition', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: menu.isOpen === undefined ? false : menu.isOpen,
        expression: "menu.isOpen === undefined ? false : menu.isOpen"
      }]
    }, [_c('div', {
      staticClass: "s-submenu-cabinet"
    }, _vm._l(menu.submenu, function (submenu, submenu_key) {
      return _c('a', {
        key: submenu_key,
        staticClass: "s-submenu-cabinet__item",
        class: submenu.active ? 'is-active' : '',
        attrs: {
          "href": submenu.url
        },
        on: {
          "click": function click($event) {
            return _vm.selectSubmenu(menu_key, submenu_key);
          }
        }
      }, [_c('q-icon', {
        attrs: {
          "name": "angle-right"
        }
      }), _vm._v(" "), _c('span', {
        domProps: {
          "innerHTML": _vm._s(submenu.name)
        }
      })], 1);
    }), 0)])], 1)] : [_c('a', {
      key: menu_key,
      staticClass: "s-menu-cabinet__item s-menu-cabinet__item--link",
      class: menu.active ? 'is-active' : '',
      attrs: {
        "href": menu.submenu[0].url,
        "target": menu.submenu[0].target ? '_blank' : ''
      },
      domProps: {
        "innerHTML": _vm._s(menu.submenu[0].name)
      },
      on: {
        "click": function click($event) {
          return _vm.selectMenu(menu_key);
        }
      }
    })]] : [_c('div', {
      key: menu_key,
      staticClass: "s-menu-cabinet__item s-menu-cabinet__item--link",
      class: [{
        'is-active': menu.active
      }, {
        'd-flex justify-content-between': menu.isPurchased
      }]
    }, [_c('a', {
      staticClass: "d-block s-c-black",
      attrs: {
        "href": menu.url,
        "target": menu.target ? '_blank' : ''
      },
      domProps: {
        "innerHTML": _vm._s(menu.name)
      },
      on: {
        "click": function click($event) {
          return _vm.selectMenu(menu_key);
        }
      }
    }), _vm._v(" "), menu.isPurchased ? _c('q-icon', {
      attrs: {
        "name": "lock"
      }
    }) : _vm._e()], 1)]];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }