import { QBtn, QTooltip } from '@quasar/components';
import VConfirmDialog from '@vjs/modals/VConfirmDialog.vue';
import { requestWrapper, textUtils, url } from '@vjs/helpers';
import VApplicationItemModal from './VApplicationItemModal.vue';
import VApplicationLgotsModal from './VApplicationLgotsModal.vue';
import VApplicationLangModal from './VApplicationLangModal.vue';
import VApplicationRemovePriorityModal from './VApplicationRemovePriorityModal.vue';

export default {
  name: 'VApplicationItem',

  components: {
    QBtn,
    QTooltip,
    VConfirmDialog,
    VApplicationItemModal,
    VApplicationLgotsModal,
    VApplicationLangModal,
    VApplicationRemovePriorityModal,
  },

  props: {
    name: {
      type: String,
      default: '',
    },
    identifier: {
      type: Number,
      required: true,
    },
    flag: String,
    statementsCount: String,
    lgotBtn: Object,
    langBtn: {
      type: Object,
      default: () => {},
    },
    removePriorityBtn: {
      type: Object,
      default: () => {},
    },
    locked: Boolean,
    isNotInterested: Boolean,
    removeSuccess: Boolean,
    desc: {
      type: Array,
      default: () => [],
    },
    uriRemove: {
      type: String,
      required: true,
    },
    uriInterest: {
      type: String,
      required: true,
    },
    uriStatementContinue: {
      type: String,
      required: true,
    },
    uriUpdateLgots: {
      type: String,
      required: true,
    },
    uriGetLgotTypes: {
      type: String,
    },
    uriGetLgotTypeDocuments: {
      type: String,
    },
    steps: {
      type: Array,
      default: () => [],
    },
    isCorrection: Boolean,
  },

  beforeCreate() {
    this.$trans.add(['organization', 'button', 'statement', 'notice', 'label']);
  },

  data() {
    return {
      modalConfirmRemove: false,
      modalContinue: false,
      modalLgots: false,
      modalLang: false,
      modalRemovePriority: false,
      loadingRemove: false,
      loadingInterest: false,
      loading: false,
    };
  },
  async mounted() {
    await this.$trans.add([
      'statement',
      'label',
      'button',
    ]);
  },
  methods: {
    handleUpdateLgotModal() {
      if (this.lgotBtn.disable === true) {
        this.$notify({
          text: this.trans('statement.already_send_update_lgot'),
          type: 'warn',
        });
        return;
      }
      this.modalLgots = true;
    },
    handleUpdateLangModal() {
      if (this.langBtn.active) {
        this.modalLang = true;
      }
    },
    handleRemovePriority() {
      this.modalRemovePriority = true;
    },
    handleConfirmRemovePriority() {
      this.modalLang = true;
      this.modalRemovePriority = false;
    },
    async setInterest(uri) {
      this.loadingInterest = true;
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: (typeof uri === 'string' && uri) || url(`${this.uriInterest}/${this.identifier}`),
      });

      if (!res.error) {
        if (res.data && res.data.status && res.data.steps) {
          this.$emit('unlock', res.data.steps);
        }

        this.$emit('is-interest');
      }

      this.loadingInterest = false;
      return !res.error;
    },
    capitalizeByWords: textUtils.capitalizeByWords,
    getIconByStatus(status = '') {
      switch (status) {
        case 'done':
          return 'check-circle';
        case 'waiting':
          return 'waiting';
        case 'inactive':
          return 'clock';
        default:
          return '';
      }
    },
    async removeApplication() {
      this.loadingRemove = true;
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriRemove,
        data: {
          statement_id: this.identifier,
        },
      });
      this.loadingRemove = false;

      if (!res.error) {
        this.modalConfirmRemove = false;
        this.$emit('removed', this.identifier);
      }
    },
    async controlDoing(name, action, stepIndex, ctrlIndex) {
      let res;
      switch (name) {
        case 'interested':
          res = await this.setInterest(action);

          if (res && stepIndex !== undefined) {
            this.steps[stepIndex].controls = this.steps[stepIndex].controls
              .filter(itm => itm.name !== name);
          }

          break;
        case 'direction_extend':
          this.modalContinue = true;
          this.stepControlsCoor = [stepIndex, ctrlIndex];
          break;
        default:
          break;
      }
    },
    setLoading(name) {
      switch (name) {
        case 'interested':
          return this.loadingInterest;
        default:
          return false;
      }
    },
    setControlSuccessFromItemModal() {
      const stepControl = this.steps[this.stepControlsCoor[0]].controls[this.stepControlsCoor[1]];
      stepControl.disabled = true;
      stepControl.tooltip = this.trans('notice.can_not_extend_direction');
    },
  },
};
