var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "faq-answear",
    class: {
      'is-active': _vm.show
    }
  }, [_c('div', {
    staticClass: "faq-answear__head",
    on: {
      "click": _vm.switcher
    }
  }, [_c('div', {
    staticClass: "faq-answear__title"
  }, [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")])]), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.show ? _c('div', {
    staticClass: "faq-answear__body",
    domProps: {
      "innerHTML": _vm._s(_vm.text)
    }
  }) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }