var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h4', {
    staticClass: "group-list-header"
  }, [_vm._v("\n      " + _vm._s(_vm.trans('funding.kindergarten.header', {
    kindergarten: _vm.kindergarten
  })) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "row date-panel"
  }, [_c('div', {
    staticClass: "col-1"
  }, [_c('div', {
    staticClass: "attendance-calendar-icon"
  }, [_c('q-icon', {
    attrs: {
      "name": "calendar",
      "size": "sm"
    }
  })], 1)]), _vm._v(" "), _vm.currentYear ? _c('div', {
    staticClass: "col-md-2 col-sm-4"
  }, [_c('q-select', {
    attrs: {
      "disable": _vm.years.length <= 1 || _vm.groupsLoad,
      "options": _vm.years
    },
    on: {
      "input": _vm.onChangeYear
    },
    model: {
      value: _vm.currentYear,
      callback: function callback($$v) {
        _vm.currentYear = $$v;
      },
      expression: "currentYear"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.currentMonth ? _c('div', {
    staticClass: "col-md-2 col-sm-4"
  }, [_c('q-select', {
    attrs: {
      "disable": _vm.months.length <= 1 || _vm.groupsLoad,
      "options": _vm.months,
      "option-value": "value",
      "option-label": "label"
    },
    on: {
      "input": _vm.onChangeMonth
    },
    model: {
      value: _vm.currentMonth,
      callback: function callback($$v) {
        _vm.currentMonth = $$v;
      },
      expression: "currentMonth"
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "funding-total-header"
  }, [_vm._v("\n      " + _vm._s(_vm.trans('funding.amount-plan-date', {
    date: _vm.currentDate.split('-').slice(0, 2).join('-')
  })) + ": "), _c('span', {
    staticClass: "s-medium"
  }, [_vm._v(_vm._s(_vm.formatPrice(_vm.totalPlan)))])]), _vm._v(" "), _c('q-table', {
    attrs: {
      "data": _vm.groups,
      "columns": _vm.groupsColumns,
      "separator": "cell",
      "flat": "",
      "loading": _vm.groupsLoad
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, _vm._l(props.cols, function (col) {
          return _c('q-th', {
            key: col.name,
            staticClass: "funding-table-header",
            attrs: {
              "props": props
            }
          }, [_vm._v("\n            " + _vm._s(col.label) + "\n          ")]);
        }), 1)];
      }
    }, {
      key: "body",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, [_c('q-td', {
          key: "group_name",
          staticClass: "funding-table-cell",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n            " + _vm._s(props.row.group_name) + "\n          ")]), _vm._v(" "), _c('q-td', {
          key: "ages",
          staticClass: "funding-table-cell",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n            " + _vm._s(props.row.ages) + "\n          ")]), _vm._v(" "), _c('q-td', {
          key: "contingent_count",
          staticClass: "funding-table-cell",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n            " + _vm._s(props.row.contingent_count) + "\n          ")]), _vm._v(" "), _c('q-td', {
          key: "funding_count",
          staticClass: "funding-table-cell",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n            " + _vm._s(props.row.funding_count ? props.row.funding_count : 0) + "\n          ")]), _vm._v(" "), _c('q-td', {
          key: "funding",
          staticClass: "funding-table-cell",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n            " + _vm._s(props.row.funding && props.row.funding.total ? _vm.formatPrice(props.row.funding.total) : 0) + "\n          ")]), _vm._v(" "), _c('q-td', {
          key: "id",
          staticClass: "funding-table-cell",
          attrs: {
            "props": props
          }
        }, [props.row.funding && props.row.funding.id ? _c('a', {
          staticClass: "funding-route-link",
          attrs: {
            "href": "/".concat(_vm.locale, "/control/funding/view/").concat(_vm.kindergartenId, "/").concat(props.row.id, "?type=").concat(_vm.type)
          }
        }, [_c('q-btn', {
          attrs: {
            "squire": "",
            "icon": "eye"
          }
        }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.view')))])], 1)], 1) : _vm._e(), _vm._v(" "), !_vm.planLock && props.row.funding && !props.row.funding.actual && !props.row.denied ? _c('a', {
          staticClass: "funding-route-link",
          attrs: {
            "href": "".concat(_vm.editUrl, "/").concat(_vm.kindergartenId, "/").concat(props.row.id, "?type=").concat(_vm.type)
          }
        }, [_c('q-btn', {
          attrs: {
            "squire": "",
            "icon": "pencil",
            "color": "orange"
          }
        }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('button.edit')))])], 1)], 1) : _vm._e(), _vm._v(" "), !_vm.planLock && !props.row.funding && !props.row.denied ? _c('a', {
          staticClass: "funding-route-link",
          attrs: {
            "href": "".concat(_vm.editUrl, "/").concat(_vm.kindergartenId, "/").concat(props.row.id, "?type=").concat(_vm.type)
          }
        }, [_c('q-btn', {
          attrs: {
            "squire": "",
            "icon": "plus",
            "color": "blue",
            "title": _vm.trans('button.calc')
          }
        }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('button.calc')))])], 1)], 1) : _vm._e()])], 1)];
      }
    }, {
      key: "bottom-row",
      fn: function fn(props) {
        return [_c('tr', [_c('th', {
          attrs: {
            "colspan": _vm.groupsColumns.length
          }
        }, [_vm._v("\n            " + _vm._s(_vm.trans('label.total')) + "\n          ")])]), _vm._v(" "), _c('tr', [_c('th', [_vm._v("\n            " + _vm._s(_vm.trans('funding.amount-plan-date', {
          date: _vm.currentDate.split('-').slice(0, 2).join('-')
        })) + "\n          ")]), _vm._v(" "), _c('td', {
          attrs: {
            "colspan": _vm.groupsColumns.length - 1
          }
        }, [_c('span', {
          staticClass: "s-medium"
        }, [_vm._v(_vm._s(_vm.formatPrice(_vm.totalPlan)))])])])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }