var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "s-direction-block mb-10"
  }, [_c('div', {
    staticClass: "s-direction-block__columns"
  }, [_c('div', {
    staticClass: "s-direction-block__column-left"
  }, [_c('div', {
    staticClass: "s-direction-block__head justify-content-between"
  }, [_c('div', {
    staticClass: "s-direction-block__name "
  }, [_vm._v(_vm._s(_vm.name))]), _vm._v(" "), _vm.flag ? _c('div', {
    staticClass: "s-direction-block__mark flex-shrink-0"
  }, [_c('div', {
    staticClass: "s-flag s-b-blue s-c-white s-to-center"
  }, [_vm._v(_vm._s(_vm.flag))]), _vm._v(" "), _c('div', {
    staticClass: "s-direction-block__refuse"
  }, [_vm.refuse ? _c('q-btn', {
    attrs: {
      "icon": "trash",
      "color": "red",
      "title": _vm.trans('directions.refuse'),
      "squire": "",
      "outline": ""
    },
    on: {
      "click": function click($event) {
        return _vm.callConfirmPopup(_vm.id, _vm.type);
      }
    }
  }) : _vm._e()], 1)]) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "s-direction-block__body justify-content-between"
  }, [_c('div', {
    staticClass: "s-direction-block__item-list"
  }, _vm._l(_vm.desc, function (item, idx) {
    return _c('div', {
      key: idx,
      staticClass: "s-direction-block__item"
    }, [_c('div', {
      staticClass: "s-direction-block__item-label mb-10"
    }, [_vm._v("\n              " + _vm._s(item.label) + "\n            ")]), _vm._v(" "), _c('div', {
      staticClass: "s-direction-block__item-value"
    }, [_vm._v("\n              " + _vm._s(item.value) + "\n            ")])]);
  }), 0), _vm._v(" "), _c('div', {
    staticClass: "s-direction-block__column-right"
  })])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }