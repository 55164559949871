var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "catalog",
    staticClass: "s-balabaqsha-catalog"
  }, [_c('v-bulletin-filters', {
    staticClass: "s-balabaqsha-catalog__filter-wrapper",
    attrs: {
      "uri-locality": _vm.uriLocality,
      "uri-statistic": _vm.uriStatistic,
      "uri-filters": _vm.uriFilters,
      "uri-get-filters": _vm.uriGetFilters,
      "uri-by-all": _vm.uriByAll,
      "uri-by-org": _vm.uriByOrg,
      "current-org-i-d": _vm.currentOrgID,
      "default-filters": _vm.defaultFilters,
      "disable-load-from-storage": _vm.disableLoadFromStorage
    },
    on: {
      "filtered-list": _vm.handleFilteredList,
      "filter-loading": function filterLoading($event) {
        _vm.loading = $event;
      },
      "changed-tab": function changedTab($event) {
        _vm.currentTab = $event;
      }
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "s-balabaqsha-catalog__content"
  }, [_c('v-statistic-panel', {
    staticClass: "s-balabaqsha-catalog__statistics--nth-4",
    attrs: {
      "icon-url": "".concat(_vm.staticUrl, "/indv/balabaqsha/images/statistics"),
      "data-list": _vm.statisticItems,
      "loading": _vm.loading,
      "loading-count": 4
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "col-lg-3 col-md-12 col-sm-12 mb-10"
  }, [_c('v-reference-time', {
    attrs: {
      "timestamp": _vm.referenceTime,
      "label": _vm.trans('label.server_datetime')
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-lg-5 col-md-6 col-sm-12 mb-10"
  }, [_vm.linkToDownloadEarlyFreePlaces ? _c('q-btn', {
    staticClass: "s-w-100 mt-10",
    attrs: {
      "to": _vm.linkToDownloadEarlyFreePlaces,
      "type": "a",
      "color": "aqua",
      "width": "20",
      "outline": "",
      "no-caps": "",
      "squire": "",
      "label": _vm.trans('directions.early_booking_schedule'),
      "icon-right": "download",
      "ellipsis": ""
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "col-lg-4 col-md-6 col-sm-12 mb-10"
  }, [_c('q-btn', {
    staticClass: "s-w-100 mt-10",
    attrs: {
      "to": _vm.linkToDirections,
      "type": "a",
      "color": "orange",
      "width": "20",
      "no-caps": "",
      "squire": "",
      "label": _vm.trans('directions.btn')
    }
  })], 1)]), _vm._v(" "), _vm.informPanelText ? _c('div', {
    staticClass: "bulletin-information-panel sn--text-center"
  }, [_vm._v("\n      " + _vm._s(_vm.informPanelText) + "\n    ")]) : _vm._e(), _vm._v(" "), _vm.informPanelTextSeven ? _c('div', {
    staticClass: "bulletin-information-panel sn--text-center"
  }, [_vm._v("\n      " + _vm._s(_vm.informPanelTextSeven) + "\n    ")]) : _vm._e(), _vm._v(" "), _c('hr', {
    staticClass: "s-hr"
  }), _vm._v(" "), _vm._l(_vm.itemsPaginated, function (bulletin, idx) {
    return _c('v-bulletin-item', {
      key: idx,
      staticClass: "mb-30",
      attrs: {
        "organization-id": bulletin.id,
        "organization": bulletin.organization,
        "params": bulletin.params,
        "group-name": bulletin.groupName,
        "education-year": bulletin.educationYear,
        "ages": bulletin.ages,
        "publish-date": bulletin.publishDate,
        "expired-at": bulletin.expiredAt,
        "date-end": bulletin.dateEnd,
        "ages-list": bulletin.agesList,
        "visible-request-button": bulletin.visibleRequestButton,
        "uri-statements": _vm.uriStatements,
        "uri-statement-request": _vm.uriStatementRequest,
        "correction": bulletin.correction,
        "sanatoria": bulletin.sanatoria,
        "type-of-tab": _vm.currentTab,
        "loading": _vm.loading,
        "diseases": bulletin.diseases,
        "sanatorium": bulletin.sanatorium
      },
      on: {
        "select-organization": _vm.selectOrganization
      }
    });
  }), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-center mt-30"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-pagination', {
    attrs: {
      "max": _vm.countOfPages,
      "max-pages": 5,
      "boundary-links": true,
      "input": _vm.countOfPages > 5,
      "input-class": "s-c-blue"
    },
    on: {
      "input": _vm.changePagination
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)]), _vm._v(" "), _vm.empty ? _c('v-no-results', {
    attrs: {
      "image": "search",
      "title": _vm.trans('warning_text.no_result_bulletin_title'),
      "text": _vm.trans('warning_text.no_result_bulletin_text')
    }
  }) : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }