import {
  QBtn,
} from '@quasar/components';

export default {
  name: 'VTabelTableView',
  components: {
    QBtn,
  },
  data() {
    return {
      result: false,
      children: [
        {
          id: 1,
          name: 'Колобов Прохор Пантелеймонович',
          cells: [
            {
              file: '',
              absent: 1,
              text: '',
              holiday: false,
            },
          ],
        },
        {
          id: 2,
          name: 'Колобов Прохор Пантелеймонович',
          cells: [
            {
              file: '',
              absent: 0,
              text: '',
              holiday: false,
            },
          ],
        },
        {
          id: 3,
          name: 'Колобов Прохор Пантелеймонович',
          cells: [
            {
              file: '',
              absent: 1,
              text: '',
              holiday: false,
            },
          ],
        },
      ],
    };
  },
  beforeCreate() {
    this.$trans.add(['attendance']);
  },
  methods: {
    calcAbsent(cellIdx) {
      return this.children.reduce((acc, itm) => acc + itm.cells[cellIdx].absent, 0);
    },
  },
};
