var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "s-modal__body"
  }, [_c('q-input', {
    attrs: {
      "label": _vm.trans('label.personnel_termination_order_number'),
      "placeholder": _vm.trans('label.personnel_termination_order_number'),
      "required": ""
    },
    model: {
      value: _vm.orderNumber,
      callback: function callback($$v) {
        _vm.orderNumber = $$v;
      },
      expression: "orderNumber"
    }
  }), _vm._v(" "), _vm.reasons ? _c('q-select', {
    attrs: {
      "options": _vm.reasons,
      "label": _vm.trans('label.reason_for_personnel_deletion'),
      "emit-value": "",
      "map-options": "",
      "filled": ""
    },
    model: {
      value: _vm.reason,
      callback: function callback($$v) {
        _vm.reason = $$v;
      },
      expression: "reason"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "row-sort-between-btn"
  }, [_c('q-btn', {
    attrs: {
      "no-caps": "",
      "width": "15",
      "color": "grey",
      "label": _vm.trans('button.cancel')
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('onClose');
      }
    }
  }), _vm._v(" "), _c('q-btn', {
    attrs: {
      "no-caps": "",
      "width": "15",
      "color": "green",
      "label": _vm.trans('button.approve'),
      "disable": !_vm.canConfirm
    },
    on: {
      "click": _vm.approve
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }