var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h5', {
    staticClass: "s-combine-title mb-30"
  }, [_c('span', {
    staticClass: "s-combine-title__label s-upper"
  }, [_vm._v(_vm._s(_vm.text))]), _vm._v(" "), _c('span', {
    staticClass: "s-combine-title__line"
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }