var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-form', {
    staticClass: "s-statement-contract__create-form",
    attrs: {
      "autocorrect": "off",
      "autocapitalize": "off",
      "autocomplete": "off",
      "spellcheck": "false"
    },
    on: {
      "submit": _vm.requestAction
    }
  }, [_c('v-form-inform', {
    attrs: {
      "color": "red",
      "message": _vm.trans('warning_text.statement_contract_form_inform')
    }
  }), _vm._v(" "), _vm._l(_vm.inputList, function (inputName, key) {
    return _c('q-input', {
      key: key,
      attrs: {
        "required": _vm.isInputRequired(inputName),
        "disable": _vm.isInputDisabled(inputName),
        "label": _vm.trans("label.statement_contract.".concat(inputName)),
        "error": _vm.hasValidationFail(inputName),
        "error-message": _vm.getValidationMessage(inputName)
      },
      on: {
        "input": function input($event) {
          return _vm.flashValidationFail(inputName);
        }
      },
      model: {
        value: _vm.form[inputName],
        callback: function callback($$v) {
          _vm.$set(_vm.form, inputName, $$v);
        },
        expression: "form[inputName]"
      }
    });
  }), _vm._v(" "), _c('q-select', {
    attrs: {
      "map-options": "",
      "filled": "",
      "emit-value": "",
      "required": "",
      "options": _vm.options.scheduleList,
      "label": _vm.trans('label.statement_contract.schedule_type'),
      "disable": _vm.disable.schedule_type,
      "error": _vm.hasValidationFail('schedule_type'),
      "error-message": _vm.getValidationMessage('schedule_type')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('schedule_type');
      }
    },
    model: {
      value: _vm.form.schedule_type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "schedule_type", $$v);
      },
      expression: "form.schedule_type"
    }
  }), _vm._v(" "), _c('q-select', {
    attrs: {
      "map-options": "",
      "filled": "",
      "emit-value": "",
      "required": "",
      "disable": _vm.disable.stay_mode_type,
      "options": _vm.options.stayModeTypeList,
      "label": _vm.trans('label.statement_contract.stay_mode_type'),
      "error": _vm.hasValidationFail('stay_mode_type'),
      "error-message": _vm.getValidationMessage('stay_mode_type')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('stay_mode_type');
      }
    },
    model: {
      value: _vm.form.stay_mode_type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "stay_mode_type", $$v);
      },
      expression: "form.stay_mode_type"
    }
  }), _vm._v(" "), _c('q-input', {
    staticClass: "morning_reception_time",
    attrs: {
      "filled": "",
      "mask": "##:##-##:##",
      "placeholder": "__:__ - __:__",
      "disable": _vm.disable.morning_reception_time,
      "label": _vm.trans('label.statement_contract.morning_reception_time'),
      "error": _vm.hasValidationFail('morning_reception_time'),
      "error-message": _vm.getValidationMessage('morning_reception_time')
    },
    model: {
      value: _vm.form.morning_reception_time,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "morning_reception_time", $$v);
      },
      expression: "form.morning_reception_time"
    }
  }), _vm._v(" "), _c('q-input', {
    staticClass: "contract-end-date",
    attrs: {
      "filled": "",
      "mask": "##.##.####",
      "label": _vm.trans('label.statement_contract.contract_end'),
      "error": _vm.hasValidationFail('contract_end'),
      "error-message": _vm.getValidationMessage('contract_end')
    },
    on: {
      "click": function click() {
        return _vm.$refs.dateEndProxy.show();
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "sn--cursor-pointer",
          attrs: {
            "name": "calendar"
          }
        }, [_c('q-popup-proxy', {
          ref: "dateEndProxy",
          attrs: {
            "transition-show": "scale",
            "transition-hide": "scale"
          }
        }, [_c('q-date', {
          attrs: {
            "mask": "DD.MM.YYYY",
            "color": "blue"
          },
          on: {
            "input": function input($event) {
              _vm.flashValidationFail('contract_end');

              _vm.$refs.dateEndProxy.hide();
            }
          },
          model: {
            value: _vm.form.contract_end,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "contract_end", $$v);
            },
            expression: "form.contract_end"
          }
        })], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.contract_end,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "contract_end", $$v);
      },
      expression: "form.contract_end"
    }
  }), _vm._v(" "), _c('v-form-inform', {
    attrs: {
      "color": "red",
      "message": _vm.trans('warning_text.statement_contract_date_start_inform')
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "s-group s-group--wider justify-content-between mt-20"
  }, [_c('q-btn', {
    attrs: {
      "width": "20",
      "no-caps": "",
      "icon": "angle-left",
      "label": _vm.trans('statement.backward'),
      "color": "grey",
      "type": "a",
      "to": _vm.apiConfig.backButtonUrl
    }
  }), _vm._v(" "), _c('q-btn', {
    attrs: {
      "width": "20",
      "no-caps": "",
      "icon-right": "angle-right",
      "label": _vm.trans('statement.forward'),
      "color": "green",
      "disable": !_vm.validate,
      "type": "submit"
    }
  })], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }