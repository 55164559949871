var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-dialog', {
    attrs: {
      "value": _vm.modal
    },
    on: {
      "input": _vm.handleModal
    }
  }, [_c('v-modal', {
    attrs: {
      "title": _vm.trans('notice.confirm'),
      "size": "medium"
    }
  }, [_c('v-form-inform', {
    staticClass: "mt-10",
    attrs: {
      "message": _vm.trans('warning_text.freeplace_only_twins_or_single')
    }
  }), _vm._v(" "), _c('v-wrapper-q-select', {
    attrs: {
      "options": _vm.statementItems,
      "multiple": "",
      "option-label": "label",
      "option-value": "value",
      "extra-binds": {
        counter: true,
        maxValues: 3,
        label: _vm.trans('label.select_child')
      }
    },
    model: {
      value: _vm.statementSelected,
      callback: function callback($$v) {
        _vm.statementSelected = $$v;
      },
      expression: "statementSelected"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-end mt-10"
  }, [_c('div', {
    staticClass: "col col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "color": "green",
      "label": _vm.trans('button.send'),
      "loading": _vm.loading,
      "disable": !_vm.statementSelected
    },
    on: {
      "click": _vm.send
    }
  })], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }