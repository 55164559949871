var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-view__group-create-wrapper"
  }, [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('label.warning_message')
    }
  }), _vm._v(" "), _c('q-form', [_c('q-select', {
    attrs: {
      "options": _vm.groupTypeOptions,
      "label": _vm.trans('label.group_type'),
      "disable": _vm.editable && _vm.lockGroupType,
      "required": ""
    },
    model: {
      value: _vm.groupTypeSelected,
      callback: function callback($$v) {
        _vm.groupTypeSelected = $$v;
      },
      expression: "groupTypeSelected"
    }
  }), _vm._v(" "), _vm.canCreateReserve ? _c('q-checkbox', {
    attrs: {
      "true-value": 1,
      "false-value": 0,
      "disable": _vm.disableReserve,
      "label": _vm.trans('label.is_reserve')
    },
    model: {
      value: _vm.isReserve,
      callback: function callback($$v) {
        _vm.isReserve = $$v;
      },
      expression: "isReserve"
    }
  }) : _vm._e(), _vm._v(" "), this.isCorrection && _vm.diseasesOptions.length > 0 ? _c('q-field', {
    attrs: {
      "label": _vm.trans('label.select_diseases'),
      "borderless": "",
      "required": ""
    }
  }, [_vm.diseasesOptions.length > 0 ? _c('q-option-group', {
    attrs: {
      "options": _vm.diseasesOptions,
      "type": "checkbox"
    },
    model: {
      value: _vm.diseasesSelected,
      callback: function callback($$v) {
        _vm.diseasesSelected = $$v;
      },
      expression: "diseasesSelected"
    }
  }) : _vm._e()], 1) : _vm._e(), _vm._v(" "), this.isSanatorium && _vm.sanatoriumOptions.length > 0 ? _c('q-field', {
    attrs: {
      "label": _vm.trans('label.select_sanatorium'),
      "borderless": "",
      "required": ""
    }
  }, [_vm.sanatoriumOptions.length > 0 ? _c('q-option-group', {
    attrs: {
      "options": _vm.sanatoriumOptions,
      "type": "checkbox"
    },
    model: {
      value: _vm.sanatoriumSelected,
      callback: function callback($$v) {
        _vm.sanatoriumSelected = $$v;
      },
      expression: "sanatoriumSelected"
    }
  }) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _c('q-field', {
    attrs: {
      "label": _vm.trans('fields.group_category_editor'),
      "borderless": "",
      "required": ""
    }
  }, [_vm.groupAgeOptions.length > 0 ? _c('q-option-group', {
    attrs: {
      "options": _vm.groupAgeList,
      "type": "checkbox"
    },
    model: {
      value: _vm.groupAgeSelected,
      callback: function callback($$v) {
        _vm.groupAgeSelected = $$v;
      },
      expression: "groupAgeSelected"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('q-input', {
    attrs: {
      "label": _vm.trans('fields.group_name'),
      "required": ""
    },
    model: {
      value: _vm.groupName,
      callback: function callback($$v) {
        _vm.groupName = $$v;
      },
      expression: "groupName"
    }
  }), _vm._v(" "), _c('q-select', {
    attrs: {
      "options": _vm.stayModeTypeOptions,
      "label": _vm.trans('group.stay_mode_type'),
      "map-options": "",
      "emit-value": "",
      "required": ""
    },
    model: {
      value: _vm.stayModeTypeSelected,
      callback: function callback($$v) {
        _vm.stayModeTypeSelected = $$v;
      },
      expression: "stayModeTypeSelected"
    }
  }), _vm._v(" "), _c('q-checkbox', {
    attrs: {
      "true-value": 1,
      "false-value": 0,
      "label": _vm.trans('label.work_six_days')
    },
    model: {
      value: _vm.workSixDays,
      callback: function callback($$v) {
        _vm.workSixDays = $$v;
      },
      expression: "workSixDays"
    }
  }), _vm._v(" "), _c('q-field', {
    attrs: {
      "label": _vm.trans('label.edu_lang'),
      "borderless": "",
      "required": ""
    }
  }, [_vm.eduLangOptions.length > 0 ? _c('q-option-group', {
    attrs: {
      "options": _vm.eduLangOptions,
      "disable": _vm.editableLang,
      "type": "radio"
    },
    model: {
      value: _vm.eduLangSelected,
      callback: function callback($$v) {
        _vm.eduLangSelected = $$v;
      },
      expression: "eduLangSelected"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('q-input', {
    attrs: {
      "label": _vm.trans('fields.limit'),
      "disable": _vm.editableLimit === false,
      "required": ""
    },
    model: {
      value: _vm.groupLimit,
      callback: function callback($$v) {
        _vm.groupLimit = $$v;
      },
      expression: "groupLimit"
    }
  }), _vm._v(" "), _vm.overLimitHide === false ? _c('q-input', {
    attrs: {
      "label": _vm.trans('group.over_limit'),
      "required": "",
      "rules": [function (val) {
        return val <= 5 || _vm.trans('group.over_limit_max_value');
      }, function (val) {
        return val >= 0 || _vm.trans('group.over_limit_min_value');
      }]
    },
    model: {
      value: _vm.groupOverLimit,
      callback: function callback($$v) {
        _vm.groupOverLimit = $$v;
      },
      expression: "groupOverLimit"
    }
  }) : _vm._e(), _vm._v(" "), _c('v-select-from-list', {
    staticClass: "mb-20",
    attrs: {
      "selected": _vm.personnelIdSelected,
      "options": _vm.personnelOptions,
      "label": _vm.trans('title.select_educator'),
      "required": _vm.personnelRequired
    },
    on: {
      "onchange": _vm.handleChangeOptions
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-end"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-btn', {
    attrs: {
      "type": "submit",
      "color": "green",
      "label": _vm.trans('button.save'),
      "disable": !_vm.submitEnable
    },
    on: {
      "click": _vm.requestData
    }
  })], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }