var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-queue-card",
    class: {
      'is-loading': _vm.loading
    }
  }, [!_vm.stoplist ? _c('div', {
    staticClass: "s-queue-card__num"
  }, [_vm._v("\n    " + _vm._s(_vm.queueNumber) + "\n  ")]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "s-queue-card__content"
  }, [_c('div', {
    staticClass: "s-queue-card__head mb-20"
  }, [_c('div', {
    staticClass: "s-queue-card__name"
  }, [_vm._v("\n        " + _vm._s(_vm.capitalizeByWords(_vm.childFullName)) + "\n      ")]), _vm._v(" "), _vm.privilegeClient.key !== 0 ? _c('div', {
    staticClass: "s-queue-card__mark"
  }, [_c('div', {
    class: _vm.getFlagStyle()
  }, [_vm._v("\n          " + _vm._s(_vm.privilegeClient.value) + "\n        ")])]) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "s-queue-card__body"
  }, [!_vm.stoplist ? _c('div', {
    staticClass: "row flex-column flex-sm-row justify-content-sm-between"
  }, [_c('div', {
    staticClass: "col-auto s-queue-card__applicant"
  }, [_c('div', {
    staticClass: "mb-5"
  }, [_c('strong', [_vm._v(_vm._s(_vm.trans('queue.applicant')) + ":")])]), _vm._v(" "), _c('div', [_vm._v(_vm._s(_vm.capitalizeByWords(_vm.userFullName)))])]), _vm._v(" "), _c('div', {
    staticClass: "col-auto s-c-black s-queue-card__filed"
  }, [_c('div', {
    staticClass: "mb-5"
  }, [_c('strong', [_vm._v(_vm._s(_vm.trans('queue.filed')) + ":")])]), _vm._v(" "), _c('div', [_vm._v(_vm._s(_vm.getDate(_vm.dateSendStatement)))])])]) : _c('div', {
    staticClass: "row justify-content-end"
  }, [_c('div', {
    staticClass: "col-auto s-c-black s-to-right"
  }, [_c('div', {
    staticClass: "mb-5"
  }, [_c('strong', [_vm._v(_vm._s(_vm.trans('queue.expiredDate')) + ":")])]), _vm._v(" "), _c('div', [_vm._v(_vm._s(_vm.getDate(_vm.expiredDate)))])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }