import { render, staticRenderFns } from "./VJournalView.vue?vue&type=template&id=5cbd3879&scoped=true&"
import script from "./VJournalView.js?vue&type=script&lang=js&"
export * from "./VJournalView.js?vue&type=script&lang=js&"
import style0 from "./VJournalView.vue?vue&type=style&index=0&id=5cbd3879&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cbd3879",
  null
  
)

export default component.exports