import { eventBus, FormField } from '@common/mixins';
import { QItem, QItemSection } from '@quasar/components';
import _ from 'lodash';

export default {
  components: {
    QItem,
    QItemSection,
  },
  mixins: [FormField],
  beforeCreate() {
    this.$trans.add(['select', 'placeholder']);
  },
  data() {
    return {
      loadedValues: [],
      selectValue: [],
      filterOptions: [],
      filteredOptions: [],
    };
  },
  methods: {
    filterFn(val, update) {
      if (val === '') {
        update(() => {
          this.filteredOptions = this.options;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.filteredOptions = this.options.filter(
          v => v?.[this.defaultAttributes.optionLabel]?.toLowerCase()?.indexOf(needle) > -1,
        );
      });
    },
    emitChange(value = null) {
      this.field.valueObject = value;
      if (this.field.extraAttributes && this.field.extraAttributes.multiple) {
        let arrayValue = null;
        if (value && value.length) {
          arrayValue = [];
          _.forEach(value, selectedValue => arrayValue.push(selectedValue?.[this.defaultAttributes.optionValue]));
        }
        this.$emit('input', arrayValue);
      } else if (this.extraAttributes.fullValue && value) {
        this.$emit('input', value);
        this.$emit('change', value);
      } else if (value && value.hasOwnProperty(this.defaultAttributes.optionValue)) {
        this.$emit('input', value[this.defaultAttributes.optionValue]);
        this.$emit('change', value[this.defaultAttributes.optionValue]);
      } else {
        this.$emit('input', null);
        this.$emit('change', null);
      }
    },
    getError() {
      const vm = this;
      return _.first(vm.errors);
    },
    setEmpty() {
      const vm = this;
      vm.$set(vm, 'loadedValues', []);
      vm.setInitialSelectValue();
      vm.emitChange(this.selectValue);
    },
    async loadValues() {
      const vm = this;
      if (!vm.field.loadValues) {
        return;
      }
      const where = vm.field.loadValues.where ?? {};
      // TODO сделать кеш что бы не лазить каждый раз за тем что уже есть
      // надо подумать если ли смысл и не будет ли проблем с памятью браузера

      const config = {
        responseType: 'json',
        method: 'GET',
        params: where,
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };
      if (vm.field.loadValues.headers) {
        _.each(vm.field.loadValues.headers, (value, key) => {
          config.headers[key] = value;
        });
      }
      let url = `/api/refs/${vm.field.loadValues.type}`;
      if (vm.field.loadValues.url) {
        url = vm.field.loadValues.url;
      }
      this.$globalLoading.show();
      await vm.$http.get(url, config).then(
        (response) => {
          const { data } = response;
          vm.$set(vm, 'loadedValues', data);
          vm.setInitialSelectValue();
        },
        (response) => {
          console.log(response);
        },
      );
      this.$globalLoading.hide();
    },
    setInitialSelectValue() {
      if (this.field.extraAttributes && this.field.extraAttributes.multiple) {
        this.localValue = _.filter(_.map(this.field.value, (item) => {
          if (_.isObject(item)) {
            return item[this.defaultAttributes.optionValue];
          }
          return item;
        }), item => ![undefined, 'undefined'].includes(item));
      } else {
        this.localValue = !(this.field.value === undefined || this.field.value === null)
          ? this.field.value
          : '';
      }
      if (this.localValue !== '' && this.localValue !== null) {
        let options = _.cloneDeep(this.options);

        if (this.field.extraAttributes && this.field.extraAttributes.groupValues !== undefined) {
          options = [];
          _.forEach(this.options, (item) => {
            if (_.isArray(item.children)) {
              options.push(...item.children);
            }
          });
        }

        if (!(this.field.extraAttributes && this.field.extraAttributes.multiple)) {
          if (this.extraAttributes.fullValue) {
            this.selectValue = this.field.value;
          } else {
            this.selectValue = _.find(options, item => item[this.defaultAttributes.optionValue] === this.localValue);
          }
        } else {
          const selectValue = [];
          _.forEach(this.localValue, (iteration) => {
            selectValue.push(
              this.options.find(option => option[this.defaultAttributes.optionValue] === iteration),
            );
          });
          this.selectValue = selectValue;
        }
      } else if (this.field.values) {
        if (this.field.values.length === 1) {
          this.selectValue = this.field.values[0];
          this.localValue = this.field.values[0][this.defaultAttributes.optionValue];
          this.emitChange(this.field.values[0]);
        } else if (_.find(this.field.values, option => option[this.defaultAttributes.optionValue] === null)) {
          this.selectValue = _.find(this.field.values, option => option[this.defaultAttributes.optionValue] === null);
          this.localValue = this.selectValue[this.defaultAttributes.optionValue];
        } else {
          this.selectValue = null;
        }
      } else {
        this.selectValue = null;
      }
      this.emitChange(this.selectValue);
    },
  },
  created() {
    eventBus.$on(`reload-refs:${this.field.name}`, async () => {
      await this.loadValues();
    });
  },
  async mounted() {
    if (this.field.loadValues) {
      this.field.loadValues.reload = this.loadValues;
      this.field.loadValues.setEmpty = this.setEmpty;
      if (!this.field.loadValues.hasOwnProperty('autoLoad') || this.field.loadValues.autoLoad) {
        await this.loadValues();
      }
    }
    this.setInitialSelectValue();
    this.filteredOptions = this.options;
  },
  watch: {
    value(val) {
      // update value
      if (!_.isEqual(val, this.localValue)) {
        this.setInitialSelectValue();
      }
    },
    options(val, oldval) {
      // update value
      if (!_.isEqual(val, oldval)) {
        this.setInitialSelectValue();
      }
    },
  },
  computed: {
    options() {
      if (this.loadedValues.length) {
        return this.loadedValues;
      }
      if (this.field.values && this.field.values.length > 0) {
        this.field.values = _.forEach(this.field.values, (item) => {
          if (item.hasOwnProperty('')) {
            item.name = this.trans(item.nameTransKey);
          }
        });
      }
      return this.field.values;
    },
    defaultAttributes() {
      return {
        name: this.field.name,
        filled: this.field?.extraAttributes?.multiple,
        placeholder: this.selectValue === null ? this.trans('placeholder.choose') : null,
        oldSearch: _.get(this.field, 'extraAttributes.oldSearch', false),
        useInput: _.get(this.field, 'extraAttributes.useInput', true),
        optionValue: _.get(this.field, 'extraAttributes.optionValue', 'id'),
        optionLabel: _.get(this.field, 'extraAttributes.optionLabel', 'name'),
        disable: _.get(this.field, 'disabled', false)
          || _.get(this.field, 'disable', false)
          || _.get(this.field, 'extraAttributes.disable', false)
          || _.get(this.field, 'extraAttributes.disabled', false),
      };
    },

    extraAttributes() {
      const attrs = this.field.extraAttributes;

      return {
        ...this.defaultAttributes,
        ...attrs,
      };
    },
  },
};
