import AttendanceTimeSheetApiProvider
  from '../../../providers/Attendance/AttendanceTimeSheetApiProvider';
import TimeSheetRepository
  from '../../../repositories/attendance/TimeSheetRepository';
import TimeSheetMarkRepository
  from '../../../repositories/attendance/TimeSheetMarkRepository';
import TimeSheetAttendanceMarkRepository
  from '../../../repositories/attendance/TimeSheetAttendanceMarkRepository';
import TimeSheetMassMarkRepository
  from '../../../repositories/attendance/TimeSheetMassMarkRepository';

const TimeSheetApiProvider = {
  data: () => ({
    apiProvider: {},
  }),
  methods: {
    initProvider({
      timeSheetGet, timeSheetMark, timeSheetMassMark, timeSheetAttendanceMark,
    }) {
      const trans = this.$trans.get;
      this.apiProvider = new AttendanceTimeSheetApiProvider({
        TimeSheetRepository: new TimeSheetRepository(timeSheetGet),
        TimeSheetMarkRepository: new TimeSheetMarkRepository(timeSheetMark),
        TimeSheetMassMarkRepository: new TimeSheetMassMarkRepository(timeSheetMassMark),
        TimeSheetAttendanceMarkRepository: new TimeSheetAttendanceMarkRepository(timeSheetAttendanceMark),
      }, trans);
    },
  },
};

export default TimeSheetApiProvider;
