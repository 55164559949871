import { FormField, StoreTranslator } from '@common/mixins';
import {
  QDate, QTime, QPopupProxy, QInput, QIcon,
} from '@quasar/components';
import _ from "lodash";

export default {
  mixins: [StoreTranslator, FormField],
  components: {
    QDate, QTime, QPopupProxy, QInput, QIcon,
  },
  methods: {
    getError() {
      const vm = this;
      return _.first(vm.errors);
    },
  },
  computed: {
    defaultAttributes() {
      return {
        name: this.field.name,
        disable: _.get(this.field, 'disabled', false)
          || _.get(this.field, 'disable', false)
          || _.get(this.field, 'extraAttributes.disable', false)
          || _.get(this.field, 'extraAttributes.disabled', false),
      };
    },

    extraAttributes() {
      const attrs = this.field.extraAttributes;

      return {
        ...this.defaultAttributes,
        ...attrs,
      };
    },
  },
};
