var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('q-dialog', {
    attrs: {
      "value": _vm.modal
    },
    on: {
      "input": _vm.handleModal
    }
  }, [_c('v-modal', {
    attrs: {
      "title": _vm.trans('label.photo_edit')
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-center mb-30"
  }, [_c('div', {
    staticClass: "image-container",
    staticStyle: {
      "margin-right": "3rem"
    }
  }, [_c('img', {
    ref: "image",
    staticClass: "image-preview",
    attrs: {
      "src": _vm.image
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "image-container"
  }, [_c('img', {
    attrs: {
      "src": _vm.previewCropped
    }
  })])]), _vm._v(" "), _c('div', {
    staticClass: "d-flex justify-content-center mb-30"
  }, [_c('q-btn', {
    staticStyle: {
      "margin-right": "1rem"
    },
    attrs: {
      "squire": "",
      "icon": "back-left"
    },
    on: {
      "click": _vm.rotateLeft
    }
  }, [_c('q-tooltip', [_vm._v(" " + _vm._s(_vm.trans('label.photo_album_actions.rotate_left')))])], 1), _vm._v(" "), _c('q-btn', {
    staticStyle: {
      "margin-right": "1rem"
    },
    attrs: {
      "squire": "",
      "icon": "back-right"
    },
    on: {
      "click": _vm.rotateRight
    }
  }, [_c('q-tooltip', [_vm._v(" " + _vm._s(_vm.trans('label.photo_album_actions.rotate_right')))])], 1), _vm._v(" "), _c('q-btn', {
    attrs: {
      "squire": "",
      "icon": "trash"
    },
    on: {
      "click": function click($event) {
        _vm.modalConfirm = true;
      }
    }
  }, [_c('q-tooltip', [_vm._v(" " + _vm._s(_vm.trans('label.photo_album_actions.delete_photo')))])], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-end"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-200",
    attrs: {
      "icon": "angle-left",
      "color": "grey",
      "label": _vm.trans('button.cancel'),
      "no-caps": "",
      "label-wider": ""
    },
    on: {
      "click": _vm.handleModal
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-auto"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-200",
    attrs: {
      "icon": "check",
      "color": "green",
      "label": _vm.trans('button.enter'),
      "no-caps": "",
      "label-wider": ""
    },
    on: {
      "click": _vm.requestSaveFile
    }
  })], 1)])])], 1), _vm._v(" "), _vm.modalConfirm ? _c('v-confirm-dialog', {
    attrs: {
      "modal": _vm.modalConfirm,
      "confirm": _vm.requestDeleteFile
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.modalConfirm = $event;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }