import { requestWrapper } from '@vjs/helpers';
import { QBtn } from '@quasar/components';
import { Loading } from '@quasar/plugins';
import VPhotoAlbum from '@common/components/VPhotoAlbum';
import VPieChart from '@vjs/components/VPieChart';

export default {
  name: 'VOrganizationProfile',
  components: {
    QBtn,
    VPhotoAlbum,
    VPieChart,
  },
  props: {
    uriGetData: {
      type: String,
      required: true,
    },
    uriGetPhotos: {
      type: String,
      required: true,
    },
    editLink: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      name: '',
      fields: [],
      statistics: {
        rows: [],
        pie: {},
      },
      description: '',
      orgType: null,
      images: [],
      qLoading: Loading,
    };
  },
  async mounted() {
    await this.$trans.add(['organization']);
    await this.requestGetData();
    await this.requestGetPhotos();
    this.$eventHub.$on('photo-added', this.requestGetPhotos);
    this.$eventHub.$on('photo-edited', this.requestGetPhotos);
    this.$eventHub.$on('renew-photos', this.requestGetPhotos);
  },
  methods: {
    async requestGetData() {
      this.qLoading.show({ message: this.trans('label.wait_loading') });
      const res = await requestWrapper.call(this, {
        url: this.uriGetData,
      });

      if (!res.error) {
        this.name = res.data.name;
        this.fields = res.data.fields;
        this.description = res.data.description;
        this.orgType = res.data.orgType;

        this.statistics = res.data.statistics ?? { rows: [], pie: {} };
      }
      this.qLoading.hide();
    },
    async requestGetPhotos() {
      this.qLoading.show({ message: this.trans('label.wait_loading') });
      const res = await requestWrapper.call(this, {
        url: this.uriGetPhotos,
      });

      if (!res.error) {
        this.images = res.data;
      }
      this.qLoading.hide();
    },
  },
};
