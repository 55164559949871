import _ from 'lodash';
import {
  requestWrapper,
} from '@vjs/helpers';
import {
  QSelect,
  QTable,
  QIcon,
  QTr,
  QTh,
  QTd,
  QBtn,
  QTooltip,
  QInput,
} from '@quasar/components';
import { Loading } from '@quasar/plugins';
import axios from 'axios';

export default {
  name: 'VContingentMonitoringView',
  components: {
    QTooltip, QSelect, QTable, QIcon, QTr, QTh, QTd, QBtn, QInput,
  },
  props: {
    apiUrl: {
      type: String,
    },
    mode: {
      type: String,
    },
    locality: {
      type: String,
    },
    uriFiltersLocality: {
      type: String,
    },
  },
  data: () => ({
    pagination: {
      rowsPerPage: 10,
      page: 1,
    },
    table: {
      inFullscreen: false,
      columns: [],
      colNames: [],
      title: '',
      totalContingent: 0,
    },
    form: {
      locality: null,
      page: 1,
    },
    qLoading: Loading,
    rows: [],
    locale: '',
    loading: false,
    filter: '',
    localityOptions: [],
    localityOptionsRaw: [],
  }),
  async mounted() {
    const { core_project } = window;
    this.locale = core_project.locale || 'kz';
    await this.$trans.add(['statistics', 'button', 'menu', 'placeholder', 'main']);
    await this.requestGetLocalities();
    await this.requestGetData();
    await this.addAllFilterField();
  },

  methods: {
    addAllFilterField() {
      this.localityOptionsRaw.unshift({
        label: this.trans('main.all'),
        value: '',
      });
    },
    async requestGetLocalities() {
      const localityRes = await requestWrapper({
        url: this.uriFiltersLocality,
      });
      if (localityRes.error) {
        if (localityRes.errorType === ERR_404 && _.isBoolean(localityRes.data.region)) {
          this.modalRegionSelect = true;
        }
        return;
      }
      this.localityOptions = localityRes.data;
      this.localityOptionsRaw = localityRes.data;
    },
    async requestGetData() {
      this.qLoading.show({ message: this.trans('label.wait_loading') });
      const dataRes = await requestWrapper({
        url: this.apiUrl,
        params: {
          locality: this.form.locality,
        },
      });
      if (!dataRes.error) {
        this.rows = dataRes.data.rows;
        this.table.colNames = dataRes.data.colNames;
        this.table.columns = dataRes.data.cols;
        this.table.totalContingent = dataRes.data.totalContingent;
      }
      this.qLoading.hide();
    },
    async clearFilters() {
      this.form.locality = '';
      await this.requestGetData();
    },
    sum(col) {
      const rows = _.filter(
        this.rows,
        item => typeof item[col] === 'number',
      );
      return _.sumBy(rows, col);
    },
    async exportu(e) {
      this.loading = true;
      axios({
        url: `${this.apiUrl}?export=1`,
        method: 'GET',
        responseType: 'blob',
        params: {
          locality: this.form.locality,
        },
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        const date = new Date();

        fileLink.setAttribute('download',
          `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()
          } ${this.$trans.get('menu.monitoring_new.contingent')
          } .xlsx`);
        document.body.appendChild(fileLink);

        fileLink.click();
        this.loading = false;
      });
    },
  },
};
