import { QBtn, QForm, QInput } from '@quasar/components';
import { Loading } from '@quasar/plugins';
import { mask } from 'vue-the-mask';
import { requestWrapper } from '../../helpers';

export default {
  name: 'VFeedbackForm',

  components: {
    QForm,
    QInput,
    QBtn,
  },

  directives: {
    mask,
  },

  props: {
    warningMessage: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
    isWarningEnabled: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      name: null,
      phone_number: null,
      email: null,
      message: null,
      qLoading: Loading,
      loading: false,
    };
  },
  async  mounted() {
    await this.$trans.add([
      'feedback',
    ]);
  },
  methods: {
    async requestAction() {
      this.loading = true;
      this.qLoading.show();
      const data = {
        name: this.name,
        phone_number: this.phone_number,
        email: this.email,
        message: this.message,
      };
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.action,
        data,
      });

      if (res.error) {
        this.loading = false;
        this.qLoading.hide();
      }
      if (res.data.errors && res.errorType === 422) {
        this.$notify({
          type: 'error',
          text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
        });
      } else if (res.data.message !== undefined) {
        this.$notify({
          type: 'error',
          text: res.data.message,
        });
      }
    },
  },
};
