var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "faq-cont-wrapper"
  }, [_c('div', {
    staticClass: "faq-cont__sections-select"
  }, _vm._l(_vm.items, function (item) {
    var _class;

    return _c('div', {
      key: item.id,
      staticClass: "faq-cont__section-block",
      class: (_class = {}, _class["s-ico--thm-".concat(_vm.checkIconName(item.icon))] = true, _class['is-active'] = _vm.currentSectionIdMutated === item.id || _vm.queryMutated.param === item.name, _class),
      on: {
        "click": function click($event) {
          _vm.currentSectionIdMutated = item.id;
        }
      }
    }, [_c('div', {
      staticClass: "faq-section-block__title"
    }, [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]), _vm._v(" "), _c('div', {
      staticClass: "faq-section-block__description",
      domProps: {
        "innerHTML": _vm._s(item.text)
      }
    })]);
  }), 0), _vm._v(" "), _c('div', {
    staticClass: "faq-cont__answear-list"
  }, [_vm.currentSectionIdMutated === '' && !_vm.queryMutated.answer && !_vm.queryMutated.section && Object.keys(_vm.items).length != 0 ? _c('h4', {
    staticClass: "mb-10"
  }, [_vm._v("\n      " + _vm._s(_vm.trans('label.faq')) + "\n    ")]) : _vm._e(), _vm._v(" "), _vm._t("spoilers", null, {
    "answers": _vm.getAnswearList,
    "query": _vm.queryMutated
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }