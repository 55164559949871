var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-statement-create-view pt-30"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-4"
  }, [_c('v-steps')], 1), _vm._v(" "), _c('div', {
    staticClass: "col-lg-8"
  }, [_c('v-steps-body', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('template', {
    slot: "content"
  }, [_vm.current === 1 ? _c('v-step-information', {
    key: "1",
    attrs: {
      "uri-about": _vm.uriAbout,
      "uri-offer-modal": _vm.uriOfferModal
    },
    on: {
      "loading": _vm.loadingProcessing
    }
  }) : _vm._e(), _vm._v(" "), _vm.current === 2 ? _c('v-step-authentication', {
    key: "2",
    attrs: {
      "uri-send-phone": _vm.uriSendPhone,
      "uri-send-code": _vm.uriSendCode,
      "uri-send-auth": _vm.uriSendAuth,
      "uri-send-restore-phone": _vm.uriSendRestorePhone,
      "uri-send-restore-code": _vm.uriSendRestoreCode,
      "uri-send-restore-password": _vm.uriSendRestorePassword
    },
    on: {
      "send-data": _vm.sendData,
      "loading": _vm.loadingProcessing
    }
  }) : _vm._e(), _vm._v(" "), _vm.current === 3 ? _c('v-step-userinfo', {
    key: "3",
    attrs: {
      "uri-get-profile": _vm.uriGetProfile,
      "uri-send-profile-update": _vm.uriSendProfileUpdate,
      "uri-send-iin": _vm.uriSendIin,
      "uri-send-password": _vm.uriSendPassword,
      "uri-nationalities": _vm.uriNationalities,
      "reference-time": _vm.referenceTime,
      "data-fields": _vm.authData
    },
    on: {
      "loading": _vm.loadingProcessing
    }
  }) : _vm._e(), _vm._v(" "), _vm.current === 4 ? _c('v-step-add-child', {
    key: "4",
    attrs: {
      "uri-send-child-iin": _vm.uriSendChildIin,
      "uri-send-child": _vm.uriSendChild,
      "reference-time": _vm.referenceTime
    },
    on: {
      "send-data": _vm.sendUserInfo,
      "loading": _vm.loadingProcessing
    }
  }) : _vm._e(), _vm._v(" "), _vm.current === 5 ? _c('v-step-add-statement', {
    key: "5",
    attrs: {
      "uri-get-statement-form-data": _vm.uriGetStatementFormData,
      "uri-send-statement": _vm.uriSendStatement,
      "uri-get-lgot-types": _vm.uriGetLgotTypes,
      "uri-get-lgot-type-documents": _vm.uriGetLgotTypeDocuments,
      "reference-time": _vm.referenceTime,
      "parent-id": _vm.userInfo.parentId,
      "child-id": _vm.userInfo.childId,
      "status-options": _vm.userInfo.statusOptions,
      "queue-type-options": _vm.queueTypes
    },
    on: {
      "loading": _vm.loadingProcessing
    }
  }) : _vm._e(), _vm._v(" "), _vm.current === 6 ? _c('v-step-congratulations', {
    key: "6",
    attrs: {
      "uri-static": _vm.uriStatic,
      "link-statements": _vm.linkStatements
    }
  }) : _vm._e()], 1)], 2)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }