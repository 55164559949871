var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row justify-content-end mb-10"
  }, [_c('div', {
    staticClass: "col-12 col-sm-auto mb-20"
  }, [_vm.files.length > 1 ? _c('div', {
    staticClass: "s-btn s-mw-200 s-w-100",
    class: "\n          s-btn--thm-".concat(_vm.isMultiDeleteActive ? _vm.selectedFiles.length ? 'red' : 'grey' : 'orange', "\n        "),
    on: {
      "click": _vm.handleMultiDeleteBtn
    }
  }, [_c('div', {
    staticClass: "s-str-ico s-str-ico--thm-trash s-str-ico--wider"
  }, [_vm._v("\n          " + _vm._s(_vm.deleteBtnLabel) + "\n        ")])]) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "col-12 col-sm-auto mb-20"
  }, [_vm.isMultiDeleteActive ? _c('div', {
    staticClass: "s-btn s-btn--thm-orange s-mw-200 s-w-100",
    on: {
      "click": _vm.handleSelectAllFilesBtn
    }
  }, [_c('div', [_vm._v("\n          " + _vm._s(_vm.files.length === _vm.selectedFiles.length ? _vm.trans('button.deselect_all_files') : _vm.trans('button.select_all_files')) + "\n        ")])]) : _c('div', {
    staticClass: "s-btn s-btn--thm-green s-mw-200 s-w-100",
    on: {
      "click": function click($event) {
        return _vm.onClickInputFile();
      }
    }
  }, [_c('div', {
    staticClass: "s-str-ico s-str-ico--thm-plus s-str-ico--wider"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('button.add_file')) + "\n        ")])]), _vm._v(" "), _c('input', {
    staticClass: "hidden",
    attrs: {
      "id": "user-file-upload-input",
      "type": "file"
    },
    on: {
      "change": _vm.onChangeInputFile
    }
  })])]), _vm._v(" "), _c('div', {
    staticClass: "row"
  }, _vm._l(_vm.files, function (file, fileIdx) {
    return _c('div', {
      key: fileIdx,
      staticClass: "col-12 col-sm-6 col-md-4 col-xl-3 mb-30"
    }, [_c('div', {
      staticClass: "s-file-select",
      class: {
        's-file-select--selected': _vm.selectedFiles.includes(file.id)
      },
      on: {
        "click": function click($event) {
          return _vm.handleAddFileToDelete(file.id);
        }
      }
    }, [_c('div', {
      staticClass: "s-file__preview",
      style: {
        backgroundImage: 'url(' + file.url + ')'
      }
    }, [_vm.isMultiDeleteActive ? _c('div', {
      staticClass: "s-file__overlay-control"
    }, [_c('div', {
      staticClass: "s-file__btn s-file__btn--thm-select"
    })]) : _vm._e()]), _vm._v(" "), _c('div', {
      staticClass: "s-file__name"
    }, [_c('div', {
      staticClass: "s-str-ico s-str-ico--thm-clip",
      attrs: {
        "title": file.name
      }
    }, [_vm._v("\n            " + _vm._s(file.name) + "\n          ")])]), _vm._v(" "), !_vm.isMultiDeleteActive ? _c('div', {
      staticClass: "row justify-content-center",
      staticStyle: {
        "padding": "0 1rem"
      }
    }, [_c('a', {
      staticClass: "s-file__btn s-file__btn--thm-show",
      staticStyle: {
        "margin-right": "1rem"
      },
      attrs: {
        "href": file.url,
        "target": "_blank"
      }
    }, [_c('div', {
      staticClass: "s-ico s-ico--thm-eye"
    })]), _vm._v(" "), _c('a', {
      staticClass: "s-file__btn s-file__btn--thm-download",
      staticStyle: {
        "margin-right": "1rem"
      },
      attrs: {
        "href": _vm.urlFilesDownload + '/' + file.id,
        "target": "_blank"
      }
    }, [_c('div', {
      staticClass: "s-ico s-ico--thm-download"
    })]), _vm._v(" "), _c('div', {
      staticClass: "s-file__btn s-file__btn--thm-remove",
      on: {
        "click": function click($event) {
          return _vm.openConfirm(file);
        }
      }
    }, [_c('div', {
      staticClass: "s-ico s-ico--thm-trash"
    })])]) : _vm._e()])]);
  }), 0), _vm._v(" "), _c('div', {
    staticClass: "s-modal s-modal--small fade",
    attrs: {
      "id": "modal-confirm-destroy",
      "tabindex": "-1"
    }
  }, [_c('div', {
    staticClass: "s-modal__container"
  }, [_c('div', {
    staticClass: "s-modal__head"
  }, [_c('div', {
    staticClass: "s-modal__title"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('notice.confirm')) + "\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "s-modal__close",
    attrs: {
      "data-toggle": "modal",
      "data-target": "#modal-confirm-destroy"
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "s-modal__body"
  }, [_vm.isMultiDeleteActive ? _c('span', [_vm._v("\n          " + _vm._s(_vm.trans('label.user_files_multi_delete', {
    count: _vm.selectedFiles.length
  })) + "\n        ")]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "row-sort-between"
  }, [_c('button', {
    staticClass: "s-btn s-btn--thm-blue s-btn--w-150 mt-10 mb-10",
    attrs: {
      "data-toggle": "modal",
      "type": "button",
      "data-target": "#modal-confirm-destroy"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.trans('button.cancel')) + "\n          ")]), _vm._v(" "), _c('button', {
    staticClass: "s-btn s-btn--thm-green s-btn--w-150 mt-10 mb-10",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": function click($event) {
        return _vm.destroy(_vm.currentFile);
      }
    }
  }, [_vm._v("\n            " + _vm._s(_vm.trans('button.approve')) + "\n          ")])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }