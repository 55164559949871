import _ from 'lodash';
import {
  QBtn,
  QRadio,
  QField,
  QSelect,
  QCheckbox,
} from '@quasar/components';
import VRegionSelect
  from '@indv/balabaqsha/javascript/components/VRegionSelect';
import MXFilters from '@vjs/mixins/MXFilters';
import {
  requestWrapper,
} from '@vjs/helpers';
import { ERR_404 } from '@vjs/helpers/requestWrapper';

export default {
  name: 'VDirectionsFilters',
  mixins: [MXFilters],
  components: {
    QBtn,
    QRadio,
    QField,
    QSelect,
    QCheckbox,
    VRegionSelect,
  },
  props: {
    uriGetOrganizations: {
      type: String,
      required: true,
    },
    uriLocality: {
      type: String,
      required: true,
    },
    uriFilters: {
      type: String,
      required: true,
    },
    uriGetDirections: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      orgNameOptions: [],
      orgNameSelected: this.currentOrgID,
      yearSelected: null,
      filterItems: [],
      languageSelected: null,
      localitySelected: null,
    };
  },
  async mounted() {
    this.loading = true;
    this.$emit('filter-loading', true);

    // Запрос населённых пунктов
    const localityRes = await requestWrapper({
      url: this.uriLocality,
    });


    if (localityRes.error) {
      this.loading = false;
      this.$emit('filter-loading', false);

      if (localityRes.errorType === ERR_404 && _.isBoolean(localityRes.data.region)) {
        this.modalRegionSelect = true;
      }
    }

    this.localityOptions = localityRes.data;
    this.filterItems = await this.requestFilters();
    this.loadFiltersFromLocalStorage({}, 'catalogFilter');
    // Проверка на соответствие нас. пунтку при смене региона
    const checkLocality = _.find(
      this.localityOptions,
      loc => typeof this.localitySelected !== 'undefined' && loc.value === this.localitySelected,
    );
    if (!checkLocality) {
      this.localitySelected = this.localityOptions[0]?.value;
    }

    await this.requestOrg();
    await this.getItems();
    this.loading = false;
    this.$emit('filter-loading', false);
  },

  beforeCreate() {
    this.$trans.add(['bulletin', 'label', 'kindergarten']);
  },
  methods: {
    // Передаёт полученный отфильтрованный список через событие
    async getItems() {
      this.loading = true;
      this.$emit('filter-loading', true);
      const data = await this.requestItems();
      this.saveFiltersToLocalStorage({
        localitySelected: this.localitySelected,
        languageSelected: this.languageSelected,
      }, 'catalogFilter');
      this.loading = false;
      this.$emit('filter-loading', false);
      this.$emit('filtered-list', {
        data,
      } || []);
    },
    // Отправляет запрос на получение отфильтрованных садов
    async requestItems() {
      const itemsRes = await requestWrapper.call(this, {
        method: 'get',
        url: this.uriGetDirections,
        params: {
          locality: this.localitySelected,
          year: this.yearSelected,
          language: this.languageSelected,
          org: this.orgNameSelected,
        },
      });
      let items = [];


      if (!itemsRes.error) {
        // TODO this.$notify();
        items = (_.isArray(itemsRes.data) && itemsRes.data) || [];
      }

      return {
        items,
      };
    },
    // Запускает фильтрацию
    async handleApplyFilters() {
      this.getItems();
    },
    async requestOrg() {
      const orgRes = await requestWrapper.call(this, {
        url: this.uriGetOrganizations,
        params: {
          locality: this.localitySelected,
        },
      });
      this.orgNameOptions = (_.isArray(orgRes.data) && orgRes.data) || [];

      if (this.orgNameSelected && this.orgNameSelected.value && this.orgNameOptions.length) {
        this.orgNameSelected = { value: null };
      }
    },

    // Получает список доступных фильтров
    async requestFilters() {
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.uriFilters,
      });
      const result = {};

      if (!res.error) {
        Object.keys(res.data).forEach((item) => {
          result[item] = (_.isArray(res.data[item]) && res.data[item]) || [];
        });
      }

      return result;
    },
    // Сбрасывает установленные фильтры на значение по умолчанию
    async resetFilters() {
      this.languageSelected = null;
      this.yearSelected = null;
      this.orgNameSelected = null;

      if (localStorage && localStorage.getItem('directionFilter')) {
        localStorage.removeItem('directionFilter');
      }

      await this.getItems();
    },
    // Запускается после выбора региона в модальном окне VRegionSelect
    async handleUpdateLocality(val) {
      this.localityOptions = val.data;
      this.filterItems = await this.requestFilters();
      const resultLoadingFromStorage = this.loadFiltersFromLocalStorage({
        localitySelected: val.selected,
      }, 'catalogFilter');

      if (!resultLoadingFromStorage) {
        this.localitySelected = val.selected;
      }

      await this.getItems();
    },
  },
};
