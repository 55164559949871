import AttendanceJournalApiProvider
  from '../../../providers/Attendance/AttendanceJournalApiProvider';
import JournalRepository
  from '../../../repositories/attendance/JournalRepository';
import JournalMarkRepository
  from '../../../repositories/attendance/JournalMarkRepository';
import JournalConfirmRepository
  from '../../../repositories/attendance/JournalConfirmRepository';

const JournalApiProvider = {
  data: () => ({
    apiProvider: {},
  }),
  methods: {
    initProvider({ journalListApi, journalMarkApi, journalConfirmApi }) {
      const trans = this.$trans.get;
      this.apiProvider = new AttendanceJournalApiProvider({
        JournalRepository: new JournalRepository(journalListApi),
        JournalMarkRepository: new JournalMarkRepository(journalMarkApi),
        JournalConfirmRepository: new JournalConfirmRepository(journalConfirmApi),
      }, trans);
    },
  },
};

export default JournalApiProvider;
