var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-field', {
    attrs: {
      "field": _vm.field,
      "field-name": _vm.fieldName,
      "errors": _vm.errors
    }
  }, [_c('template', {
    slot: "field"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.field.value)
    }
  })])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }