const PriceFormat = {
  methods: {
    formatPrice(value) {
      const val = (value / 1).toFixed(2);
      return val.toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ₸';
    },
    formatPriceNoCurrency(value) {
      const val = (value / 1).toFixed(2);
      return val.toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
  },
};

export default PriceFormat;
