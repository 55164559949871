<template>
  <q-dialog
    :value="modal"
    @input="handleModal"
    persistent
  >
    <v-modal
      :size="size"
      :title="title || trans('notice.confirm')"
    >
      <div v-if="text" :class="{'s-light': lightText}">
        {{ text }}
      </div>
      <div class="row-sort-between-btn">
        <q-btn
          icon="close"
          width="20"
          :label="trans('button.cancel')"
          labelWider
          :color="declineColor"
          v-close-popup
        />
        <q-btn
          icon="check"
          width="20"
          :label="trans('button.approve')"
          labelWider
          :color="submitColor"
          :loading="loading"
          @click="confirm"
        />
      </div>
    </v-modal>
  </q-dialog>
</template>

<script>
import {
  QDialog,
  QBtn,
} from '@quasar/components';
import { ClosePopup } from '@quasar/directives';
import VModal from '@vjs/components/VModal';


export default {
  name: 'VModalConfirm',

  components: {
    QDialog,
    QBtn,
    VModal,
  },

  directives: { ClosePopup },

  props: {
    modal: Boolean,
    confirm: {
      type: Function,
      required: true,
    },
    title: String,
    text: String,
    lightText: Boolean,
    loading: Boolean,
    submitColor: {
      type: String,
      default: 'orange',
    },
    declineColor: {
      type: String,
      default: 'grey',
    },
    size: {
      type: String,
      default: () => '480',
    },
  },

  beforeCreate() {
    this.$trans.add(['notice', 'button']);
  },

  methods: {
    handleModal() {
      this.$emit('modal-close', false);
    },
  },
};
</script>
