var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-field', {
    attrs: {
      "field": _vm.field,
      "field-name": _vm.fieldName,
      "errors": _vm.errors
    }
  }, [_c('template', {
    slot: "field"
  }, [_c('vue-select2', _vm._b({
    staticClass: "mb-10",
    model: {
      value: _vm.localValue,
      callback: function callback($$v) {
        _vm.localValue = $$v;
      },
      expression: "localValue"
    }
  }, 'vue-select2', _vm.extraAttributes, false), [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("\n        " + _vm._s(_vm.trans('placeholder.choose')) + "\n      ")]), _vm._v(" "), _c('option', [_vm._v("09:00")]), _vm._v(" "), _c('option', [_vm._v("09:30")]), _vm._v(" "), _c('option', [_vm._v("10:00")]), _vm._v(" "), _c('option', [_vm._v("10:30")]), _vm._v(" "), _c('option', [_vm._v("11:00")]), _vm._v(" "), _c('option', [_vm._v("11:30")]), _vm._v(" "), _c('option', [_vm._v("12:00")]), _vm._v(" "), _c('option', [_vm._v("12:30")]), _vm._v(" "), _c('option', [_vm._v("13:00")]), _vm._v(" "), _c('option', [_vm._v("13:30")]), _vm._v(" "), _c('option', [_vm._v("14:00")]), _vm._v(" "), _c('option', [_vm._v("14:30")]), _vm._v(" "), _c('option', [_vm._v("15:00")]), _vm._v(" "), _c('option', [_vm._v("15:30")]), _vm._v(" "), _c('option', [_vm._v("16:00")]), _vm._v(" "), _c('option', [_vm._v("16:30")]), _vm._v(" "), _c('option', [_vm._v("17:00")]), _vm._v(" "), _c('option', [_vm._v("17:30")]), _vm._v(" "), _c('option', [_vm._v("18:00")]), _vm._v(" "), _c('option', [_vm._v("18:30")]), _vm._v(" "), _c('option', [_vm._v("19:00")]), _vm._v(" "), _c('option', [_vm._v("19:30")]), _vm._v(" "), _c('option', [_vm._v("20:00")]), _vm._v(" "), _c('option', [_vm._v("20:30")])])], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }