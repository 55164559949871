var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('confirm', {
    attrs: {
      "active": _vm.confirmationPopup,
      "title": _vm.trans('notice.confirm'),
      "on-hide": _vm.decline,
      "persistent": _vm.loadingData,
      "light-text": true
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('span', {
    staticClass: "confirm-text"
  }, [_vm._v(_vm._s(_vm.trans('attendance.journal-lock-warn')))])]), _vm._v(" "), _c('div', {
    staticClass: "row confirm-control"
  }, [_c('q-btn', {
    attrs: {
      "disabled": _vm.loadingData,
      "outline": ""
    },
    on: {
      "click": _vm.decline
    }
  }, [_vm._v("\n          " + _vm._s(_vm.trans('button.cancel')) + "\n        ")]), _vm._v(" "), _c('q-btn', {
    attrs: {
      "disabled": _vm.loadingData,
      "color": "green"
    },
    on: {
      "click": _vm.confirm
    }
  }, [_vm._v("\n          " + _vm._s(_vm.trans('button.approve')) + "\n        ")])], 1)])]), _vm._v(" "), _vm.loadedData ? _c('div', {
    staticClass: "attendance-journal"
  }, [_vm.$q.screen.lt.md ? _c('h3', [_vm._v("\n      " + _vm._s(_vm.currentDate) + "\n    ")]) : _vm._e(), _vm._v(" "), _c('q-table', {
    attrs: {
      "flat": !_vm.$q.screen.lt.md,
      "grid": _vm.$q.screen.lt.md,
      "hide-bottom": "",
      "columns": _vm.columns,
      "data": _vm.contingent,
      "separator": "cell",
      "table-class": "journal-table",
      "rows-per-page": _vm.contingent.length
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, [_c('q-td', {
          key: "id",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n            " + _vm._s(props.row.__index) + "\n          ")]), _vm._v(" "), _c('q-td', {
          key: "full_name",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n            " + _vm._s(props.row.full_name) + "\n          ")]), _vm._v(" "), _c('q-td', {
          key: "child_id",
          staticClass: "sn--non-selectable attendance-row",
          attrs: {
            "props": props
          }
        }, [_c('q-radio', {
          attrs: {
            "disable": props.row.id === _vm.loadingItems || props.row.lock,
            "value": _vm.absentValue(props.row.id),
            "val": true,
            "label": _vm.trans('attendance.journal-absent-no')
          },
          on: {
            "input": function input(value) {
              return _vm.selectAbsent(props.row, value);
            }
          }
        }), _vm._v(" "), _c('q-radio', {
          attrs: {
            "disable": props.row.id === _vm.loadingItems || props.row.lock,
            "value": _vm.absentValue(props.row.id),
            "val": false,
            "label": _vm.trans('attendance.journal-absent-yes')
          },
          on: {
            "input": function input(value) {
              return _vm.selectAbsent(props.row, value);
            }
          }
        }), _vm._v(" "), _c('div', {
          staticClass: "row-load"
        }, [_vm.loadingItems.indexOf(props.row.id) !== -1 ? _c('q-spinner', {
          attrs: {
            "indeterminate": "",
            "color": "blue"
          }
        }) : _vm._e(), _vm._v(" "), _vm.loadingItems.indexOf(props.row.id) === -1 ? _c('div', {
          staticClass: "spinner-placeholder"
        }) : _vm._e()], 1)], 1), _vm._v(" "), props.row.showTimes ? _c('q-td', {
          key: "arrival_time",
          staticClass: "sn--non-selectable attendance-row",
          attrs: {
            "props": props
          }
        }, [_c('q-input', {
          attrs: {
            "filled": "",
            "mask": "##:##",
            "date": "",
            "disable": props.row.id === _vm.loadingItems || props.row.lockArrivalTime
          },
          model: {
            value: _vm.contingent[props.row.__index - 1].arrival_time,
            callback: function callback($$v) {
              _vm.$set(_vm.contingent[props.row.__index - 1], "arrival_time", $$v);
            },
            expression: "contingent[props.row.__index - 1].arrival_time"
          }
        })], 1) : _vm._e(), _vm._v(" "), props.row.showTimes ? _c('q-td', {
          key: "leaving_time",
          staticClass: "sn--non-selectable attendance-row",
          attrs: {
            "props": props
          }
        }, [_c('q-input', {
          attrs: {
            "filled": "",
            "mask": "##:##",
            "date": "",
            "disable": props.row.id === _vm.loadingItems || props.row.lockLeavingTime
          },
          on: {
            "change": function change(newValue) {
              return _vm.saveLeaveTime(props.row, newValue.target.value);
            }
          },
          model: {
            value: _vm.contingent[props.row.__index - 1].leaving_time,
            callback: function callback($$v) {
              _vm.$set(_vm.contingent[props.row.__index - 1], "leaving_time", $$v);
            },
            expression: "contingent[props.row.__index - 1].leaving_time"
          }
        })], 1) : _vm._e()], 1)];
      }
    }, {
      key: "item",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "sn-table__grid-item col-xs-12 col-sm-6 col-md-4 col-lg-3"
        }, [_c('div', {
          staticClass: "sn-table__card sn-table__grid-item-card sn-table--bordered sn-table--flat"
        }, _vm._l(props.cols, function (col) {
          return _c('div', {
            key: col.field,
            staticClass: "table__grid-item-row attendance-card-row"
          }, [col.field !== 'child_id' ? _c('div', {
            staticClass: "sn-table__grid-item-title"
          }, [_vm._v("\n                " + _vm._s(col.label) + "\n              ")]) : _vm._e(), _vm._v(" "), !['arrival_time', 'leaving_time', 'child_id', 'id'].includes(col.field) ? _c('div', {
            staticClass: "sn-table__grid-item-value"
          }, [_vm._v("\n                " + _vm._s(col.value) + "\n              ")]) : _vm._e(), _vm._v(" "), col.field === 'id' ? _c('div', {
            staticClass: "sn-table__grid-item-value"
          }, [_vm._v("\n                " + _vm._s(props.row.__index) + "\n              ")]) : _vm._e(), _vm._v(" "), props.row.showTimes && col.field === 'arrival_time' ? _c('div', {
            staticClass: "sn-table__grid-item-value"
          }, [_c('q-input', {
            attrs: {
              "filled": "",
              "mask": "##:##",
              "date": "",
              "disable": props.row.id === _vm.loadingItems || props.row.lockArrivalTime
            },
            model: {
              value: _vm.contingent[props.row.__index - 1].arrival_time,
              callback: function callback($$v) {
                _vm.$set(_vm.contingent[props.row.__index - 1], "arrival_time", $$v);
              },
              expression: "contingent[props.row.__index - 1].arrival_time"
            }
          })], 1) : _vm._e(), _vm._v(" "), props.row.showTimes && col.field === 'leaving_time' ? _c('div', {
            staticClass: "sn-table__grid-item-value"
          }, [_c('q-input', {
            attrs: {
              "filled": "",
              "mask": "##:##",
              "date": "",
              "disable": props.row.id === _vm.loadingItems || props.row.lockLeavingTime
            },
            on: {
              "change": function change(newValue) {
                return _vm.saveLeaveTime(props.row, newValue.target.value);
              }
            },
            model: {
              value: _vm.contingent[props.row.__index - 1][col.field],
              callback: function callback($$v) {
                _vm.$set(_vm.contingent[props.row.__index - 1], col.field, $$v);
              },
              expression: "contingent[props.row.__index - 1][col.field]"
            }
          })], 1) : _vm._e(), _vm._v(" "), col.field === 'child_id' ? _c('div', {
            staticClass: "sn-table__grid-item-value sn--non-selectable attendance-card-control"
          }, [_c('q-radio', {
            attrs: {
              "disable": props.row.id === _vm.loadingItems || props.row.lock,
              "value": _vm.absentValue(props.row.id),
              "val": true,
              "label": _vm.trans('attendance.journal-absent-no')
            },
            on: {
              "input": function input(value) {
                return _vm.selectAbsent(props.row, value);
              }
            }
          }), _vm._v(" "), _c('q-radio', {
            attrs: {
              "disable": props.row.id === _vm.loadingItems || props.row.lock,
              "value": _vm.absentValue(props.row.id),
              "val": false,
              "label": _vm.trans('attendance.journal-absent-yes')
            },
            on: {
              "input": function input(value) {
                return _vm.selectAbsent(props.row, value);
              }
            }
          }), _vm._v(" "), _vm.loadingItems.indexOf(props.row.id) !== -1 ? _c('q-spinner', {
            attrs: {
              "indeterminate": "",
              "color": "blue",
              "size": "1.5em"
            }
          }) : _vm._e()], 1) : _vm._e()]);
        }), 0)])];
      }
    }, {
      key: "bottom-row",
      fn: function fn(props) {
        return [_c('q-tr', [_c('q-td', {
          attrs: {
            "colspan": _vm.columns.length - 1
          }
        }, [_vm._v("\n            " + _vm._s(_vm.trans('attendance.journal-absent-total')) + "\n          ")]), _vm._v(" "), _c('q-td', [_vm._v("\n            " + _vm._s(_vm.totalAbsent) + "\n          ")])], 1), _vm._v(" "), _c('q-tr', [_c('q-td', {
          attrs: {
            "colspan": _vm.columns.length - 1
          }
        }, [_vm._v("\n            " + _vm._s(_vm.trans('attendance.journal-attend-total')) + "\n          ")]), _vm._v(" "), _c('q-td', [_vm._v("\n            " + _vm._s(_vm.totalAttend) + "\n          ")])], 1)];
      }
    }], null, false, 2698121640)
  }), _vm._v(" "), _vm.$q.screen.lt.md ? _c('div', {
    staticClass: "total-panel"
  }, [_c('div', {
    staticClass: "sn-table__card sn-table__grid-item-card sn-table--bordered sn-table--flat"
  }, [_c('div', {
    staticClass: "table__grid-item-row"
  }, [_c('div', {
    staticClass: "sn-table__grid-item-title"
  }, [_vm._v("\n            " + _vm._s(_vm.trans('attendance.journal-absent-total')) + "\n          ")]), _vm._v(" "), _c('div', [_vm._v("\n            " + _vm._s(_vm.totalAbsent) + "\n          ")])]), _vm._v(" "), _c('div', {
    staticClass: "table__grid-item-row"
  }, [_c('div', {
    staticClass: "sn-table__grid-item-title"
  }, [_vm._v("\n            " + _vm._s(_vm.trans('attendance.journal-attend-total')) + "\n          ")]), _vm._v(" "), _c('div', [_vm._v("\n            " + _vm._s(_vm.totalAttend) + "\n          ")])])])]) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.loadingData ? _c('div', {
    staticClass: "row load-panel"
  }, [_c('q-spinner', {
    attrs: {
      "indeterminate": "",
      "color": "blue",
      "size": "5em"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.loadedData ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 confirm-panel"
  }, [!_vm.lock ? _c('q-btn', {
    attrs: {
      "disable": !_vm.isAllSelected || _vm.loadingItems.length > 0,
      "color": "green",
      "type": "button",
      "label": _vm.trans('button.save')
    },
    on: {
      "click": _vm.askConfirm
    }
  }) : _vm._e()], 1)]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }