var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-field', {
    attrs: {
      "field": _vm.field,
      "field-name": _vm.fieldName,
      "errors": _vm.errors
    }
  }, [_c('template', {
    slot: "field"
  }, [_c('div', {
    staticClass: "row-sort-start"
  }, [_c('input', _vm._b({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.localValue,
      expression: "localValue"
    }, {
      name: "mask",
      rawName: "v-mask",
      value: {
        mask: '999999999999',
        placeholder: '____________'
      },
      expression: "{mask: '999999999999', placeholder: '____________' }"
    }],
    staticClass: "s-form__input",
    attrs: {
      "type": "text",
      "name": _vm.field.name
    },
    domProps: {
      "value": _vm.localValue
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.localValue = $event.target.value;
      }
    }
  }, 'input', _vm.extraAttributes, false)), _vm._v(" "), _c('button', {
    staticClass: "s-btn s-btn--thm-orange s-btn--w-150 ml-20",
    class: {
      'loading-progress': _vm.processSend
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onClickSearch($event);
      }
    }
  }, [_c('span', {
    staticClass: "s-str-ico s-str-ico--thm-search s-str-ico--wider"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('button.search')) + "\n        ")])])])])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }