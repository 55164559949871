var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-form', {
    staticClass: "v-edit-group-limit-dialog",
    on: {
      "submit": _vm.requestAction
    }
  }, [_c('q-input', {
    attrs: {
      "label": _vm.trans("table.throughput"),
      "error": _vm.hasValidationFail('limit'),
      "error-message": _vm.getValidationMessage('limit')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('limit');
      }
    },
    model: {
      value: _vm.limit,
      callback: function callback($$v) {
        _vm.limit = $$v;
      },
      expression: "limit"
    }
  }), _vm._v(" "), _c('q-input', {
    staticClass: "mb-30",
    attrs: {
      "label": _vm.trans("table.throughput_over"),
      "error": _vm.hasValidationFail('over_limit'),
      "error-message": _vm.getValidationMessage('over_limit')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('over_limit');
      }
    },
    model: {
      value: _vm.over_limit,
      callback: function callback($$v) {
        _vm.over_limit = $$v;
      },
      expression: "over_limit"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "s-group s-group--wider justify-content-between"
  }, [_c('q-btn', {
    attrs: {
      "width": "20",
      "no-caps": "",
      "color": "grey",
      "label": _vm.trans('button.close')
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('onClose');
      }
    }
  }), _vm._v(" "), _c('q-btn', {
    attrs: {
      "type": "submit",
      "width": "20",
      "no-caps": "",
      "color": "orange",
      "label": _vm.trans('button.save')
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }