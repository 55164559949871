import {
  QDialog,
  QBtn,
  QSpinner,
  QTooltip,
} from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import VModal from '@vjs/components/VModal';
import Cropper from 'cropperjs';
import _ from 'lodash';
import VConfirmDialog from '@vjs/modals/VConfirmDialog';

export default {
  name: 'VPhotoAlbumEditModal',

  components: {
    QDialog,
    QBtn,
    QSpinner,
    QTooltip,
    VModal,
    Cropper,
    VConfirmDialog,
  },

  props: {
    modal: Boolean,
    isCustom: Boolean,
    currentPic: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      cropper: null,
      previewCropped: null,
      debouncedUpdatePreview: _.debounce(this.updatePreview, 0),
      image: null,
      objectUrl: null,
      modalConfirm: false,
    };
  },

  async mounted() {
    await this.$trans.add([
      'label',
      'notice',
      'button',
    ]);

    this.setupCropper();
  },

  methods: {
    setupCropper() {
      if (this.cropper) {
        this.cropper.destroy();
      }

      if (this.image) {
        this.image = null;
      }

      this.image = this.currentPic.img;
      this.$nextTick(this.setupCropperInstance);
    },
    setupCropperInstance() {
      this.cropper = new Cropper(this.$refs.image, {
        crop: this.debouncedUpdatePreview,
      });
    },
    handleModal() {
      this.$emit('modal-close', false);
    },
    rotateLeft() {
      this.cropper.rotate(-90);
    },
    rotateRight() {
      this.cropper.rotate(90);
    },
    updatePreview(event) {
      const canvas = this.cropper.getCroppedCanvas();
      this.previewCropped = canvas.toDataURL('image/png');
    },
    async requestSaveFile() {
      const canvas = this.cropper.getCroppedCanvas();
      canvas.toBlob(async (blob) => {
        if (!this.isCustom) {
          const formData = new FormData();
          formData.append('file', blob);
          formData.append('photoId', this.currentPic.id);

          const res = await requestWrapper.call(this, {
            method: 'post',
            url: '/api/v1/control/organization/makeupAvatar',
            data: formData,
          });

          if (res.error) {
            this.$notify({
              type: 'error',
              title: window.L.error,
              text: this.trans('notice.error_on_server'),
            });
            return;
          }
        }
        this.$eventHub.$emit('photo-edited', blob);
        this.handleModal();
      });
    },
    async requestDeleteFile() {
      const formData = new FormData();
      formData.append('avatar', this.currentPic);
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: '/api/v1/control/organization/deleteAvatar',
        data: {
          photoId: this.currentPic.id,
        },
      });
      if (res.error) {
        this.$notify({
          type: 'error',
          title: window.L.error,
          text: this.trans('notice.error_on_server'),
        });
        return;
      }

      this.$emit('photo-deleted');
      this.handleModal();
    },
  },
};
