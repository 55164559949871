import { FormField, StoreTranslator } from '@common/mixins';
import _ from 'lodash';

export default {
  mixins: [StoreTranslator, FormField],
  props: {},
  methods: {
    getError() {
      const vm = this;
      return _.first(vm.errors);
    },
  },
  data() {
    let config = {
      mask: '9', repeat: 10,
    };
    if (this.field.hasOwnProperty('config')) {
      config = this.field.config;
    }
    return {
      config,
    };
  },
  computed: {
    defaultAttributes() {
      return {
        type: this.field.type || 'text',
        placeholder: this.field.placeholder,
      };
    },

    extraAttributes() {
      const attrs = this.field.extraAttributes;

      return {
        ...this.defaultAttributes,
        ...attrs,
      };
    },
  },
  watch: {
    localValue(value) {
      const { min, max } = this.config;
      if (![null, undefined].includes(min) && Number(value) < min) {
        this.localValue = min;
        return;
      }

      if (![null, undefined].includes(max) && Number(value) > max) {
        this.localValue = max;
        return;
      }

      // Дабы избежать значений по типу '000'
      if ((min || max) && value !== Number(value)) {
        this.localValue = Number(value);
      }
      this.fieldEmitInput(value);
    },
  },
};
