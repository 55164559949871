import axios from 'axios';
import _ from 'lodash';
import {
  requestWrapper,
} from '@vjs/helpers';
import {
  QInput,
  QDate,
  QPopupProxy,
  QSelect,
  QTable,
  QIcon,
  QTr,
  QTh,
  QTd,
  QBtn,
  QTooltip,
} from '@quasar/components';


export default {
  name: 'VQueueMonitoringView',
  components: {
    QInput, QDate, QPopupProxy, QSelect, QTable, QIcon, QTr, QTh, QTd, QBtn, QTooltip,
  },
  props: {
    apiUrl: {
      type: String,
    },
    withDate: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'bySocial',
    },
  },
  data: () => ({
    table: {
      inFullscreen: false,
      pagination: {
        rowsPerPage: 10,
      },
    },
    rows: [],
    cols: [],
    detailHeaders: [],
    loading: false,
    filters: {
      date: {
        from: '',
        to: '',
      },
    },
    filter: '',
  }),
  async mounted() {
    await this.requestGetData();
  },
  beforeCreate() {
    this.$trans.add(['statistics', 'button', 'menu', 'placeholder']);
  },
  methods: {
    clearFilters() {
      this.filters.date.to = '';
      this.filters.date.from = '';
    },
    async requestGetData() {
      this.loading = true;
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.apiUrl,
        params: {
          to: this.filters.date.to,
          from: this.filters.date.from,
        },
      });

      if (!res.error) {
        const { data } = res;
        this.rows = data?.rows ?? [];
        this.cols = data?.cols ?? [];
        this.detailHeaders = data?.detailHeaders ?? [];
      }
      this.loading = false;
      this.$globalLoading.hide();
    },
    sum(col) {
      return _.sumBy(this.rows, col.name);
    },
    async exportu(e) {
      this.loading = true;
      this.$globalLoading.show();
      axios({
        url: `${this.apiUrl}`,
        method: 'GET',
        responseType: 'blob',
        params: {
          to: this.filters.date.to,
          from: this.filters.date.from,
          export: true,
        },
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        const date = new Date();

        fileLink.setAttribute('download',
          `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()
          } ${this.withDate ? this.$trans.get('menu.monitoring_new.queue_portal') : this.$trans.get('menu.monitoring_new.queue')
          } ${this.filters.date.from ? this.$trans.get('statistics.file.from', { date: this.filters.date.from }) : ''
          } ${this.filters.date.to ? this.$trans.get('statistics.file.to', { date: this.filters.date.to }) : ''
          } .xlsx`);
        document.body.appendChild(fileLink);

        fileLink.click();
        this.loading = false;
        this.$globalLoading.hide();
      });
    },
  },
  watch: {
    'filters.date.from': function () {
      this.requestGetData();
    },
    'filters.date.to': function () {
      this.requestGetData();
    },
  },
};
