import Multiselect from 'vue-multiselect/src/Multiselect';
import { QForm, QSlideTransition, QField } from '@quasar/components';
import { VFormInform } from '@vjs/components';
import WrapperMultiSelect from './WrapperMultiSelect';
import DefaultField from './DefaultField';
import DateField from './DateField';
import TextField from './TextField';
import TextShowField from './TextShowField';
import CkeditorField from './CkeditorField';
import NumberField from './NumberField';
import RadioField from './RadioField';
import BooleanField from './BooleanField';
import VMultiSelectField from './VMultiSelectField';
import SelectTimeField from './SelectTimeField';
import PhoneField from './PhoneField';
import IINField from './IINField';
import SearchIINField from './SearchIINField';
import ListStatementsField from './ListStatementsField';
import BetweenField from './BetweenField';
import SelectUserFileField from './SelectUserFileField';

import MultipleFilesField from './MultipleFilesField';
import FileField from './FileField';
import PhotoField from './PhotoField';
import TextAreaField from './TextAreaField';
import DatePicker from './DatePicker';
import VYearMonthPicker from './VYearMonthPicker';
import WrapperMultiFileField from './WrapperMultiFileField';


// Quasar form components
import QBetweenField from './QBetweenField';
import QDateField from './QDateField';
import QTimeField from './QTimeField';
import QTextField from './QTextField';
import QTextFieldRawControl from './QTextFieldRawControl';
import QNumberField from './QNumberField';
import QSelectField from './QSelectField';
import WrapperQSelect from './WrapperQSelect';
import WrapperQTime from './WrapperQTime';
import WrapperQDate from './WrapperQDate';
import WrapperQNumberInput from './WrapperQNumberInput';
import QMultiCheckboxField from './QMultiCheckboxField';
import VMultiQInputField from './VMultiQInputField';
import QCheckboxField from './QCheckboxField';
import QRadioField from './QRadioField';


export default function (Vue) {
  Vue.component('v-multiselect', Multiselect);
  Vue.component('default-field', DefaultField);
  Vue.component('date-field', DateField);
  Vue.component('text-field', TextField);
  Vue.component('text-show-field', TextShowField);
  Vue.component('ckeditor-field', CkeditorField);
  Vue.component('number-field', NumberField);
  Vue.component('radio-field', RadioField);
  Vue.component('boolean-field', BooleanField);
  Vue.component('v-multi-select-field', VMultiSelectField);
  Vue.component('multiple-files-field', MultipleFilesField);
  Vue.component('file-field', FileField);
  Vue.component('photo-field', PhotoField);
  Vue.component('textarea-field', TextAreaField);
  Vue.component('v-wrapper-multi-select', WrapperMultiSelect);

  Vue.component('between-field', BetweenField);

  Vue.component('select-time-field', SelectTimeField);
  Vue.component('phone-field', PhoneField);
  Vue.component('iin-field', IINField);
  Vue.component('search-iin-field', SearchIINField);
  Vue.component('list-statements-field', ListStatementsField);
  Vue.component('vue-date-picker', DatePicker);
  Vue.component('select-user-file-field', SelectUserFileField);
  Vue.component('v-year-month-picker', VYearMonthPicker);
  Vue.component('v-wrapper-multi-files-field', WrapperMultiFileField);

  // Quasar form components
  Vue.component('q-between-field', QBetweenField);
  Vue.component('q-date-field', QDateField);
  Vue.component('q-time-field', QTimeField);
  Vue.component('q-select-field', QSelectField);
  Vue.component('v-wrapper-q-select', WrapperQSelect);
  Vue.component('v-wrapper-q-date', WrapperQDate);
  Vue.component('v-wrapper-q-time', WrapperQTime);
  Vue.component('v-wrapper-q-number-input', WrapperQNumberInput);
  Vue.component('q-text-field', QTextField);
  Vue.component('q-text-field-raw-control', QTextFieldRawControl);
  Vue.component('q-number-field', QNumberField);
  Vue.component('q-multi-checkbox-field', QMultiCheckboxField);
  Vue.component('v-multi-q-input-field', VMultiQInputField);
  Vue.component('q-checkbox-field', QCheckboxField);
  Vue.component('q-radio-field', QRadioField);
  Vue.component('q-form', QForm);
  Vue.component('q-slide-transition', QSlideTransition);
  Vue.component('q-field', QField);

  Vue.component('v-form-inform', VFormInform);
}
