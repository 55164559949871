export default class AttendanceJournalApiProvider {
  constructor(
    { JournalRepository, JournalMarkRepository, JournalConfirmRepository },
    trans,
  ) {
    this.journalRepository = JournalRepository;
    this.journalMarkRepository = JournalMarkRepository;
    this.journalConfirmRepository = JournalConfirmRepository;
    this.trans = trans;
  }

  provideFetch() {
    const { journalRepository, trans } = this;
    // eslint-disable-next-line func-names
    return async function (groupId) {
      try {
        const response = await journalRepository.fetchData(groupId);
        const { data: { status, data } } = response;
        if (status === 'ok') {
          return data;
        } else {
          this.$notify({
            type: 'error',
            text: response.data.message,
          });
        }
      } catch (error) {
        this.$notify({
          type: 'error',
          text: trans('notice.error_on_server'),
        });
      }
      return false;
    };
  }

  provideMark() {
    const { journalMarkRepository, trans } = this;
    // eslint-disable-next-line func-names
    return async function (contingentId, status, date, arrival_time, leaving_time) {
      try {
        const response = await journalMarkRepository.mark({
          contingentId,
          status,
          date,
          arrival_time,
          leaving_time,
        });
        const { data: { status: responseStatus, data } } = response;
        if (responseStatus === 'ok') {
          if (response.data.message) {
            this.$notify({
              type: 'error',
              text: response.data.message,
            });
          }
          return data;
        } else {
          this.$notify({
            type: 'error',
            text: response.data.message,
          });
        }
      } catch (error) {
        this.$notify({
          type: 'error',
          text: trans('notice.error_on_server'),
        });
      }
      return false;
    };
  }

  provideConfirm() {
    const { journalConfirmRepository, trans } = this;
    // eslint-disable-next-line func-names
    return async function (groupId, date) {
      try {
        const response = await journalConfirmRepository.confirm(groupId, date);
        const { data: { status: responseStatus, data } } = response;
        if (responseStatus === 'ok') {
          if (response.data.message) {
            this.$notify({
              type: 'error',
              text: response.data.message,
            });
          }
          return data;
        } else {
          this.$notify({
            type: 'error',
            text: response.data.message,
          });
        }
      } catch (error) {
        this.$notify({
          type: 'error',
          text: trans('notice.error_on_server'),
        });
      }
      return false;
    };
  }
}
