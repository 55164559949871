var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-support-team-button"
  }, [_c('q-btn-dropdown', {
    staticClass: "support-team-margin",
    class: {
      'support-team-button-active': _vm.isActive,
      'support-team-button-active-white': _vm.white,
      'support-team-button-active-qujat': _vm.qujat
    },
    attrs: {
      "color": _vm.color,
      "outline": "",
      "dropdown-icon": "null",
      "content-class": {
        'support-team-list': true,
        'support-team-list--white': _vm.white,
        'support-team-list--qujat': _vm.qujat
      }
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('span', {
          staticClass: "s-str-ico-fa s-ico-fa--thm-phone-square",
          staticStyle: {
            "font-weight": "600"
          }
        }, [_vm._v("\n        " + _vm._s(_vm.trans('feedback.support_team')) + "\n      ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.isActive,
      callback: function callback($$v) {
        _vm.isActive = $$v;
      },
      expression: "isActive"
    }
  }, [_vm._v(" "), _c('q-list', [_c('q-item', [_c('q-item-section', [_c('div', {
    staticClass: "s-main-page-contacts-phone-number pt-15"
  }, [_c('a', {
    staticClass: "s-str-ico s-str-ico--thm-telephone",
    attrs: {
      "href": "tel:+77473397222"
    }
  }, [_vm._v("+7 747 339 72 22")])]), _vm._v(" "), _c('a', {
    staticClass: "s-main-page-contacts-text ",
    attrs: {
      "href": "tel:+77473397222"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.message)
    }
  })])], 1), _vm._v(" "), _c('q-item', [_c('q-item-section', [_c('div', {
    staticClass: "s-main-page-contacts-phone-number"
  }, [_c('a', {
    staticClass: "s-str-ico s-str-ico--thm-telephone",
    attrs: {
      "href": "tel:+77273397222"
    }
  }, [_vm._v("+7 727 339 72 22")])]), _vm._v(" "), _c('a', {
    staticClass: "s-main-page-contacts-text ",
    attrs: {
      "href": "tel:+77273397222"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.message)
    }
  })])], 1), _vm._v(" "), _c('q-item', [_c('q-item-section', [_c('div', {
    staticClass: "s-main-page-contacts-phone-number"
  }, [_c('a', {
    staticClass: "s-str-ico-fab s-str-ico-fa--thm-whatsapp",
    attrs: {
      "href": "https://wa.me/+77003397222"
    }
  }, [_vm._v("+7 700 339 72 22")])]), _vm._v(" "), _c('a', {
    staticClass: "s-main-page-contacts-text pb-15",
    attrs: {
      "target": "_blank",
      "href": "https://wa.me/+77003397222"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.trans('feedback.whatsapp_message'))
    }
  })])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }