var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-ad-banner"
  }, [_c('img', {
    staticClass: "s-ad-banner__image",
    attrs: {
      "src": "".concat(_vm.staticUrl, "/images/banners/").concat(_vm.banner.image, "_").concat(_vm.locale, ".png"),
      "alt": _vm.banner.name
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "s-ad-banner__btns-wrapper"
  }, [_c('a', {
    staticClass: "s-ad-banner__btn",
    attrs: {
      "href": _vm.banner.iosUrl,
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": "".concat(_vm.staticUrl, "/images/logotypes/app_store.png")
    }
  })]), _vm._v(" "), _c('a', {
    staticClass: "s-ad-banner__btn",
    attrs: {
      "href": _vm.banner.googleUrl,
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": "".concat(_vm.staticUrl, "/images/logotypes/google_play.png")
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }