var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('confirm', {
    attrs: {
      "active": _vm.configPopup,
      "on-hide": _vm.cancelConfig,
      "title": _vm.trans('config.school.allow-edit-all'),
      "persistent": true,
      "light-text": true
    }
  }, [_c('div', {
    staticClass: "confirm-school-popup-text"
  }, [_vm._v("\n      " + _vm._s(_vm.description) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "popup-btn-control"
  }, [_c('q-btn', {
    attrs: {
      "color": "red"
    },
    on: {
      "click": function click() {
        return _vm.confirmConfig('timesheet_extended_edit', false);
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.trans('config.off')) + "\n      ")]), _vm._v(" "), _c('q-btn', {
    attrs: {
      "color": "green"
    },
    on: {
      "click": function click() {
        return _vm.confirmConfig('timesheet_extended_edit', true);
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.trans('config.on')) + "\n      ")])], 1)]), _vm._v(" "), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-sm-4"
  }, [_c('q-select', {
    attrs: {
      "label": _vm.trans('label.locality'),
      "options": _vm.localityOptions,
      "emit-value": "",
      "map-options": "",
      "input-debounce": "0"
    },
    on: {
      "filter": _vm.filterFn,
      "input": _vm.requestGetTable
    },
    model: {
      value: _vm.localitySelected,
      callback: function callback($$v) {
        _vm.localitySelected = $$v;
      },
      expression: "localitySelected"
    }
  })], 1)]), _vm._v(" "), _c('q-table', {
    attrs: {
      "data": _vm.table.rows,
      "columns": _vm.table.columns,
      "row-key": "name",
      "flat": _vm.$q.screen.width >= 876,
      "grid": _vm.$q.screen.width < 876,
      "pagination": _vm.table.pagination,
      "filter": _vm.filter,
      "loading": _vm.loading,
      "separator": "cell"
    },
    on: {
      "update:pagination": function updatePagination($event) {
        return _vm.$set(_vm.table, "pagination", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "top-right",
      fn: function fn(props) {
        return [!_vm.$q.screen.lt.sm ? _c('q-btn', {
          staticClass: "q-ml-md",
          attrs: {
            "squire": "",
            "unelevated": "",
            "icon": "fullscreen",
            "color": props.inFullscreen ? 'blue' : 'grey'
          },
          on: {
            "click": props.toggleFullscreen
          }
        }, [_c('q-tooltip', [_vm._v("\n          " + _vm._s(props.inFullscreen ? _vm.trans('label.fullscreen_off') : _vm.trans('label.fullscreen_on')) + "\n        ")])], 1) : _vm._e()];
      }
    }, {
      key: "top-left",
      fn: function fn(props) {
        return [_c('q-input', {
          attrs: {
            "outlined": "",
            "dense": "",
            "debounce": "300",
            "placeholder": _vm.trans('label.search')
          },
          scopedSlots: _vm._u([{
            key: "append",
            fn: function fn() {
              return [_c('q-icon', {
                attrs: {
                  "name": "search"
                }
              })];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.filter,
            callback: function callback($$v) {
              _vm.filter = $$v;
            },
            expression: "filter"
          }
        })];
      }
    }, {
      key: "header",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, _vm._l(props.cols, function (col) {
          return _c('q-th', {
            key: col.name,
            style: {
              width: "".concat(col.width, "px")
            },
            attrs: {
              "props": props
            }
          }, [_vm._v("\n          " + _vm._s(col.label) + "\n        ")]);
        }), 1)];
      }
    }, {
      key: "body",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, [_vm._l(props.row, function (item, i) {
          return [i === 'name' ? _c('q-td', {
            key: i
          }, [_vm._v("\n            " + _vm._s(item) + "\n          ")]) : _vm._e(), _vm._v(" "), i === 'groups_count' ? _c('q-td', {
            key: i,
            staticClass: "s-to-center"
          }, [_vm._v("\n            " + _vm._s(item) + "\n          ")]) : _vm._e(), _vm._v(" "), i === 'actions' ? _c('q-td', {
            key: i,
            staticClass: "s-to-center"
          }, [_c('div', {
            staticClass: "s-group s-group--to-center"
          }, [_vm._l(item, function (action, key) {
            return [_c('q-btn', {
              key: key,
              staticClass: "s-c-black",
              attrs: {
                "to": action.to,
                "icon": action.icon,
                "loading": _vm.loading,
                "type": action.type,
                "squire": ""
              }
            }, [_c('q-tooltip', [_vm._v(_vm._s(action.tooltip))])], 1)];
          }), _vm._v(" "), _vm.canEdit ? _c('q-btn', {
            attrs: {
              "icon": "settings",
              "color": props.row.configs === null || typeof props.row.configs.timesheet_extended_edit === 'undefined' || !props.row.configs.timesheet_extended_edit ? 'grey' : 'blue',
              "squire": ""
            },
            on: {
              "click": function click() {
                return _vm.selectConfig(props.row.id, props.row.configs);
              }
            }
          }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('config.school.allow-edit-all')))])], 1) : _vm._e()], 2)]) : _vm._e()];
        })], 2)];
      }
    }, {
      key: "item",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "sn-table__grid-item col-xs-12 col-sm-6 col-md-4 col-lg-3"
        }, [_c('div', {
          staticClass: "sn-table__grid-item-card sn-table__card"
        }, _vm._l(props.cols, function (col, i) {
          return _c('div', {
            key: i,
            staticClass: "sn-table__grid-item-row"
          }, [['name', 'groups_count'].includes(col.field) ? _c('div', {
            key: i
          }, [_c('div', {
            staticClass: "sn-table__grid-item-title"
          }, [_vm._v("\n                " + _vm._s(col.label) + "\n              ")]), _vm._v(" "), _c('div', {
            staticClass: "sn-table__grid-item-value"
          }, [_vm._v("\n                " + _vm._s(col.value) + "\n              ")])]) : _vm._e(), _vm._v(" "), col.field === 'actions' ? _c('div', [_c('div', {
            staticClass: "sn-table__grid-item-title mb-10"
          }, [_vm._v("\n                " + _vm._s(col.label) + "\n              ")]), _vm._v(" "), _c('div', {
            staticClass: "sn-table__grid-item-value"
          }, [_vm._l(col.value, function (action, key) {
            return [_c('q-btn', {
              key: key,
              staticClass: "funding-route-link s-c-black",
              staticStyle: {
                "margin-right": "0.5rem"
              },
              attrs: {
                "type": action.type,
                "to": action.to,
                "icon": action.icon,
                "loading": _vm.loading,
                "squire": ""
              }
            }, [_c('q-tooltip', [_vm._v(_vm._s(action.tooltip))])], 1)];
          }), _vm._v(" "), _vm.canEdit ? _c('q-btn', {
            attrs: {
              "icon": "settings",
              "color": props.row.configs === null || typeof props.row.configs.timesheet_extended_edit === 'undefined' || !props.row.configs.timesheet_extended_edit ? 'grey' : 'blue',
              "title": _vm.trans('school.allow-edit-all'),
              "squire": ""
            },
            on: {
              "click": function click() {
                return _vm.selectConfig(props.row.id, props.row.configs);
              }
            }
          }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('config.school.allow-edit-all')))])], 1) : _vm._e()], 2)]) : _vm._e()]);
        }), 0)])];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('v-no-results', {
          staticClass: "s-w-100",
          attrs: {
            "image": "search",
            "title": _vm.trans('warning_text.no_result_data_title'),
            "text": _vm.trans('warning_text.no_result_data_text')
          }
        })];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }