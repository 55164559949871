/* eslint-disable no-prototype-builtins */
import {
  QBtn,
  QForm,
  QIcon,
  QInput,
  QSelect,
  QSlideTransition,
} from '@quasar/components';
import _ from 'lodash';
import { Helper } from '@common/src/helpers';
import { requestWrapper } from '@vjs/helpers';
import { eventBus } from '@common/mixins';
import VConfirmDialog from '@vjs/modals/VConfirmDialog';
import axios from 'axios';
import DefaultTableProps from '../mixins/DefaultTableProps';

export default {
  name: 'NewTableFilters',
  mixins: [DefaultTableProps],
  components: {
    QInput,
    QBtn,
    QSelect,
    QIcon,
    QForm,
    QSlideTransition,
    VConfirmDialog,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    metaLinks: {
      type: Object,
      default: () => ({}),
    },
    dataConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      filtersLoadedInterval: undefined,
      filtersLoaded: false,
      customSearchPlaceholder: '',
      isFiltersActive: false,
      isConfirmModalActive: false,
      buttonConfig: [],
      filters: {},
      options: {},
      currentBtnAction: {},
      isEnabled: {
        filters: false,
        button: false,
        search: false,
        sort: false,
      },
      filtersDefault: {
        sort: 0,
        search: null,
        rowsPerPage: null,
      },
      isActiveFilters: false,
      visibleCols: [],
      colsOptions: [],
    };
  },
  async mounted() {
    eventBus.$on('getItems', this.getItems);
    eventBus.$on('sendRowsPerPage', (payload) => {
      this.filtersDefault.rowsPerPage = payload;
    });
    await this.getFilters();
  },
  computed: {
    isFieldHidden() {
      return (name) => {
        const filter = _.cloneDeep(this.filters[name]);
        if (filter.visibleWhen) {
          if (_.isObject(filter.visibleWhen) && !_.isArray(filter.visibleWhen)) {
            const relativeFilter = _.cloneDeep(this.filters[filter.visibleWhen.field]);
            if (!relativeFilter) {
              return true;
            }
            if (_.isArray(filter.visibleWhen.value)) {
              return !filter.visibleWhen.value.includes(relativeFilter.value);
            }
            if (!filter.visibleWhen.hasOwnProperty('value')) {
              return [null, undefined, ''].includes(relativeFilter.value);
            }
            return relativeFilter.value !== filter.visibleWhen.value;
          }
          if (_.isArray(filter.visibleWhen)) {
            const isHidden = {};
            _.forEach(filter.visibleWhen, (relativeFilterChecks) => {
              const relativeFilter = _.cloneDeep(this.filters[relativeFilterChecks.field]);
              if (_.isArray(relativeFilterChecks.value)) {
                isHidden[relativeFilterChecks.field] = !relativeFilterChecks.value.includes(relativeFilter.value);
              } else if (!relativeFilterChecks.hasOwnProperty('value')) {
                isHidden[relativeFilterChecks.field] = !relativeFilter.value;
              } else {
                isHidden[relativeFilterChecks.field] = relativeFilter.value !== relativeFilterChecks.value;
              }
            });
            return _.uniq(Object.values(isHidden)).includes(true);
          }
        }
        return false;
      };
    },
    searchPlaceholderComputed() {
      if (this.forceCustomSearchPlaceholder || this.$q.screen.width > 480) {
        return this.customSearchPlaceholder || this.trans('placeholder.default_search');
      }
      return this.trans('placeholder.default_search');
    },
    isButtonsDisabled() {
      return !this.rows.length;
    },
  },
  watch: {
    visibleCols(val) {
      this.$emit('returnVisibleCols', val);
    },
    async filtersLoaded(val) {
      if (val) {
        clearInterval(this.filtersLoadedInterval);
        this.applyFilters();
        await this.getItems('default');
      }
    },
  },
  methods: {
    async handleExport() {
      this.$emit('toggleLoaders', true);
      if (this.alternativeExport) {
        await axios({
          url: this.uriExport,
          method: 'GET',
          responseType: 'blob',
          params: this.getRequestParams(),
        }).then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');

          fileLink.href = fileURL;

          fileLink.setAttribute('download', this.exportName || this.trans('label.default_export_name'));
          document.body.appendChild(fileLink);

          fileLink.click();
        }).catch(async (err) => {
          let parsedError = null;
          await err.response.data.text().then((text) => {
            parsedError = JSON.parse(text);
          });
          this.$notify({
            type: 'error',
            title: window.L.error,
            text: parsedError.message || this.trans('notice.error_on_server'),
          });
        });
      } else {
        const res = await requestWrapper.call(this, {
          method: 'post',
          url: this.uriExport,
          params: this.getRequestParams(),
        });
        if (!res.error) {
          Helper.showNotice(this.trans('notice.send_for_export'), 'success', this.trans('notice.message'));
        } else {
          Helper.handlerResponseError({ status: res.errorType, data: res.data });
        }
      }

      this.$emit('toggleLoaders', false);
    },

    async getFilters() {
      if (this.uriGetFilters) {
        this.$emit('toggleLoaders', true);
        this.isFiltersActive = true;

        const res = await requestWrapper.call(this, {
          method: 'get',
          url: this.uriGetFilters,
        });
        if (!res.error) {
          const data = _.cloneDeep(res.data);
          if (res.buttonConfig) {
            this.buttonConfig = res.buttonConfig;
            this.isEnabled.button = true;
          }
          if (res.data.search) {
            delete data.search;
            this.isEnabled.search = true;
            if (res.data.search.label) {
              this.customSearchPlaceholder = res.data.search.label;
            }
            if (res.data.search.value) {
              this.filtersDefault.search = res.data.search.value;
            }
          }
          if (_.isObject(res.data.sort) && !_.isFunction(res.data.sort)) {
            delete data.sort;
            this.options.sort = res.data?.sort?.values;
            if (res.data?.sort?.value !== 0) {
              this.filtersDefault.sort = res.data?.sort?.value;
            }
            this.isEnabled.sort = true;
          }
          if (_.isObject(data) && !_.isEmpty(data)) {
            this.filters = data;
            this.isEnabled.filters = true;
            _.forEach(this.filters, (filter) => {
              if (filter.addWhere && _.isObject(filter.addWhere)) {
                if (!filter.events) {
                  filter.events = {};
                }
                filter.events.input = (value) => {
                  this.$nextTick(() => {
                    _.forEach(filter.addWhere, async (fieldName) => {
                      const changingFilter = this.filters[fieldName];
                      if (!this.filtersLoaded && !changingFilter.value && localStorage && this.storageName && localStorage.getItem(this.storageName)) {
                        const keysObj = JSON.parse(localStorage.getItem(this.storageName));
                        changingFilter.value = keysObj[fieldName];
                      }
                      if (this.filtersLoaded) {
                          // eslint-disable-next-line no-unused-expressions
                          changingFilter.loadValues?.setEmpty && changingFilter.loadValues.setEmpty();
                      }
                      if (![null, undefined, ''].includes(value) && changingFilter?.loadValues?.url) {
                        if (!changingFilter.loadValues.where) {
                          changingFilter.loadValues.where = {};
                        }
                        changingFilter.loadValues.where[filter.name] = value;
                          // eslint-disable-next-line no-unused-expressions
                          changingFilter.loadValues?.reload && await changingFilter.loadValues.reload();
                      }
                    });
                  });
                };
              }
            });
            // TODO: если допилить - может пригодиться
            // _.forEach(this.filters, (filter) => {
            //   if (filter.clearFields && _.isObject(filter.clearFields)) {
            //     if (!filter.events) {
            //       filter.events = {};
            //     }
            //     filter.events.input = () => {
            //         _.forEach(filter.clearFields, (clearValue, fieldName) => {
            //           if (this.filters[fieldName]) {
            //             this.filters[fieldName].value = clearValue;
            //           }
            //         });
            //     };
            //   }
            // });
          }
          await this.loadFiltersFromLocalStorage();
          this.setDefaultFilters();
        }

        setTimeout(async () => {
          this.isFiltersActive = false;
        });
        this.filtersLoadedInterval = setInterval(() => {
          const result = {};
          const filtersWithAddWhere = _.filter(this.filters, item => !!item.addWhere);
          if (filtersWithAddWhere.length) {
            _.forEach(
              filtersWithAddWhere,
              filter => _.forEach(filter.addWhere, (fieldName) => {
                if (localStorage && this.storageName && localStorage.getItem(this.storageName)) {
                  const keysObj = JSON.parse(localStorage.getItem(this.storageName));
                  const storageVal = keysObj[fieldName];
                  if (storageVal) {
                    result[fieldName] = this.filters[fieldName].value === keysObj[fieldName];
                  } else {
                    result[fieldName] = true;
                  }
                }
              }),
            );
            this.filtersLoaded = !Object.values(result).includes(false);
          } else {
            this.filtersLoaded = true;
          }
        });
        this.$emit('toggleLoaders', false);
      }
    },
    clearSingleFilter(filter) {
      filter.value = null;
      if (filter.hasOwnProperty('clearValue')) {
        filter.clearValue();
      }
      if (filter.hasOwnProperty('defaultValue')) {
        filter.value = filter.defaultValue;
      }
    },
    async resetFilters() {
      if (localStorage && this.storageName && localStorage.getItem(this.storageName)) {
        localStorage.removeItem(this.storageName);
      }

      _.forEach(this.filters, this.clearSingleFilter);
      this.filtersDefault.sort = 0;
      this.filtersDefault.search = null;
      this.setDefaultFilters();
      this.applyFilters();
      await this.getItems('default');
    },
    applyFilters() {
      this.isActiveFilters = Object.values(this.filters)
        .find(
          filter => !this.isFieldHidden(filter.name)
            && (
              ![null, undefined].includes(filter.value)
              || (filter.hasOwnProperty('hasValue') && filter.hasValue())
            ),
        );
    },
    saveFiltersToLocalStorage(params) {
      if (localStorage && this.storageName) {
        localStorage.setItem(this.storageName, JSON.stringify(params));
      }
    },

    async loadFiltersFromLocalStorage() {
      return new Promise((success) => {
        if (localStorage && this.storageName && localStorage.getItem(this.storageName)) {
          const keysObj = JSON.parse(localStorage.getItem(this.storageName));
          Object.keys(keysObj).forEach((item) => {
            if (this.filters[item] && (this.forceStorageFilters || !this.filters[item].value)) {
              if (['q-between-field', 'between-field'].includes(this.filters[item].component)) {
                const betweenValue = keysObj[item];
                if (!_.isEmpty(betweenValue)) {
                  this.filters[item].configComponent.fieldFrom.value = betweenValue.from;
                  this.filters[item].configComponent.fieldTo.value = betweenValue.to;
                }
              } else {
                this.filters[item].value = keysObj[item];
              }
            }
          });
          success(true);
        }
        success(false);
      });
    },
    setDefaultFilters() {
      if (_.isObject(this.dataConfig) && !_.isEmpty(this.dataConfig)) {
        Object.keys(this.dataConfig).forEach((item) => {
          if (this.filters[item] && !this.filters[item].value) {
            this.filters[item].value = this.dataConfig[item];
          }
        });
        this.isFiltersActive = true;
      }
    },
    async getItems(type = 'default', withSave = false) {
      this.$emit('toggleLoaders', true);
      const params = this.getRequestParams();
      let url = '';
      if (type === 'default') {
        url = this.uriGetItems;
      } else if (this.metaLinks?.[type]) {
        url = this.metaLinks[type];
      } else {
        url = type;
      }

      const res = await requestWrapper.call(this, {
        method: 'get',
        url,
        params,
      });

      if (!res.error) {
        const rows = res.data ?? [];
        const meta = res.meta ?? {};
        const metaLinks = res.links ?? {};
        const generatedLinks = res.generated_links ?? [];

        let cols = _.cloneDeep(res.cols) ?? [];
        cols = cols.map(item => ({
          ...item,
          sortable: false,
          field: item.name,
          required: false,
          align: item.align ?? 'left',
        }));

        const returnData = {
          rows, meta, metaLinks, cols, generatedLinks,
        };

        this.colsOptions = cols
          .filter(item => item.name !== 'index')
          .map(item => ({ name: item.name, label: item.label }));
        if (!this.visibleCols.length) {
          this.visibleCols = cols.map(v => v.name);
        }

        this.$emit('returnItems', returnData);
      }

      if (withSave) {
        this.saveFiltersToLocalStorage(params);
      }

      this.$emit('toggleLoaders');
    },

    getRequestParams() {
      let params = {};
      _.forEach(this.filters, (filter) => {
        if (this.isFieldHidden(filter.name)) {
          return;
        }
        if (filter.component === 'between-field' && filter.configComponent) {
          const betweenValue = (filter.configComponent?.fieldFrom?.value || filter.configComponent?.fieldTo?.value)
            ? {}
            : null;
          if (filter.configComponent?.fieldFrom?.value) {
            betweenValue.from = filter.configComponent.fieldFrom.value;
          }
          if (filter.configComponent?.fieldTo?.value) {
            betweenValue.to = filter.configComponent.fieldTo.value;
          }
          _.assign(params, { [filter.name]: JSON.stringify(betweenValue) });
          return;
        }
        if (![null, undefined, ''].includes(filter.value)) {
          _.assign(params, { [filter.name]: filter.value });
        }
        if (filter.hasOwnProperty('fill')) {
          if (filter.hasOwnProperty('hasValue')) {
            if (filter.hasValue()) {
              filter.fill(params);
            }
          } else {
            filter.fill(params);
          }
        }
      });

      params = { ...params, ...this.filtersDefault, visibleCols: this.visibleCols };
      return params;
    },
    async handleBtn(btn) {
      if (btn.type === 'button') {
        if (btn.requestUrl && btn.method) {
          this.currentBtnAction = btn;
          if (btn.withConfirm) {
            this.isConfirmModalActive = true;
            return;
          }
          await this.handleBtnRequest();
        }
        if (btn.modal === 's-modal' && btn['s-modal-config']) {
          const binds = {
            ...btn['s-modal-config']?.binds ?? {},
            meta: this.getRequestParams(),
          };

          this.$sModal.open(
            btn['s-modal-config'].component,
            { ...btn['s-modal-config'], binds },
          );
        }
      }
    },

    async handleBtnRequest() {
      this.$emit('toggleLoaders', true);

      const resConfig = {
        method: this.currentBtnAction.method,
        url: this.currentBtnAction.requestUrl,
      };

      const filters = this.getRequestParams();
      if (this.currentBtnAction.method === 'get') {
        resConfig.params = {
          tableData: this.rows,
          filters,
        };
      } else {
        resConfig.data = {
          tableData: this.rows,
          filters,
        };
      }

      const res = await requestWrapper.call(this, resConfig);

      if (!res.error) {
        if (res.tableData) {
          this.$emit('returnItems', res.tableData);
        }
        this.$emit('buttonRequestSuccess', res);
        this.handleCloseConfirmModal();
        Helper.handlerResponseError({ data: res, status: res.status });
      } else {
        Helper.handlerResponseError({ data: res.data, status: res.errorType });
      }
      this.currentBtnAction = {};

      this.$emit('toggleLoaders', false);
    },
    handleCloseConfirmModal() {
      this.currentBtnAction = {};
      this.isConfirmModalActive = false;
    },
  },
};
