import _ from 'lodash';
import {
  requestWrapper,
} from '@vjs/helpers';
import {
  QInput,
  QDate,
  QPopupProxy,
  QSelect,
  QTable,
  QIcon,
  QTr,
  QTh,
  QTd,
  QBtn,
  QTooltip,
} from '@quasar/components';
import { Loading } from '@quasar/plugins';
import axios from 'axios';
import { ERR_404 } from '../../../../helpers/requestWrapper';
import { capitalizeByWords } from '../../../../helpers/text';


export default {
  name: 'VContingentByPeriodMonitoringView',
  components: {
    QInput,
    QDate,
    QPopupProxy,
    QSelect,
    QTable,
    QIcon,
    QTr,
    QTh,
    QTd,
    QBtn,
    QTooltip,
  },
  props: {
    apiUrl: {
      type: String,
    },
    uriContingent: {
      type: String,
    },
    colNames: {
      type: Array,
      required: true,
    },
    orgTypes: {
      type: Array,
      required: true,
    },
    uriLocality: {
      type: String,
    },
    uriGetOrganizations: {
      type: String,
    },
  },
  data: () => ({
    form: {
      locality: null,
      organization: null,
      orgType: 0,
      page: 1,
    },
    qLoading: Loading,
    exportLoading: false,
    table: {
      inFullscreen: false,
      pagination: {
        rowsPerPage: 10,
      },
      columns: [],
      title: '',
    },
    rows: [],
    filters: {
      date: {
        from: '',
        to: '',
      },
    },
    filterString: '',
    localityOptions: [],
    orgNameOptions: [],
    localityOptionsRaw: [],
  }),
  async mounted() {
    await this.$trans.add(['statistics', 'button', 'menu', 'placeholder', 'label', 'main']);
    await this.requestGetData();
    await this.requestGetLocalities();
    const cols = [];
    this.colNames.forEach((itm, itmIdx) => {
      cols.push({
        name: itm.name,
        required: true,
        label: this.trans(`statistics.contingent_by_period.${itm.label}`),
        align: itmIdx === 0 ? 'left' : 'center',
        field: itm.name,
        sortable: true,
        colspan: itm.colspan,
        rowspan: itm.rowspan,
      });
    });
    this.table.columns = cols;
  },
  computed: {
    apiParams() {
      return {
        locality_id: this.form.locality,
        organization_id: this.form.organization,
        type: this.form.orgType,
        to: this.filters.date.to,
        from: this.filters.date.from,
      };
    },
  },
  methods: {
    filtersFn(val, update) {
      if (val === '') {
        update(() => {
          this.localityOptions = this.localityOptionsRaw;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.localityOptions = this.localityOptionsRaw.filter(item => _.lowerCase(item.label).indexOf(needle) > -1);
      });
    },
    clearFilters() {
      this.filters.date.from = '';
      this.filters.date.to = '';
      this.form.organization = '';
      this.form.locality = '';
      this.orgNameOptions = [];
      this.form.orgType = 0;
      this.requestGetData();
    },
    async requestGetLocalities() {
      this.qLoading.show({ message: this.trans('label.wait_loading') });
      const localityRes = await requestWrapper({
        url: this.uriLocality,
      });
      if (localityRes.error) {
        if (localityRes.errorType === ERR_404 && _.isBoolean(localityRes.data.region)) {
          this.modalRegionSelect = true;
        }
        this.qLoading.hide();
        return;
      }
      this.localityOptions = localityRes.data;
      this.localityOptionsRaw = localityRes.data;
      this.qLoading.hide();
    },
    clearLocality() {
      this.form.locality = '';
      this.form.organization = '';
      this.form.orgType = 0;
      this.orgNameOptions = [];
    },
    async requestGetOrgs() {
      this.qLoading.show({ message: this.trans('label.wait_loading') });
      const orgRes = await requestWrapper({
        url: this.uriGetOrganizations,
        params: {
          locality: this.form.locality,
          type: this.form.orgType,
        },
      });
      this.orgNameOptions = (_.isArray(orgRes.data) && orgRes.data) || [];
      this.orgNameOptions.unshift({
        label: this.trans('main.all'),
        value: 0,
      });
      this.form.organization = 0;
      this.qLoading.hide();
    },
    async requestGetData() {
      this.qLoading.show({ message: this.trans('label.wait_loading') });
      const dataRes = await requestWrapper({
        url: this.apiUrl,
      });
      if (!dataRes.error) {
        this.rows = dataRes.data;
      }
      this.qLoading.hide();
    },
    async submit() {
      this.qLoading.show({ message: this.trans('label.wait_loading') });
      const dataRes = await requestWrapper({
        url: this.uriContingent,
        params: this.apiParams,
      });
      if (!dataRes.error) {
        this.rows = dataRes.data;
      }
      this.qLoading.hide();
    },
    async getExport() {
      const { apiParams } = this;
      this.qLoading.show({ message: this.trans('label.wait_loading') });
      await axios({
        url: `${this.uriContingent}?export=true`,
        params: apiParams,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileLink = document.createElement('a');
        fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
        const today = new Date();
        fileLink.setAttribute('download',
          `${this.trans('menu.monitoring_new.contingent-by-period')}-${today.getFullYear()}.${today.getMonth() + 1}.${today.getDate()
          }.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.qLoading.hide();
      });
    },
  },
};
