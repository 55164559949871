var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.data.length > 1 ? _c('div', {
    staticClass: "d-flex justify-content-end mb-20"
  }, [_c('q-btn', {
    attrs: {
      "label": _vm.trans('button.drop_out_all'),
      "type": "button",
      "color": "red",
      "no-caps": "",
      "outline": ""
    },
    on: {
      "click": _vm.deactivateAllWarning
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('q-table', {
    attrs: {
      "data": _vm.table,
      "columns": _vm.columns,
      "pagination": _vm.pagination,
      "separator": "cell",
      "flat": _vm.$q.screen.width >= 768,
      "grid": _vm.$q.screen.width <= 768
    },
    on: {
      "update:pagination": function updatePagination($event) {
        _vm.pagination = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, [_vm._l(props.cols, function (col, i) {
          return [_c('q-td', {
            key: i,
            style: col.style
          }, [!['__index', 'child_birthday', 'child_id', 'contingent_id', 'correction', 'sanatorium', 'freeplace_budget'].includes(col.field) ? [_vm._v("\n              " + _vm._s(col.value) + "\n            ")] : _vm._e(), _vm._v(" "), col.field === 'freeplace_budget' ? [col.value ? [_vm._v("\n                " + _vm._s(col.value) + "\n              ")] : _vm._e(), _vm._v(" "), !col.value && !props.row.change_budget_access ? [_vm._v("\n                " + _vm._s(_vm.getBudgetBtnLabel(props.row.contingent_budget)) + "\n              ")] : _vm._e(), _vm._v(" "), !col.value && props.row.change_budget_access ? _c('q-btn', {
            staticStyle: {
              "max-width": "4.5rem",
              "min-height": "37px",
              "padding": "0.8rem 0"
            },
            attrs: {
              "outline": "",
              "no-caps": "",
              "width": "4.5",
              "icon": props.row.contingent_budget === null ? 'minus' : undefined,
              "color": _vm.getBudgetBtnColor(props.row.contingent_budget),
              "label": _vm.getBudgetBtnLabel(props.row.contingent_budget)
            },
            on: {
              "click": function click($event) {
                return _vm.handleContingentBudget(props.row.contingent_id);
              }
            }
          }) : _vm._e()] : _vm._e(), _vm._v(" "), col.field === 'contingent_id' ? [_c('div', {
            staticClass: "s-group s-group--equal s-group--to-center"
          }, [_c('q-btn', {
            staticClass: "contingent-control-btn",
            attrs: {
              "no-caps": "",
              "outline": ""
            },
            on: {
              "click": function click($event) {
                return _vm.prepareMove(col.value);
              }
            }
          }, [_vm._v("\n                  " + _vm._s(_vm.trans('button.move_to_group')) + "\n                ")]), _vm._v(" "), _c('q-btn', {
            staticClass: "contingent-control-btn",
            attrs: {
              "no-caps": "",
              "outline": ""
            },
            on: {
              "click": function click($event) {
                return _vm.confirmDeactivate(col.value);
              }
            }
          }, [_vm._v("\n                  " + _vm._s(_vm.trans('button.drop_out')) + "\n                ")])], 1)] : _vm._e()], 2)];
        })], 2)];
      }
    }, {
      key: "item",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "sn-table__grid-item col-xs-12 col-sm-6 col-md-4 col-lg-4"
        }, [_c('div', {
          staticClass: "sn-table__grid-item-card sn-table__card"
        }, [_vm._l(props.cols, function (col, i) {
          return [_c('div', {
            key: i,
            staticClass: "sn-table__grid-item-row"
          }, [_c('div', {
            staticClass: "sn-table__grid-item-title"
          }, [_vm._v("\n                " + _vm._s(col.label) + "\n              ")]), _vm._v(" "), _c('div', {
            staticClass: "sn-table__grid-item-value"
          }, [!['__index', 'child_birthday', 'child_id', 'contingent_id', 'correction', 'sanatorium', 'freeplace_budget'].includes(col.field) ? [_vm._v("\n                  " + _vm._s(col.value) + "\n                ")] : _vm._e(), _vm._v(" "), col.field === 'freeplace_budget' ? [col.value ? [_vm._v("\n                    " + _vm._s(col.value) + "\n                  ")] : _c('q-btn', {
            staticStyle: {
              "max-width": "4.5rem",
              "min-height": "37px",
              "padding": "0.8rem 0"
            },
            attrs: {
              "outline": "",
              "no-caps": "",
              "width": "4.5",
              "icon": props.row.contingent_budget === null ? 'minus' : undefined,
              "color": _vm.getBudgetBtnColor(props.row.contingent_budget),
              "label": _vm.getBudgetBtnLabel(props.row.contingent_budget)
            },
            on: {
              "click": function click($event) {
                return _vm.handleContingentBudget(props.row.contingent_id);
              }
            }
          })] : _vm._e(), _vm._v(" "), col.field === 'contingent_id' ? [_c('div', {
            staticClass: "s-group s-group--wider"
          }, [_c('q-btn', {
            staticClass: "contingent-control-btn",
            attrs: {
              "no-caps": "",
              "outline": ""
            },
            on: {
              "click": function click($event) {
                return _vm.prepareMove(col.value);
              }
            }
          }, [_vm._v("\n                      " + _vm._s(_vm.trans('button.move_to_group')) + "\n                    ")]), _vm._v(" "), _c('q-btn', {
            staticClass: "contingent-control-btn",
            attrs: {
              "no-caps": "",
              "outline": ""
            },
            on: {
              "click": function click($event) {
                return _vm.confirmDeactivate(col.value);
              }
            }
          }, [_vm._v("\n                      " + _vm._s(_vm.trans('button.drop_out')) + "\n                    ")])], 1)] : _vm._e()], 2)])];
        })], 2)])];
      }
    }])
  }), _vm._v(" "), _c('confirm', {
    attrs: {
      "active": _vm.deactivateAllWarningPopup,
      "title": _vm.trans('notice.confirm'),
      "on-hide": _vm.cancelDeactivate,
      "size": "medium"
    }
  }, [_c('v-form-inform', {
    attrs: {
      "color": "orange",
      "message": _vm.trans('warning_text.group_contingent_retirement')
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "s-group s-group--equal s-group--wider"
  }, [_c('q-btn', {
    attrs: {
      "outline": "",
      "label": _vm.trans('button.cancel')
    },
    on: {
      "click": _vm.cancelDeactivate
    }
  }), _vm._v(" "), _c('q-btn', {
    attrs: {
      "color": "green",
      "label": _vm.trans('button.approve')
    },
    on: {
      "click": _vm.confirmDeactivateAll
    }
  })], 1)], 1), _vm._v(" "), _c('confirm', {
    attrs: {
      "active": _vm.deactivatePopup,
      "title": _vm.trans('label.deactivate_contingent_title'),
      "on-hide": _vm.cancelDeactivate,
      "size": "medium"
    }
  }, [_c('q-input', {
    attrs: {
      "label": _vm.trans('label.personnel_termination_order_number'),
      "error": _vm.deactivateContractNumber !== null && _vm.deactivateContractNumber.length === 0,
      "error-message": _vm.trans('label.contract_number_required')
    },
    model: {
      value: _vm.deactivateContractNumber,
      callback: function callback($$v) {
        _vm.deactivateContractNumber = $$v;
      },
      expression: "deactivateContractNumber"
    }
  }), _vm._v(" "), _c('q-select', {
    attrs: {
      "options": _vm.deactivateReasonList,
      "label": _vm.trans('label.deactivate_contingent_message'),
      "popup-content-class": "contingent-select-reason-popup"
    },
    on: {
      "input": _vm.changeReason
    },
    model: {
      value: _vm.deactivateReason,
      callback: function callback($$v) {
        _vm.deactivateReason = $$v;
      },
      expression: "deactivateReason"
    }
  }), _vm._v(" "), _vm.showOtherReason ? _c('q-input', {
    attrs: {
      "label": _vm.trans('label.deactivate_other_reason'),
      "error": _vm.deactivateOtherReason !== null && _vm.deactivateOtherReason.length === 0,
      "error-message": _vm.trans('label.other_reason_required')
    },
    model: {
      value: _vm.deactivateOtherReason,
      callback: function callback($$v) {
        _vm.deactivateOtherReason = $$v;
      },
      expression: "deactivateOtherReason"
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "s-group s-group--equal s-group--wider"
  }, [_c('q-btn', {
    attrs: {
      "outline": ""
    },
    on: {
      "click": _vm.cancelDeactivate
    }
  }, [_vm._v("\n        " + _vm._s(_vm.trans('button.cancel')) + "\n      ")]), _vm._v(" "), _c('q-btn', {
    attrs: {
      "disabled": !_vm.deactivateFilled || _vm.deactivating,
      "color": "green"
    },
    on: {
      "click": _vm.deactivate
    }
  }, [_vm._v("\n        " + _vm._s(_vm.trans('button.approve')) + "\n      ")])], 1)], 1), _vm._v(" "), _c('confirm', {
    attrs: {
      "active": _vm.movePopup,
      "title": _vm.trans('notice.select_group'),
      "on-hide": _vm.deactivateMove,
      "size": "large"
    }
  }, [_c('div', {
    staticClass: "move-child-data"
  }, [_c('div', {
    staticClass: "move-child-item"
  }, [_c('span', {
    staticClass: "move-child-header"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('label.fullname')) + ":\n        ")]), _vm._v("\n        " + _vm._s(_vm.selectedContingent ? _vm.selectedContingent.child_full_name : '') + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "move-child-item"
  }, [_c('span', {
    staticClass: "move-child-header"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('label.born_date')) + ":\n        ")]), _vm._v("\n        " + _vm._s(_vm.selectedContingent ? _vm.selectedContingent.child_birthday : '') + "\n      ")])]), _vm._v(" "), _c('q-table', {
    staticClass: "contingent-move-group-table",
    attrs: {
      "flat": !_vm.$q.screen.lt.md,
      "selection": "single",
      "separator": "cell",
      "selected": _vm.selectedGroups,
      "row-key": "id",
      "columns": _vm.groupColumns,
      "data": _vm.allowedMoveGroups,
      "grid": _vm.$q.screen.lt.md,
      "wrap-cells": true
    },
    on: {
      "update:selected": function updateSelected($event) {
        _vm.selectedGroups = $event;
      }
    }
  }), _vm._v(" "), _c('q-slide-transition', [_vm.documentDiseaseRequired || !_vm.diseasesLeastOne ? _c('div', {
    staticClass: "file-upload"
  }, [_c('input', {
    ref: "fileInput",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": "application/msword, application/pdf, image/*"
    },
    on: {
      "change": _vm.fileChange
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "mb-20"
  }, [_c('div', {
    staticClass: "sn-field__label mb-15"
  }, [_vm._v("\n            " + _vm._s(_vm.trans('label.disease_commission')) + ":\n            "), _c('i', [_vm._v("*")])]), _vm._v(" "), !_vm.documentDisease ? _c('q-btn', {
    staticClass: "form-upload__button",
    attrs: {
      "icon": "clip",
      "color": "blue",
      "type": "button",
      "label": _vm.trans('button.overview'),
      "label-wider": ""
    },
    on: {
      "click": function click($event) {
        return _vm.triggerFile();
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.documentDisease ? _c('q-btn', {
    staticClass: "s-btn--border sn-btn--ellipsis",
    attrs: {
      "icon": "close",
      "label": _vm.documentDisease.name,
      "label-wider": "",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.clearFile();
      }
    },
    model: {
      value: _vm.documentDisease,
      callback: function callback($$v) {
        _vm.documentDisease = $$v;
      },
      expression: "documentDisease"
    }
  }) : _vm._e()], 1)]) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "popup-btn-control"
  }, [_c('q-btn', {
    attrs: {
      "outline": ""
    },
    on: {
      "click": _vm.deactivateMove
    }
  }, [_vm._v("\n        " + _vm._s(_vm.trans('button.cancel')) + "\n      ")]), _vm._v(" "), _c('q-btn', {
    attrs: {
      "color": "green",
      "disable": _vm.buttonApproveDisable
    },
    on: {
      "click": _vm.applyMove
    }
  }, [_vm._v("\n        " + _vm._s(_vm.trans('button.approve')) + "\n      ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }