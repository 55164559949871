var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('confirm', {
    attrs: {
      "title": _vm.trans('notice.confirm'),
      "active": _vm.confirmPopup,
      "on-hide": _vm.cancel
    }
  }, [_c('div', {
    staticClass: "confirm-text"
  }, [_vm._v("\n      " + _vm._s(_vm.popupText) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "popup-btn-control"
  }, [_c('q-btn', {
    attrs: {
      "outline": "",
      "disabled": _vm.sending
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("\n        " + _vm._s(_vm.trans('button.cancel')) + "\n      ")]), _vm._v(" "), _c('q-btn', {
    attrs: {
      "disabled": _vm.sending,
      "color": "green"
    },
    on: {
      "click": _vm.applyAct
    }
  }, [_vm._v("\n        " + _vm._s(_vm.trans('button.approve')) + "\n      ")])], 1)]), _vm._v(" "), _c('div', {
    staticClass: "row refuse-action-group"
  }, [_c('div', {
    staticClass: "col-md-4 btn-wrapper"
  }, [_c('q-btn', {
    attrs: {
      "color": "orange"
    },
    on: {
      "click": function click() {
        return _vm.prepare(_vm.refuse.url, _vm.refuse.text);
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.refuse.button) + "\n      ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "col-md-4 sn--text-center btn-wrapper"
  }, [_c('q-btn', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": function click() {
        return _vm.prepare(_vm.returnTo.url, _vm.returnTo.text);
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.returnTo.button) + "\n      ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "col-md-4 sn--text-right btn-wrapper"
  }, [_c('q-btn', {
    attrs: {
      "color": "green"
    },
    on: {
      "click": function click() {
        return _vm.prepare(_vm.restore.url, _vm.restore.text);
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.restore.button) + "\n      ")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }