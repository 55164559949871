import {
  QInput,
  QBtn,
  QPagination,
} from '@quasar/components';
import { genArray } from '@vjs/helpers';
import VNoResults from '@vjs/components/VNoResults';
import VStatisticPanel from '@vjs/components/VStatisticPanel';
import MXListView from '@vjs/mixins/MXListView';
import VCatalogItem from '../../components/VCatalogItem';
import VCatalogFilters from '../../components/VCatalogFilters';
import { scrollTop } from '../../helpers';

export default {
  name: 'VCatalogListView',
  mixins: [MXListView],
  components: {
    QInput,
    QBtn,
    QPagination,
    VStatisticPanel,
    VCatalogItem,
    VCatalogFilters,
    VNoResults,
  },
  props: {
    uriGetOrganizations: {
      type: String,
      required: true,
    },
    uriFilters: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchValue: '',
      debounceTimeout: null,
    };
  },
  beforeCreate() {
    this.$trans.add(['queue', 'button', 'warning_text']);
  },
  watch: {
    // Сбрасывает отфильтрованный список при удалении строки в поиске
    searchValue(val) {
      if (val === '') {
        this.itemsFiltered = this.items;
      }
    },
  },
  computed: {
    // Отдаёт кол-во очереди на страницу
    paginate() {
      if (this.loading) {
        return genArray({
          linkImage: '',
          linkItem: '',
          title: '',
          marks: [
            {
              color: 'blue',
              text: '',
            },
          ],
          languages: '',
          yearsRestriction: '',
          address: '',
          throughputOrg: '',
          availablePlaces: '',
        }, 4);
      }

      const current = (this.currentPage - 1) * this.countByPage;
      return this.itemsFiltered.slice(current, current + this.countByPage);
    },
    // Считает кол-во страниц в пагинации по выставленным в data параметрам
    countOfPages() {
      let count = parseInt(this.itemsFiltered.length / this.countByPage, 10);
      const remains = this.itemsFiltered.length % this.countByPage;
      count += remains !== 0 ? 1 : 0;
      this.currentPage = 1;
      return count <= 1 ? 0 : count;
    },
  },
  methods: {
    // Для обработки реактивного поиска
    debounceSearch() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(
        this.handleSearch,
        300,
      );
    },
    // Обычный поиск по запросу
    handleSearch() {
      if (this.searchValue !== '') {
        const searchStr = this.searchValue.toLowerCase();
        this.itemsFiltered = this.items.filter((a) => {
          const orgName = a.title || '';
          const orgAddress = a.address || '';
          return orgName.toLowerCase().indexOf(searchStr) !== -1
            || orgAddress.toLowerCase().indexOf(searchStr) !== -1;
        });
      } else {
        this.itemsFiltered = this.items;
      }
    },
    // Принимает и обрабатывает список от фильтра
    handleFilteredList(arr) {
      this.items = arr.data.items || [];

      if (arr.accessPlaceChecked && Array.isArray(this.items)) {
        this.itemsFiltered = this.items.filter(itm => parseInt(itm.availablePlaces, 10));
      } else {
        this.itemsFiltered = this.items;
      }

      this.statisticItems = arr.data.statistics || [];
      this.searchValue = '';
    },
    // Запускается на событие @input в q-pagination
    async changePagination() {
      // Перевод страницы к началу при выборе страницы
      scrollTop(
        window,
        window.scrollY,
        window.scrollY - Math.abs(this.$refs.catalog.getBoundingClientRect().top),
        500,
      );
    },
  },
};
