import RefuseArchiveStatementList from './VRefuseArchiveStatementsList.vue';
import RefuseStatementList from './VRefuseStatementsList.vue';
import RefuseActions from './VRefuseActions';

export const VRefuseArchiveStatementsList = RefuseArchiveStatementList;
export const VRefuseStatementsList = RefuseStatementList;
export const VRefuseActions = RefuseActions;

export default {
  VRefuseStatementsList,
  VRefuseArchiveStatementsList,
  VRefuseActions,
};
