var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "catalog"
  }, [_vm._l(_vm.itemsPaginated, function (application) {
    return _c('v-application-item', {
      key: application.id,
      staticClass: "mb-30",
      class: {
        'is-loading': _vm.loading
      },
      attrs: {
        "identifier": application.id,
        "name": application.fullname,
        "statements-count": "".concat(application.statementsCount),
        "flag": application.flag,
        "locked": application.locked,
        "isNotInterested": application.isNotInterested,
        "desc": application.desc,
        "lgot-btn": application.lgotBtn,
        "lang-btn": application.langBtn || {},
        "remove-priority-btn": application.removePriorityBtn || {},
        "removeSuccess": application.remove,
        "uriRemove": _vm.uriRemove,
        "uriInterest": _vm.uriInterest,
        "uriStatementContinue": _vm.uriStatementContinue,
        "uriUpdateLgots": _vm.uriUpdateLgots,
        "uriGetLgotTypes": _vm.uriGetLgotTypes,
        "uriGetLgotTypeDocuments": _vm.uriGetLgotTypeDocuments,
        "steps": application.steps,
        "isCorrection": _vm.correction
      },
      on: {
        "removed": _vm.removeApplication,
        "unlock": function unlock($event) {
          return _vm.unlockApplication(application.id, $event);
        },
        "is-interest": function isInterest($event) {
          return _vm.setInterest(application.id);
        }
      }
    });
  }), _vm._v(" "), _vm.itemsPaginated.length === 0 ? _c('v-no-results', {
    staticClass: "s-w-100",
    attrs: {
      "image": "queue",
      "title": _vm.trans('statement.not-found')
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-pagination', {
    attrs: {
      "max": _vm.countOfPages,
      "max-pages": 5,
      "boundary-links": true,
      "input": _vm.countOfPages > 5,
      "input-class": "s-c-blue"
    },
    on: {
      "input": _vm.changePagination
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }