var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-input', _vm._b({
    ref: "q-number-field",
    attrs: {
      "mask": _vm.config.mask,
      "error": _vm.error,
      "error-message": _vm.errorMessage,
      "placeholder": new Array(String(_vm.config.max).length).fill('_').join('')
    },
    scopedSlots: _vm._u([_vm.extraAttributes.counter ? {
      key: "counter",
      fn: function fn() {
        return [_vm._v("\n    " + _vm._s(_vm.trans('label.from_to_number_field', {
          from: _vm.config.min || 0,
          to: _vm.config.max || new Array(_vm.config.mask).fill('9').join('')
        })) + "\n  ")];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm.localValue,
      callback: function callback($$v) {
        _vm.localValue = $$v;
      },
      expression: "localValue"
    }
  }, 'q-input', _vm.extraAttributes, false));
}
var staticRenderFns = []

export { render, staticRenderFns }