var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.empty ? _c('div', {
    staticClass: "s-direction-block__container"
  }, [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('directions.refuse-info')
    }
  }), _vm._v(" "), _vm._l(_vm.directionList, function (direction, idx) {
    return _c('v-direction', {
      key: idx,
      attrs: {
        "id": direction.id,
        "expired": direction.expired,
        "name": direction.name,
        "flag": direction.flag,
        "desc": direction.desc,
        "refuse": direction.refuse,
        "type": direction.type
      },
      on: {
        "confirm-popup": _vm.handleConfirmPopup
      }
    });
  })], 2) : _vm._e(), _vm._v(" "), _vm.empty ? _c('div', {
    staticClass: "s-w-800"
  }, [_c('v-no-results', {
    attrs: {
      "image": "search",
      "title": _vm.trans('warning_text.no_result_directions_title'),
      "text": _vm.trans('warning_text.no_result_directions_text')
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('v-confirm-dialog', {
    attrs: {
      "modal": _vm.showConfirmPopup,
      "loading": _vm.sendingConfirm,
      "confirm": _vm.applyConfirm,
      "title": _vm.trans('directions.refuse-confirm')
    },
    on: {
      "modal-close": _vm.cancelConfirm
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }