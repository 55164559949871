var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "stat-section-wrapper s-w-100"
  }, [_c('div', {
    staticClass: "s-home-container"
  }, [_c('div', {
    staticClass: "s-stat-block-size"
  }), _vm._v(" "), _c('div', {
    staticClass: "s-stats-wrapper"
  }, [_vm.$q.screen.width > 999 ? _c('div', {
    staticClass: "s-stats-chilldren-count-wrapper"
  }, [_c('div', {
    staticClass: "chilldren-count-image"
  }), _vm._v(" "), _c('div', {
    staticClass: "s-stats-block-count"
  }, [_vm._v("\n          " + _vm._s(_vm.formatNumberValue(_vm.childCountVal)) + "\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "s-stats-block-text"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('label.statements_in_group')) + "\n        ")])]) : _vm._e(), _vm._v(" "), _vm.$q.screen.width > 999 ? _c('div', {
    staticClass: "s-stats-balabaqsha-count-wrapper"
  }, [_c('div', {
    staticClass: "s-stats-block-title"
  }, [_c('span', {
    staticClass: "s-str-ico  s-str-ico--thm-location-pin ",
    staticStyle: {
      "padding-left": "1.5em"
    }
  }, [_vm._v(_vm._s(_vm.trans('main.department_stats')))]), _vm._v(" "), _c('span', {
    staticStyle: {
      "padding": "0px !important",
      "display": "flex",
      "justify-content": "center",
      "flex-flow": "wrap"
    },
    domProps: {
      "innerHTML": _vm._s(this.departmentName)
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "balabaqsha-count-image"
  }), _vm._v(" "), _c('div', {
    staticClass: "s-stats-block-count"
  }, [_vm._v("\n          " + _vm._s(_vm.formatNumberValue(_vm.organizationCountVal)) + "\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "s-stats-block-text",
    staticStyle: {
      "padding-left": "2rem",
      "padding-right": "2rem"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.trans('label.organizations_in_system')) + "\n        ")])]) : _vm._e(), _vm._v(" "), _vm.$q.screen.width > 999 ? _c('div', {
    staticClass: "s-stats-application-count-wrapper"
  }, [_c('div', {
    staticClass: "application-count-image"
  }), _vm._v(" "), _c('div', {
    staticClass: "s-stats-block-count"
  }, [_vm._v("\n          " + _vm._s(_vm.formatNumberValue(_vm.applicationCountVal)) + "\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "s-stats-block-text"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('label.statements_in_queue')) + "\n        ")])]) : _c('div', {
    staticClass: "s-stats-block-mobile-wrapper"
  }, [_c('div', {
    staticClass: "s-stats-block-mobile-title-wrapper"
  }, [_c('span', {
    staticClass: " s-stats-block-mobile-title  s-str-ico  s-str-ico--thm-location-pin "
  }, [_vm._v(_vm._s(_vm.trans('main.department_stats')))]), _vm._v(" "), _c('br'), _vm._v(" "), _c('span', {
    staticClass: "s-stats-block-mobile-department "
  }, [_vm._v(_vm._s(this.departmentName))])]), _vm._v(" "), _c('div', {
    staticClass: "s-stats-block-mobile-element"
  }, [_c('div', {
    staticClass: "chilldren-count-image-mobile"
  }), _vm._v(" "), _c('div', {
    staticClass: "s-stats-block-mobile-element-mini-wrapper"
  }, [_c('span', {
    staticClass: "s-stats-block-mobile-counter"
  }, [_vm._v(_vm._s(_vm.formatNumberValue(_vm.childCountVal)))]), _vm._v(" "), _c('br'), _vm._v(" "), _c('span', {
    staticClass: "s-stats-block-mobile-text"
  }, [_vm._v(_vm._s(_vm.trans('label.statements_in_group')))])])]), _vm._v(" "), _c('div', {
    staticClass: "s-stats-block-mobile-element"
  }, [_c('div', {
    staticClass: "balabaqsha-count-image-mobile"
  }), _vm._v(" "), _c('div', {
    staticClass: "s-stats-block-mobile-element-mini-wrapper"
  }, [_c('span', {
    staticClass: "s-stats-block-mobile-counter"
  }, [_vm._v(_vm._s(_vm.formatNumberValue(_vm.organizationCountVal)))]), _vm._v(" "), _c('br'), _c('span', {
    staticClass: "s-stats-block-mobile-text"
  }, [_vm._v(_vm._s(_vm.trans('label.organizations_in_system')))])])]), _vm._v(" "), _c('div', {
    staticClass: "s-stats-block-mobile-element"
  }, [_c('div', {
    staticClass: "application-count-image-mobile"
  }), _vm._v(" "), _c('div', {
    staticClass: "s-stats-block-mobile-element-mini-wrapper"
  }, [_c('span', {
    staticClass: "s-stats-block-mobile-counter"
  }, [_vm._v(_vm._s(_vm.formatNumberValue(_vm.applicationCountVal)))]), _vm._v(" "), _c('br'), _vm._v(" "), _c('span', {
    staticClass: "s-stats-block-mobile-text"
  }, [_vm._v(_vm._s(_vm.trans('label.statements_in_queue')))])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }