var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-field', {
    attrs: {
      "field": _vm.field,
      "field-name": _vm.fieldName,
      "errors": _vm.errors
    }
  }, [_c('template', {
    slot: "field"
  }, [_c('v-multiselect', _vm._b({
    staticClass: "mb-10",
    attrs: {
      "label": "name",
      "track-by": "id",
      "options": _vm.values,
      "placeholder": _vm.trans('placeholder.choose'),
      "tag-placeholder": _vm.trans('select.tag_placeholder'),
      "select-label": _vm.trans('select.select_label'),
      "select-group-label": _vm.trans('select.select_group_label'),
      "selected-label": _vm.trans('select.selected_label'),
      "deselect-label": _vm.trans('select.deselect_label'),
      "deselect-group-label": _vm.trans('select.deselect_group_label')
    },
    on: {
      "input": _vm.emitChange
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function fn(props) {
        return [_c('template-single-label', {
          attrs: {
            "functional": "",
            "field": _vm.field,
            "option": props.option
          }
        })];
      }
    }, {
      key: "option",
      fn: function fn(props) {
        return [_c('template-option', {
          attrs: {
            "functional": "",
            "field": _vm.field,
            "option": props.option
          }
        })];
      }
    }]),
    model: {
      value: _vm.selectValue,
      callback: function callback($$v) {
        _vm.selectValue = $$v;
      },
      expression: "selectValue"
    }
  }, 'v-multiselect', _vm.extraAttributes, false), [_c('template', {
    slot: "noOptions"
  }, [_vm._v("\n        " + _vm._s(_vm.trans('select.list_is_empty')) + "\n      ")]), _vm._v(" "), _c('template', {
    slot: "noResult"
  }, [_vm._v("\n        " + _vm._s(_vm.trans('select.list_is_empty')) + "\n      ")])], 2)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }