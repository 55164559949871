import _ from 'lodash';
import {
  QBtn,
  QTabs,
  QTab,
  QRadio,
  QField,
  QSelect,
  QOptionGroup,
  QSlideTransition,
} from '@quasar/components';
import VRegionSelect
from '@indv/balabaqsha/javascript/components/VRegionSelect';
import MXFilters from '@vjs/mixins/MXFilters';
import {
  requestWrapper,
} from '@vjs/helpers';
import { ERR_404 } from '../../../helpers/requestWrapper';
import VTextModal from '../../modals/VTextModal.vue';

export default {
  name: 'VBulletinFilters',
  mixins: [MXFilters],
  components: {
    QBtn,
    QTabs,
    QTab,
    QRadio,
    QField,
    QSelect,
    QOptionGroup,
    QSlideTransition,
    VTextModal,
    VRegionSelect,
  },
  props: {
    uriStatistic: {
      type: String,
      required: true,
    },
    uriFilters: {
      type: String,
      required: true,
    },
    uriGetFilters: {
      type: String,
      required: true,
    },
    uriByAll: {
      type: String,
      required: true,
    },
    uriByOrg: {
      type: String,
      required: true,
    },
    currentOrgID: {
      type: Object,
      default: () => ({
        value: null,
        label: '',
      }),
    },
    defaultFilters: Object,
    disableLoadFromStorage: Boolean,
  },
  data() {
    const form = {};
    _.forEach(this.defaultFilters, (value, key) => {
      if (key === 'ageSelected') {
        form.ageSelectedNew = value;
      }
      form[key] = value;
    });
    return {
      tab: 'all',
      filterItems: [],
      orgNameOptions: [],
      orgNameSelected: this.currentOrgID,
      ageSelected: null,
      ageSelectedNew: null,
      statusSelected: null,
      languageSelected: null,
      organizationTypeSelected: null,
      groupTypeSelected: null,
      typeSelected: null,
      kindSelected: 0,
      diseases: [],
      sanatorium: [],
      diseasesSpoiler: false,
      sanatoriumSpoiler: false,
      ...form,
    };
  },
  async mounted() {
    this.loading = true;
    this.$emit('filter-loading', true);

    // Запрос населённых пунктов
    const localityRes = await requestWrapper.call(this, {
      url: this.uriLocality,
    }, true);

    if (localityRes.error) {
      this.loading = false;
      this.$emit('filter-loading', false);

      if (localityRes.errorType === ERR_404 && _.isBoolean(localityRes.data.region)) {
        this.modalRegionSelect = true;
      }

      return;
    }

    this.localityOptions = localityRes.data;
    this.filterItems = await this.requestFilters();
    if (!this.disableLoadFromStorage) {
      this.loadFiltersFromLocalStorage({}, 'bulletinFilter', true);
    }
    // Проверка на соответствие нас. пунтку при смене региона
    const checkLocality = _.find(
      this.localityOptions,
      loc => typeof this.localitySelected !== 'undefined' && loc.value === this.localitySelected.value,
    );

    if (!checkLocality || this.defaultFilters?.localitySelected) {
      const compareLocality = _.find(
        this.localityOptions,
        loc => loc.value === this.defaultFilters?.localitySelected,
      );

      if (compareLocality) {
        // Если код locality пришёл извне
        this.localitySelected = compareLocality;
      } else {
        this.localitySelected = this.localityOptions[0];
      }
    }

    if (this.defaultFilters?.ageSelected) {
      const compareAge = _.find(
        this.filterItems.age,
        loc => loc.value === this.defaultFilters?.ageSelected,
      );

      if (compareAge) {
        // Если код locality пришёл извне
        this.ageSelectedNew = compareAge;
      } else {
        this.ageSelectedNew = this.filterItems.age[0];
      }
    }

    await this.requestOrg();
    await this.getItems();
    this.loading = false;
    this.$emit('filter-loading', false);
  },
  watch: {
    currentOrgID(val) {
      this.orgNameSelected = val?.value;
      this.tab = 'all';
      this.changedTab('all');
    },
    typeSelected(val) {
      this.diseases = [];
      this.sanatorium = [];
      this.diseasesSpoiler = val ? val === 1 : false;
      this.sanatoriumSpoiler = val ? val === 2 : false;
    },
  },
  computed: {
    bulletinTypeList() {
      const { filterItems: { type }, $trans: { get: trans } } = this;
      return _.map(type, item => ({
        ...item,
        label: trans(item.label),
      }));
    },
    bulletinType: {
      get() {
        const { bulletinTypeList, typeSelected } = this;
        if (!typeSelected && typeSelected !== 0) {
          return null;
        }
        return _.find(bulletinTypeList, { value: typeSelected });
      },
      set(val) {
        this.typeSelected = val.value;
        this.requestOrg();
      },
    },
    bulletinKindList() {
      const { filterItems: { kind }, $trans: { get: trans } } = this;
      return _.map(kind, item => ({
        ...item,
        label: trans(item.label),
      }));
    },
    uriForRequestItems() {
      if (this.tab === 'all') {
        return this.uriByAll;
      }

      if (this.tab === 'org') {
        return this.uriByOrg;
      }

      return undefined;
    },
  },
  methods: {
    // Передаёт полученный отфильтрованный список через событие
    async getItems() {
      this.loading = true;
      this.$emit('filter-loading', true);
      const data = await this.requestItems();
      this.saveFiltersToLocalStorage({
        localitySelected: this.localitySelected,
        orgNameSelected: this.orgNameSelected,
        ageSelected: this.ageSelectedNew,
        statusSelected: this.statusSelected,
        languageSelected: this.languageSelected,
        organizationTypeSelected: this.organizationTypeSelected,
        groupTypeSelected: this.groupTypeSelected,
        diseases: this.diseases,
        sanatorium: this.sanatorium,
        typeSelected: this.typeSelected,
        kindSelected: this.kindSelected,
      }, 'bulletinFilter');
      this.loading = false;
      this.$emit('filter-loading', false);
      this.$emit('filtered-list', data || []);
    },
    // Отправляет запрос на получение отфильтрованных садов
    async requestItems() {
      const statisticRes = await requestWrapper.call(this, {
        url: this.uriStatistic,
        params: {
          locality: typeof this.localitySelected !== 'undefined' ? this.localitySelected.value : null,
        },
      });
      const itemsRes = await requestWrapper.call(this, {
        method: 'get',
        url: this.uriForRequestItems,
        params: {
          locality: typeof this.localitySelected !== 'undefined' ? this.localitySelected.value : null,
          age: this.ageSelectedNew && this.ageSelectedNew.value,
          organization: this.orgNameSelected,
          status: this.statusSelected,
          language: this.languageSelected,
          organizationType: this.organizationTypeSelected,
          groupType: this.groupTypeSelected,
          types: this.typeSelected,
          kinds: this.kindSelected,
          diseases: this.diseases,
          sanatorium: this.sanatorium,
        },
      });
      let statistics = [];
      let items = [];

      if (!statisticRes.error) {
        // TODO this.$notify();
        statistics = (_.isArray(statisticRes.data) && statisticRes.data) || [];
      }

      if (!itemsRes.error) {
        // TODO this.$notify();
        items = (_.isArray(itemsRes.data) && itemsRes.data) || [];
      }

      return {
        items,
        statistics,
      };
    },
    // Запускает фильтрацию
    async handleApplyFilters() {
      this.getItems();
    },
    // Получает список организаций с выбранного района
    async requestOrg() {
      const orgRes = await requestWrapper.call(this, {
        url: this.uriFilters,
        params: {
          locality: typeof this.localitySelected !== 'undefined' ? this.localitySelected.value : null,
          types: this.typeSelected,
          kinds: this.kindSelected,
        },
      });
      this.orgNameOptions = (_.isArray(orgRes.data) && orgRes.data) || [];

      if (this.orgNameSelected && this.orgNameOptions.length) {
        this.orgNameSelected = null;
      }
    },
    // Получает список доступных фильтров
    async requestFilters() {
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.uriGetFilters,
      });
      const result = {};

      if (!res.error) {
        Object.keys(res)
          .forEach((item) => {
            result[item] = (_.isArray(res[item]) && res[item]) || [];
          });
      }

      return result;
    },
    // Сбрасывает установленные фильтры на значение по умолчанию
    async resetFilters() {
      this.ageSelected = null;
      this.ageSelectedNew = null;
      this.statusSelected = null;
      this.languageSelected = null;
      this.organizationTypeSelected = null;
      this.groupTypeSelected = null;
      this.typeSelected = 0;
      this.kindSelected = 0;
      this.diseases = [];
      this.sanatorium = [];
      this.orgNameSelected = null;

      if (localStorage && localStorage.getItem('bulletinFilter')) {
        localStorage.removeItem('bulletinFilter');
      }

      await this.getItems();
    },
    // Запускается после выбора региона в модальном окне VRegionSelect
    async handleUpdateLocality(val) {
      this.localityOptions = val.data;
      this.filterItems = await this.requestFilters();

      if (!this.loadFiltersFromLocalStorage({
        localitySelected: val.selected,
      }, 'bulletinFilter', true)) {
        this.localitySelected = this.localityOptions[0];
      }

      await this.requestOrg();
      await this.getItems();
    },
    async changedTab(val) {
      await this.getItems();
      this.$emit('changed-tab', val);
    },
  },
};
