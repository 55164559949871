var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-form', {
    staticClass: "s-auth-form",
    on: {
      "submit": _vm.submit
    }
  }, [_c('q-input', {
    attrs: {
      "label": _vm.fields[0].label,
      "rules": _vm.fields[0].rules,
      "type": _vm.fields[0].type,
      "name": _vm.fields[0].name,
      "required": "",
      "placeholder": _vm.fields[0].placeholder,
      "error": _vm.errorMessages[_vm.fields[0].name] ? true : false,
      "error-message": _vm.errorMessages[_vm.fields[0].name] ? _vm.errorMessages[_vm.fields[0].name][0] : ''
    },
    on: {
      "input": function input($event) {
        delete _vm.errorMessages[_vm.fields[0].name];
      }
    },
    model: {
      value: _vm.fields[0].value,
      callback: function callback($$v) {
        _vm.$set(_vm.fields[0], "value", $$v);
      },
      expression: "fields[0].value"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "label": _vm.trans('auth.password'),
      "rules": _vm.fields[1].rules,
      "type": _vm.fields[1].type,
      "name": _vm.fields[1].name,
      "required": "",
      "placeholder": _vm.trans('auth.password_placeholder'),
      "error": _vm.errorMessages[_vm.fields[1].name] ? true : false,
      "error-message": _vm.errorMessages[_vm.fields[1].name] ? _vm.errorMessages[_vm.fields[1].name][0] : ''
    },
    on: {
      "input": function input($event) {
        delete _vm.errorMessages[_vm.fields[1].name];
      }
    },
    model: {
      value: _vm.fields[1].value,
      callback: function callback($$v) {
        _vm.$set(_vm.fields[1], "value", $$v);
      },
      expression: "fields[1].value"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "_token"
    },
    domProps: {
      "value": _vm.token
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-end mb-30"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('a', {
    staticClass: "s-auth-form__lost-pass",
    attrs: {
      "href": _vm.links.lostPass
    }
  }, [_vm._v("\n        " + _vm._s(_vm.trans('auth.forgot_password')) + "\n      ")])])]), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-end"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-200",
    attrs: {
      "label": _vm.trans('auth.auth_to_system_title_enter'),
      "type": "submit",
      "color": "green"
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }