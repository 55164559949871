var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    staticClass: "s-form__create",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_c('div', {
    staticClass: "s-form__inform"
  }, [_c('span', {
    staticClass: "s-str-ico s-str-ico--thm-info-alt",
    domProps: {
      "innerHTML": _vm._s(_vm.trans('warning_text.modal_inform'))
    }
  })]), _vm._v(" "), _c('q-select', {
    attrs: {
      "clearable": "",
      "required": "",
      "option-value": "id",
      "option-label": "group_name",
      "label": _vm.trans('label.freeplace_name'),
      "options": _vm.groupsFiltered,
      "value": _vm.selectedGroup,
      "error": _vm.hasValidationFail('group_id'),
      "error-message": _vm.getValidationMessage('group_id'),
      "use-input": ""
    },
    on: {
      "filter": _vm.filterOptions,
      "input": _vm.setSelectedGroup
    }
  }), _vm._v(" "), _vm.availableCorrection ? _c('q-checkbox', {
    attrs: {
      "disabled": _vm.groupCorrection.value === 2,
      "label": _vm.trans('bulletin.correction_type')
    },
    model: {
      value: _vm.correction,
      callback: function callback($$v) {
        _vm.correction = $$v;
      },
      expression: "correction"
    }
  }) : _vm._e(), _vm._v(" "), _vm.availableSanatorium ? _c('q-checkbox', {
    attrs: {
      "disabled": _vm.groupCorrection.value === 4,
      "label": _vm.trans('bulletin.sanatorium_type')
    },
    model: {
      value: _vm.sanatorium,
      callback: function callback($$v) {
        _vm.sanatorium = $$v;
      },
      expression: "sanatorium"
    }
  }) : _vm._e(), _vm._v(" "), _vm.correction ? _c('div', [_vm.selectedGroup.diseases && _vm.selectedGroup.diseases.length ? _c('q-field', {
    attrs: {
      "label": _vm.trans('bulletin.correction-type'),
      "required": "",
      "borderless": ""
    }
  }, [_vm.selectedGroup.diseases.length ? _c('q-option-group', {
    attrs: {
      "options": _vm.selectedGroupDiseases,
      "label": _vm.trans('bulletin.correction-type'),
      "type": "radio"
    },
    model: {
      value: _vm.disease,
      callback: function callback($$v) {
        _vm.disease = $$v;
      },
      expression: "disease"
    }
  }) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.hasValidationFail('correction') ? _c('div', {
    staticClass: "s-form__inform--red"
  }, [_c('span', [_vm._v(_vm._s(_vm.getValidationMessage('correction')))])]) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.sanatoria ? _c('div', [_vm.selectedGroup.sanatorium && _vm.selectedGroup.sanatorium.length ? _c('q-field', {
    attrs: {
      "label": _vm.trans('bulletin.sanatorium-type'),
      "required": "",
      "borderless": ""
    }
  }, [_vm.selectedGroup.sanatorium.length ? _c('q-option-group', {
    attrs: {
      "options": _vm.selectedGroupSanatorium,
      "label": _vm.trans('bulletin.sanatorium-type'),
      "type": "radio"
    },
    model: {
      value: _vm.sanatoriumType,
      callback: function callback($$v) {
        _vm.sanatoriumType = $$v;
      },
      expression: "sanatoriumType"
    }
  }) : _vm._e()], 1) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.selectedGroupId ? _c('q-input', {
    attrs: {
      "disable": "",
      "label": _vm.trans('fields.group_category'),
      "value": _vm.groupTranslation.age,
      "error": _vm.hasValidationFail('age'),
      "error-message": _vm.getValidationMessage('age')
    }
  }) : _vm._e(), _vm._v(" "), _vm.selectedGroupId ? _c('q-input', {
    attrs: {
      "disable": "",
      "label": _vm.trans('label.freeplace_lang'),
      "value": _vm.groupLanguage,
      "error": _vm.hasValidationFail('lang'),
      "error-message": _vm.getValidationMessage('lang')
    }
  }) : _vm._e(), _vm._v(" "), _c('q-select', {
    attrs: {
      "required": "",
      "disable": _vm.correction || _vm.sanatoria,
      "option-value": "id",
      "option-label": "label",
      "hide-bottom-space": "",
      "label": _vm.trans('label.freeplace_type'),
      "options": _vm.freePlaceTypes,
      "value": _vm.selectedType,
      "error": _vm.hasValidationFail('type'),
      "error-message": _vm.getValidationMessage('type')
    },
    on: {
      "input": _vm.setSelectedType
    }
  }), _vm._v(" "), _vm.isPrivate ? _c('q-field', {
    attrs: {
      "error": _vm.hasValidationFail('budget'),
      "error-message": _vm.getValidationMessage('budget'),
      "borderless": "",
      "hide-bottom-space": ""
    }
  }, [_c('q-checkbox', {
    attrs: {
      "disable": "",
      "label": _vm.trans('label.free_place_is_budget')
    },
    model: {
      value: _vm.budgetProxy,
      callback: function callback($$v) {
        _vm.budgetProxy = $$v;
      },
      expression: "budgetProxy"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('q-input', {
    attrs: {
      "filled": "",
      "required": "",
      "name": "date_active",
      "mask": "##.##.####",
      "label": _vm.trans('label.freeplace_date_active'),
      "placeholder": _vm.trans('placeholder.freeplace_date_active'),
      "error": _vm.hasValidationFail('date_active'),
      "error-message": _vm.getValidationMessage('date_active')
    },
    on: {
      "click": function click() {
        return _vm.$refs.qDateActiveProxy.show();
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "sn--cursor-pointer",
          attrs: {
            "name": "calendar"
          }
        }, [_c('q-popup-proxy', {
          ref: "qDateActiveProxy",
          attrs: {
            "transition-show": "scale",
            "transition-hide": "scale"
          }
        }, [_c('q-date', {
          attrs: {
            "mask": "DD.MM.YYYY",
            "color": "green"
          },
          on: {
            "input": function input() {
              return _vm.$refs.qDateActiveProxy.hide();
            }
          },
          model: {
            value: _vm.dateActive,
            callback: function callback($$v) {
              _vm.dateActive = $$v;
            },
            expression: "dateActive"
          }
        })], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.dateActive,
      callback: function callback($$v) {
        _vm.dateActive = $$v;
      },
      expression: "dateActive"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "filled": "",
      "name": "date_start",
      "mask": "##.##.####",
      "required": _vm.selectedTypeId !== _vm.COMMON_FREE_PLACE_TYPE,
      "disable": _vm.selectedTypeId === _vm.COMMON_FREE_PLACE_TYPE,
      "label": _vm.trans('label.freeplace_date_start'),
      "placeholder": _vm.trans('placeholder.freeplace_date_start'),
      "error": _vm.hasValidationFail('date_start'),
      "error-message": _vm.getValidationMessage('date_start')
    },
    on: {
      "click": function click() {
        if (_vm.selectedTypeId !== _vm.COMMON_FREE_PLACE_TYPE) {
          _vm.$refs.qDateStartProxy.show();
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "name": "iconfont s-ico s-ico--thm-calendar"
          }
        }, [_c('q-popup-proxy', {
          ref: "qDateStartProxy",
          attrs: {
            "transition-show": "scale",
            "transition-hide": "scale"
          }
        }, [_c('q-date', {
          attrs: {
            "mask": "DD.MM.YYYY",
            "color": "green"
          },
          on: {
            "input": function input() {
              return _vm.$refs.qDateStartProxy.hide();
            }
          },
          model: {
            value: _vm.dateStart,
            callback: function callback($$v) {
              _vm.dateStart = $$v;
            },
            expression: "dateStart"
          }
        })], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.dateStartProxy,
      callback: function callback($$v) {
        _vm.dateStartProxy = $$v;
      },
      expression: "dateStartProxy"
    }
  }), _vm._v(" "), _vm.selectedTypeId === _vm.TEMPORARY_FREE_PLACE_TYPE ? _c('q-input', {
    attrs: {
      "filled": "",
      "required": "",
      "name": "date_end",
      "mask": "##.##.####",
      "label": _vm.trans('label.freeplace_date_end'),
      "placeholder": _vm.trans('placeholder.freeplace_date_end'),
      "error": _vm.hasValidationFail('date_end'),
      "error-message": _vm.getValidationMessage('date_end')
    },
    on: {
      "click": function click() {
        return _vm.$refs.qDateEndProxy.show();
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "name": "iconfont s-ico s-ico--thm-calendar"
          }
        }, [_c('q-popup-proxy', {
          ref: "qDateEndProxy",
          attrs: {
            "transition-show": "scale",
            "transition-hide": "scale"
          }
        }, [_c('q-date', {
          attrs: {
            "mask": "DD.MM.YYYY",
            "color": "green"
          },
          on: {
            "input": function input() {
              return _vm.$refs.qDateEndProxy.hide();
            }
          },
          model: {
            value: _vm.dateEnd,
            callback: function callback($$v) {
              _vm.dateEnd = $$v;
            },
            expression: "dateEnd"
          }
        })], 1)], 1)];
      },
      proxy: true
    }], null, false, 4269719958),
    model: {
      value: _vm.dateEnd,
      callback: function callback($$v) {
        _vm.dateEnd = $$v;
      },
      expression: "dateEnd"
    }
  }) : _vm._e(), _vm._v(" "), _c('q-input', {
    attrs: {
      "required": "",
      "name": "count",
      "type": "number",
      "label": _vm.trans('label.freeplace_count'),
      "placeholder": _vm.trans('placeholder.places'),
      "error": _vm.hasValidationFail('count'),
      "error-message": _vm.getValidationMessage('count')
    },
    model: {
      value: _vm.placeCount,
      callback: function callback($$v) {
        _vm.placeCount = $$v;
      },
      expression: "placeCount"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-between"
  }, [_c('div', {
    staticClass: "col-lg-4 col-md-6 col-sm-12 mb-10"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "icon": "close",
      "label": _vm.trans('button.cancel'),
      "label-wider": "",
      "color": "grey"
    },
    on: {
      "click": _vm.goBack
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-lg-4 col-md-6 col-sm-12"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "icon": "check",
      "type": "submit",
      "color": "green",
      "label": _vm.trans('button.save'),
      "label-wider": "",
      "loading": _vm.sendingData
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }