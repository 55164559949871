export default class FormApiProvider {
  constructor({ ContingentRepository }, trans) {
    this.ContingentRepository = ContingentRepository;
    this.trans = trans;
  }

  provideDeactivateContingent() {
    const { ContingentRepository, trans } = this;
    // eslint-disable-next-line func-names
    return async function (modelId, deactivateContractNumber, reasonId, deactivateOtherReason = null, byGroup = false) {
      try {
        const response = await ContingentRepository.deactivate(
          modelId, deactivateContractNumber, reasonId, deactivateOtherReason, byGroup,
        );
        const { data: { status } } = response;
        if (status === 'ok') {
          return true;
        }
      } catch (error) {
        let message = trans('notice.error_on_server');
        if (error.response) {
          if (error.response.data && error.response.data.message) {
            // eslint-disable-next-line prefer-destructuring
            message = error.response.data.message;
          } else {
            const statusMessages = {
              400: trans('notice.invalid_deactivate_reason'),
              404: trans('notice.child_not_found'),
            };
            if (statusMessages[error.response.status] !== undefined) {
              message = statusMessages[error.response.status];
            }
          }
        }

        this.$notify({
          type: 'error',
          text: message,
        });
      }
      return false;
    };
  }

  provideGetAllowedGroupsByContingentId() {
    const { ContingentRepository } = this;
    // eslint-disable-next-line func-names
    return async function (contingentId) {
      try {
        // noinspection JSUnresolvedFunction
        const response = await ContingentRepository.getAllowedGroups(contingentId);
        const { data: { status, groups } } = response;

        return status === 'ok'
          ? groups
          : [];
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'server error',
          text: error,
        });
      }
      return [];
    };
  }

  provideMoveChildToGroup() {
    const { ContingentRepository, trans } = this;
    // eslint-disable-next-line func-names
    return async function (contingentId, groupId, file) {
      try {
        const response = await ContingentRepository.moveChildToGroup(contingentId, groupId, file);
        const { data: { status } } = response;
        if (status === 'ok') {
          return true;
        }
      } catch (error) {
        let message = trans('notice.error_on_server');
        if (error.response) {
          if (error.response.data && error.response.data.message) {
            // eslint-disable-next-line prefer-destructuring
            message = error.response.data.message;
          } else {
            const statusMessages = {
              507: trans('notice.no_free_places'),
              404: trans('label.data_not_found'),
              424: trans('notice.same_group'),
            };
            if (statusMessages[error.response.status] !== undefined) {
              message = statusMessages[error.response.status];
            }
          }
        }

        this.$notify({
          type: 'error',
          text: message,
        });

        return false;
      }
      return false;
    };
  }
}
