var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-funding-calculator', {
    attrs: {
      "context": _vm.calculation,
      "calculable": _vm.calculable,
      "listable": _vm.listRatios,
      "api-config": _vm.apiConfig
    },
    on: {
      "close": _vm.redirect
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }