var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-field', {
    attrs: {
      "field": _vm.field,
      "field-name": _vm.fieldName,
      "errors": _vm.errors
    }
  }, [_c('template', {
    slot: "field"
  }, [_vm.file.selected ? _c('div', {
    staticClass: "statement__upload-wrapper mb-10"
  }, [_c('div', {
    staticClass: "button button_type_attachment button_theme_green"
  }, [_vm._v("\n        " + _vm._s(_vm.trans('label.attached')) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "button button_type_file remove_uploaded_file",
    on: {
      "click": function click($event) {
        return _vm.onRemoveFile();
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.file.name) + "\n      ")])]) : _c('div', {
    staticClass: "statement__upload-wrapper"
  }, [_c('div', {
    staticClass: "button button_type_add button_theme_blue hidden_file_input",
    on: {
      "click": function click($event) {
        return _vm.onClickInputFile();
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.trans('button.add')) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "button button_theme_file"
  }, [_vm._v("\n        " + _vm._s(_vm.trans('label.file_not_chosen')) + "\n      ")]), _vm._v(" "), _c('input', {
    ref: "input_file",
    staticClass: "hidden",
    attrs: {
      "type": "file"
    },
    on: {
      "change": _vm.onChangeInputFile
    }
  })])])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }