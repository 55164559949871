var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-field', {
    attrs: {
      "field": _vm.field,
      "field-name": _vm.fieldName,
      "errors": _vm.errors
    }
  }, [_c('template', {
    slot: "field"
  }, [_c('input', _vm._b({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.localValue,
      expression: "localValue"
    }, {
      name: "mask",
      rawName: "v-mask",
      value: {
        mask: '+7 (999) 999-99-99',
        placeholder: '+7 (___) ___-__-__'
      },
      expression: "{mask: '+7 (999) 999-99-99', placeholder: '+7 (___) ___-__-__' }"
    }],
    staticClass: "s-form__input",
    attrs: {
      "type": "text",
      "name": _vm.field.name
    },
    domProps: {
      "value": _vm.localValue
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.localValue = $event.target.value;
      }
    }
  }, 'input', _vm.extraAttributes, false))])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }