var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-tabel-wrapper"
  }, [_c('div', {
    staticClass: "s-tabel-table__container",
    class: {
      'is-result': _vm.result
    }
  }, [_c('div', {
    staticClass: "s-tabel-table__scroll"
  }, [_c('table', {
    staticClass: "s-tabel-table"
  }, [_c('thead', {
    staticClass: "s-tabel-table__head"
  }, [_c('tr', {
    staticClass: "s-tabel-table__head-days"
  }, [_c('th', {
    attrs: {
      "colspan": "2"
    }
  }), _vm._v(" "), _vm._l(_vm.children[0].cells.length, function (itm) {
    return _c('th', {
      key: itm
    }, [_vm._v("\n          " + _vm._s(itm) + "\n        ")]);
  }), _vm._v(" "), _vm.result ? [_c('th', {
    staticClass: "s-tabel-table__cell-total"
  }, [_vm._v(_vm._s(_vm.trans('attendance.total')))]), _vm._v(" "), _c('th', {
    staticClass: "s-tabel-table__cell-including-count"
  }, [_vm._v("\n            " + _vm._s(_vm.trans('attendance.including_those_that_count')) + "\n          ")]), _vm._v(" "), _c('th', {
    staticClass: "s-tabel-table__cell-reasons"
  }, [_vm._v("\n            " + _vm._s(_vm.trans('attendance.reasons_for_absent')) + "\n          ")])] : _vm._e()], 2)]), _vm._v(" "), _c('tbody', {
    staticClass: "s-tabel-table__body"
  }, _vm._l(_vm.children, function (child, childIdx) {
    return _c('tr', {
      key: child.id
    }, [_c('td', {
      staticClass: "s-tabel-table__td-num"
    }, [_vm._v(_vm._s(childIdx + 1))]), _vm._v(" "), _c('td', {
      staticClass: "s-tabel-table__td-name"
    }, [_vm._v(_vm._s(child.name))]), _vm._v(" "), _vm._l(child.cells, function (cell, cellIdx) {
      return _c('td', {
        key: cellIdx
      }, [_vm._v("\n          " + _vm._s(cell.absent) + "\n        ")]);
    }), _vm._v(" "), _vm.result ? [_c('td', {
      staticClass: "s-tabel-table__cell-total"
    }), _vm._v(" "), _c('td', {
      staticClass: "s-tabel-table__cell-including-count"
    }), _vm._v(" "), _c('td', {
      staticClass: "s-tabel-table__cell-reasons"
    })] : _vm._e()], 2);
  }), 0), _vm._v(" "), _c('tfoot', {
    staticClass: "s-tabel-table__foot"
  }, [_c('tr', [_c('td', {
    staticClass: "s-tabel-table__td-num"
  }), _vm._v(" "), _c('td', {
    staticClass: "s-tabel-table__td-total-title"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('attendance.total_absent_children')) + "\n        ")]), _vm._v(" "), _vm._l(_vm.children[0].cells.length, function (itm, cellIdx) {
    return _c('td', {
      key: itm
    }, [_vm._v("\n          " + _vm._s(_vm.calcAbsent(cellIdx)) + "\n        ")]);
  }), _vm._v(" "), _vm.result ? [_c('td', {
    staticClass: "s-tabel-table__cell-total"
  }), _vm._v(" "), _c('td', {
    staticClass: "s-tabel-table__cell-including-count"
  }), _vm._v(" "), _c('td', {
    staticClass: "s-tabel-table__cell-reasons"
  })] : _vm._e()], 2)])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }