var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-input', _vm._b({
    attrs: {
      "mask": "##:##",
      "placeholder": "__:__",
      "error": _vm.error,
      "error-message": _vm.errorMessage,
      "label": _vm.extraBinds.labelTransKey ? _vm.trans(_vm.extraBinds.labelTransKey) : _vm.extraBinds.label,
      "date": ""
    },
    on: {
      "click": function click() {
        return _vm.$refs.qTimeFieldActiveProxy.show();
      },
      "popup-hide": function popupHide($event) {
        return _vm.emitChange(_vm.localValue);
      }
    },
    scopedSlots: _vm._u([{
      key: "before",
      fn: function fn() {
        return [_c('q-popup-proxy', {
          ref: "qTimeFieldActiveProxy",
          attrs: {
            "transition-show": "scale",
            "transition-hide": "scale"
          }
        }, [_c('q-time', {
          attrs: {
            "color": "blue",
            "mask": "HH:mm",
            "now-btn": "",
            "format24h": "",
            "minute-options": [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]
          },
          model: {
            value: _vm.localValue,
            callback: function callback($$v) {
              _vm.localValue = $$v;
            },
            expression: "localValue"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "sn--cursor-pointer",
          attrs: {
            "name": "clock"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.localValue,
      callback: function callback($$v) {
        _vm.localValue = $$v;
      },
      expression: "localValue"
    }
  }, 'q-input', _vm.extraBinds.input, false));
}
var staticRenderFns = []

export { render, staticRenderFns }