import Vue from 'vue';
import axios from 'axios';
// import Notifications from 'vue-notification';
import VueTimeago from 'vue-timeago';
import sentry from '@vendor/sentry';
import quasarApp from '@quasar/install';
import Translator from '@common/plugins/Translator';
import { eventBus } from '@common/mixins';
import plugin from '@common/plugins';
import SModal from '@common/plugins/SModal';
import {
  QBtn,
  QDialog,
  QForm,
  QInput,
  QSelect,
} from '@quasar/components';
import PortalVue from 'portal-vue';
import componentsForm from '@common/components/Form';
import store from './store';
import Modal from './plugins/ModalWindow';
import Notifications from './plugins/Notification';
import Components from './componentList';
import Modals from './modals';

Vue.use(PortalVue);
sentry(Vue, 'https://af3b1b90ce92441d9cd72ea0ec78db9e@sentry.services.snation.kz/5');

plugin(Vue);
Vue.use(Translator, { store, eventBus });
Vue.use(Notifications);
Vue.use(Modal);
Vue.use(SModal);
Vue.use(quasarApp, {
  components: {
    QInput,
    QForm,
    QDialog,
    QBtn,
  },
});
Vue.component('q-select', QSelect);
Modals(Vue);

Vue.use(VueTimeago, {
  name: 'Timeago',
  locale: 'ru',
  locales: {
    ru: require('@vjs/views/VInsta/locales/ru/index.js'),
    kz: require('@vjs/views/VInsta/locales/kz/index.js'),
  },
});

Vue.prototype.$axios = axios;
Vue.prototype.$eventHub = new Vue();

if (document.getElementById('v-app')) {
  componentsForm(Vue);

  if (window.core_project) {
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': window.core_project.csrfToken || '',
      'Accept-Language': window.core_project.locale,
    };
  } else {
    console.log('No window.core_project! You\'re probably testing static components');
  }
  window.vm = new Vue({
    el: '#v-app',
    components: { ...Components },
    store,
  });
}
