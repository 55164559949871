var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-vue-modal__body"
  }, [_c('p', {
    staticClass: "s-medium"
  }, [_vm._v("\n    " + _vm._s(_vm.trans('label.docs_criteries')) + ":\n  ")]), _vm._v(" "), _c('ul', {
    staticClass: "mb-30"
  }, [_c('li', [_vm._v(_vm._s(_vm.trans('label.file_formats')) + ";")]), _vm._v(" "), _c('li', [_vm._v(_vm._s(_vm.trans('label.file_size')) + ";")]), _vm._v(" "), _c('li', [_vm._v(_vm._s(_vm.trans('label.file_dimensions')) + ";")])]), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-between"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('button', {
    staticClass: "s-btn s-btn--w-200 s-btn--thm-grey",
    on: {
      "click": _vm.close
    }
  }, [_c('span', {
    staticClass: "s-str-ico s-str-ico--thm-angle-left"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('button.cancel')) + "\n        ")])])]), _vm._v(" "), _c('div', {
    staticClass: "col-auto"
  }, [_c('button', {
    staticClass: "s-btn s-btn--w-200 s-btn--thm-green",
    on: {
      "click": _vm.fileUpload
    }
  }, [_c('span', {
    staticClass: "s-str-ico s-str-ico--thm-export"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('button.review')) + "\n        ")])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }