import _ from 'lodash';
import {
  requestWrapper,
} from '@vjs/helpers';
import {
  QInput,
  QDate,
  QPopupProxy,
  QSelect,
  QTable,
  QIcon,
  QTr,
  QTh,
  QTd,
  QBtn,
  QTooltip,
} from '@quasar/components';


export default {
  name: 'VDirectionsMonitoringView',
  components: {
    QInput,
    QDate,
    QPopupProxy,
    QSelect,
    QTable,
    QIcon,
    QTr,
    QTh,
    QTd,
    QBtn,
    QTooltip,
  },
  props: {
    apiUrl: {
      type: String,
    },
    mode: {
      type: String,
      default: '',
    },
    filter: {
      type: Boolean,
      default: true,
    },
    colNames: {
      type: Array,
      required: true,
    },
    secondaryHeaders: {
      type: Array,
      default: () => [],
    },
    directionsUrl: {
      type: String,
      default: () => '/control/monitoring/reports/directions',
    },
  },
  data: () => ({
    table: {
      inFullscreen: false,
      pagination: {
        rowsPerPage: 10,
      },
      columns: [],
      secondaryColumns: [],
      secondaryColumnsMap: {},
      title: '',
    },
    rows: [],
    exportLoading: false,
    filters: {
      date: {
        from: '',
        to: '',
      },
    },
    filterString: '',
  }),
  mounted() {
    const { core_project } = window;
    this.locale = core_project.locale || 'kz';
    this.requestGetData();

    const cols = [];

    this.colNames.forEach((itm, itmIdx) => {
      cols.push({
        name: itm.name,
        required: true,
        label: `statistics.directions.${itm.label}`,
        align: itmIdx === 0 ? 'left' : 'center',
        field: itm.name,
        sortable: true,
        colspan: itm.colspan,
        rowspan: itm.rowspan,
      });
    });
    this.table.columns = cols;

    const colsSecond = [];
    const colsSecondMap = {};

    this.secondaryHeaders.forEach((itm, itmIdx) => {
      const col = {
        name: itm.name,
        required: true,
        label: `statistics.directions.${itm.label}`,
        align: itmIdx === 0 ? 'left' : 'center',
        field: itm.name,
        colspan: itm.colspan,
        rowspan: itm.rowspan,
      };
      colsSecond.push(col);
      colsSecondMap[col.name] = col;
    });
    this.table.secondaryColumns = colsSecond;
    this.table.secondaryColumnsMap = { colsMap: colsSecondMap };
  },
  beforeCreate() {
    this.$trans.add(['statistics', 'button', 'menu', 'placeholder']);
  },
  methods: {
    clearFilters() {
      this.filters.date.to = '';
      this.filters.date.from = '';
    },
    checkTotal(item) {
      if (this.mode !== 'details') {
        return item.name !== 'name' && item.name !== 'operation' && item.name !== 'private';
      }

      return item.name !== 'name' && item.name !== 'type';
    },
    async requestGetData() {
      const dataRes = await requestWrapper({
        url: this.apiUrl,
        params: {
          to: this.filters.date.to,
          from: this.filters.date.from,
        },
      });

      if (!dataRes.error) {
        this.rows = dataRes.data;
      }
    },
    sum(col) {
      const rows = _.filter(
        this.rows,
        item => typeof item[col.name] === 'number',
      );
      return _.sumBy(rows, col.name);
    },
    async exportu(e) {
      this.exportLoading = true;
      this.$axios({
        url: `${this.apiUrl}`,
        method: 'GET',
        responseType: 'blob',
        params: {
          to: this.filters.date.to,
          from: this.filters.date.from,
          export: true,
        },
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        const date = new Date();

        fileLink.setAttribute('download',
          `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()
          } ${this.filter ? this.$trans.get('menu.monitoring_new.queue_portal') : this.$trans.get('menu.monitoring_new.queue')
          } ${this.filters.date.from ? this.$trans.get('statistics.file.from', { date: this.filters.date.from }) : ''
          } ${this.filters.date.to ? this.$trans.get('statistics.file.to', { date: this.filters.date.to }) : ''
          } .xlsx`);
        document.body.appendChild(fileLink);

        fileLink.click();
        this.exportLoading = false;
      });
    },
  },
  watch: {
    'filters.date.from': function () {
      this.requestGetData();
    },
    'filters.date.to': function () {
      this.requestGetData();
    },
  },
};
