var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-dialog', {
    attrs: {
      "value": _vm.modal
    },
    on: {
      "input": _vm.handleModal
    }
  }, [_c('v-modal', {
    attrs: {
      "title": _vm.trans('label.doc_extend_direction'),
      "size": "medium"
    }
  }, [_c('div', {
    staticClass: "s-plain-text"
  }, [_c('p', {
    staticClass: "s-medium"
  }, [_vm._v(_vm._s(_vm.trans('label.extend_direction_description')))]), _vm._v(" "), _c('p', {
    staticClass: "s-medium"
  }, [_vm._v(_vm._s(_vm.trans('label.docs_criteries')) + ":")]), _vm._v(" "), _c('ul', {
    staticClass: "mb-30"
  }, [_c('li', [_vm._v(_vm._s(_vm.trans('label.file_formats')) + ";")]), _vm._v(" "), _c('li', [_vm._v(_vm._s(_vm.trans('label.file_size')) + ";")]), _vm._v(" "), _c('li', [_vm._v(_vm._s(_vm.trans('label.file_dimensions')) + ";")])])]), _vm._v(" "), _c('q-input', {
    staticClass: "mb-20",
    attrs: {
      "type": "file"
    },
    model: {
      value: _vm.file,
      callback: function callback($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-end"
  }, [_c('div', {
    staticClass: "col col-sm-auto"
  }, [_c('q-btn', {
    directives: [{
      name: "close-popup",
      rawName: "v-close-popup"
    }],
    attrs: {
      "width": "20",
      "label": _vm.trans('button.cancel'),
      "labelWider": "",
      "color": "grey"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col col-sm-auto"
  }, [_c('q-btn', {
    attrs: {
      "width": "20",
      "label": _vm.trans('button.send'),
      "color": "green",
      "loading": _vm.loading,
      "disable": !_vm.file
    },
    on: {
      "click": _vm.send
    }
  })], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }