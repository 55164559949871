var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-field', {
    attrs: {
      "field": _vm.field,
      "field-name": _vm.fieldName,
      "label-class": '',
      "errors": _vm.errors
    }
  }, [_c('template', {
    slot: "field"
  }, [_c('q-input', _vm._b({
    attrs: {
      "name": _vm.field.name,
      "placeholder": _vm.field.placeholder ? _vm.trans(_vm.field.placeholder) : undefined
    },
    model: {
      value: _vm.localValue,
      callback: function callback($$v) {
        _vm.localValue = $$v;
      },
      expression: "localValue"
    }
  }, 'q-input', _vm.extraAttributes, false))], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }