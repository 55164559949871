import VPieChart from '@vjs/components/VPieChart';
import {
  QBtn,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import VPhotoAlbum from '@common/components/VPhotoAlbum';
import { scrollTop } from '../../helpers';

export default {
  name: 'VPassportPageView',

  components: {
    VPieChart,
    QBtn,
    VPhotoAlbum,
  },

  props: {
    passportId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      picItems: [],
      flags: '',
      name: '',
      description: '',
      address: '',
      phone: '',
      email: '',
      groups: [],
      aboutOrg: [],
      statistics: {
        throughput: '',
        eduLangs: '',
        yearRestriction: '',
        pieChart: [],
      },
    };
  },

  async mounted() {
    this.$trans.add(['organization']);
    const res = await requestWrapper.call(this, {
      url: '/api/v1/balabaqshas/show',
      params: {
        id: this.passportId,
      },
    });

    if (!res.error) {
      this.flags = res.data.marks;
      this.name = res.data.name;
      this.description = res.data.body;
      this.address = res.data.address;
      this.phone = res.data.phone_number;
      this.email = res.data.email;
      this.groups = res.data.groups;
      this.aboutOrg = res.data.informBlock;
      this.statistics.throughput = res.data.pieBlock.throughput;

      if (Array.isArray(res.data.pieBlock.eduLangs)) {
        this.statistics.eduLangs = res.data.pieBlock.eduLangs.reduce((acc, item, idx) => {
          const symbComma = (idx !== res.data.pieBlock.eduLangs.length - 1 && ', ') || '';
          return acc + item + symbComma;
        }, '');
      }

      this.statistics.yearRestriction = res.data.pieBlock.orgYearRestriction;
      this.statistics.pieChart = res.data.pieBlock.pie;
      this.picItems = res.data.images;
    }
  },

  methods: {
    scrollTo(name = '') {
      if (this.$refs[name]) {
        scrollTop(
          window,
          window.scrollY,
          window.scrollY + Math.abs(this.$refs[name].getBoundingClientRect().top),
          500,
        );
      }
    },
  },
};




/*

        {
          img: '/images/test_image/1.jpg',
          thumb: '/images/test_image/1-min.jpg',
        },
        {
          img: '/images/test_image/2.jpg',
          thumb: '/images/test_image/2-min.jpg',
        },
        {
          img: '/images/test_image/3.jpg',
          thumb: '/images/test_image/3-min.jpg',
        },
        {
          img: '/images/test_image/4.jpg',
          thumb: '/images/test_image/4-min.jpg',
        },
        {
          img: '/images/test_image/5.jpg',
          thumb: '/images/test_image/5-min.jpg',
        },
        {
          img: '/images/test_image/6.jpg',
          thumb: '/images/test_image/6-min.jpg',
        },
        {
          img: '/images/test_image/7.jpg',
          thumb: '/images/test_image/7-min.jpg',
        },
        {
          img: '/images/test_image/8.jpg',
          thumb: '/images/test_image/8-min.jpg',
        },
        {
          img: '/images/test_image/9.jpg',
          thumb: '/images/test_image/9-min.jpg',
        },
        {
          img: '/images/test_image/10.jpg',
          thumb: '/images/test_image/10-min.jpg',
        },
        {
          img: '/images/test_image/11.jpg',
          thumb: '/images/test_image/11-min.jpg',
        },

 */
