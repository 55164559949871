var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "s-application-block",
    class: {
      'is-locked': _vm.locked
    }
  }, [_c('div', {
    staticClass: "s-application-block__columns"
  }, [_c('div', {
    staticClass: "s-application-block__column-left"
  }, [_c('div', {
    staticClass: "s-application-block__head"
  }, [_c('div', {
    staticClass: "s-application-block__name"
  }, [_vm._v("\n          " + _vm._s(_vm.capitalizeByWords(_vm.name)) + "\n        ")]), _vm._v(" "), _vm.flag ? _c('div', {
    staticClass: "s-application-block__mark"
  }, [_c('div', {
    staticClass: "s-flag s-b-blue s-c-white"
  }, [_vm._v("\n            " + _vm._s(_vm.flag) + "\n          ")])]) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "s-application-block__head"
  }, [_c('div', [_vm._v("\n          " + _vm._s(_vm.trans('label.statements_count_submitted')) + ": " + _vm._s(_vm.statementsCount) + "\n        ")])]), _vm._v(" "), _c('div', {
    staticClass: "s-application-block__body"
  }, [_c('div', {
    staticClass: "row"
  }, _vm._l(_vm.desc, function (item, idx) {
    return _c('div', {
      key: idx,
      staticClass: "col-lg-4 col-md-6 col-sm-6"
    }, [_c('div', {
      staticClass: "s-desc-item"
    }, [_c('div', {
      staticClass: "s-desc-item__label mb-5"
    }, [_vm._v("\n                " + _vm._s(item.label) + ":\n              ")]), _vm._v(" "), _c('div', {
      staticClass: "s-desc-item__value"
    }, [_vm._v("\n                " + _vm._s(item.value) + "\n              ")])])]);
  }), 0)])]), _vm._v(" "), !_vm.locked ? _c('div', {
    staticClass: "s-application-block__column-right"
  }, [_c('div', {
    staticClass: "s-application-block__controls"
  }, [_vm.lgotBtn && _vm.lgotBtn.canUpdate && !_vm.isCorrection ? _c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "color": _vm.lgotBtn.disable ? 'grey' : 'blue',
      "label": _vm.trans('label.update_statement_lgot'),
      "no-caps": ""
    },
    on: {
      "click": _vm.handleUpdateLgotModal
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "s-application-block__controls"
  }, [_vm.removePriorityBtn.active ? _c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "outline": "",
      "color": "red",
      "label": _vm.trans('button.remove_priority_status'),
      "no-caps": ""
    },
    on: {
      "click": _vm.handleRemovePriority
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "s-application-block__controls"
  }, [_vm.langBtn.active ? _c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "color": "orange",
      "label": _vm.trans('label.update_statement_lang'),
      "no-caps": ""
    },
    on: {
      "click": _vm.handleUpdateLangModal
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "s-application-block__controls--update-btn"
  }, [_vm.removeSuccess ? _c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "icon": "trash",
      "color": "red",
      "no-caps": "",
      "label": _vm.trans('button.remove'),
      "outline": ""
    },
    on: {
      "click": function click($event) {
        _vm.modalConfirmRemove = true;
      }
    }
  }) : _vm._e()], 1)]) : _vm._e()]), _vm._v(" "), !_vm.locked ? _c('div', {
    staticClass: "s-application-block__steps"
  }, _vm._l(_vm.steps, function (step, stepIndex) {
    return _c('div', {
      key: stepIndex,
      staticClass: "s-application-block__step"
    }, [_c('div', {
      staticClass: "s-application-block__step-head",
      class: "s-application-block__step-head--".concat(step.status)
    }, [_c('div', {
      staticClass: "s-vis-ellipsis"
    }, [_c('span', {
      class: "s-str-ico s-ico--thm-".concat(_vm.getIconByStatus(step.status))
    }, [_vm._v("\n            " + _vm._s(step.title) + "\n          ")])])]), _vm._v(" "), _c('transition', {
      attrs: {
        "name": "a-slide-vertical"
      }
    }, [_c('div', {
      staticClass: "s-application-block__step-body"
    }, [_c('div', {
      staticClass: "s-application-block__step-text",
      domProps: {
        "innerHTML": _vm._s(step.text)
      }
    }), _vm._v(" "), _vm._l(step.controls, function (ctrl, ctrlIndex) {
      return [ctrl.type === 'button' && !(_vm.isCorrection && ctrl.name === 'interested') ? _c('div', {
        key: ctrlIndex
      }, [_c('q-btn', {
        attrs: {
          "label": ctrl.label,
          "color": ctrl.color,
          "disable": ctrl.disabled,
          "loading": _vm.setLoading(ctrl.name),
          "no-caps": ""
        },
        on: {
          "click": function click($event) {
            return _vm.controlDoing(ctrl.name, ctrl.action, stepIndex, ctrlIndex);
          }
        }
      }), _vm._v(" "), ctrl.tooltip ? _c('q-tooltip', {
        attrs: {
          "anchor": "top middle",
          "self": "center middle"
        }
      }, [_c('div', {
        staticStyle: {
          "max-width": "28rem"
        }
      }, [_vm._v("\n                  " + _vm._s(ctrl.tooltip) + "\n                ")])]) : _vm._e()], 1) : _vm._e(), _vm._v(" "), ctrl.type === 'link' ? _c('a', {
        key: ctrlIndex,
        attrs: {
          "href": ctrl.action,
          "target": "_blank",
          "title": ctrl.label
        }
      }, [_vm._v(_vm._s(ctrl.label))]) : _vm._e()];
    })], 2)])], 1);
  }), 0) : _vm._e(), _vm._v(" "), _vm.locked && _vm.isNotInterested ? _c('div', {
    staticClass: "s-application-block__warning"
  }, [_c('div', {
    staticClass: "s-application-block__warning-message"
  }, [_vm._v("\n      " + _vm._s(_vm.trans('statement.is_interest_stoplist')) + "\n    ")]), _vm._v(" "), _c('q-btn', {
    attrs: {
      "color": "green",
      "label": _vm.trans('button.approve'),
      "loading": _vm.loadingInterest,
      "no-caps": ""
    },
    on: {
      "click": _vm.setInterest
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('v-confirm-dialog', {
    attrs: {
      "modal": _vm.modalConfirmRemove,
      "loading": _vm.loadingRemove,
      "confirm": _vm.removeApplication,
      "text": _vm.trans('statement.remove'),
      "light-text": true
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.modalConfirmRemove = $event;
      }
    }
  }), _vm._v(" "), _c('v-application-item-modal', {
    attrs: {
      "modal": _vm.modalContinue,
      "identifier": _vm.identifier,
      "uri-statement-continue": _vm.uriStatementContinue,
      "success": _vm.setControlSuccessFromItemModal
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.modalContinue = $event;
      }
    }
  }), _vm._v(" "), _c('v-application-lgots-modal', {
    attrs: {
      "modal": _vm.modalLgots,
      "identifier": _vm.identifier,
      "uri-update-lgots": _vm.uriUpdateLgots,
      "uri-get-lgot-types": _vm.uriGetLgotTypes,
      "uri-get-lgot-type-documents": _vm.uriGetLgotTypeDocuments
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.modalLgots = $event;
      }
    }
  }), _vm._v(" "), _c('v-application-lang-modal', {
    attrs: {
      "modal": _vm.modalLang,
      "lang": _vm.langBtn,
      "statement-id": _vm.identifier
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.modalLang = $event;
      }
    }
  }), _vm._v(" "), _c('v-application-remove-priority-modal', {
    attrs: {
      "modal": _vm.modalRemovePriority
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.modalRemovePriority = $event;
      },
      "modal-confirm": _vm.handleConfirmRemovePriority
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }