var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "tag": "div",
      "name": "s-vue-modal",
      "duration": 1000
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.modals.length,
      expression: "modals.length"
    }],
    staticClass: "s-vue-modal__wrapper",
    attrs: {
      "tabindex": "0"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        $event.preventDefault();
        return _vm.handleEscapeKey($event);
      },
      "click": function click($event) {
        return _vm.dismiss();
      }
    }
  }, _vm._l(_vm.modals, function (modal, index) {
    return _c('div', {
      key: index,
      staticClass: "s-vue-modal",
      class: Object.assign({}, modal.classes, {
        's-vue-modal--disabled': index < _vm.$last
      }),
      style: modal.styles,
      on: {
        "click": function click($event) {
          $event.stopPropagation();
        }
      }
    }, [_c('div', {
      staticClass: "s-vue-modal__content"
    }, [(modal.title || modal.dismissable) && !modal.header ? _c('header', {
      staticClass: "s-vue-modal__header"
    }, [_c('span', {
      staticClass: "s-vue-modal__title"
    }, [_vm._v(_vm._s(modal.title))]), _vm._v(" "), modal.dismissable ? _c('span', {
      staticClass: "s-vue-modal__close",
      on: {
        "click": function click($event) {
          return _vm.dismiss();
        }
      }
    }) : _vm._e()]) : _vm._e(), _vm._v(" "), modal.header ? _c('header', {
      staticClass: "s-vue-modal__header"
    }, [_c(modal.header.component, _vm._b({
      tag: "component"
    }, 'component', modal.header.props, false))], 1) : _vm._e(), _vm._v(" "), _c(modal.component, _vm._b({
      tag: "component"
    }, 'component', modal.props, false))], 1)]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }