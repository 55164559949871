var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row attendance-panel"
  }, [_vm.type === 'attendances-by-kindergarten' ? _c('div', {
    staticClass: "col-md-2 col-sm-4"
  }, [_c('q-select', {
    attrs: {
      "disable": _vm.localities.length <= 1,
      "options": _vm.localities
    },
    on: {
      "input": _vm.onChangeLocality
    },
    model: {
      value: _vm.locality,
      callback: function callback($$v) {
        _vm.locality = $$v;
      },
      expression: "locality"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.type !== 'attendances-by-kindergarten' ? _c('div', {
    staticClass: "col-1"
  }, [_c('div', {
    staticClass: "attendance-calendar-icon"
  }, [_c('q-icon', {
    attrs: {
      "name": "calendar",
      "size": "sm"
    }
  })], 1)]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "col-md-2 col-sm-4"
  }, [_c('q-select', {
    attrs: {
      "disable": _vm.years.length <= 1,
      "options": _vm.years
    },
    on: {
      "input": _vm.onChangeYear
    },
    model: {
      value: _vm.year,
      callback: function callback($$v) {
        _vm.year = $$v;
      },
      expression: "year"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-md-2 col-sm-4"
  }, [_c('q-select', {
    attrs: {
      "disable": _vm.months.length <= 1,
      "options": _vm.months,
      "option-value": "value",
      "option-label": "label"
    },
    on: {
      "input": _vm.onChangeMonth
    },
    model: {
      value: _vm.month,
      callback: function callback($$v) {
        _vm.month = $$v;
      },
      expression: "month"
    }
  })], 1), _vm._v(" "), _c('div', {
    class: _vm.type === 'attendances-by-kindergarten' ? 'col-md-6 col-sm-4 pr-0' : 'col-md-7 col-sm-4 pr-0'
  }, [_vm.canExport ? _c('q-btn', {
    staticClass: "s-btn--w-200 mt-10 s-pos-float-right",
    attrs: {
      "color": "orange",
      "no-caps": "",
      "text-color": "white",
      "icon": "file-excel",
      "label": _vm.trans('button.export'),
      "loading": _vm.loading
    },
    on: {
      "click": _vm.openExportModal
    }
  }) : _vm._e()], 1)])]), _vm._v(" "), _c('q-table', {
    attrs: {
      "flat": !_vm.$q.screen.lt.md,
      "grid": _vm.$q.screen.lt.md,
      "columns": _vm.columns,
      "data": _vm.groups,
      "loading": _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, _vm._l(props.cols, function (col) {
          return _c('q-th', {
            key: col.name,
            staticClass: "attendance-table-header",
            attrs: {
              "props": props
            }
          }, [_vm._v("\n          " + _vm._s(col.label) + "\n        ")]);
        }), 1)];
      }
    }, {
      key: "body",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, [_c('q-td', {
          key: "group_name",
          staticClass: "attendance-table-cell",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n          " + _vm._s(props.row.name) + "\n        ")]), _vm._v(" "), _c('q-td', {
          key: "age_category",
          staticClass: "attendance-table-cell",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n          " + _vm._s(props.row.ageCategory) + "\n        ")]), _vm._v(" "), _c('q-td', {
          key: "organization_throughput",
          staticClass: "attendance-table-cell",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n          " + _vm._s(props.row.organization_throughput) + "\n        ")]), _vm._v(" "), _c('q-td', {
          key: "active_contingent_count",
          staticClass: "attendance-table-cell",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n          " + _vm._s(props.row.active_contingent_count) + "\n        ")]), _vm._v(" "), _c('q-td', {
          key: "attendances",
          staticClass: "attendance-table-cell",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n          " + _vm._s(props.row.attendances) + "\n        ")]), _vm._v(" "), _c('q-td', {
          key: "count",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n          " + _vm._s(props.row.count) + "\n        ")]), _vm._v(" "), _c('q-td', {
          key: "absentDays",
          staticClass: "attendance-table-cell",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n          " + _vm._s(props.row.absentDays) + "\n        ")]), _vm._v(" "), _c('q-td', {
          key: "respectfulAbsentDays",
          staticClass: "attendance-table-cell",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n          " + _vm._s(props.row.respectfulAbsentDays) + "\n        ")]), _vm._v(" "), _c('q-td', {
          key: "attendancesIsNull",
          staticClass: "attendance-table-cell",
          attrs: {
            "props": props
          }
        }, [_vm._v("\n          " + _vm._s(props.row.attendancesIsNull) + "\n        ")]), _vm._v(" "), _c('q-td', {
          key: "id",
          staticClass: "attendance-table-cell",
          attrs: {
            "props": props
          }
        }, [_c('a', {
          staticClass: "funding-route-link",
          attrs: {
            "href": _vm.makeRouteLink(props)
          }
        }, [_c('q-btn', {
          attrs: {
            "squire": "",
            "icon": "eye",
            "title": _vm.trans('label.view')
          }
        })], 1)])], 1)];
      }
    }, {
      key: "item",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "sn-table__grid-item col-xs-12 col-sm-6 col-md-4 col-lg-3"
        }, [_c('div', {
          staticClass: "sn-table__card sn-table__grid-item-card sn-table--bordered sn-table--flat"
        }, _vm._l(props.cols, function (col) {
          return _c('div', {
            key: col.field,
            staticClass: "table__grid-item-row attendance-row"
          }, [_c('div', {
            staticClass: "sn-table__grid-item-title"
          }, [_vm._v("\n              " + _vm._s(col.label) + "\n            ")]), _vm._v(" "), col.field !== 'id' ? _c('div', {
            staticClass: "sn-table__grid-item-value"
          }, [_vm._v("\n              " + _vm._s(col.value) + "\n            ")]) : _vm._e(), _vm._v(" "), col.field === 'id' ? _c('div', {
            staticClass: "sn-table__grid-item-value attendance-route-link"
          }, [_c('a', {
            staticClass: "funding-route-link",
            attrs: {
              "href": "".concat(_vm.link, "/").concat(props.row.id)
            }
          }, [_c('q-btn', {
            attrs: {
              "squire": "",
              "icon": "eye",
              "title": _vm.trans('label.view')
            }
          })], 1)]) : _vm._e()]);
        }), 0)])];
      }
    }])
  }), _vm._v(" "), _vm.exportModal ? _c('attendance-export-dialog', {
    attrs: {
      "modal": _vm.exportModal,
      "export-url": _vm.exportUrl,
      "group-list": _vm.groups,
      "month": _vm.month,
      "year": _vm.year
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.exportModal = $event;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }