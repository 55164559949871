var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-field', {
    attrs: {
      "field": _vm.field,
      "field-name": _vm.fieldName,
      "errors": _vm.errors
    }
  }, [_c('template', {
    slot: "field"
  }, [_vm._l(_vm.localValue, function (value, key) {
    return _c('q-field', {
      key: key,
      staticClass: "v-multi-q-input-field",
      staticStyle: {
        "pointer-events": "unset"
      },
      attrs: {
        "readonly": "",
        "hide-bottom-space": ""
      },
      scopedSlots: _vm._u([{
        key: "append",
        fn: function fn() {
          return [_c('q-btn', {
            staticStyle: {
              "border-top-left-radius": "0",
              "border-bottom-left-radius": "0"
            },
            attrs: {
              "squire": "",
              "color": "red",
              "icon": "close"
            },
            on: {
              "click": function click($event) {
                return _vm.handleRemove(key);
              }
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_vm._v("\n      " + _vm._s(value) + "\n      ")]);
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "name": _vm.field.name,
      "placeholder": _vm.field.placeholder ? _vm.trans(_vm.field.placeholder) : undefined
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('q-btn', {
          staticStyle: {
            "border-top-left-radius": "0",
            "border-bottom-left-radius": "0"
          },
          attrs: {
            "squire": "",
            "color": "green",
            "icon": "check"
          },
          on: {
            "click": _vm.handleAddValue
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.localValueSingle,
      callback: function callback($$v) {
        _vm.localValueSingle = $$v;
      },
      expression: "localValueSingle"
    }
  })], 2)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }