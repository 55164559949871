var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-form', {
    staticClass: "s-statement-contract__conclude-form",
    attrs: {
      "autocorrect": "off",
      "autocapitalize": "off",
      "autocomplete": "off",
      "spellcheck": "false"
    },
    on: {
      "submit": _vm.requestAction
    }
  }, [_c('v-form-inform', {
    attrs: {
      "color": "red",
      "message": _vm.comment || _vm.trans('warning_text.statement_contract_conclude_form_inform', {
        date: _vm.additionalConfig.statementExpiredDate
      })
    }
  }), _vm._v(" "), !!_vm.triesLeftInform ? _c('v-form-inform', {
    attrs: {
      "color": "red",
      "message": _vm.triesLeftInform
    }
  }) : _vm._e(), _vm._v(" "), !_vm.editFiles ? [_vm._l(_vm.inputList, function (inputName, key) {
    return [inputName !== 'child_born_date' ? _c('q-input', {
      key: key,
      attrs: {
        "required": "",
        "placeholder": inputName === 'issuer_phone' ? '+7 (___) ____-__-__' : undefined,
        "mask": inputName === 'issuer_phone' ? '+7 (###) ###-##-##' : undefined,
        "disable": _vm.isInputDisabled(inputName),
        "label": _vm.trans("label.statement_contract.".concat(inputName)),
        "error": _vm.hasValidationFail(inputName),
        "error-message": _vm.getValidationMessage(inputName)
      },
      on: {
        "input": function input($event) {
          return _vm.flashValidationFail(inputName);
        }
      },
      model: {
        value: _vm.form[inputName],
        callback: function callback($$v) {
          _vm.$set(_vm.form, inputName, $$v);
        },
        expression: "form[inputName]"
      }
    }) : _c('div', {
      key: key,
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-lg-6 col-md-6 col-sm-12"
    }, [_c('q-input', {
      key: key,
      attrs: {
        "required": "",
        "disable": _vm.isInputDisabled(inputName),
        "label": _vm.trans("label.statement_contract.".concat(inputName)),
        "error": _vm.hasValidationFail(inputName),
        "error-message": _vm.getValidationMessage(inputName)
      },
      on: {
        "input": function input($event) {
          return _vm.flashValidationFail(inputName);
        }
      },
      scopedSlots: _vm._u([{
        key: "append",
        fn: function fn() {
          return [_c('q-icon', {
            attrs: {
              "name": "calendar"
            }
          })];
        },
        proxy: true
      }], null, true),
      model: {
        value: _vm.form[inputName],
        callback: function callback($$v) {
          _vm.$set(_vm.form, inputName, $$v);
        },
        expression: "form[inputName]"
      }
    })], 1), _vm._v(" "), _c('div', {
      staticClass: "col-lg-6 col-md-6 col-sm-12"
    }, [_c('q-field', {
      attrs: {
        "borderless": "",
        "required": "",
        "disable": "",
        "label": _vm.trans('label.statement_contract.child_sex')
      }
    }, [_c('q-option-group', {
      staticClass: "d-flex",
      attrs: {
        "inline": "",
        "disable": "",
        "options": _vm.genderOptions
      },
      model: {
        value: _vm.form.child_sex,
        callback: function callback($$v) {
          _vm.$set(_vm.form, "child_sex", $$v);
        },
        expression: "form.child_sex"
      }
    })], 1)], 1)])];
  })] : _vm._e(), _vm._v(" "), _vm._l(_vm.files, function (file, fileType) {
    return [_c('label', {
      key: "label-".concat(fileType),
      staticClass: "s-form__label mb-10"
    }, [_vm._v("\n      " + _vm._s(_vm.trans("label.statement_contract.files.".concat(fileType))) + ": "), _c('i', [_vm._v("*")])]), _vm._v(" "), _c('div', {
      key: fileType,
      staticClass: "row align-items-center mb-20"
    }, [_c('div', {
      staticClass: "col-sm-6 col-md-4 col-lg-3",
      class: _vm.$q.screen.width < 576 ? 'mb-10' : ''
    }, [_c('q-btn', {
      staticClass: "s-w-100",
      attrs: {
        "label": _vm._.isEmpty(file) ? _vm.trans('button.add') : _vm.trans('label.attached'),
        "color": _vm._.isEmpty(file) ? 'blue' : 'green',
        "icon-right": _vm._.isEmpty(file) ? 'plus' : 'clip',
        "label-wider": "",
        "disable": _vm.disableFiles[fileType],
        "ellipsis": "",
        "no-caps": ""
      },
      on: {
        "click": function click($event) {
          return _vm.triggerFile(fileType);
        }
      }
    })], 1), _vm._v(" "), _c('div', {
      staticClass: "col-sm-6 col-md-8 col-lg-9"
    }, [_c('q-btn', {
      staticClass: "s-w-100",
      attrs: {
        "outline": "",
        "label": _vm.getFileName(fileType),
        "label-wider": "",
        "disable": _vm.disableFiles[fileType],
        "color": _vm.hasValidationFail(fileType) ? 'red' : 'grey',
        "no-caps": "",
        "ellipsis": "",
        "icon-right": _vm._.isEmpty(file) ? undefined : 'close'
      },
      on: {
        "click": function click($event) {
          return _vm.clearFile(fileType);
        }
      }
    })], 1)])];
  }), _vm._v(" "), _vm.editFiles ? [_c('label', {
    staticClass: "s-form__label mt-40"
  }, [_vm._v(_vm._s(_vm.trans('label.statement_contract.contract')))]), _vm._v(" "), _c('div', {
    staticClass: "s-statement-contract__contract-wrapper"
  }, [_c('q-input', {
    staticClass: "s-to-center",
    staticStyle: {
      "flex": "auto"
    },
    attrs: {
      "disable": ""
    },
    scopedSlots: _vm._u([{
      key: "before",
      fn: function fn() {
        return [_c('img', {
          attrs: {
            "src": "".concat(_vm.apiConfig.staticUrl, "/indv/balabaqsha/images/contract.svg")
          }
        })];
      },
      proxy: true
    }], null, false, 3778516840),
    model: {
      value: _vm.contractName,
      callback: function callback($$v) {
        _vm.contractName = $$v;
      },
      expression: "contractName"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "s-statement-contract__contract-buttons"
  }, [_c('q-btn', {
    attrs: {
      "label-wider": "",
      "width": "14",
      "no-caps": "",
      "label": _vm.trans('button.download'),
      "color": "blue",
      "to": _vm.apiConfig.downloadApiUrl,
      "type": "a",
      "target": "_blank",
      "icon-right": "download"
    }
  })], 1)], 1)] : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "s-group s-group--wider justify-content-between mt-20"
  }, [_c('q-btn', {
    attrs: {
      "width": "20",
      "no-caps": "",
      "icon": "angle-left",
      "label": _vm.trans('statement.backward'),
      "color": "grey",
      "type": "a",
      "to": _vm.apiConfig.backButtonUrl
    }
  }), _vm._v(" "), _c('q-btn', {
    attrs: {
      "width": "20",
      "no-caps": "",
      "icon-right": "angle-right",
      "label": _vm.trans('statement.forward'),
      "color": "green",
      "disable": !_vm.validate,
      "type": "submit"
    }
  })], 1), _vm._v(" "), _c('select-user-file', {
    attrs: {
      "open-modal": _vm.modalFiles,
      "default-value": _vm.filesDefaultValue,
      "url-files": _vm.apiConfig.getFiles,
      "url-file-store": _vm.apiConfig.getFiles,
      "multiple": _vm.getMaxFiles(_vm.activeFile) > 1,
      "max-files": _vm.getMaxFiles(_vm.activeFile)
    },
    on: {
      "input": _vm.selectFile,
      "select-close": _vm.fileModalClose
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }