import {
  QBtn,
  QDialog,
  QForm,
  QSelect,
} from '@quasar/components';
import VModal from '@vjs/components/VModal';
import { url } from '@vjs/helpers';

export default {
  name: 'VRegionSelect',
  components: {
    QBtn,
    QDialog,
    QForm,
    QSelect,
    VModal,
  },
  props: {
    modal: Boolean,
    regions: {
      type: String,
      default: 'api/v1/regions',
    },
    departments: {
      type: String,
      default: 'api/v1/department',
    },
    localities: {
      type: String,
      default: 'api/v1/locality',
    },
    persistent: Boolean,
    setReload: Boolean,
    setAjax: Boolean,
  },
  data() {
    return {
      current: null,
      curRegion: null,
      curDep: null,
      curLoc: null,
      optionsRegions: [],
      optionsDepartments: [],
      optionsLocalities: [],
    };
  },
  beforeCreate() {
    this.$trans.add(['label', 'button']);
  },
  computed: {
    validateChooseRegion() {
      return this.curDep || this.curLoc;
    },
  },
  methods: {
    handleModal() {
      this.$emit('modal-close', false);
    },

    async request(address = '', params) {
      try {
        const options = {
          method: 'get',
          url: url(address),
        };

        if (params) {
          options.params = params;
        }

        const { data } = await this.$axios(options);
        return Array.isArray(data.data) ? data.data : [];
      } catch (err) {
        console.error((err && err.response && err.response.data) || err);
      }
    },

    async filterRegions(val, update, abort) {
      if (this.optionsRegions.length > 0) {
        update();
        return;
      }

      try {
        this.optionsRegions = await this.request(this.regions);
        update();
      } catch {
        abort();
      }
    },

    async checkOptions(keyCur = '', keyOptions, keyRequest, keyParams) {
      if (this[keyCur]
          && this[keyCur].value
          && keyOptions
          && keyRequest
          && keyParams
      ) {
        this[keyOptions] = await this.request(this[keyRequest], {
          [keyParams]: this[keyCur].value,
        });
      }

      this.current = {
        keyParams,
        request: this[keyRequest],
        ...this[keyCur],
      };

      if (keyCur === 'curRegion') {
        this.optionsLocalities = [];
        this.curDep = null;
        this.curLoc = null;
      }

      if (keyCur === 'curDep') {
        this.curLoc = null;
      }
    },

    async submit() {
      if (this.validateChooseRegion) {
        if (this.setAjax) {
          let localitiesData = {
            data: [],
            selected: {},
          };

          if (this.curLoc) {
            localitiesData.data = this.optionsLocalities;
            localitiesData.selected = this.curLoc;
          } else {
            localitiesData.data = await this.request(this.current.request, {
              [this.current.keyParams]: this.current.value,
            });
            localitiesData.selected = localitiesData.data[0];
          }

          this.$emit('update-locality', localitiesData);
        }

        this.handleModal();
        this.$emit('selected-region', this.current);

        if (this.setReload) {
          const { keyParams, value } = this.current;
          const { href } = location;
          location.href = `${href}?${keyParams}=${value}`;
        }
      }
    },
  },
};
