var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-statement-create__item-inner"
  }, [_c('v-reference-time', {
    staticClass: "mb-10",
    attrs: {
      "timestamp": _vm.referenceTime,
      "label": _vm.trans('label.server_datetime')
    }
  }), _vm._v(" "), _c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.info_about_orders')
    }
  }), _vm._v(" "), _c('v-form-inform', {
    attrs: {
      "message": _vm.trans('label.warning_message')
    }
  }), _vm._v(" "), _c('q-form', {
    staticClass: "mb-30",
    on: {
      "submit": _vm.requestForm
    }
  }, [_c('q-select', {
    attrs: {
      "options": _vm.form.groupTypeOptions,
      "label": _vm.trans('label.group_type'),
      "required": "",
      "disable": ""
    },
    model: {
      value: _vm.form.groupTypeCurrent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "groupTypeCurrent", $$v);
      },
      expression: "form.groupTypeCurrent"
    }
  }), _vm._v(" "), _c('q-field', {
    attrs: {
      "label": _vm.trans('label.edu_lang'),
      "borderless": "",
      "required": ""
    }
  }, [_c('q-option-group', {
    attrs: {
      "options": _vm.form.langOptions,
      "type": "checkbox"
    },
    model: {
      value: _vm.form.langCurrent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "langCurrent", $$v);
      },
      expression: "form.langCurrent"
    }
  })], 1), _vm._v(" "), _c('q-select', {
    attrs: {
      "options": _vm.form.regionOptions,
      "label": _vm.trans('label.choose_area'),
      "required": ""
    },
    on: {
      "input": function input($event) {
        return _vm.checkOptions('regionCurrent', 'departmentOptions', 'uriDepartments', 'region');
      }
    },
    model: {
      value: _vm.form.regionCurrent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "regionCurrent", $$v);
      },
      expression: "form.regionCurrent"
    }
  }), _vm._v(" "), _vm.form.departmentOptions.length > 0 ? _c('q-select', {
    attrs: {
      "options": _vm.form.departmentOptions,
      "label": _vm.trans('label.select_region'),
      "required": ""
    },
    on: {
      "input": function input($event) {
        return _vm.checkOptions('departmentCurrent', 'localityOptions', 'uriLocalities', 'department');
      }
    },
    model: {
      value: _vm.form.departmentCurrent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "departmentCurrent", $$v);
      },
      expression: "form.departmentCurrent"
    }
  }) : _vm._e(), _vm._v(" "), _vm.form.localityOptions.length > 0 ? _c('q-select', {
    attrs: {
      "options": _vm.form.localityOptions,
      "label": _vm.trans('label.choose_locality'),
      "required": ""
    },
    model: {
      value: _vm.form.localityCurrent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "localityCurrent", $$v);
      },
      expression: "form.localityCurrent"
    }
  }) : _vm._e(), _vm._v(" "), _vm.form.orgOptions.length > 0 ? _c('q-select', {
    attrs: {
      "options": _vm.form.orgOptions,
      "label": _vm.trans('label.choose_organization'),
      "required": ""
    },
    model: {
      value: _vm.form.orgCurrent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "orgCurrent", $$v);
      },
      expression: "form.orgCurrent"
    }
  }) : _vm._e(), _vm._v(" "), _c('q-select', {
    attrs: {
      "options": _vm.statusOptions,
      "label": _vm.trans('label.soc_status'),
      "disable": _vm.statusOptions.length <= 1,
      "required": ""
    },
    model: {
      value: _vm.form.statusCurrent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "statusCurrent", $$v);
      },
      expression: "form.statusCurrent"
    }
  }), _vm._v(" "), _c('q-field', {
    attrs: {
      "label": _vm.trans('label.statement_add_lgot'),
      "borderless": ""
    }
  }, [_c('q-option-group', {
    attrs: {
      "options": _vm.queueTypeOptions,
      "type": "radio",
      "inline": ""
    },
    on: {
      "input": _vm.clearLgots
    },
    model: {
      value: _vm.form.queueType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "queueType", $$v);
      },
      expression: "form.queueType"
    }
  })], 1), _vm._v(" "), _c('q-slide-transition', [_vm._v("Lgot\n      "), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.queueType > 0,
      expression: "form.queueType > 0"
    }]
  }, [_c('q-select', {
    attrs: {
      "label": _vm.trans('label.select_type'),
      "options": _vm.form.lgotTypeOptions,
      "required": ""
    },
    on: {
      "input": function input($event) {
        return _vm.getLgotTypeDocuments();
      }
    },
    model: {
      value: _vm.form.lgotType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "lgotType", $$v);
      },
      expression: "form.lgotType"
    }
  })], 1)]), _vm._v(" "), _c('q-slide-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: Object.keys(_vm.form.lgotTypeDocuments).length,
      expression: "Object.keys(form.lgotTypeDocuments).length"
    }]
  }, _vm._l(_vm.form.lgotTypeDocuments, function (item, key) {
    return _c('div', {
      key: key,
      staticClass: "mt-10 mb-20"
    }, [_c('div', {
      staticClass: "sn-field__label mb-15"
    }, [_vm._v("\n            " + _vm._s(_vm.form.lgotTypeDocumentRules[key].label) + ":\n            "), _vm.form.lgotTypeDocumentRules[key].required === true ? _c('i', [_vm._v("*")]) : _vm._e()]), _vm._v(" "), !item ? _c('q-btn', {
      staticClass: "s-w-100 form-upload__button",
      attrs: {
        "icon": "clip",
        "color": "blue",
        "type": "button",
        "label": _vm.trans('button.overview'),
        "label-wider": ""
      },
      on: {
        "click": function click($event) {
          return _vm.triggerFile(key);
        }
      }
    }) : _vm._e(), _vm._v(" "), item ? _c('q-btn', {
      staticClass: "s-w-100 s-btn--border sn-btn--ellipsis",
      attrs: {
        "icon": "close",
        "label": item.name,
        "label-wider": "",
        "type": "button"
      },
      on: {
        "click": function click($event) {
          return _vm.clearFile(key);
        }
      }
    }) : _vm._e()], 1);
  }), 0)]), _vm._v(" "), _c('input', {
    ref: "fileInput",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": "application/msword, application/pdf, image/*"
    },
    on: {
      "change": _vm.fileChange
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }