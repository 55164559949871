import axios from 'axios';
import { Loading } from '@quasar/plugins';
import _ from 'lodash';
import {
  QDialog,
  QToggle,
  QBtn,
  QSlideTransition,
  QDate,
  QPopupProxy,
  QIcon,
  QTable,
} from '@quasar/components';

import { ClosePopup } from '@quasar/directives';
import {
  QCard,
  QCardSection,
} from '@quasar/components/card';
import VModal from '@vjs/components/VModal';

export default {
  name: 'AttendanceExportDialog',
  components: {
    QToggle,
    QBtn,
    QDialog,
    QSlideTransition,
    VModal,
    QCard,
    QCardSection,
    QDate,
    QPopupProxy,
    QIcon,
    QTable,
  },
  directives: {
    ClosePopup,
  },
  props: {
    modal: Boolean,
    year: {
      type: Number,
      default: null,
    },
    month: {
      type: Object,
      default: () => ({}),
    },
    groupList: {
      type: Array,
      default: () => ([]),
    },
    exportUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    const { $trans: { get: trans } } = this;
    return {
      openModal: false,
      title: trans('attendance.download_the_report_card_for_all_groups_of_the_garden'),
      selected: [],
      timeSheet: {},
      date: '',
      group_id: 0,
      group_name: '',
      pagination: {
        page: 1,
        rowsPerPage: 0, // 0 means all rows
      },
      qLoading: Loading,
      loading: false,
    };
  },
  computed: {
    columns() {
      const { $trans: { get: trans } } = this;
      return [
        {
          name: 'group_name',
          field: 'group_name',
          label: trans('fields.group_name'),
          id: 'id',
          align: 'center',
        },
        {
          name: 'active_contingent_count',
          field: 'active_contingent_count',
          label: trans('organization.group_child_amount'),
          id: 'id',
          align: 'center',
        },
        {
          name: 'attendances',
          field: 'attendances',
          label: trans('attendance.timesheet-day-count'),
          id: 'id',
        },
        {
          name: 'absentDays',
          field: 'absentDays',
          label: trans('attendance.child-absent'),
          id: 'id',
          align: 'center',
        },
        {
          name: 'respectfulAbsentDays',
          field: 'respectfulAbsentDays',
          label: trans('attendance.child-absent-respect'),
          id: 'id',
          align: 'center',
        },
        {
          name: 'attendancesIsNull',
          field: 'attendancesIsNull',
          label: trans('attendance.not-marked-timesheet-day-count'),
          id: 'id',
        },

      ];
    },
    timeSheetData() {
      const { timeSheet: { attendance }, $trans: { get: trans } } = this;
      if (typeof attendance === 'undefined') {
        return [];
      }
      const daysTotalAbsent = {
        fixed: {
          index: ' ',
          full_name: trans('attendance.time-sheet.absent-total'),
        },
        scroll: {
          absentDays: 0,
          reasons: '',
          respectfulAbsentDays: 0,
          days: [],
        },
        is_total: true,
      };
      const daysTotalAttend = {
        fixed: {
          index: ' ',
          full_name: trans('attendance.time-sheet.attend-total'),
        },
        scroll: {
          absentDays: '',
          reasons: '',
          respectfulAbsentDays: '',
          days: [],
        },
        is_total: true,
      };

      attendance.forEach((item) => {
        Object.keys(item.days)
          .forEach((key) => {
            const day = item.days[key];
            if (typeof daysTotalAbsent.scroll.days[key] === 'undefined') {
              daysTotalAbsent.scroll.days[key] = {
                attendance: 0,
                is_work: day.is_work,
                day: day.day,
              };
              daysTotalAttend.scroll.days[key] = {
                attendance: 0,
                is_work: day.is_work,
                day: day.day,
              };
            }
            if (
              typeof day.attendance !== 'undefined'
              && typeof day.attendance.attendance !== 'undefined'
              && day.attendance.attendance !== null
              && (
                (typeof day.is_moved === 'undefined' || !day.is_moved)
                && (typeof day.is_dropped === 'undefined' || !day.is_dropped)
                && (typeof day.is_out === 'undefined' || !day.is_out)
              )
            ) {
              if (parseInt(day.attendance.attendance, 10) === 0) {
                daysTotalAbsent.scroll.days[key].attendance += 1;
              } else {
                daysTotalAttend.scroll.days[key].attendance += 1;
              }
            }
          });
        daysTotalAbsent.scroll.absentDays += item.absentDays;
        daysTotalAbsent.scroll.respectfulAbsentDays += item.respectfulAbsentDays;
      });

      daysTotalAbsent.scroll.days = Object.keys(daysTotalAbsent.scroll.days)
        .map(key => daysTotalAbsent.scroll.days[key]);

      daysTotalAttend.scroll.days = Object.keys(daysTotalAttend.scroll.days)
        .map(key => daysTotalAttend.scroll.days[key]);

      const rows = attendance.map((item, index) => ({
        fixed: {
          index: index + 1,
          full_name: item.full_name,
        },
        scroll: {
          days: Object.keys(item.days)
            .map(key => ({
              ...item.days[key],
              date: key,
            })),
          absentDays: item.absentDays,
          reasons: item.reasons,
          respectfulAbsentDays: item.respectfulAbsentDays,
        },
      }));
      return [...rows, daysTotalAbsent, daysTotalAttend];
    },
  },
  beforeCreate() {
    this.$trans.add(['label', 'notice', 'attendance', 'placeholder']);
  },
  methods: {
    async handleModal() {
      this.$emit('modal-close', false);
    },
    async exportAttendanceTimeSheet() {
      this.qLoading.show({ message: this.trans('label.wait_loading') });
      this.loading = true;
      if (this.selected.length > 0) {
        this.$set(this, 'date', `${this.year}-${this.month.value}`);
        let requestIteration = 0;
        const recursiveRequest = async () => {
          const group = this.selected[requestIteration];
          await axios.get(`${this.exportUrl}/${group.id}`,
            {
              responseType: 'JSON',
              params: { date: this.date },
            }).then(async (response) => {
            this.$set(this, 'timeSheet', response.data.data);
            this.$set(this, 'group_id', group.id);
            this.$set(this, 'group_name', group.name);
            await this.export(group.id, group.name);
          });
          if (requestIteration + 1 !== this.selected.length) {
            requestIteration += 1;
            await recursiveRequest();
          }
        };
        await recursiveRequest();
        this.qLoading.hide();
        this.loading = false;
      } else {
        this.$notify({
          type: 'error',
          text: this.trans('notice.select_group'),
        });
      }
    },
    async export(id, name) {
      const totalAbsent = this.timeSheetData[this.timeSheetData.length - 1];
      const totalAttend = this.timeSheetData[this.timeSheetData.length - 2];
      const params = {
        export: true,
        date: this.date,
        totalAbsent,
        totalAttend,
      };
      await axios({
        url: `${this.exportUrl}/${id}`,
        method: 'GET',
        responseType: 'blob',
        params,
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        const today = new Date();

        fileLink.setAttribute('download',
          `${name}-${today.getFullYear()}.${today.getMonth() + 1}.${today.getDate()
          }-${this.$trans.get('attendance.export_timesheet')
          } .xlsx`);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
  },
};
