import { requestWrapper } from '@vjs/helpers';
import { QBtn } from '@quasar/components';

export default {
  name: 'VOrganizationInfo',
  components: {
    QBtn,
  },
  props: {
    uriGetData: {
      type: String,
      required: true,
    },
    editLink: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      name: '',
      fields: [],
    };
  },
  mounted() {
    this.requestGetData();
  },
  methods: {
    async requestGetData() {
      const res = await requestWrapper.call(this, {
        url: this.uriGetData,
      });

      if (!res.error) {
        this.name = res.data.name;
        this.fields = res.data.fields;
      }
    },
  },
};
