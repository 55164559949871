var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-form__create v-regular-statement-create"
  }, [_c('q-form', {
    staticClass: "q-gutter-md",
    on: {
      "submit": _vm.onSubmit
    }
  }, [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.info_about_orders')
    }
  }), _vm._v(" "), _c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.modal_inform')
    }
  }), _vm._v(" "), _c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.5mb_docs')
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "mb-30"
  }, [_c('v-reference-time', {
    attrs: {
      "timestamp": _vm.referenceTime,
      "label": _vm.trans('label.server_datetime')
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "group-type-block mb-10"
  }, [_c('q-select', {
    attrs: {
      "options": _vm.groupTypes,
      "label": _vm.trans('label.group_type'),
      "required": "",
      "emit-value": "",
      "map-options": ""
    },
    on: {
      "input": _vm.changeGroupType
    },
    model: {
      value: _vm.statement.type,
      callback: function callback($$v) {
        _vm.$set(_vm.statement, "type", $$v);
      },
      expression: "statement.type"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "select-child-block mb-20"
  }, [_vm.children.length > 0 ? _c('q-field', {
    attrs: {
      "label": _vm.trans('label.select_child'),
      "required": "",
      "borderless": ""
    }
  }, [_c('q-option-group', {
    attrs: {
      "options": _vm.children,
      "type": "radio"
    },
    on: {
      "input": _vm.checkBenefits
    },
    model: {
      value: _vm.statement.child,
      callback: function callback($$v) {
        _vm.$set(_vm.statement, "child", $$v);
      },
      expression: "statement.child"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('div', [_c('label', {
    staticClass: "s-form__label mb-10"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('label.if_child_not_in_list')) + ":\n        ")]), _vm._v(" "), _c('a', {
    staticClass: "button s-btn--thm-green",
    attrs: {
      "href": _vm.uriChildCreate
    }
  }, [_c('div', {
    staticClass: "s-str-ico s-str-ico--wider s-str-ico--thm-plus"
  }, [_vm._v("\n            " + _vm._s(_vm.trans('button.create_child')) + "\n          ")])])])], 1), _vm._v(" "), _c('q-slide-transition', {
    attrs: {
      "duration": 750
    }
  }, [_vm.statement.type === 1 ? _c('div', [_c('div', {
    staticClass: "disease-block mb-15"
  }, [_c('q-field', {
    attrs: {
      "label": _vm.trans('statement.correction-type'),
      "required": "",
      "borderless": ""
    }
  }, [_c('q-option-group', {
    attrs: {
      "options": _vm.diseaseTypes,
      "label": _vm.trans('statement.correction-type'),
      "type": "radio",
      "inline": ""
    },
    on: {
      "input": _vm.loadOrganizations
    },
    model: {
      value: _vm.statement.correction,
      callback: function callback($$v) {
        _vm.$set(_vm.statement, "correction", $$v);
      },
      expression: "statement.correction"
    }
  })], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "mb-20"
  }, [_c('div', {
    staticClass: "sn-field__label mb-15"
  }, [_vm._v("\n            " + _vm._s(_vm.trans('statement.disease-document')) + ": "), _c('i', [_vm._v("*")])]), _vm._v(" "), !_vm.statement.statementDocumentDisease ? _c('q-btn', {
    staticClass: "s-w-100 form-upload__button",
    attrs: {
      "icon": "clip",
      "color": "blue",
      "type": "button",
      "label": _vm.trans('button.overview'),
      "label-wider": ""
    },
    on: {
      "click": function click($event) {
        return _vm.triggerFile('statementDocumentDisease');
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.statement.statementDocumentDisease ? _c('q-btn', {
    staticClass: "s-w-100 s-btn--border sn-btn--ellipsis",
    attrs: {
      "icon": "close",
      "label": _vm.statement.statementDocumentDisease.name,
      "label-wider": "",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.clearFile('statementDocumentDisease');
      }
    },
    model: {
      value: _vm.statement.statementDocumentDisease,
      callback: function callback($$v) {
        _vm.$set(_vm.statement, "statementDocumentDisease", $$v);
      },
      expression: "statement.statementDocumentDisease"
    }
  }) : _vm._e()], 1)]) : _vm._e()]), _vm._v(" "), _c('q-slide-transition', {
    attrs: {
      "duration": 750
    }
  }, [_vm.statement.type === 3 ? _c('div', [_c('div', {
    staticClass: "disease-block mb-15"
  }, [_c('q-field', {
    attrs: {
      "label": _vm.trans('statement.sanatorium-type'),
      "required": "",
      "borderless": ""
    }
  }, [_c('q-option-group', {
    attrs: {
      "options": _vm.sanatoriumTypes,
      "label": _vm.trans('statement.sanatorium-type'),
      "type": "radio",
      "inline": ""
    },
    on: {
      "input": _vm.loadOrganizations
    },
    model: {
      value: _vm.statement.sanatorium,
      callback: function callback($$v) {
        _vm.$set(_vm.statement, "sanatorium", $$v);
      },
      expression: "statement.sanatorium"
    }
  })], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "mb-20"
  }, [_c('div', {
    staticClass: "sn-field__label mb-15"
  }, [_vm._v("\n            " + _vm._s(_vm.trans('statement.sanatorium-document')) + ": "), _c('i', [_vm._v("*")])]), _vm._v(" "), !_vm.statement.sanatoriumDocumentDisease ? _c('q-btn', {
    staticClass: "s-w-100 form-upload__button",
    attrs: {
      "icon": "clip",
      "color": "blue",
      "type": "button",
      "label": _vm.trans('button.overview'),
      "label-wider": ""
    },
    on: {
      "click": function click($event) {
        return _vm.triggerFile('sanatoriumDocumentDisease');
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.statement.sanatoriumDocumentDisease ? _c('q-btn', {
    staticClass: "s-w-100 s-btn--border sn-btn--ellipsis",
    attrs: {
      "icon": "close",
      "label": _vm.statement.sanatoriumDocumentDisease.name,
      "label-wider": "",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.clearFile('sanatoriumDocumentDisease');
      }
    },
    model: {
      value: _vm.statement.sanatoriumDocumentDisease,
      callback: function callback($$v) {
        _vm.$set(_vm.statement, "sanatoriumDocumentDisease", $$v);
      },
      expression: "statement.sanatoriumDocumentDisease"
    }
  }) : _vm._e()], 1)]) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "langs-block"
  }, [_c('q-field', {
    attrs: {
      "label": _vm.trans('label.edu_lang'),
      "required": "",
      "borderless": ""
    }
  }, [_c('q-option-group', {
    attrs: {
      "options": _vm.languages,
      "label": _vm.trans('label.edu_lang'),
      "type": "checkbox",
      "inline": ""
    },
    model: {
      value: _vm.statement.languages,
      callback: function callback($$v) {
        _vm.$set(_vm.statement, "languages", $$v);
      },
      expression: "statement.languages"
    }
  })], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "region-block mb-10"
  }, [_c('q-select', {
    attrs: {
      "options": _vm.optionsRegions,
      "label": _vm.trans('label.choose_area'),
      "required": ""
    },
    on: {
      "filter": _vm.filterRegions,
      "input": function input($event) {
        return _vm.checkOptions('curRegion', 'optionsDepartments', 'departments', 'region');
      }
    },
    model: {
      value: _vm.curRegion,
      callback: function callback($$v) {
        _vm.curRegion = $$v;
      },
      expression: "curRegion"
    }
  }), _vm._v(" "), _c('q-slide-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.optionsDepartments.length > 0,
      expression: "optionsDepartments.length > 0"
    }]
  }, [_c('q-select', {
    attrs: {
      "options": _vm.optionsDepartments,
      "label": _vm.trans('label.select_region'),
      "required": ""
    },
    on: {
      "input": function input($event) {
        return _vm.checkOptions('curDep', 'optionsLocalities', 'localities', 'department');
      }
    },
    model: {
      value: _vm.curDep,
      callback: function callback($$v) {
        _vm.curDep = $$v;
      },
      expression: "curDep"
    }
  })], 1)]), _vm._v(" "), _c('q-slide-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.optionsLocalities.length > 0,
      expression: "optionsLocalities.length > 0"
    }]
  }, [_c('q-select', {
    attrs: {
      "options": _vm.optionsLocalities,
      "label": _vm.trans('label.choose_locality'),
      "required": "",
      "emit-value": "",
      "map-options": ""
    },
    on: {
      "input": function input($event) {
        return _vm.loadOrganizations();
      }
    },
    model: {
      value: _vm.statement.locality_id,
      callback: function callback($$v) {
        _vm.$set(_vm.statement, "locality_id", $$v);
      },
      expression: "statement.locality_id"
    }
  })], 1)]), _vm._v(" "), _c('q-slide-transition', [_vm.optionsOrganization.length > 0 || (_vm.getCorrection || _vm.getSanatorium) && _vm.statement.locality_id ? _c('div', [_c('q-select', {
    attrs: {
      "options": _vm.optionsOrganization,
      "label": _vm.trans('label.choose_organization'),
      "required": "",
      "emit-value": "",
      "map-options": ""
    },
    model: {
      value: _vm.statement.organization_id,
      callback: function callback($$v) {
        _vm.$set(_vm.statement, "organization_id", $$v);
      },
      expression: "statement.organization_id"
    }
  })], 1) : _vm._e()])], 1), _vm._v(" "), _c('div', {
    staticClass: "benefits-block mb-10"
  }, [_c('q-select', {
    attrs: {
      "options": _vm.benefits,
      "label": _vm.trans('main.social_status'),
      "required": "",
      "emit-value": "",
      "map-options": "",
      "readonly": ""
    },
    model: {
      value: _vm.statement.soc_status_id,
      callback: function callback($$v) {
        _vm.$set(_vm.statement, "soc_status_id", $$v);
      },
      expression: "statement.soc_status_id"
    }
  })], 1), _vm._v(" "), _c('q-field', {
    attrs: {
      "label": _vm.trans('label.statement_add_lgot'),
      "borderless": ""
    }
  }, [_c('q-option-group', {
    attrs: {
      "options": _vm.queueTypeOptions,
      "type": "radio"
    },
    on: {
      "input": _vm.clearLgots
    },
    model: {
      value: _vm.statement.queueType,
      callback: function callback($$v) {
        _vm.$set(_vm.statement, "queueType", $$v);
      },
      expression: "statement.queueType"
    }
  })], 1), _vm._v(" "), _c('q-slide-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.statement.queueType > 0,
      expression: "statement.queueType > 0"
    }]
  }, [_c('q-select', {
    attrs: {
      "label": _vm.trans('label.select_type'),
      "options": _vm.statement.lgotTypeOptions,
      "required": ""
    },
    on: {
      "input": function input($event) {
        return _vm.getLgotTypeDocuments();
      }
    },
    model: {
      value: _vm.statement.lgotType,
      callback: function callback($$v) {
        _vm.$set(_vm.statement, "lgotType", $$v);
      },
      expression: "statement.lgotType"
    }
  })], 1)]), _vm._v(" "), _c('q-slide-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: Object.keys(_vm.statement.lgotTypeDocuments).length,
      expression: "Object.keys(statement.lgotTypeDocuments).length"
    }]
  }, _vm._l(_vm.statement.lgotTypeDocuments, function (item, key) {
    return _c('div', {
      key: key,
      staticClass: "mt-10 mb-20"
    }, [_c('div', {
      staticClass: "sn-field__label mb-15"
    }, [_vm._v("\n              " + _vm._s(_vm.statement.lgotTypeDocumentRules[key].label) + ":\n              "), _vm.statement.lgotTypeDocumentRules[key].required === true ? _c('i', [_vm._v("*")]) : _vm._e()]), _vm._v(" "), !item ? _c('q-btn', {
      staticClass: "s-w-100 form-upload__button",
      attrs: {
        "icon": "clip",
        "color": "blue",
        "type": "button",
        "label": _vm.trans('button.overview'),
        "label-wider": ""
      },
      on: {
        "click": function click($event) {
          return _vm.triggerLgotFile(key);
        }
      }
    }) : _vm._e(), _vm._v(" "), item ? _c('q-btn', {
      staticClass: "s-w-100 s-btn--border sn-btn--ellipsis",
      attrs: {
        "icon": "close",
        "label": item.name,
        "label-wider": "",
        "type": "button"
      },
      on: {
        "click": function click($event) {
          return _vm.clearLgotFile(key);
        }
      }
    }) : _vm._e()], 1);
  }), 0)]), _vm._v(" "), _c('input', {
    ref: "fileInput",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": "application/msword, application/pdf, image/*"
    },
    on: {
      "change": _vm.fileChange
    }
  }), _vm._v(" "), _c('input', {
    ref: "lgotFileInput",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": "application/msword, application/pdf, image/*"
    },
    on: {
      "change": _vm.lgotFileChange
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "lisence-block mt-10 mb-30"
  }, [_c('label', {
    staticClass: "s-form__label mb-10 s-bold"
  }, [_vm._v("\n        " + _vm._s(_vm.trans('label.agreement')) + ":"), _c('i', [_vm._v("*")])]), _vm._v(" "), _c('q-checkbox', {
    attrs: {
      "label": _vm.trans('label.agree')
    },
    model: {
      value: _vm.license,
      callback: function callback($$v) {
        _vm.license = $$v;
      },
      expression: "license"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "submit-block mb-30 row-sort-end"
  }, [_c('q-btn', {
    staticClass: "s-btn s-btn--w-200",
    attrs: {
      "type": "submit",
      "disable": !_vm.validate,
      "color": "green",
      "label": _vm.trans('button.send')
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }