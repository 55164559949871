import Localization from '../Mixins/QuasarLocale';
import FundingProvider
  from './Mixins/FundingProvider';

import VFundingCalculator from './Components/VFundingCalculator.vue';

export default {
  name: 'VFundingCalculation',
  components: {
    VFundingCalculator,
  },
  mixins: [Localization, FundingProvider],
  props: {
    apiConfig: {
      type: Object,
      default: () => {
      },
    },
    dataConfig: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    // eslint-disable-next-line camelcase
    const { core_project } = window;
    const locale = core_project.locale || 'kz';
    const {
      dataConfig: {
        calculation,
        calculable,
        listRatios,
        groupName,
        backUrl,
      },
    } = this;

    return {
      locale,
      backUrl,
      calculable,
      calculation,
      listRatios,
      groupName,
    };
  },
  beforeMount() {
    const { apiConfig } = this;
    this.initLocale();
    this.initProvider(apiConfig);
  },
  methods: {
    redirect() {
      const { backUrl } = this;
      window.location.href = backUrl;
    }
  }
};
