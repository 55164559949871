import { FormField, StoreTranslator } from '@common/mixins';
import TusClient from 'tus-js-client';
import uuidv4 from 'uuid/v4';
import pathinfo from 'locutus/php/filesystem/pathinfo';
import base64_encode from 'locutus/php/url/base64_encode';
import _ from 'lodash';
import { Helper } from '@app_jumys/helpers';

export default {
  mixins: [StoreTranslator, FormField],
  props: {},
  data() {
    let files = [];
    if (this.value !== undefined && this.value) {
      files = this.value;
    }
    return {
      files,
      max: this.field.config.max ?? 4,
    };
  },
  mounted() {
    this.field.fill = this.fill;
    this.$trans.add('validation');
  },
  methods: {
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
    fill(data) {
      if (this.files.length) {
        const dataFiles = [];
        _.each(this.files, (file) => {
          dataFiles.push(file.uuid);
        });
        _.set(data, this.nameToDot(this.field.name), dataFiles);
      }
    },
    onClickInputFile() {
      const el = this.$refs.input_file;
      if (el.onclick) {
        el.onclick();
      } else if (el.click) {
        el.click();
      }
    },
    onChangeInputFile(e) {
      const vm = this;
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      const file = files[0];
      e.target.value = null;
      const loader = vm.$loading.show();
      const { accepts } = this.field.config;
      const { size } = this.field.config;
      const extension = pathinfo(file.name, 'PATHINFO_EXTENSION');
      if (_.isArray(accepts) && accepts.length) {
        const index = accepts.findIndex(accept => accept.toLowerCase() === extension.toLowerCase());
        if (index === -1) {
          loader.hide();
          Helper.showNotice(
            this.trans('validation.mimes',
              {
                attribute: this.trans(this.field.labelTransKey),
                values: accepts.join(', '),
              }),
          );

          return;
        }
      }
      if (typeof size !== 'undefined' && file.size > size) {
        loader.hide();
        console.log(file.size);
        Helper.showNotice(this.trans('validation.max.file_mb', { max: size / 1024 / 1024 }));
        return;
      }
      const metaData = {
        full_name: base64_encode(file.name),
        filename: `${uuidv4()}.${extension}`,
      };
      if (file.type) {
        metaData.filetype = file.type;
      }
      const upload = new TusClient.Upload(file, {
        endpoint: '/tus/',
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: metaData,
        onError(error) {
          console.error(error);
          loader.hide();
        },
        onProgress(bytesUploaded, bytesTotal) {
          // let percentage = (bytesUploaded / bytesTotal * 100).toFixed(2);
          // console.log(bytesUploaded, bytesTotal, percentage + "%")
        },
        onSuccess(xhr) {
          loader.hide();

          // console.log("Download %s from %s", upload.file.name, upload.url);
          const uuid = xhr.getResponseHeader('X-File-UUID');
          if (uuid) {
            if (!_.find(vm.files, { uuid })) {
              vm.files.push({
                uuid,
                name: upload.file.name,
              });
              // Уведомление о том что выбранный файл уже был загружен
              // не знаю почему, но по другому уведомление не вызывается, поэтому сделал отдельной функцией
            } else {
              vm.showFileExistedNotice();
            }
          }
        },
      });

      // Start the upload
      upload.start();
    },
    showFileExistedNotice() {
      Helper.showNotice(
        this.trans('notice.file_already_selected'),
      );
    },
    onRemoveFile(file) {
      const index = _.findIndex(this.files, file);
      if (index !== false && index !== undefined) {
        this.$delete(this.files, index);
      }
    },
  },
  watch: {
    files(val = []) {
      const result = [];
      _.forEach(val, (itm) => {
        result.push(itm.uuid);
      });
      this.$emit('input', result);
    },
  },
};
