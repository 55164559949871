import _ from 'lodash';
import {
  QBtn,
  QRadio,
  QField,
  QSelect,
  QCheckbox,
} from '@quasar/components';
import VRegionSelect
  from '@indv/balabaqsha/javascript/components/VRegionSelect';
import MXFilters from '@vjs/mixins/MXFilters';
import {
  requestWrapper,
} from '@vjs/helpers';
import { ERR_404 } from '../../../helpers/requestWrapper';
import VTextModal from '../../modals/VTextModal.vue';

export default {
  name: 'VCatalogFilters',
  mixins: [MXFilters],
  components: {
    QBtn,
    QRadio,
    QField,
    QSelect,
    QCheckbox,
    VRegionSelect,
    VTextModal,
  },
  props: {
    uriLocality: {
      type: String,
      required: true,
    },
    uriStatistic: {
      type: String,
      required: true,
    },
    uriFilters: {
      type: String,
      required: true,
    },
    uriGetOrganizations: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      filterItems: [],
      languageSelected: null,
      typeSelected: null,
      accessPlaceChecked: false,
      schoolTypes: null,
    };
  },
  async mounted() {
    this.loading = true;
    this.$emit('filter-loading', true);

    // Запрос населённых пунктов
    const localityRes = await requestWrapper({
      url: this.uriLocality,
    });

    if (localityRes.error) {
      this.loading = false;
      this.$emit('filter-loading', false);

      if (localityRes.errorType === ERR_404 && _.isBoolean(localityRes.data.region)) {
        this.modalRegionSelect = true;
      }

      return;
    }

    this.localityOptions = localityRes.data;
    this.filterItems = await this.requestFilters();
    this.loadFiltersFromLocalStorage({}, 'catalogFilter');
    // Проверка на соответствие нас. пунтку при смене региона
    const checkLocality = _.find(
      this.localityOptions,
      loc => typeof this.localitySelected !== 'undefined' && loc.value === this.localitySelected.value,
    );
    if (!checkLocality) {
      this.localitySelected = this.localityOptions[0];
    }

    await this.getItems();
    this.loading = false;
    this.$emit('filter-loading', false);
  },

  beforeCreate() {
    this.$trans.add(['bulletin', 'label', 'kindergarten']);
  },
  methods: {
    // Передаёт полученный отфильтрованный список через событие
    async getItems() {
      this.loading = true;
      this.$emit('filter-loading', true);
      const data = await this.requestItems();
      this.saveFiltersToLocalStorage({
        localitySelected: this.localitySelected,
        languageSelected: this.languageSelected,
        typeSelected: this.typeSelected,
        accessPlaceChecked: this.accessPlaceChecked,
        schoolTypes: this.schoolTypes,
      }, 'catalogFilter');
      this.loading = false;
      this.$emit('filter-loading', false);
      this.$emit('filtered-list', { data, accessPlaceChecked: this.accessPlaceChecked } || []);
    },
    // Отправляет запрос на получение отфильтрованных садов
    async requestItems() {
      const statisticRes = await requestWrapper.call(this, {
        url: this.uriStatistic,
        params: {
          locality: typeof this.localitySelected !== 'undefined' ? this.localitySelected.value : null,
        },
      });
      const itemsRes = await requestWrapper.call(this, {
        method: 'get',
        url: this.uriGetOrganizations,
        params: {
          locality: typeof this.localitySelected !== 'undefined' ? this.localitySelected.value : null,
          language: this.languageSelected,
          type: this.typeSelected,
          accessPlace: this.accessPlaceChecked,
          schoolTypes: this.schoolTypes,
        },
      });
      let statistics = [];
      let items = [];

      if (!statisticRes.error) {
        // TODO this.$notify();
        statistics = (_.isArray(statisticRes.data) && statisticRes.data) || [];
      }

      if (!itemsRes.error) {
        // TODO this.$notify();
        items = (_.isArray(itemsRes.data) && itemsRes.data) || [];
      }

      return {
        items,
        statistics,
      };
    },
    // Запускает фильтрацию
    async handleApplyFilters() {
      this.getItems();
    },
    // Получает список доступных фильтров
    async requestFilters() {
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.uriFilters,
      });
      const result = {};

      if (!res.error) {
        Object.keys(res.data).forEach((item) => {
          result[item] = (_.isArray(res.data[item]) && res.data[item]) || [];
        });
      }

      return result;
    },
    // Сбрасывает установленные фильтры на значение по умолчанию
    async resetFilters() {
      this.languageSelected = null;
      this.typeSelected = null;
      this.accessPlaceChecked = false;
      this.schoolTypes = null;

      if (localStorage && localStorage.getItem('catalogFilter')) {
        localStorage.removeItem('catalogFilter');
      }

      await this.getItems();
    },
    // Запускается после выбора региона в модальном окне VRegionSelect
    async handleUpdateLocality(val) {
      this.localityOptions = val.data;
      this.filterItems = await this.requestFilters();
      const resultLoadingFromStorage = this.loadFiltersFromLocalStorage({
        localitySelected: val.selected,
      }, 'catalogFilter');

      if (!resultLoadingFromStorage) {
        this.localitySelected = val.selected;
      }

      await this.getItems();
    },
  },
};
