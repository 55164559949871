var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('confirm', {
    attrs: {
      "active": _vm.show,
      "on-hide": _vm.cancel,
      "size": "medium"
    }
  }, [_c('div', {
    staticClass: "attendance-child-info"
  }, [_vm._v("\n    " + _vm._s("".concat(_vm.trans('attendance.time-sheet.full-name'), ": ").concat(_vm.fullName)) + "\n    "), _c('br'), _vm._v("\n    " + _vm._s(_vm.date) + "\n  ")]), _vm._v(" "), _vm.isAlt ? _c('q-field', {
    attrs: {
      "borderless": ""
    }
  }, [_c('q-option-group', {
    staticClass: "d-flex justify-content-around s-w-100",
    attrs: {
      "options": _vm.attendanceOptionsList,
      "type": "radio",
      "inline": ""
    },
    model: {
      value: _vm.attendance,
      callback: function callback($$v) {
        _vm.attendance = $$v;
      },
      expression: "attendance"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('q-slide-transition', [!_vm.isAlt || _vm.attendance === 0 ? _c('div', {
    staticClass: "s-light"
  }, [_c('q-select', {
    attrs: {
      "options": _vm.reasons,
      "label": _vm.trans('attendance.timesheet-absent-reason'),
      "disable": _vm.confirming || _vm.reloading || !_vm.isEdit,
      "option-value": "value",
      "option-label": "label"
    },
    model: {
      value: _vm.typeControl,
      callback: function callback($$v) {
        _vm.typeControl = $$v;
      },
      expression: "typeControl"
    }
  }), _vm._v(" "), _c('q-slide-transition', [_vm.type === 1 ? _c('div', {
    staticClass: "mb-10"
  }, [_c('q-input', {
    attrs: {
      "error": _vm.hasValidateFail('other'),
      "error-message": _vm.fails.other,
      "disable": _vm.confirming || _vm.reloading || !_vm.isEdit,
      "required": "",
      "label": _vm.trans('attendance.timesheet-absent-other')
    },
    model: {
      value: _vm.otherControl,
      callback: function callback($$v) {
        _vm.otherControl = $$v;
      },
      expression: "otherControl"
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c('q-slide-transition', [![0, 7].includes(_vm.type) ? _c('div', {
    staticClass: "mb-10"
  }, [_c('div', {
    staticClass: "sn-field__label"
  }, [_vm._v("\n            " + _vm._s(_vm.trans('attendance.timesheet-absent-file')) + ":\n            "), _vm.type !== 5 && _vm.type !== 6 ? _c('i', [_vm._v("*")]) : _vm._e()]), _vm._v(" "), !_vm.file && !_vm.hasFile ? _c('q-btn', {
    staticClass: "s-w-100 form-upload__button",
    attrs: {
      "icon": "clip",
      "color": "blue",
      "type": "button",
      "label": _vm.trans('button.overview'),
      "label-wider": "",
      "disable": !_vm.isEdit,
      "loading": _vm.confirming || _vm.reloading
    },
    on: {
      "click": function click($event) {
        return _vm.triggerFile('file');
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.file || _vm.hasFile ? _c('q-btn', {
    staticClass: "s-w-100 s-btn--border sn-btn--ellipsis",
    attrs: {
      "icon": "close",
      "label": _vm.hasFile ? _vm.trans('attendance.file-was-loaded') : _vm.file.name,
      "label-wider": "",
      "no-caps": "",
      "type": "button",
      "disable": !_vm.isEdit,
      "loading": _vm.confirming || _vm.reloading
    },
    on: {
      "click": function click($event) {
        return _vm.clearFile('file');
      }
    },
    model: {
      value: _vm.file,
      callback: function callback($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  }) : _vm._e(), _vm._v(" "), _vm.hasValidateFail('file') ? _c('div', {
    staticClass: "s-form__error-message mt-5"
  }, [_vm._v("\n            " + _vm._s(_vm.fails.file) + "\n          ")]) : _vm._e()], 1) : _vm._e()]), _vm._v(" "), _c('input', {
    ref: "fileInput",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": "image/*,.pdf"
    },
    on: {
      "change": _vm.fileChange
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "s-group s-group--wider justify-content-end"
  }, [_vm.reason.uriDownload ? _c('q-btn', {
    attrs: {
      "color": "orange",
      "type": "a",
      "target": "_blank",
      "to": _vm.reason.uriDownload
    }
  }, [_vm._v("\n      " + _vm._s(_vm.trans('button.download_file')) + "\n    ")]) : _vm._e(), _vm._v(" "), _c('q-btn', {
    attrs: {
      "disable": !_vm.isValid || !_vm.isEdit,
      "color": "green",
      "loading": _vm.confirming || _vm.reloading
    },
    on: {
      "click": _vm.confirm
    }
  }, [_vm._v("\n      " + _vm._s(_vm.trans('button.approve')) + "\n    ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }