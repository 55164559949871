var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', {
    ref: "rowElement",
    class: _vm.rowClass,
    style: _vm.rowHeightStyle
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }