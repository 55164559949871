var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-statement-create__item-inner"
  }, [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('label.warning_message')
    }
  }), _vm._v(" "), _c('q-form', {
    on: {
      "submit": _vm.requestForm
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('q-input', {
    attrs: {
      "label": _vm.trans('label.login_phone'),
      "mask": "+7 (###) ###-##-##",
      "placeholder": "+7 (___) ___-__-__",
      "error": _vm.hasValidationFail('phone'),
      "error-message": _vm.getValidationMessage('phone'),
      "rules": [function (val) {
        return !!val || _vm.trans('validation.required', {
          attribute: _vm.trans('label.login_phone')
        });
      }],
      "required": "",
      "readonly": _vm.step > 1
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('phone');
      }
    },
    model: {
      value: _vm.form.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  })], 1)]), _vm._v(" "), _vm.step === 2 || _vm.step === 5 ? [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('v-countdown-for-sms', {
    staticClass: "mb-10",
    attrs: {
      "request-sms-again": _vm.requestForCheckPhone,
      "uri-sms-again": _vm.uriSendPhone
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('q-input', {
    attrs: {
      "label": _vm.trans('auth.sms_code'),
      "mask": "####",
      "error": _vm.hasValidationFail('code'),
      "error-message": _vm.getValidationMessage('code')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('code');
      }
    },
    model: {
      value: _vm.form.code,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  })], 1)])] : _vm._e(), _vm._v(" "), _vm.step === 3 || _vm.step === 6 ? [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('q-input', {
    attrs: {
      "label": _vm.trans('label.password'),
      "placeholder": _vm.trans('auth.pass_placeholder'),
      "rules": [function (val) {
        return !!val || _vm.trans('validation.required', {
          attribute: _vm.trans('label.password')
        });
      }, function (val) {
        return val.length >= 8 || _vm.trans('auth.strong_password');
      }],
      "type": "password",
      "lazy-rules": "",
      "required": ""
    },
    model: {
      value: _vm.form.password,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('q-input', {
    attrs: {
      "label": _vm.trans('label.confirm_the_password'),
      "placeholder": _vm.trans('auth.pass_placeholder'),
      "rules": [function (val) {
        return val === _vm.form.password || _vm.trans('validation.confirmed', {
          attribute: _vm.trans('label.confirm_the_password')
        });
      }],
      "type": "password",
      "lazy-rules": "",
      "required": ""
    },
    model: {
      value: _vm.form.passwordConfirm,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "passwordConfirm", $$v);
      },
      expression: "form.passwordConfirm"
    }
  })], 1)])] : _vm._e(), _vm._v(" "), _vm.step < 3 || _vm.step === 5 ? _c('div', {
    staticClass: "row justify-content-center align-items-center mb-30"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-btn', {
    attrs: {
      "color": "blue",
      "label": _vm.trans('button.continue'),
      "type": "submit",
      "disable": _vm.buttonDisable
    }
  })], 1)]) : _vm._e(), _vm._v(" "), _vm.step === 4 ? [_c('q-input', {
    staticClass: "mb-20",
    attrs: {
      "label": _vm.trans('label.password'),
      "type": "password"
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('phone');
      }
    },
    model: {
      value: _vm.form.password,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-end mb-30"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('div', {
    staticClass: "s-auth-form__lost-pass",
    on: {
      "click": _vm.requestRestorePhone
    }
  }, [_vm._v("\n            " + _vm._s(_vm.trans('auth.forgot_password')) + "\n          ")])])])] : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }