var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('div', {
    staticClass: "row load-panel"
  }, [_c('q-spinner', {
    attrs: {
      "indeterminate": "",
      "color": "blue",
      "size": "5em"
    }
  })], 1) : _vm._e(), _vm._v(" "), !_vm.loading ? _c('v-time-sheet', {
    ref: "timeSheet",
    attrs: {
      "columns": _vm.columns,
      "check-is-current-day": _vm.checkIsCurrentDay,
      "rows": _vm.timeSheetData
    },
    scopedSlots: _vm._u([{
      key: "fixedRow",
      fn: function fn(props) {
        return [_c('v-ts-cell', {
          attrs: {
            "class-name": {
              's-time-sheet-cell-total': props.props.row.is_total === true
            }
          }
        }, [_vm._v("\n        " + _vm._s(props.props.row.fixed.index) + "\n      ")]), _vm._v(" "), _c('v-ts-cell', {
          style: _vm.isChildrenTimesheet ? 'white-space:nowrap' : '',
          attrs: {
            "class-name": {
              's-time-sheet-cell-total': props.props.row.is_total === true
            }
          }
        }, [_vm._v("\n        " + _vm._s(props.props.row.fixed.full_name) + "\n        "), props.props.row.fixed.bornDate ? _c('span', {
          staticClass: "s-c-light-grey-blue"
        }, [_vm._v("\n          (" + _vm._s(props.props.row.fixed.bornDate) + ")\n        ")]) : _vm._e()])];
      }
    }, {
      key: "scrollRow",
      fn: function fn(props) {
        return [_vm._l(props.props.row.scroll.days, function (dayRow, i) {
          return _c('v-ts-cell', {
            key: "day-".concat(dayRow.day, "-").concat(props.props.index),
            attrs: {
              "id": "day-".concat(dayRow.day, "-").concat(props.props.index),
              "class-name": {
                's-time-sheet-cell-day': true,
                // 's-time-sheet-cell-out': dayRow.is_out === true,
                's-time-sheet-cell-moved': dayRow.is_moved === true,
                's-time-sheet-cell-dropped': dayRow.is_dropped === true,
                's-time-sheet-cell-holiday': dayRow.is_work === false,
                's-time-sheet-cell-total': props.props.row.is_total === true,
                's-time-sheet-cell-absent': _vm.checkIsEditable(dayRow),
                'absent-symbol-load-file': dayRow.is_work === true && typeof dayRow.attendance.attendance !== 'undefined' && parseInt(dayRow.attendance.attendance, 10) === 0 && dayRow.attendance['has-file'] && dayRow.attendance.absent_reason_type !== 0,
                's-time-sheet-cell-attend': dayRow.is_work === true && typeof dayRow.attendance.attendance !== 'undefined' && parseInt(dayRow.attendance.attendance, 10) === 1,
                'without-hover-icon': _vm.isWorker || _vm.isChildrenTimesheet
              }
            },
            on: {
              "click": function click() {
                return _vm.changeReason(props.props.row, dayRow);
              }
            },
            nativeOn: {
              "mouseover": function mouseover($event) {
                return _vm.handleTooltip("#day-".concat(dayRow.day, "-").concat(props.props.index), dayRow.date);
              }
            }
          }, [_c('span', {
            staticClass: "s-time-sheet-cell-absent--value"
          }, [_vm._v("\n          " + _vm._s(_vm.getCellLabel(dayRow, props.props.row.is_total)) + "\n        ")])]);
        }), _vm._v(" "), _c('v-ts-cell', {
          attrs: {
            "class-name": {
              's-time-sheet-cell-total': props.props.row.is_total === true
            }
          }
        }, [_vm._v("\n        " + _vm._s(props.props.row.scroll.absentDays) + "\n      ")]), _vm._v(" "), _c('v-ts-cell', {
          attrs: {
            "class-name": {
              's-time-sheet-cell-total': props.props.row.is_total === true
            }
          }
        }, [_vm._v("\n        " + _vm._s(props.props.row.scroll.respectfulAbsentDays) + "\n      ")]), _vm._v(" "), _c('v-ts-cell', [_vm._v("\n        " + _vm._s(props.props.row.scroll.reasons) + "\n      ")])];
      }
    }], null, false, 3319904834)
  }, [_c('template', {
    slot: "headFixedRow"
  }, [_c('tr', {
    staticClass: "s-time-sheet--head-row"
  }, [_c('th', {
    staticClass: "s-time-sheet--head-cell"
  }, [_vm._v("\n          №\n        ")]), _vm._v(" "), _c('th', {
    staticClass: "s-time-sheet--head-cell"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('attendance.time-sheet.full-name')) + "\n        ")])])]), _vm._v(" "), _c('template', {
    slot: "headScrollRow"
  }, [_c('tr', {
    staticClass: "s-time-sheet--head-row"
  }, [_vm._l(_vm.dateStrings, function (item, key) {
    return _c('th', {
      key: key,
      staticClass: "s-time-sheet--head-cell s-time-sheet--head-cell-month",
      style: _vm.getMonthStyle(item.daysCount),
      attrs: {
        "colspan": item.daysCount
      }
    }, [_vm._v("\n          " + _vm._s(item.label) + "\n        ")]);
  }), _vm._v(" "), _c('th', {
    staticClass: "s-time-sheet--head-cell",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.trans('attendance.time-sheet.absent-header')) + "\n        ")])], 2)])], 2) : _vm._e(), _vm._v(" "), _vm.activeTooltipText && _vm.$q.screen.width > 768 ? _c('q-tooltip', {
    ref: "timesheetTooltip",
    attrs: {
      "max-width": "24rem",
      "target": _vm.activeTooltipId
    }
  }, [_vm._v("\n    " + _vm._s(_vm.activeTooltipText) + "\n  ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }