var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.field.options ? _c('default-field', {
    attrs: {
      "field": _vm.field,
      "field-name": _vm.fieldName,
      "errors": _vm.errors,
      "show-label": false,
      "label-class": ''
    }
  }, [_c('template', {
    slot: "field"
  }, [_c('q-field', {
    attrs: {
      "borderless": "",
      "label": _vm.fieldLabel,
      "required": _vm.field.required,
      "disable": _vm.isDisabled
    }
  }, [_c('q-option-group', {
    class: {
      'd-flex flex-wrap': _vm.field.inline
    },
    attrs: {
      "options": _vm.field.options,
      "inline": _vm.field.inline,
      "type": "checkbox",
      "disable": _vm.isDisabled
    },
    on: {
      "click": _vm.handleClick
    },
    model: {
      value: _vm.localValue,
      callback: function callback($$v) {
        _vm.localValue = $$v;
      },
      expression: "localValue"
    }
  })], 1)], 1)], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }