import {
  QInput,
  QBtn,
  QPagination,
} from '@quasar/components';
import { genArray } from '@vjs/helpers';
import VNoResults from '@vjs/components/VNoResults';
import VStatisticPanel from '@vjs/components/VStatisticPanel';
import MXListView from '@vjs/mixins/MXListView';
import VQueueItem from '../../components/VQueueItem';
import VQueueFilters from '../../components/VQueueFilters';
import { scrollTop } from '../../helpers';

export default {
  name: 'VQueueListView',
  mixins: [MXListView],
  components: {
    QInput,
    QBtn,
    QPagination,
    VStatisticPanel,
    VQueueItem,
    VQueueFilters,
    VNoResults,
  },
  props: {
    typeOptions: {
      type: Array,
      required: true,
    },
    diseases: {
      type: Array,
      default: () => [],
    },
    sanatorium: {
      type: Array,
      default: () => [],
    },
    uriGetYears: {
      type: String,
      required: true,
    },
    uriGetOrganizations: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      stoplist: false,
      searchValue: '',
      debounceTimeout: null,
    };
  },
  beforeCreate() {
    this.$trans.add(['queue', 'button', 'warning_text', 'bulletin']);
  },
  watch: {
    // Сбрасывает отфильтрованный список при удалении строки в поиске
    searchValue(val) {
      if (val === '') {
        this.itemsFiltered = this.items;
      }
    },
  },
  computed: {
    // Отдаёт кол-во очереди на страницу
    paginate() {
      if (this.loading) {
        return genArray({
          childFullName: '',
          dateSendStatement: 0,
          expiredDate: 0,
          privilegeClient: {
            key: 1,
            value: '',
          },
          queueFlagStyle: '',
          queueNumber: 0,
          userFullName: '',
        }, 4);
      }

      const current = (this.currentPage - 1) * this.countByPage;
      return this.itemsFiltered.slice(current, current + this.countByPage);
    },
    // Считает кол-во страниц в пагинации по выставленным в data параметрам
    countOfPages() {
      let count = parseInt(this.itemsFiltered.length / this.countByPage, 10);
      const remains = this.itemsFiltered.length % this.countByPage;
      count += remains !== 0 ? 1 : 0;
      this.currentPage = 1;
      return count <= 1 ? 0 : count;
    },
  },
  methods: {
    // Для обработки реактивного поиска
    debounceSearch() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(
        this.handleSearch,
        300,
      );
    },
    // Обычный поиск по запросу
    handleSearch() {
      if (this.searchValue !== '') {
        const searchStr = this.searchValue.toLowerCase();
        this.itemsFiltered = this.items.filter((a) => {
          const childName = a.childFullName || '';
          const userName = a.userFullName || '';
          return childName.toLowerCase().indexOf(searchStr) !== -1
            || userName.toLowerCase().indexOf(searchStr) !== -1;
        });
      } else {
        this.itemsFiltered = this.items;
      }
    },
    // Принимает и обрабатывает список от фильтра
    handleFilteredList(arr) {
      this.itemsFiltered = this.items = arr.items;
      this.statisticItems = arr.statistics;
      this.stoplist = arr.stoplist;
      this.searchValue = '';
    },
    // Запускается на событие @input в q-pagination
    async changePagination() {
      // Перевод страницы к началу при выборе страницы
      scrollTop(
        window,
        window.scrollY,
        window.scrollY - Math.abs(this.$refs.catalog.getBoundingClientRect().top),
        500,
      );
    },
  },
};
