var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-30"
  }, [_c('div', {
    staticClass: "candidate-list"
  }, _vm._l(_vm.field.value, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "candidate-item"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.status,
        expression: "item.status"
      }],
      staticClass: "__success",
      attrs: {
        "id": _vm.field.name + item.id + '-1',
        "type": "radio",
        "value": "1",
        "checked": "",
        "name": _vm.field.name + '[' + index + '][status]'
      },
      domProps: {
        "checked": _vm._q(item.status, "1")
      },
      on: {
        "change": function change($event) {
          return _vm.$set(item, "status", "1");
        }
      }
    }), _vm._v(" "), _c('label', {
      attrs: {
        "for": _vm.field.name + item.id + '-1'
      }
    }), _vm._v(" "), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.status,
        expression: "item.status"
      }],
      staticClass: "__reject",
      attrs: {
        "id": _vm.field.name + item.id + '-0',
        "type": "radio",
        "value": "0",
        "name": _vm.field.name + '[' + index + '][status]'
      },
      domProps: {
        "checked": _vm._q(item.status, "0")
      },
      on: {
        "change": function change($event) {
          return _vm.$set(item, "status", "0");
        }
      }
    }), _vm._v(" "), _c('label', {
      attrs: {
        "for": _vm.field.name + item.id + '-0'
      }
    }), _vm._v(" "), _c('div', {
      staticClass: "candidate-item__name"
    }, [_vm._v("\n        " + _vm._s(item.name) + " (\n        "), _c('a', {
      attrs: {
        "href": item.url,
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(_vm.trans('label.look')))]), _vm._v("\n        )\n      ")])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }