var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('q-table', {
    staticClass: "s-b-white",
    class: _vm.onFire ? 'sn-table__on-fire' : '',
    attrs: {
      "data": _vm.rows,
      "columns": _vm.columns,
      "row-key": "name",
      "flat": !_vm.$q.screen.lt.md,
      "grid": _vm.$q.screen.lt.md,
      "pagination": _vm.pagination,
      "filter": _vm.filter,
      "no-data-label": _vm.trans('label.no_data'),
      "no-results-label": _vm.trans('label.no_results'),
      "visible-columns": _vm.visibleColumns,
      "loading": _vm.loading,
      "separator": _vm.separator,
      "wrap-cells": _vm.wrapCells
    },
    on: {
      "update:pagination": function updatePagination($event) {
        _vm.pagination = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "sn-table__top-filters"
        }, [_vm.search ? _c('q-input', {
          attrs: {
            "dense": "",
            "debounce": "300",
            "placeholder": _vm.trans('placeholder.search')
          },
          model: {
            value: _vm.filter,
            callback: function callback($$v) {
              _vm.filter = $$v;
            },
            expression: "filter"
          }
        }, [_c('q-icon', {
          attrs: {
            "slot": "append",
            "name": "search"
          },
          slot: "append"
        })], 1) : _vm._e(), _vm._v(" "), _vm.columnFilter ? _c('q-select', {
          attrs: {
            "multiple": "",
            "display-value": _vm.trans('placeholder.choose_column'),
            "emit-value": "",
            "map-options": "",
            "options": _vm.columns,
            "option-value": "name"
          },
          model: {
            value: _vm.visibleColumns,
            callback: function callback($$v) {
              _vm.visibleColumns = $$v;
            },
            expression: "visibleColumns"
          }
        }) : _vm._e()], 1), _vm._v(" "), !_vm.$q.screen.lt.sm && _vm.fullscreen ? _c('q-btn', {
          staticClass: "q-ml-md",
          attrs: {
            "squire": "",
            "unelevated": "",
            "icon": "fullscreen",
            "color": props.inFullscreen ? 'blue' : 'grey'
          },
          on: {
            "click": props.toggleFullscreen
          }
        }, [_c('q-tooltip', [_vm._v(_vm._s(props.inFullscreen ? _vm.trans('label.fullscreen_off') : _vm.trans('label.fullscreen_on')))])], 1) : _vm._e()];
      }
    }])
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }