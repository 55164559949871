var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-field', {
    attrs: {
      "field": _vm.field,
      "field-name": _vm.fieldName,
      "label-class": '',
      "errors": _vm.errors
    }
  }, [_c('template', {
    slot: "field"
  }, [_c('q-select', _vm._b({
    staticClass: "q-select-field",
    class: {
      'wrap-chips': !!_vm.extraAttributes.multiple
    },
    attrs: {
      "options": _vm.filteredOptions,
      "popup-content-style": "width: ".concat(_vm.$q.platform.is.desktop ? '1px' : undefined),
      "input-debounce": "0",
      "use-chips": !!_vm.extraAttributes.multiple
    },
    on: {
      "input": _vm.emitChange,
      "filter": _vm.filterFn
    },
    model: {
      value: _vm.selectValue,
      callback: function callback($$v) {
        _vm.selectValue = $$v;
      },
      expression: "selectValue"
    }
  }, 'q-select', _vm.extraAttributes, false))], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }