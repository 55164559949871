var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ol-lbc__option-wrapper",
    style: {
      marginRight: _vm.index === 0 ? '5rem' : ''
    }
  }, [_vm.labelLeft || _vm.labelRight ? _c('div', {
    staticClass: "ol-lbc__option-head"
  }, [_vm.labelLeft ? _c('div', {
    staticClass: "ol-lbc__option-head-title"
  }, [_vm._v("\n      " + _vm._s(_vm.labelLeft) + "\n    ")]) : _vm._e(), _vm._v(" "), _vm.labelRight ? _c('div', {
    staticClass: "ol-lbc__option-head-title",
    style: {
      marginRight: _vm.index === 0 ? '' : '5rem'
    }
  }, [_vm._v("\n      " + _vm._s(_vm.labelRight) + "\n    ")]) : _vm._e()]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "ol-lbc__option-body"
  }, [_c('div', {
    staticClass: "ol-lbc__option-text"
  }, [_c('input', {
    staticClass: "ol-lbc__option-input",
    attrs: {
      "type": "text",
      "placeholder": _vm.placeholder,
      "readonly": _vm.readonly
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": _vm.input
    }
  })]), _vm._v(" "), _vm._t("default"), _vm._v(" "), _vm.index !== 0 ? _c('div', {
    staticClass: "ol-lbc__option-remove",
    on: {
      "click": _vm.handleRemove
    }
  }) : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }