var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-select', _vm._b({
    ref: "q-select-wrapper-ref",
    staticClass: "wrapper-q-select",
    class: {
      'wrap-chips': _vm.multiple
    },
    attrs: {
      "option-value": _vm.optionValue,
      "option-label": _vm.optionLabel,
      "popup-content-style": "width: ".concat(_vm.$q.platform.is.desktop ? '1px' : undefined),
      "options": _vm.localOptions,
      "label": _vm.extraAttributes.labelTransKey ? _vm.trans(_vm.extraAttributes.labelTransKey) : _vm.extraAttributes.label,
      "hide-selected": _vm.oldSearch && _vm.useInput && !(_vm.multiple && _vm.showMultipleValues),
      "fill-input": _vm.oldSearch && _vm.useInput,
      "use-input": _vm.useInput,
      "old-search": _vm.oldSearch,
      "input-debounce": _vm.inputDebounce,
      "error": _vm.error,
      "multiple": _vm.multiple,
      "use-chips": _vm.multiple && _vm.showMultipleValues,
      "error-message": _vm.errorMessage
    },
    on: {
      "input": _vm.emitChange,
      "filter": _vm.filterFn,
      "virtual-scroll": function virtualScroll(event) {
        return _vm.$emit('virtual-scroll', event);
      }
    },
    model: {
      value: _vm.selectValue,
      callback: function callback($$v) {
        _vm.selectValue = $$v;
      },
      expression: "selectValue"
    }
  }, 'q-select', _vm.extraAttributes, false));
}
var staticRenderFns = []

export { render, staticRenderFns }