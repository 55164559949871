var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('q-dialog', {
    attrs: {
      "persistent": "",
      "value": _vm.modal
    },
    on: {
      "hide": _vm.handleModal
    }
  }, [_c('v-modal', {
    attrs: {
      "size": "auto",
      "title": _vm.title
    }
  }, [_c('div', {
    staticClass: "s-group s-group--wider"
  }, [_c('div', {
    staticClass: "q-pa-md"
  }, [_c('q-table', {
    attrs: {
      "data": _vm.groupList,
      "columns": _vm.columns,
      "selection": "multiple",
      "row-key": "id",
      "selected": _vm.selected,
      "rows-per-page-options": [0],
      "pagination": _vm.pagination,
      "loading": _vm.loading,
      "checkbox-label": _vm.trans('placeholder.select_all'),
      "left-label": true,
      "hide-bottom": ""
    },
    on: {
      "update:selected": function updateSelected($event) {
        _vm.selected = $event;
      },
      "update:pagination": function updatePagination($event) {
        _vm.pagination = $event;
      }
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "mt-10 row-sort-between-btn"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-150",
    attrs: {
      "color": "grey",
      "label": _vm.trans('button.cancel')
    },
    on: {
      "click": _vm.handleModal
    }
  }), _vm._v(" "), _c('q-btn', {
    staticClass: "s-btn--w-150",
    staticStyle: {
      "margin-left": "auto"
    },
    attrs: {
      "color": "green",
      "label": _vm.trans('button.export')
    },
    on: {
      "click": _vm.exportAttendanceTimeSheet
    }
  })], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }