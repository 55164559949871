var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-vue-modal__body"
  }, [_c('div', {
    staticClass: "mb-30"
  }, [_vm._v("\n    " + _vm._s(_vm.trans('notice.confirm')) + "\n  ")]), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-between"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('button', {
    staticClass: "s-btn s-btn--w-200 s-btn--thm-grey",
    on: {
      "click": _vm.close
    }
  }, [_vm._v("\n        " + _vm._s(_vm.trans('button.cancel')) + "\n      ")])]), _vm._v(" "), _c('div', {
    staticClass: "col-auto"
  }, [_c('button', {
    staticClass: "s-btn s-btn--w-200 s-btn--thm-green",
    on: {
      "click": _vm.removeFile
    }
  }, [_vm._v("\n        " + _vm._s(_vm.trans('button.approve')) + "\n      ")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }