var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.loading ? 'div' : 'a', {
    tag: "component",
    staticClass: "s-catalog-item mb-30",
    class: {
      'is-loading': _vm.loading
    },
    attrs: {
      "href": _vm.linkItem
    }
  }, [_c('div', {
    staticClass: "s-catalog-item__image",
    style: !_vm.loading && _vm.linkImage ? "background-image: url(".concat(_vm.linkImage, ");") : ''
  }), _vm._v(" "), _c('div', {
    staticClass: "s-catalog-item__content"
  }, [_c('div', {
    staticClass: "s-catalog-item__head"
  }, [_c('div', {
    staticClass: "s-catalog-item__title"
  }, [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "s-catalog-item__mark"
  }, _vm._l(_vm.marks, function (itm, idx) {
    return _c('div', {
      key: idx,
      class: "s-flag s-b-".concat(itm.color, " s-c-white")
    }, [_vm._v("\n          " + _vm._s(itm.text) + "\n        ")]);
  }), 0)]), _vm._v(" "), _c('div', {
    staticClass: "s-catalog-item__body"
  }, [_c('div', {
    staticClass: "s-catalog-item__body-item mb-10"
  }, [_c('span', {
    staticClass: "s-medium"
  }, [_vm._v(_vm._s(_vm.trans('fields.study_language')) + ": ")]), _vm._v("\n        " + _vm._s(_vm.languages) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "s-catalog-item__body-item mb-10"
  }, [_c('span', {
    staticClass: "s-medium"
  }, [_vm._v(_vm._s(_vm.trans('fields.group_category')) + ":")]), _vm._v("\n        " + _vm._s(_vm.yearsRestriction) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "s-catalog-item__body-item"
  }, [_c('span', {
    staticClass: "s-medium"
  }, [_vm._v(_vm._s(_vm.trans('label.address')) + ":")]), _vm._v("\n        " + _vm._s(_vm.address) + "\n      ")])]), _vm._v(" "), _c('div', {
    staticClass: "s-catalog-item__foot"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('div', {
    staticClass: "s-str-ico s-ico--thm-check-circle s-c-green"
  }, [_c('span', {
    staticClass: "s-medium"
  }, [_vm._v(_vm._s(_vm.trans('label.free')) + ": ")]), _vm._v(" "), _c('span', {
    staticClass: "s-c-blue-dark"
  }, [_vm._v(_vm._s(_vm.availablePlaces))])])]), _vm._v(" "), _c('div', {
    staticClass: "col-auto"
  }, [_c('div', {
    staticClass: "s-str-ico-fa s-ico-fa--thm-user-circle s-c-blue"
  }, [_c('span', {
    staticClass: "s-medium"
  }, [_vm._v(_vm._s(_vm.trans('label.throughput')) + ": ")]), _vm._v(" "), _c('span', {
    staticClass: "s-c-blue-dark"
  }, [_vm._v(_vm._s(_vm.throughputOrg))])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }