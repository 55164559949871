var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.uriGetFilters && !_vm.forceHideFilters ? _c('div', [_c('div', {
    class: _vm.isEnabled.button && !_vm.isEnabled.search ? 's-table-with-filters__between-btns-row' : 's-table-with-filters__search-row'
  }, [_vm.isEnabled.filters ? _c('q-btn', {
    attrs: {
      "outline": !_vm.isActiveFilters,
      "color": _vm.isActiveFilters ? 'orange' : 'blue',
      "no-caps": "",
      "icon-right": _vm.isFiltersActive ? 'chevron-up' : 'chevron-down',
      "label": _vm.trans('label.filters'),
      "use-fa-icons": ""
    },
    on: {
      "click": function click($event) {
        _vm.isFiltersActive = !_vm.isFiltersActive;
      }
    }
  }) : _vm._e(), _vm._v(" "), !_vm.isEnabled.search && _vm.isEnabled.button && _vm.buttonConfig.length ? _vm._l(_vm.buttonConfig, function (btn, key) {
    return _c('q-btn', {
      key: key,
      attrs: {
        "color": btn.color,
        "to": btn.to,
        "disable": btn.disableWithoutData ? _vm.isButtonsDisabled : undefined,
        "icon": btn.icon,
        "label": btn.label,
        "no-caps": "",
        "outline": btn.outline,
        "width": btn.width,
        "type": btn.type
      },
      on: {
        "click": function click($event) {
          return _vm.handleBtn(btn);
        }
      }
    });
  }) : _vm._e(), _vm._v(" "), _vm.isEnabled.search ? _c('q-input', {
    staticClass: "s-table-with-filters__search",
    attrs: {
      "placeholder": _vm.searchPlaceholderComputed
    },
    on: {
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.getItems('default');
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          attrs: {
            "name": "search"
          }
        })];
      },
      proxy: true
    }], null, false, 4009527860),
    model: {
      value: _vm.filtersDefault.search,
      callback: function callback($$v) {
        _vm.$set(_vm.filtersDefault, "search", $$v);
      },
      expression: "filtersDefault.search"
    }
  }) : _vm._e()], 2), _vm._v(" "), _c('q-slide-transition', [_c('q-form', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isFiltersActive,
      expression: "isFiltersActive"
    }],
    staticClass: "mt-30",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "autocorrect": "off",
      "autocapitalize": "off",
      "autocomplete": "off",
      "spellcheck": "false"
    },
    on: {
      "submit": function submit($event) {
        return _vm.getItems('default', true);
      }
    }
  }, [_c('div', {
    staticClass: "row"
  }, _vm._l(_vm.filters, function (filter) {
    return _c('q-slide-transition', {
      key: filter.name
    }, [!_vm.isFieldHidden(filter.name) ? _c(filter.component, _vm._g({
      tag: "component",
      staticClass: "col-lg-4 col-md-4 col-sm-12",
      attrs: {
        "field": filter,
        "field-name": filter.labelTransKey ? _vm.trans(filter.labelTransKey) : filter.label,
        "show-label": filter.showLabel
      },
      model: {
        value: filter.value,
        callback: function callback($$v) {
          _vm.$set(filter, "value", $$v);
        },
        expression: "filter.value"
      }
    }, filter.events ? filter.events : {})) : _vm._e()], 1);
  }), 1), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-end",
    class: _vm.isEnabled.sort ? '' : 'mb-20'
  }, [_vm.uriExport ? _c('div', {
    staticClass: "mb-10 col-lg-3 col-md-4 col-sm-12"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "label": _vm.trans('label.export'),
      "color": "blue",
      "no-caps": "",
      "outline": ""
    },
    on: {
      "click": _vm.handleExport
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "mb-10 col-lg-3 col-md-4 col-sm-12"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "label": _vm.trans('label.clear_filters'),
      "color": "orange",
      "no-caps": "",
      "outline": ""
    },
    on: {
      "click": _vm.resetFilters
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "mb-10 col-lg-3 col-md-4 col-sm-12"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "label": _vm.trans('label.submit'),
      "color": "green",
      "no-caps": "",
      "outline": "",
      "type": "submit"
    },
    on: {
      "click": _vm.applyFilters
    }
  })], 1)])])], 1), _vm._v(" "), _vm.isEnabled.sort || _vm.columnFilter ? _c('div', {
    staticClass: "s-table-with-filters__sort-row",
    class: {
      's-table-with-filters__sort-row--with-col-filter': _vm.columnFilter
    }
  }, [_vm.isEnabled.sort ? _c('div', [_c('span', {
    staticClass: "s-table-with-filters__sort-row--label"
  }, [_vm._v(_vm._s(_vm.trans('label.sort_by')))]), _vm._v(" "), _c('q-select', {
    staticClass: "s-table-with-filters__sort",
    class: _vm.filtersDefault.sort ? 's-table-with-filters__sort-active' : '',
    attrs: {
      "option-value": "id",
      "option-label": "name",
      "hide-dropdown-icon": "",
      "hide-bottom-space": "",
      "map-options": "",
      "emit-value": "",
      "options": _vm.options.sort
    },
    on: {
      "input": function input($event) {
        return _vm.getItems('default');
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          attrs: {
            "name": "sort",
            "icon-class": "s-ico-fa"
          }
        })];
      },
      proxy: true
    }], null, false, 4264071343),
    model: {
      value: _vm.filtersDefault.sort,
      callback: function callback($$v) {
        _vm.$set(_vm.filtersDefault, "sort", $$v);
      },
      expression: "filtersDefault.sort"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.columnFilter ? _c('div', [!_vm.isEnabled.sort || _vm.$q.screen.width < 580 ? _c('span', {
    staticClass: "s-table-with-filters__sort-row--label"
  }, [_vm._v(_vm._s(_vm.trans('label.choose_column_filter')))]) : _vm._e(), _vm._v(" "), _c('q-select', {
    staticClass: "s-table-with-filters__sort",
    attrs: {
      "multiple": "",
      "display-value": _vm.trans('placeholder.choose_column'),
      "emit-value": "",
      "map-options": "",
      "options": _vm.colsOptions,
      "option-value": "name",
      "hide-dropdown-icon": "",
      "hide-bottom-space": ""
    },
    scopedSlots: _vm._u([_vm.isEnabled.sort && _vm.$q.screen.width > 579 ? {
      key: "prepend",
      fn: function fn() {
        return [_c('q-icon', {
          attrs: {
            "name": "sort",
            "icon-class": "s-ico-fa"
          }
        })];
      },
      proxy: true
    } : null, !_vm.isEnabled.sort || _vm.$q.screen.width < 580 ? {
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          attrs: {
            "name": "sort",
            "icon-class": "s-ico-fa"
          }
        })];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm.visibleCols,
      callback: function callback($$v) {
        _vm.visibleCols = $$v;
      },
      expression: "visibleCols"
    }
  })], 1) : _vm._e()]) : _vm._e(), _vm._v(" "), _vm.isEnabled.search && _vm.isEnabled.button && _vm.buttonConfig.length ? _c('div', {
    staticClass: "mt-20 mb-20 s-group s-group--wider justify-content-end"
  }, _vm._l(_vm.buttonConfig, function (btn, key) {
    return _c('q-btn', {
      key: key,
      attrs: {
        "color": btn.color,
        "to": btn.to,
        "disable": btn.disableWithoutData ? _vm.isButtonsDisabled : undefined,
        "icon": btn.icon,
        "label": btn.label,
        "no-caps": "",
        "outline": btn.outline,
        "width": btn.width,
        "type": btn.type
      },
      on: {
        "click": function click($event) {
          return _vm.handleBtn(btn);
        }
      }
    });
  }), 1) : _vm._e(), _vm._v(" "), _vm.isConfirmModalActive ? _c('v-confirm-dialog', {
    attrs: {
      "modal": _vm.isConfirmModalActive,
      "confirm": _vm.handleBtnRequest
    },
    on: {
      "modal-close": _vm.handleCloseConfirmModal
    }
  }) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }