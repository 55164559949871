import {
  QBtn,
} from '@quasar/components';

export default {
  name: 'VStepsBody',

  components: {
    QBtn,
  },

  props: {
    accessPrev: Boolean,
    accessNext: Boolean,
    loading: Boolean,
  },

  data() {
    return {
      direction: 'swipe-left',
    };
  },

  methods: {
    handlePrev() {
      this.direction = 'swipe-right';
      this.$eventHub.$emit('SET_PREV_STEP');
      // this.$store.commit('SET_PREV_STEP');
    },

    handleNext() {
      this.direction = 'swipe-left';
      this.$eventHub.$emit('SET_NEXT_STEP');
      // this.$store.commit('SET_NEXT_STEP');
    },

    // Рендер блока с transition-group и слотом "content"
    createContent(h) {
      return h('transition-group', {
        staticClass: 's-steps-body__content',
        props: {
          tag: 'div',
          name: this.direction,
        },
      }, this.$scopedSlots.content());
    },

    // Рендер блока с col-auto и кнопкой направления
    createBtn(h, iconDirection, iconName, label, color, disable, handle, style, loading) {
      const props = {
        [iconDirection]: iconName,
        label,
        color,
        disable,
      };

      if (loading) {
        props.loading = this.loading;
      }

      return h('div', {
        staticClass: 'col-auto',
        style,
      }, [
        h('q-btn', {
          props,
          on: {
            click: handle,
          },
        }),
      ]);
    },

    // Рендер блока-обёртки с кнопками направления
    createControls(h) {
      return Object.keys(this.$store.getters.getStep()).length !== 0 && h('div', {
        staticClass: 'row justify-content-between',
      }, [
        this.$store.state.vSteps.currentStep !== 1 && this.createBtn(
          h,
          'icon',
          'angle-left',
          this.trans('statement.backward'),
          'grey',
          !this.$store.getters.getStep().backward,
          this.handlePrev,
        ),
        this.createBtn(
          h,
          'icon-right',
          'angle-right',
          this.trans('statement.forward'),
          'green',
          !this.$store.getters.getStep().access,
          this.handleNext,
          'margin-left: auto;',
          true,
        ),
      ]);
    },
  },

  render(h) {
    return h('div', {
      staticClass: `
    s-steps-body-wrapper
    ${this.$store.getters.getStep().step === 1 ? 's-steps-body-wrapper--info' : ''}
  `,
    }, [
      this.createContent(h),
      this.createControls(h),
    ]);
  },
};
