import {
  QBtn,
  QField,
  QForm,
  QInput,
  QOptionGroup,
  QSelect,
  QCheckbox,
} from '@quasar/components';
import { Loading } from '@quasar/plugins';
import VFormInform from '@vjs/components/VFormInform';
import { requestWrapper } from '@vjs/helpers';
import _ from 'lodash';
import VSelectFromList from '../../components/VSelectFromList';

export default {
  name: 'VGroupCreateView',

  components: {
    QForm,
    QInput,
    QSelect,
    QField,
    QOptionGroup,
    QBtn,
    VFormInform,
    VSelectFromList,
    QCheckbox,
  },

  props: {
    uriGetForm: String,
    uriGetFormEdit: String,
    uriRequestData: String,
    editable: Boolean,
    editableLang: Boolean,
    editableLimit: {
      type: Boolean,
      default: true,
    },
    overLimitHide: {
      type: Boolean,
      default: false,
    },
    minCorrectionAge: {
      type: Number,
      default: () => 3,
    },
  },

  data() {
    return {
      qLoading: Loading,
      loading: false,
      lockGroupType: false,
      groupTypeSelected: {
        value: 0,
      },
      groupTypeOptions: [],
      diseasesSelected: [],
      sanatoriumSelected: [],
      diseasesOptions: [],
      sanatoriumOptions: [],
      groupAgeSelected: [],
      groupAgeOptions: [],
      groupName: '',
      workSixDays: 0,
      isReserve: 0,
      stayModeTypeSelected: '',
      stayModeTypeOptions: [],
      eduLangSelected: '',
      eduLangOptions: [],
      groupLimit: '',
      groupOverLimit: 0,
      personnelIdSelected: [],
      personnelOptions: [],
      orgType: '',
      canCreateReserve: 0,
      disableReserve: 0,
      personnelRequired: true,
    };
  },

  async mounted() {
    await this.$trans.add([
      'label',
      'fields',
      'group',
      'title',
      'button',
      'warning_text',
    ]);
    this.qLoading.show({ message: this.trans('label.wait_loading') });

    await this.requestForm();

    if (this.editable) {
      await this.requestFormEdit();
    }
    this.qLoading.hide();
  },

  watch: {
    'groupTypeSelected.value': function (val) {
      // Если нет списка коррекций, то вывести уведомление
      if ((val === 2 && val === 3) && this.diseasesOptions.length === 0) {
        this.$notify({
          type: 'warn',
          title: this.trans('notice.message'),
          text: this.trans('warning_text.no_diseases_available'),
        });
      }
      if ((val === 4 && val === 5) && this.sanatoriumOptions.length === 0) {
        this.$notify({
          type: 'warn',
          title: this.trans('notice.message'),
          text: this.trans('warning_text.no_sanatorium_available'),
        });
      }
    },
  },

  computed: {
    isCorrection() {
      return [2, 3].includes(this.groupTypeSelected.value);
    },
    isSanatorium() {
      return [4, 5].includes(this.groupTypeSelected.value);
    },
    isCombined() {
      return [3, 5].includes(this.groupTypeSelected.value);
    },
    submitEnable() {
      let status = true;
      let personnel = true;

      if (this.personnelRequired) {
        personnel = this.personnelIdSelected.length > 0;
      }

      if (this.isCorrection) {
        status = this.diseasesSelected.length > 0;
      }
      if (this.isSanatorium) {
        status = this.sanatoriumSelected.length > 0;
      }

      return status
        && this.groupTypeSelected.value > 0
        && this.groupAgeSelected.length > 0
        && this.groupName !== ''
        && this.stayModeTypeSelected
        && this.eduLangSelected !== ''
        && this.groupLimit !== ''
        && (this.overLimitHide === true || (this.overLimitHide === false && this.groupOverLimit !== '' && this.groupOverLimit <= 5 && this.groupOverLimit >= 0))
        && personnel;
    },
    groupAgeList() {
      if (this.isCorrection) {
        return this.groupAgeOptions.filter(
          item => ((item.value >= this.minCorrectionAge)
            && (this.isCombined ? item.value < 7 : true)),
        );
      }

      if (this.isSanatorium) {
        return this.groupAgeOptions.filter(item => item.value > 0);
      }

      return this.groupAgeOptions.filter(item => item.value < 7);
    },
  },

  methods: {
    async requestForm() {
      const res = await requestWrapper.call(this, {
        url: this.uriGetForm,
      });

      if (!res.error) {
        this.groupTypeOptions = res.data.correction_list;
        this.diseasesOptions = res.data.diseases;
        this.sanatoriumOptions = res.data.sanatorium;
        this.groupAgeOptions = res.data.groups;
        this.stayModeTypeOptions = res.data.mode;
        this.eduLangOptions = res.data.lang;
        this.personnelOptions = res.data.personnel;
        this.orgType = res.data.organization_type;
        this.canCreateReserve = res.data.can_create_reserve;
        this.personnelRequired = res.data.personnelRequired;
      }
    },
    async requestFormEdit() {
      const res = await requestWrapper.call(this, {
        url: this.uriGetFormEdit,
      });

      if (!res.error) {
        this.groupTypeSelected = this.groupTypeOptions.find(
          item => item.value === res.data.correction_type,
        );

        if (this.isCorrection) {
          this.diseasesSelected = res.data.diseasesCurrent;
        }

        if (this.isSanatorium) {
          this.sanatoriumSelected = res.data.sanatoriumCurrent;
        }

        // Если не комбинированная, то нельзя изменить при редактировании
        if (res.data.correction_type !== 3 || res.data.correction_type !== 5) {
          this.lockGroupType = true;
        }

        this.groupAgeSelected = res.data.ages;
        this.eduLangSelected = res.data.edu_lang;
        this.groupName = res.data.groupName;
        this.workSixDays = res.data.workSixDays;
        this.isReserve = res.data.isReserve;
        this.disableReserve = res.data.disable_reserve;
        this.groupLimit = res.data.limit;
        this.groupOverLimit = res.data.overLimit;
        this.stayModeTypeSelected = res.data.stay_mode_type;
        this.personnelIdSelected = res.data.personnel;
        this.personnelRequired = res.data.personnelRequired;
      }
    },
    async requestData() {
      this.qLoading.show();
      this.loading = true;
      const formData = new FormData();

      if (this.groupAgeSelected.length > 0) {
        this.groupAgeSelected.forEach(
          item => formData.append('age[]', item),
        );
      }

      if (this.editable) {
        formData.append('_method', 'patch');
      }

      formData.append(
        'group[correction_type]',
        this.groupTypeSelected.value,
      );
      formData.append('group[group_name]', this.groupName);
      formData.append('group[work_six_days]', this.workSixDays);
      formData.append('group[is_reserve]', this.isReserve);
      formData.append(
        'group[stay_mode_type]',
        this.stayModeTypeSelected,
      );
      formData.append('group[edu_lang]', this.eduLangSelected);
      formData.append('group[limit]', this.groupLimit);
      formData.append('group[over_limit]', this.groupOverLimit);
      formData.append('group[personnel_required]', this.personnelRequired);

      if (this.personnelIdSelected.length > 0) {
        this.personnelIdSelected.forEach(item => formData
          .append('group[educators][]', item));
      }

      if (this.isCorrection) {
        this.diseasesSelected.forEach(item => formData
          .append('disease[]', item));
      }
      if (this.isSanatorium) {
        this.sanatoriumSelected.forEach(item => formData
          .append('sanatorium[]', item));
      }

      // eslint-disable-next-line no-unused-vars
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriRequestData,
        data: formData,
      });
      if (res.error) {
        if (res.errorType !== 302) {
          this.qLoading.hide();
          this.loading = false;
        }
        if (
          res.errorType === 422
          && typeof res.data !== 'undefined'
          && typeof res.data.errors !== 'undefined'
        ) {
          const allFails = res.data.errors;
          const messages = Object.keys(allFails).map(key => allFails[key]);
          if (
            messages.length
            && typeof messages[0] !== 'undefined'
            && messages[0].length
            && typeof messages[0][0] !== 'undefined'
          ) {
            this.$notify({
              type: 'error',
              text: messages[0][0],
            });
          }
        }
      }
    },
    handleChangeOptions(list) {
      this.personnelIdSelected = _.uniq(list.map(item => item.value));
    },
  },
};
