var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-field', {
    attrs: {
      "field": _vm.field,
      "field-name": _vm.fieldName,
      "errors": _vm.errors
    }
  }, [_c('template', {
    slot: "field"
  }, [_c('div', {
    staticClass: "camera-button"
  }, [!_vm.isConfirmed ? _c('button', {
    staticClass: "button button_type_add button_theme_blue",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.openModal();
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.trans('button.make_photo')) + "\n      ")]) : _vm._e(), _vm._v(" "), _vm.isConfirmed ? _c('div', [_c('button', {
    staticClass: "button button_theme_green",
    attrs: {
      "type": "button"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.trans('button.photo_success')) + "\n        ")])]) : _vm._e()]), _vm._v(" "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.localValue,
      expression: "localValue"
    }],
    staticClass: "s-form__input",
    attrs: {
      "type": "hidden",
      "name": _vm.field.name
    },
    domProps: {
      "value": _vm.localValue
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.localValue = $event.target.value;
      }
    }
  })])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }