var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('form', {
    staticClass: "row-sort-wrap",
    attrs: {
      "method": "get"
    }
  }, [_c('div', {
    staticClass: "row align-items-end mb-10"
  }, [_c('div', {
    staticClass: "col-md-4 col-sm-6"
  }, [_c('q-select', {
    attrs: {
      "filled": "",
      "options": _vm.localityOptions,
      "label": _vm.trans('label.locality'),
      "emit-value": "",
      "map-options": ""
    },
    on: {
      "input": _vm.requestGetData
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('q-icon', {
          attrs: {
            "name": "place"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "name": "close"
          },
          on: {
            "click": function click($event) {
              _vm.form.locality = '';
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.locality,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "locality", $$v);
      },
      expression: "form.locality"
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "row align-items-stretch mb-10  justify-content-end"
  }, [_c('div', {
    staticClass: "col-sm-auto mb-10"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "no-caps": "",
      "label": _vm.trans('button.flash_filter'),
      "color": "orange"
    },
    on: {
      "click": _vm.clearFilters
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "color": "green",
      "no-caps": "",
      "label": _vm.trans('button.export'),
      "loading": _vm.loading
    },
    on: {
      "click": _vm.exportu
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "row align-items-stretch mb-10  justify-content-end"
  }, [_c('div', {
    staticClass: "col-sm-auto"
  }, [_c('q-input', {
    attrs: {
      "dense": "",
      "debounce": "300",
      "placeholder": _vm.trans('placeholder.search')
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }, [_c('q-icon', {
    attrs: {
      "slot": "append",
      "name": "search"
    },
    slot: "append"
  })], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "q-ml-md s-pos-float-right mt-10",
    attrs: {
      "squire": "",
      "unelevated": "",
      "icon": "fullscreen",
      "color": "grey"
    },
    on: {
      "click": function click($event) {
        _vm.table.inFullscreen = true;
      }
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.fullscreen_on')))])], 1)], 1)])]), _vm._v(" "), _c('div', {
    staticClass: "statistiks-table"
  }, [_c('q-table', {
    attrs: {
      "data": _vm.rows,
      "columns": _vm.table.columns,
      "pagination": _vm.pagination,
      "row-key": "name",
      "separator": "cell",
      "filter": _vm.filter,
      "fullscreen": _vm.table.inFullscreen
    },
    on: {
      "update:pagination": function updatePagination($event) {
        _vm.pagination = $event;
      }
    },
    scopedSlots: _vm._u([_vm.table.inFullscreen ? {
      key: "top",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "row align-items-stretch justify-content-end s-w-100"
        }, [_c('div', {
          staticClass: "col-sm-auto"
        }, [_c('q-input', {
          attrs: {
            "dense": "",
            "debounce": "300",
            "placeholder": _vm.trans('placeholder.search')
          },
          model: {
            value: _vm.filter,
            callback: function callback($$v) {
              _vm.filter = $$v;
            },
            expression: "filter"
          }
        }, [_c('q-icon', {
          attrs: {
            "slot": "append",
            "name": "search"
          },
          slot: "append"
        })], 1)], 1), _vm._v(" "), _c('div', {
          staticClass: "col-sm-auto"
        }, [_c('q-btn', {
          staticClass: "q-ml-md s-pos-float-right mt-10",
          attrs: {
            "squire": "",
            "unelevated": "",
            "icon": "fullscreen",
            "color": "blue"
          },
          on: {
            "click": function click($event) {
              _vm.table.inFullscreen = false;
            }
          }
        }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.fullscreen_off')))])], 1)], 1)])];
      }
    } : null, {
      key: "header",
      fn: function fn(props) {
        return [_c('q-tr', [_c('th', {
          staticClass: "sn--text-center",
          attrs: {
            "colspan": "100%"
          }
        }, [_vm._v("\n            " + _vm._s(_vm.trans('statistics.contingent.table_name')) + "\n          ")])]), _vm._v(" "), _c('q-tr', [_c('th'), _vm._v(" "), _c('th'), _vm._v(" "), _c('th', {
          staticClass: "sn--text-center",
          attrs: {
            "colspan": "8"
          }
        }, [_vm._v("\n            " + _vm._s(_vm.trans('statistics.contingent.table_name')) + "\n          ")]), _vm._v(" "), _c('th', {
          staticClass: "sn--text-center",
          attrs: {
            "colspan": "56"
          }
        }, [_vm._v("\n            " + _vm._s(_vm.trans('statistics.contingent.details')) + "\n          ")])]), _vm._v(" "), _c('q-tr', [_c('th'), _vm._v(" "), _c('th'), _vm._v(" "), _c('th', {
          attrs: {
            "colspan": "8"
          }
        }), _vm._v(" "), _c('th', {
          staticStyle: {
            "width": "105px",
            "white-space": "normal"
          },
          attrs: {
            "colspan": "8"
          }
        }, [_vm._v("\n            " + _vm._s(_vm.trans('statistics.socStatuses.army_kids')) + "\n          ")]), _vm._v(" "), _c('th', {
          staticStyle: {
            "width": "105px",
            "white-space": "normal"
          },
          attrs: {
            "colspan": "8"
          }
        }, [_vm._v("\n            " + _vm._s(_vm.trans('statistics.socStatuses.noparents_kids')) + "\n          ")]), _vm._v(" "), _c('th', {
          staticStyle: {
            "width": "105px",
            "white-space": "normal"
          },
          attrs: {
            "colspan": "8"
          }
        }, [_vm._v("\n            " + _vm._s(_vm.trans('statistics.socStatuses.disabled_kids')) + "\n          ")]), _vm._v(" "), _c('th', {
          staticStyle: {
            "width": "105px",
            "white-space": "normal"
          },
          attrs: {
            "colspan": "8"
          }
        }, [_vm._v("\n            " + _vm._s(_vm.trans('statistics.socStatuses.lots_kids')) + "\n          ")]), _vm._v(" "), _c('th', {
          staticStyle: {
            "width": "105px",
            "white-space": "normal"
          },
          attrs: {
            "colspan": "8"
          }
        }, [_vm._v("\n            " + _vm._s(_vm.trans('statistics.socStatuses.batman_kids')) + "\n          ")]), _vm._v(" "), _c('th', {
          staticStyle: {
            "width": "105px",
            "white-space": "normal"
          },
          attrs: {
            "colspan": "8"
          }
        }, [_vm._v("\n            " + _vm._s(_vm.trans('statistics.socStatuses.employee_kids')) + "\n          ")]), _vm._v(" "), _c('th', {
          staticStyle: {
            "width": "105px",
            "white-space": "normal"
          },
          attrs: {
            "colspan": "8"
          }
        }, [_vm._v("\n            " + _vm._s(_vm.trans('statistics.socStatuses.no_lgot')) + "\n          ")])]), _vm._v(" "), _c('q-tr', {
          attrs: {
            "props": props
          }
        }, _vm._l(props.cols, function (col) {
          return _c('q-th', {
            key: col.name,
            attrs: {
              "props": props
            }
          }, [_vm._v("\n            " + _vm._s(_vm.trans(col.label)) + "\n          ")]);
        }), 1)];
      }
    }, {
      key: "body",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, [_vm._l(props.cols, function (col, i) {
          return [i !== 'id' && i !== '__index' ? _c('q-td', {
            key: i
          }, [_vm._v("\n              " + _vm._s(col.value) + "\n            ")]) : _vm._e()];
        })], 2)];
      }
    }, {
      key: "bottom-row",
      fn: function fn() {
        return [_c('q-tr', {
          key: "total"
        }, [_c('q-td', [_c('b', [_vm._v(_vm._s(_vm.trans('statistics.contingent.total')))])]), _vm._v(" "), _c('q-td', [_c('b', [_vm._v(_vm._s(_vm.table.totalContingent))])]), _vm._v(" "), _vm._l(_vm.table.colNames, function (item, i) {
          return _c('q-td', {
            key: i
          }, [_vm._v("\n            " + _vm._s(_vm.sum(item)) + "\n          ")]);
        })], 2)];
      },
      proxy: true
    }], null, true)
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }