<template>
      <q-form
        class="v-edit-group-limit-dialog"
        @submit="requestAction"
      >
        <q-input
          v-model="limit"
          :label="trans(`table.throughput`)"
          :error="hasValidationFail('limit')"
          :error-message="getValidationMessage('limit')"
          @input="flashValidationFail('limit')"
        />
        <q-input
          v-model="over_limit"
          :label="trans(`table.throughput_over`)"
          class="mb-30"
          :error="hasValidationFail('over_limit')"
          :error-message="getValidationMessage('over_limit')"
          @input="flashValidationFail('over_limit')"
        />
        <div class="s-group s-group--wider justify-content-between">
          <q-btn
            width="20"
            no-caps
            color="grey"
            :label="trans('button.close')"
            @click="$emit('onClose')"
          />
          <q-btn
            type="submit"
            width="20"
            no-caps
            color="orange"
            :label="trans('button.save')"
          />
        </div>
      </q-form>
</template>

<style lang="scss">
.v-edit-group-limit-dialog {
  padding: 2.5rem;

  .sn-textarea .sn-field__native {
    height: 8rem;
  }
  .sn-checkbox {
    align-items: center;
    &__inner {
      margin-right: 1rem;
    }

    &__bg {
      width: 2.7rem;
      height: 2.7rem;
    }

    &__label {
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
    }
  }

  &__title {
    font-weight: 300;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #212545;
    margin-bottom: 1.5rem;
  }
}
@media screen and (max-width: 530px) {
  .v-edit-group-limit-dialog {
    padding: unset;
  }
}
</style>

<script>
import {
  QBtn,
  QForm,
  QInput,
} from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import Validation from '@vjs/mixins/Validation';

export default {
  name: 'VEditGroupLimitDialog',
  components: {
    QBtn,
    QForm,
    QInput,
  },
  mixins: [Validation],
  props: {
    row: {
      type: Object,
      required: true,
    },
    uriAction: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      limit: this.row.limit,
      over_limit: this.row.over_limit,
    };
  },
  methods: {
    async requestAction() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.uriAction,
        method: 'post',
        data: {
          limit: this.limit,
          over_limit: this.over_limit,
        },
      });

      if (!res.error) {
        this.row.limit = res.newLimit;
        this.row.over_limit = res.newOverLimit;
        this.$emit('onClose');
      }

      this.$globalLoading.hide();
    },
  },
};
</script>
