export default class AttendanceGroupsApiProvider {
  constructor({ RefreshRepository }, trans) {
    this.RefreshRepository = RefreshRepository;
    this.trans = trans;
  }

  provideRefresh() {
    const { RefreshRepository, trans } = this;
    // eslint-disable-next-line func-names
    return async function (date, kindergartenId = null) {
      try {
        const response = await RefreshRepository.refresh({ date, kindergartenId });
        const { data: { status, data } } = response;
        if (status === 'ok') {
          return data;
        }
      } catch (error) {
        this.$notify({
          type: 'error',
          text: trans('notice.error_on_server'),
        });
      }
      return false;
    };
  }

}
