var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('q-dialog', {
    attrs: {
      "persistent": ""
    },
    on: {
      "hide": _vm.onModalClose
    },
    model: {
      value: _vm.modalWarningRoles,
      callback: function callback($$v) {
        _vm.modalWarningRoles = $$v;
      },
      expression: "modalWarningRoles"
    }
  }, [_c('v-modal', [_c('div', {
    staticClass: "s-plain-text"
  }, [_vm.type === 'user' ? [_c('p', {
    staticClass: "s-to-center",
    domProps: {
      "innerHTML": _vm._s(_vm.promoText)
    }
  })] : _vm._e()], 2)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }