import {
  requestWrapper,
} from '@vjs/helpers';
import {
  QForm,
  QInput,
  QBtn,
  QSelect,
  QField,
  QRadio,
} from '@quasar/components';
import VFormInform from '@vjs/components/VFormInform';
import VReferenceTime from '@vjs/components/VReferenceTime';
import Validation from '../mixins/Validation';

export default {
  name: 'VStepUserinfo',

  components: {
    VFormInform,
    VReferenceTime,
    QForm,
    QInput,
    QBtn,
    QSelect,
    QField,
    QRadio,
  },

  mixins: [Validation],

  props: {
    uriGetProfile: {
      type: String,
      required: true,
    },
    uriSendProfileUpdate: {
      type: String,
      required: true,
    },
    uriSendPassword: {
      type: String,
      required: true,
    },
    uriSendIin: {
      type: String,
      required: true,
    },
    uriNationalities: {
      type: String,
      required: true,
    },
    referenceTime: Number,
    dataFields: {
      type: Object,
      default: () => ({
        fromRegistration: true,
        phone: '',
        password: '',
        code: '',
      }),
    },
  },

  data() {
    return {
      access: false,
      loading: false,
      iinAfterRequest: null,
      profileExists: false,
      form: {
        iin: null,
        lastname: '',
        firstname: '',
        middlename: '',
        phone: '',
        additionalPhone: '',
        email: '',
        birthday: '',
        address: '',
        nationalityCurrent: {
          code: null,
        },
        nationalityOptions: [],
        nationalityOptionsFiltered: [],
        genderCurrent: null,
        genderOptions: [
          {
            value: 'm',
            label: 'label.male',
          },
          {
            value: 'w',
            label: 'label.female',
          },
        ],
        langCurrent: 'kz',
        langOptions: [
          {
            value: 'kz',
            label: 'label.lang.kz',
          },
          {
            value: 'ru',
            label: 'label.lang.ru',
          },
        ],
      },
      validation: {},
    };
  },

  beforeCreate() {
    this.$trans.add(['label', 'placeholder', 'validation', 'notice']);
  },

  created() {
    this.$eventHub.$on('SET_NEXT_STEP', this.requestForm);
  },

  beforeDestroy() {
    this.$eventHub.$off('SET_NEXT_STEP', this.requestForm);
  },

  async mounted() {
    this.loading = true;
    this.form = { ...this.form, ...this.dataFields };

    if (!this.dataFields.fromRegistration) {
      const profileRes = await requestWrapper.call(this, {
        url: this.uriGetProfile,
      });

      if (!profileRes.error && profileRes.iin) {
        this.profileExists = true;
        this.iinAfterRequest = profileRes.iin;
        this.access = true;
        this.form = Object.assign(this.form, profileRes);
      }
    }

    const natRes = await requestWrapper.call(this, {
      url: this.uriNationalities,
    });

    if (!natRes.error) {
      this.form.nationalityOptions = this.form.nationalityOptionsFiltered = natRes.data;
    }

    this.loading = false;
  },

  computed: {
    hasValidationFail() {
      const { validation } = this;
      return field => Array.isArray(validation[field]) && validation[field].length > 0;
    },
    checkIinDisable() {
      return !this.validateIin(this.form.iin);
    },
    validate() {
      return this.form.genderCurrent !== null
              && this.form.address !== ''
              && this.validateIin(this.form.iin)
              && this.form.firstname !== ''
              && this.form.lastname !== ''
              && this.form.phone !== ''
              && this.form.password !== '';
    },
  },

  watch: {
    validate(val) {
      this.$store.commit('SET_ACCESS_STEP', {
        step: 3,
        access: val,
      });
    },
    'form.iin': function (val) {
      if (this.iinAfterRequest !== val) {
        this.resetFields();
      }
    },
    'form.email': function(val) {
      this.form.email = val.trim();
    },
  },

  methods: {
    filterNationality(val, update) {
      const { nationalityOptions } = this.form;

      if (val === '') {
        update(() => {
          this.form.nationalityOptionsFiltered = nationalityOptions;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.form.nationalityOptionsFiltered = nationalityOptions.filter(v => v.label.toLowerCase().indexOf(needle) > -1);
      });
    },
    resetFields() {
      this.form.firstname = '';
      this.form.lastname = '';
      this.form.middlename = '';
      this.form.birthday = '';
    },
    async requestForCheckIin() {
      this.loading = true;
      const iinRes = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriSendIin,
        data: {
          iin: this.form.iin,
        },
      });

      if (iinRes.error) {
        this.resetFields();
        this.loading = false;
        return;
      }

      this.access = true;
      this.iinAfterRequest = this.form.iin;
      this.form.firstname = iinRes.name;
      this.form.lastname = iinRes.surname;
      this.form.middlename = iinRes.lastname;
      this.form.birthday = iinRes.born_date;
      this.form.genderCurrent = iinRes.sex;
      this.loading = false;
    },

    async requestForm() {
      this.$emit('loading', true);
      const sendFormRes = await requestWrapper.call(this, {
        method: 'post',
        url: this.profileExists ? this.uriSendProfileUpdate : this.uriSendPassword,
        data: {
          iin: this.form.iin,
          phone: this.form.phone,
          password: this.form.password,
          code: this.form.code,
          firstname: this.form.firstname,
          surname: this.form.lastname,
          lastname: this.form.middlename,
          born_date: this.form.birthday,
          sex: this.form.genderCurrent,
          email: this.form.email,
          additional_phone: this.form.additionalPhone,
          address: this.form.address,
          lang: this.form.langCurrent,
          nationality_id: this.form.nationalityCurrent.code,
        },
      });

      if (sendFormRes.error) {
        this.$emit('loading', false);
        return false;
      }

      this.$emit('loading', false);
      this.$store.commit('SET_NEXT_STEP');
      return true;
    },
  },
};
