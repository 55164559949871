var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-field', {
    attrs: {
      "field": _vm.field,
      "field-name": _vm.fieldName,
      "errors": _vm.errors
    }
  }, [_c('template', {
    slot: "field"
  }, [_c('div', {
    staticClass: "form-combine-checkbox"
  }, [_vm._l(_vm.field.values, function (item, index) {
    return [_c('input', _vm._b({
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.localValue,
        expression: "localValue"
      }],
      staticClass: "s-form__radio",
      attrs: {
        "id": _vm.field.name + '-' + index,
        "type": "radio"
      },
      domProps: {
        "value": item.value,
        "checked": _vm._q(_vm.localValue, item.value)
      },
      on: {
        "change": function change($event) {
          _vm.localValue = item.value;
        }
      }
    }, 'input', _vm.extraAttributes, false)), _vm._v(" "), _c('label', {
      staticClass: "mr-20",
      attrs: {
        "for": _vm.field.name + '-' + index
      }
    }, [_vm._v(_vm._s(_vm.fieldLabel(item)))])];
  })], 2)])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }