<template>
  <q-dialog
    :value="modal"
    @input="handleModal"
  >
    <v-modal
      :title="trans('label.doc_extend_direction')"
      size="medium"
    >
      <div class="s-plain-text">
        <p class="s-medium">{{ trans('label.extend_direction_description') }}</p>
        <p class="s-medium">{{ trans('label.docs_criteries') }}:</p>
        <ul class="mb-30">
          <li>{{ trans('label.file_formats') }};</li>
          <li>{{ trans('label.file_size') }};</li>
          <li>{{ trans('label.file_dimensions') }};</li>
        </ul>
      </div>
      <q-input
        type="file"
        v-model="file"
        class="mb-20"
      />
      <div class="row justify-content-end">
        <div class="col col-sm-auto">
          <q-btn
            width="20"
            :label="trans('button.cancel')"
            labelWider
            color="grey"
            v-close-popup
          />
        </div>
        <div class="col col-sm-auto">
          <q-btn
            width="20"
            :label="trans('button.send')"
            color="green"
            :loading="loading"
            :disable="!file"
            @click="send"
          />
        </div>
      </div>
    </v-modal>
  </q-dialog>
</template>

<script>
import {
  QDialog,
  QInput,
  QBtn,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import { ClosePopup } from '@quasar/directives';
import VModal from '@vjs/components/VModal';

export default {
  name: 'VApplicationItemModal',
  components: {
    QDialog,
    QInput,
    QBtn,
    VModal,
  },
  directives: { ClosePopup },
  props: {
    modal: Boolean,
    uriStatementContinue: {
      type: String,
      required: true,
    },
    identifier: {
      type: Number,
      required: true,
    },
    success: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      file: null,
      loading: false,
    };
  },
  beforeCreate() {
    this.$trans.add(['label', 'button']);
  },
  methods: {
    handleModal() {
      this.$emit('modal-close', false);
    },
    async send() {
      this.loading = true;
      const formData = new FormData();
      formData.append('upload_file', this.file[0]);
      formData.append('type', 7);
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriStatementContinue.replace('{id}', this.identifier),
        data: formData,
      });

      if (!res.error) {
        this.success();
        this.handleModal();
      }

      this.loading = false;
    },
  },
};
</script>
