var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-dialog', {
    attrs: {
      "persistent": "",
      "value": _vm.modal
    },
    on: {
      "input": _vm.handleModal
    }
  }, [_c('v-modal', {
    attrs: {
      "title": _vm.trans('notice.confirm'),
      "size": "700",
      "in-body": ""
    }
  }, [_c('v-form-inform', {
    attrs: {
      "color": "red",
      "message": _vm.informMessage
    }
  }), _vm._v(" "), _c('q-form', {
    staticClass: "v-statement-contract-revision-modal",
    on: {
      "submit": _vm.requestAction
    }
  }, [_c('div', {
    staticClass: "v-statement-contract-revision-modal__title"
  }, [_vm._v("\n        " + _vm._s(_vm.trans('label.statement_contract.select_files')) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "mb-20"
  }, _vm._l(_vm.docsTypes, function (docType, key) {
    return _c('q-checkbox', {
      key: key,
      staticClass: "s-w-100",
      attrs: {
        "val": docType,
        "label": _vm.trans("label.statement_contract.files.".concat(docType))
      },
      model: {
        value: _vm.form.selectedDocs,
        callback: function callback($$v) {
          _vm.$set(_vm.form, "selectedDocs", $$v);
        },
        expression: "form.selectedDocs"
      }
    });
  }), 1), _vm._v(" "), _c('q-input', {
    staticClass: "mb-30",
    attrs: {
      "label": _vm.trans("label.statement_contract.comment"),
      "filled": "",
      "type": "textarea",
      "required": "",
      "counter": "",
      "maxlength": 1000,
      "error": _vm.hasValidationFail('comment'),
      "error-message": _vm.getValidationMessage('comment')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('comment');
      }
    },
    model: {
      value: _vm.form.comment,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "comment", $$v);
      },
      expression: "form.comment"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "s-group s-group--wider justify-content-between"
  }, [_c('q-btn', {
    attrs: {
      "width": "20",
      "no-caps": "",
      "color": "grey",
      "label": _vm.trans('button.close')
    },
    on: {
      "click": _vm.handleModal
    }
  }), _vm._v(" "), _c('q-btn', {
    attrs: {
      "type": "submit",
      "width": "20",
      "no-caps": "",
      "color": "orange",
      "label": _vm.trans('button.send_to_revision')
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }