var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-field', {
    attrs: {
      "field": _vm.field,
      "field-name": _vm.fieldName,
      "errors": _vm.errors,
      "label-class": ''
    }
  }, [_c('template', {
    slot: "field"
  }, [_c('q-input', _vm._b({
    attrs: {
      "filled": "",
      "mask": "##:##",
      "date": ""
    },
    on: {
      "click": function click() {
        return _vm.$refs.qTimeFieldActiveProxy.show();
      }
    },
    scopedSlots: _vm._u([{
      key: "before",
      fn: function fn() {
        return [_c('q-popup-proxy', {
          ref: "qTimeFieldActiveProxy",
          attrs: {
            "transition-show": "scale",
            "transition-hide": "scale"
          }
        }, [_c('q-time', {
          attrs: {
            "color": "blue",
            "mask": "HH:mm",
            "now-btn": "",
            "format24h": "",
            "minute-options": [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]
          },
          on: {
            "input": function input() {
              return _vm.$refs.qTimeFieldActiveProxy.hide();
            }
          },
          model: {
            value: _vm.localValue,
            callback: function callback($$v) {
              _vm.localValue = $$v;
            },
            expression: "localValue"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "sn--cursor-pointer",
          attrs: {
            "name": "clock"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.localValue,
      callback: function callback($$v) {
        _vm.localValue = $$v;
      },
      expression: "localValue"
    }
  }, 'q-input', _vm.extraAttributes, false))], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }