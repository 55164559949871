import {
  QInput,
  QBtn,
} from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import { Loading } from '@quasar/plugins';
import VFormInform from '@vjs/components/VFormInform';
import VConfirmDialog from '@vjs/modals/VConfirmDialog';
import VStatementContractRevisionModal from './VStatementContractRevisionModal';

export default {
  name: 'VStatementContractShow',
  components: {
    QInput,
    QBtn,
    VStatementContractRevisionModal,
    VFormInform,
    VConfirmDialog,
  },
  props: {
    apiConfig: {
      type: Object,
      default: () => ({
        editUrl: null,
        signApiUrl: null,
        submitUrl: null,
        downloadApiUrl: null,
        getData: null,
        staticUrl: null,
        sendRevision: null,
        editFilesUrl: null,
        deleteContract: null,
      }),
    },
    additionalConfig: {
      type: Object,
      default: {
        statementExpiredDate: null,
        editTriesLeft: null,
      },
    },
    contractName: String,
    forCorrection: Boolean,
  },
  data() {
    return {
      table: [],
      qLoading: Loading,
      revisionModal: false,
      isConfirmModalOpen: false,
    };
  },

  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'statement',
      'warning_text',
    ]);
    await this.requestGetData();
  },

  methods: {
    async requestDeleteContract() {
      this.handleLoading();
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.apiConfig.deleteContract,
      });

      if (res.error) {
        this.handleLoading(false);
      }
    },
    handleLoading(value = true) {
      if (value) {
        this.qLoading.show({ message: this.trans('label.wait_for_load_data') });
      } else {
        this.qLoading.hide();
      }
    },
    async requestGetData() {
      this.handleLoading();
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.getData,
      });

      if (!res.error) {
        this.table = res.data;
      }
      this.handleLoading(false);
    },
    async requestAction() {
      this.qLoading.show({ message: this.trans('label.wait_for_load_data') });
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.submitUrl,
        method: 'post',
        data: { ...this.form, language: this.language },
      });

      if (res.error) {
        this.qLoading.hide();
      }
    },
    async handleSendBtn() {
      if (this.apiConfig.submitUrl && !this.apiConfig.signApiUrl) {
        await this.requestAction();
      }
      if (this.apiConfig.signApiUrl && !this.apiConfig.submitUrl) {
        await this.sign();
      }
    },
    async sign() {
      this.handleLoading();
      const webSocket = new WebSocket('wss://127.0.0.1:13579/');
      let callback = null;
      const xmlToSign = '<status>Sign</status>';
      if ([webSocket.CLOSED, webSocket.CLOSING].includes(webSocket.readyState)) {
        this.$notify({
          text: this.trans('notice.ncalayer_connection_error'),
          type: 'error',
        });
        // Ошибка при подключении к NCALayer. Перезапустите программу и обновите страницу.
        return;
      }
      webSocket.onopen = (event) => {
        callback = async (response) => {
          const signXml = response.responseObject;
          const res = await requestWrapper.call(this, {
            url: this.apiConfig.signApiUrl,
            method: 'post',
            data: { signXml },
          });
          if (res.error) {
            this.handleLoading(false);
          }
        };
        webSocket.send(JSON.stringify(
          {
            id: 1,
            module: 'kz.gov.pki.knca.commonUtils',
            method: 'signXml',
            args: ['PKCS12', 'SIGNATURE', xmlToSign, '', ''],
          },
        ));
      };

      webSocket.onclose = (event) => {
        if (event.wasClean) {
          console.warn('connection has been closed');
        } else {
          this.$notify({
            text: this.trans('notice.ncalayer_connection_closed'),
            type: 'error',
          });
          // Соединение с NCALayer было прервано. Попробуйте авторизоваться еще раз или обновить страницу.
          this.handleLoading(false);
        }
      };

      webSocket.onmessage = (event) => {
        const result = JSON.parse(event.data);
        if (result != null) {
          if (result?.code === '200') {
            if (typeof callback === 'function') {
              callback(result);
            }
          } else if (result?.code === '500') {
            this.handleLoading(false);
          }
        }
      };
    },
  },
};
